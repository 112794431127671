/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, numericality } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getDataUpdatingLicense, updateLicense } from 'api/licenses'

import withStyles from '@material-ui/core/styles/withStyles'

const LicenseUpdate = ({ classes, match }) => (
  <React.Fragment>
    <Query query={getDataUpdatingLicense} variables={match.params}>
      {({ data: { license } = {}, loading }) =>
        !loading && (
          <FormDialog
            title={license.name}
            form="LicenseUpdate"
            mutation={[updateLicense]}
            initialValues={license}
            success="License updated successfully"
          >
            {({ doc, invalid, submitting }) => (
              <React.Fragment>
                <Field name="name" component={TextField} label="License Name" validate={[required()]} />
                <Field
                  name="quantity"
                  type="number"
                  component={TextField}
                  label="Number of Licenses"
                  validate={[required(), numericality()]}
                  normalize={number}
                />
              </React.Fragment>
            )}
          </FormDialog>
        )
      }
    </Query>
  </React.Fragment>
)

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(LicenseUpdate)
