/** @format */

import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import { Provider as StoreProvider } from 'react-redux'
import { BrowserRouter as RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'

import * as serviceWorker from './serviceWorker'

import CssBaseline from '@material-ui/core/CssBaseline'

// CSS
import './index.css'
import 'loaders.css/loaders.css'

// Apollo
import createApolloClient from 'apollo'

// Store
import createStore from 'store'
import * as Sentry from '@sentry/browser';
// Theme
import createTheme from 'styles/theme'

import App from 'App'
let sentryOptions = {
  dsn: "https://6aa2c306ad984643a0c5c323c3b88017@o382373.ingest.sentry.io/5220807"
}
if(document.location.host.indexOf('localhost')!==-1)
  sentryOptions.defaultIntegrations = false
Sentry.init(sentryOptions);
const client = createApolloClient(window.__APOLLO_STATE__)
const store = createStore(window.__INITIAL_STATE__)
const theme = createTheme()

ReactDOM.render(
  <ApolloProvider client={client}>
    <StoreProvider store={store}>
      <RouterProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </RouterProvider>
    </StoreProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
