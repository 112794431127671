/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Products from './Products'
import Product from './Product'
import ProductCreate from './ProductCreate'

const Module = ({ match }) => (
  <Container maxWidth="lg">
    <Box my={2}>
      <Switch>
        <Route path={`${match.path}/builder/:productId`} component={Product} />
        <Route path={`${match.path}/create`} component={ProductCreate} />
        <Route path={`${match.path}`} component={Products} />
      </Switch>
    </Box>
  </Container>
)

export default Module
