/** @format */
import React, { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import qs from 'qs'

import { loginByToken } from 'api/auth'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

const Token = ({ classes, location, match, history }) => {
  let { token, path = '' } = match.params
  let query = qs.parse(location.search.slice(1))
  const [error, setError] = useState(null)

  if (query.redir) {
    path = query.redir
  }

  useEffect(() => {
    const validateToken = async token => {
      let res = await fetch(`${process.env.REACT_APP_API_URL}/v1/check-token`, {
        method: 'post',
        body: JSON.stringify({ token }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(r => r.json())
        .catch(e => console.log(e))

      if (path) {
        path = window.decodeURIComponent(path)
      }

      if (res && res.isValid) {
        return loginByToken(token, path)
      }
      setError('The token is invalid or expired.')
    }
    validateToken(token)
  }, [token])

  return (
    <Paper className={classes.root}>
      {error ? (
        <div className={classes.loaderContainer}>{error}</div>
      ) : (
        <div className={classes.loaderContainer}>
          <Loader type="ball-beat" color="#182ce2" active />
          <p>Logging in...</p>
        </div>
      )}
    </Paper>
  )
}

const styles = () => ({
  root: {
    padding: 10,
    textAlign: 'center',
    height: 300
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  }
})

export default withStyles(styles)(Token)
