/** @format */

import gql from 'graphql-tag'

export const invoiceFragment = gql`
  fragment invoice on Invoice {
    name
    userId
    auto_advance
    billing
    amount_due
    description
    due_date
    footer
    statement_descriptor
    tax_percent
  }
`
export const getInvoices = gql`
  query getInvoices($userId: String, $status: String, $skip: Int, $limit: Int, $sort: [String]) {
    invoices(userId: $userId, status: $status, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        amount_due
        amount_paid
        amount_remaining
        application_fee_amount
        attempt_count
        attempted
        auto_advance
        billing
        billing_reason
        currency
        created
        description
        due_date
        ending_balance
        footer
        hosted_invoice_url
        invoice_pdf
        next_payment_attempt
        number
        paid
        period_start
        period_end
        receipt_number
        starting_balance
        statement_descriptor
        status
        subscription_proration_date
        subtotal
        tax
        tax_percent
        total
        user {
          id
          name
          email
          avatar
          phone
        }
      }
      count
      pages
    }
  }
`

export const getInvoice = gql`
  query getInvoice($invoiceId: String!) {
    invoice(id: $invoiceId) {
      id
      ...invoice
    }
  }
  ${invoiceFragment}
`

export const getInvoiceForUpdating = gql`
  query getInvoiceForUpdating($invoiceId: String!) {
    invoice(id: $invoiceId) {
      id
      ...invoice
    }
  }
  ${invoiceFragment}
`

export const createInvoice = gql`
  mutation createInvoice($userId: String!, $data: InvoiceInput!) {
    createInvoice(userId: $userId, data: $data) {
      id
      ...invoice
    }
  }
  ${invoiceFragment}
`

export const updateInvoice = gql`
  mutation updateInvoice($invoiceId: String!, $data: InvoiceInput!) {
    updateInvoice(id: $invoiceId, data: $data) {
      id
      ...invoice
    }
  }
  ${invoiceFragment}
`

export const removeInvoice = gql`
  mutation removeInvoice($id: String!) {
    removeInvoice(id: $id) {
      id
    }
  }
`

export const onCreateInvoice = (cache, { data: { createInvoice: invoice } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { invoices } = cache.readQuery({ query: getInvoices, variables })
  cache.writeQuery({
    query: getInvoices,
    variables,
    data: {
      invoices: {
        ...invoices,
        data: [invoice].concat(invoices.data)
      }
    }
  })
}

export const onRemoveInvoice = (cache, { data: { removeInvoice: invoice } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { invoices } = cache.readQuery({ query: getInvoices, variables })
  cache.writeQuery({
    query: getInvoices,
    variables,
    data: {
      invoices: {
        ...invoices,
        data: invoices.data.filter(r => r.id !== invoice.id)
      }
    }
  })
}
