/** @format */

import gql from 'graphql-tag'

export const send2FaCode = gql`
  mutation send2FaCode($data: UserInput!) {
    send2FaCode(data: $data) {
      id
    }
  }
`

export const login = gql`
  mutation login($data: LoginDataInput!) {
    login(data: $data) {
      userId
      token
    }
  }
`

export const loginAs = gql`
  mutation loginAs($userId: String!) {
    loginAs(userId: $userId) {
      userId
      token
    }
  }
`

export const loginByToken = (token, path) => {
  onLogin(token, path)
}

export const onLogin = async (token, path) => {
  await localStorage.setItem('loginToken', token)
  if (!path) {
    path = '/dashboard'
  }
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  setTimeout(()=>window.location.href = path, 3000)
}

export const onLogout = token => {
  localStorage.removeItem('loginToken')
  setTimeout(()=>window.location.href = '/auth/login', 2000)
}
