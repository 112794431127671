/** @format */

import qs from 'qs'
import _ from 'lodash'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const connectWithService = (userId, service) => {
  if (service === 'stripe') {
    const redirect_uri = `${window.location.origin}/services/connect/stripe` // ?userId=${userId}
    window.location = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${redirect_uri}`
  }
}

export const createCCToken = async data => {
  if (_.isEmpty(data)) {
    return { token: null }
  }
  data.exp_month = (data.expiry || '').split('/')[0] * 1
  data.exp_year = (data.expiry || '').split('/')[1] * 1
  const fields = ['number', 'exp_month', 'exp_year', 'cvc', 'name']
  const body = new URLSearchParams(qs.stringify({ card: _.pick(data, fields) }))

  const result = await fetch('https://api.stripe.com/v1/tokens', {
    method: 'post',
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
    },
    body
  }).then(res => res.json())

  if (result.error) {
    const field = fields.reduce((prev, curr) => (String(result.error.param).includes(curr) ? curr : prev), 'number')
    console.log(field)
    MySwal.fire({
      icon: 'error',
      title: `Error!`,
      text: result.error.message
    })
    return { errors: { [field]: [result.error.message] } }
  }
  return { token: result.id }
}

export const createACHToken = () => {
  const { Plaid } = window
  return new Promise((resolve, reject) => {
    const linkHandler = Plaid.create({
      env: process.env.REACT_APP_PLAID_ENV,
      clientName: 'ChatFortress',
      key: process.env.REACT_APP_PLAID_PUBLIC_KEY,
      product: ['auth'],
      selectAccount: true,
      onSuccess: function(public_token, metadata) {
        return resolve({ token: public_token, account_id: metadata.account_id, type: 'plaid' })
      },
      onExit: function(err, metadata) {
        return reject(err)
      }
    })
    linkHandler.open()
  })
}
