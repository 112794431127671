/** @format */

import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'

import { withStyles } from '@material-ui/core/styles'
import {getHackedReportbyToken} from "api/hackedReports"
import {Query} from "react-apollo"
import qs from 'qs'


const HackedReports = ({ classes, history, match, confirm, context }) => {
  let query = qs.parse(history.location.search.slice(1))
  return (
    <Query query={getHackedReportbyToken} variables={query}>
      {({data, loading}) => {
        if(loading) return "Loading..."
        let {companyObject, personalObject } = data.hackedReportByToken
        let lastPersonal = personalObject
          ? _.maxBy(personalObject.breaches || [], 'breachDate') || personalObject.breaches[0]
          : null
        let lastCompany = companyObject
          ? _.maxBy(companyObject.breaches || [], 'breachDate') || companyObject.breaches[0]
          : null
        return !loading && (
          <React.Fragment>

            <div>
              <section className="fdb-block py-4">
                <div className="container align-items-end justify-content-center d-flex">
                  <div className="row align-items-top text-left">
                    <div className="col-10 col-md-6 col-lg-5">
                      <p className="mb-5 mt-5">
                        <img
                          alt="CF-logo-v2-NO-BGCrop"
                          src="https://chatfortress.s3.amazonaws.com/img/CF-logo-v2-NO-BGCrop.png"
                          height={60}
                        />
                      </p>
                      <h1>ChatFortress Hacked Data Report</h1>
                      <h2>Prepared For: {companyObject ? companyObject.domain : personalObject.email} </h2>
                      <h2>
                        {new Date(
                          companyObject
                            ? companyObject.updatedAt || companyObject.createdAt
                            : personalObject.updatedAt || personalObject.createdAt
                        ).toLocaleString()}
                      </h2>
                      <p className="lead">
                        ChatFortress scans the dark web to assess if your personal information and account information
                        has
                        been compromised in a data breach. These data breaches expose your data to cyber criminals which
                        increases your risk of cyber attacks, which include email phishing, spear phishing, ransomware
                        and
                        malware exploits. This report allows you to identify vulnerabilities, so you can be proactive in
                        your
                        cybersecurity efforts.
                      </p>
                    </div>
                    <div className="col-10 col-sm-4 col-md-6 col-lg-4 m-auto pt-5">
                      <img
                        alt="w"
                        className="img-fluid rounded-0"
                        src="https://chatfortress.s3.amazonaws.com/img/w_img.png"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-4">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col col-md-10 text-center">
                      <div className="alert alert-primary" role="alert">
                        <h1>DID YOU KNOW: User credentials are one of the most valuable products on the dark web. </h1>
                        <p className="lead">
                          <strong>Your User Credentials are already compromised and exposed on the dark web!</strong>
                          <i>This means hackers have access to your systems using your username and password.</i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {lastPersonal && personalObject && (
                <section className="fdb-block" data-block-type="features" data-id={6} draggable="true">
                  <div className="container">
                    <div className="row justify-content-center pb-xl-5">
                      <div className="col-8 col-sm-8 text-center">
                        <img
                          alt="development"
                          className="img-fluid mt-5 round-0"
                          src="https://cdn.jsdelivr.net/gh/froala/design-blocks@2.0.1/dist/imgs//draws/development.svg"
                          width="70%"
                        />
                        <h1>Current Number of Exposed Credentials for Your Personal Email on the Dark Web!</h1>
                      </div>
                    </div>
                    <div className="row text-left justify-content-center pt-2">
                      <div className="col-12 col-md-6 col-lg-5 m-sm-auto">
                        <div className="row">
                          <div className="col-3">
                            <img
                              alt="noun_schedule"
                              className="img-fluid"
                              src="https://chatfortress.s3.amazonaws.com/img/noun_schedule_527999.png"
                              width="100%"
                            />
                          </div>
                          <div className="col-9">
                            <div className="alert alert-danger" role="alert">
                              <h3>
                                <strong>
                                  Last Data Exposure:
                                  <br/>
                                  {new Date(lastPersonal.breachDate || personalObject.createdAt).toLocaleString()}
                                </strong>
                              </h3>
                            </div>
                            <p>This is how recent your data was exposed on the dark web.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-5 m-sm-auto pt-3 pt-sm-5 pt-md-0">
                        <div className="row">
                          <div className="col-3">
                            <img
                              alt="noun_Login"
                              className="img-fluid"
                              src="https://chatfortress.s3.amazonaws.com/img/noun_Login_2544997.png"
                              width="100%"
                            />
                          </div>
                          <div className="col-9">
                            <div className="alert alert-danger" role="alert">
                              <h3>
                                <strong>
                                  Total Personal Records Exposed: <br/>
                                  {personalObject ? personalObject.breaches.length : 0}
                                </strong>
                              </h3>
                            </div>

                            <p>
                              This is the total number of times we found your email address associated with a data
                              breach
                              on
                              the dark web.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {companyObject && companyObject.breaches.length && (
                <section className="fdb-block fp-active py-4">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-12 text-center">
                        <h1>Current Number of Exposed Credentials for Your Company on the Dark Web!</h1>
                        <h2> Domain Name Scanned: {companyObject.domain} </h2>
                      </div>
                    </div>
                    <div className="row text-center mt-5">
                      <div className="col-12 col-sm-4">
                        <img
                          alt="noun_schedule2"
                          className="fdb-icon"
                          src="https://chatfortress.s3.amazonaws.com/img/noun_schedule_527999.png"
                          width="50%"
                        />
                        <div className="alert alert-danger" role="alert">
                          <h3>
                            <strong>
                              Last Exposed
                              <br/> {new Date(lastCompany.breachDate || companyObject.createdAt).toLocaleString()}
                            </strong>
                          </h3>
                        </div>
                        <p>This is the time of the most recent data breach that contained your domain data. </p>
                      </div>
                      <div className="col-12 col-sm-4 pt-4 pt-sm-0">
                        <img
                          alt="noun_Login2"
                          className="fdb-icon"
                          src="https://chatfortress.s3.amazonaws.com/img/noun_Login_2544997.png"
                          width="50%"
                        />
                        <div className="alert alert-danger" role="alert">
                          <h3>
                            <strong>
                              Total Personal Records Exposed
                              <br/> {personalObject ? personalObject.breaches.length : 0}
                            </strong>
                          </h3>
                        </div>
                        <p>
                          This is the total number of records within the data breaches on the dark web that contained
                          your
                          personal details.
                        </p>
                      </div>
                      <div className="col-12 col-sm-4 pt-4 pt-sm-0">
                        <img
                          alt="noun_executive"
                          className="fdb-icon"
                          src="https://chatfortress.s3.amazonaws.com/img/noun_executive_1064500.png"
                          width="50%"
                        />
                        <div className="alert alert-danger" role="alert">
                          <h3>
                            <strong>
                              Total Executive Credentials Exposed
                              <br/> {companyObject.breaches.length}
                            </strong>
                          </h3>
                        </div>
                        <p>This is the number of other users data that contained your domain name on the dark web.</p>
                      </div>
                    </div>
                  </div>
                </section>
              )}
              <section className="fdb-block" data-block-type="call_to_action" data-id={10} draggable="true">
                <div className="container">
                  <div className="row align-items-center justify-content-center">
                    <img
                      alt="datascience-oneshseet-blogassets"
                      className="img-fluid mt-5 round-0"
                      src="https://chatfortress.s3.amazonaws.com/img/datascience-oneshseet-blogassets-05.png"
                      width="70%"
                    />
                    <div className="col-10 mt-4 mt-sm-0 text-center py-2">
                      <div className="alert alert-warning" role="alert">
                        <h1>If we can find your username and passwords so can cybercriminals!</h1>
                      </div>
                      <h2>
                        Our data analysts report if your data is exposed on the dark web you're 4.4X times more likely to
                        experience a data breach.
                      </h2>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-5">
                <div className="container">
                  <div className="row justify-content-center pb-1">
                    <div className="col-10 text-center">
                      <h1 className="text-danger">It's Time To Implement Hacked Data Monitoring... You're Already at
                        Risk!</h1>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-2">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-10 text-left">
                      <h1>You're Already at risk!</h1>
                      <p className="lead">
                        The bad news is your user credentials are already exposed on the dark web. This means hackers
                        can
                        access your systems using current active user credentials. You need to take action that requires
                        all
                        users to change their passwords. This user credentials information is used to compromise your
                        VPNS,
                        Networks, Email Accounts, Social Media Accounts, Banking Information and in Identity Theft.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-5">
                <div className="container">
                  <div className="alert alert-success" role="alert">
                    <div className="row justify-content-center">
                      <div className="col-10 col-md-8 text-center">
                        <h1>
                          ChatFortress Monitors the Dark Web and Alerts You to Data Compromises to Prevent Cyber
                          Attacks!
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-5">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-10 col-md-4 mb-4 mb-md-0">
                      <img
                        alt="stop use email"
                        className="img-fluid"
                        src="https://chatfortress.s3.amazonaws.com/img/stop using email-img.png"
                      />
                    </div>
                    <div className="col-10 col-md-6 col-lg-5 ml-md-auto text-left">
                      <h1>80,000 emails compromised daily!</h1>
                      <p className="lead">
                        Our ability to scan these data breaches allows you to take proactive steps to protecting your
                        data
                        security. With 80,000 new emails being breached  this means you're constantly under attack.
                      </p>
                      <h1>9 out of 10 Cyber Attacks Start With Email!</h1>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-5">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-10 text-left">
                      <h1>Your Most Recent Hacked Accounts</h1>
                      <p className="lead">
                        This table displays only part of the data we were able to obtain on the dark web. If we were
                        able
                        to
                        find it, it means anyone else can find it who has malicious intent. Our scans can show the following types of information:
                        personal identifiable information (PII), email accounts, passwords, phone numbers, hashed
                        passwords,
                        IP addresses and mailing addresses.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-2">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 text-left col-sm-12">
                      <div className="table-responsive">
                        <table className="table table-dark">
                          <thead>
                          <tr>
                            <th scope="col" className="d-none d-sm-block">
                              #
                            </th>
                            <th scope="col">Date Added</th>
                            <th scope="col">Email</th>
                            <th scope="col">Password</th>
                            <th scope="col">IP Address</th>
                            <th scope="col">Source</th>
                            <th scope="col">Additional data</th>
                          </tr>
                          </thead>
                          <tbody>
                          {personalObject &&
                          personalObject.breaches.map((breach, i) => (
                            <tr key={i}>
                              <th scope="row" className="d-none d-sm-block small">
                                {i + 1}
                              </th>
                              <td className="small">
                                {new Date(
                                  breach.breachDate || personalObject ? personalObject.createdAt : new Date()
                                ).toLocaleString()}
                              </td>
                              <td className="small">{breach.email}</td>
                              <td className="small">
                                {breach.password
                                  ? breach.password.slice(
                                  0,
                                  breach.password.length - Math.round(breach.password.length / 2)
                                ) + '***'
                                  : breach.hashedPassword
                                    ? 'hash:' + breach.hashedPassword.slice(0, 4) + '***'
                                    : 'N/A'}
                              </td>
                              <td className="small">{breach.ipAddress || 'N/A'}</td>
                              <td className="small">{breach.title}</td>
                              <td className="small">
                                {breach.username ? <div>Username: {breach.username}</div> : ''}
                                {breach.phone ? <div>Phone: {breach.phone}</div> : ''}
                                {breach.address ? <div>Address: {breach.address}</div> : ''}
                                {breach.description ? (
                                  <div>
                                    Description:
                                    <div
                                      className="small"
                                      dangerouslySetInnerHTML={{
                                        __html: breach.description.replace('ihavebeenpwned', 'monitoring service')
                                      }}
                                    ></div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          ))}

                          {companyObject &&
                          companyObject.breaches.map((breach, i) => (
                            <tr key={i}>
                              <th scope="row" className="small">
                                {personalObject ? personalObject.breaches.length : 0 + i + 1}
                              </th>
                              <td className="small">
                                {new Date(breach.breachDate || companyObject.createdAt).toLocaleString()}
                              </td>
                              <td className="small">{breach.email}</td>
                              <td className="small">
                                {breach.password
                                  ? breach.password.slice(
                                  0,
                                  breach.password.length - Math.round(breach.password.length / 2)
                                ) + '***'
                                  : breach.hashedPassword
                                    ? 'hash:' + breach.hashedPassword.slice(0, 4) + '***'
                                    : 'N/A'}
                              </td>
                              <td className="small">{breach.ipAddress || 'N/A'}</td>
                              <td className="small">{breach.title}</td>
                              <td className="small">
                                {breach.username ? <div>Username: {breach.username}</div> : ''}
                                {breach.phone ? <div>Phone: {breach.phone}</div> : ''}
                                {breach.address ? <div>Address: {breach.address}</div> : ''}
                                {breach.description ? (
                                  <div>
                                    Description:
                                    <div className="small" dangerouslySetInnerHTML={{__html: breach.description}}></div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-5">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    <div className="row justify-content-center">
                      <div className="col col-md-8 text-center">
                        <h1>39% of US Adults use the SAME password for multiple online services!</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-3">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-10 text-left">
                      <h1>Why ChatFortress Hacked Credential Monitoring? </h1>
                      <h2>How are your credential compromised? </h2>
                      <ul className="lead">
                        <li>
                          <strong>Phishing Attacks:</strong> These are the bad emails sent by hackers designed to steal
                          your
                          data. Hackers send 156 million emails per hour to steal data. These include ransomware
                          attacks,
                          spear phishing and invoice fraud.
                        </li>
                        <li>
                          <strong>Phishing Attacks:</strong>These are the bad emails sent by hackers designed to steal
                          your
                          data. Hackers send 156 million emails per hour to steal data. These include ransomware
                          attacks,
                          spear phishing and invoice fraud.
                        </li>
                        <li>
                          <strong> Malvertising: </strong> This is where hackers inject malware into legitimate online
                          advertising networks.
                        </li>
                        <li>
                          <strong> Web Attacks: </strong> Hackers use Zero-Day exploits or known vulnerabilities within
                          your
                          websites and existing software to compromise your system.
                        </li>
                        <li>
                          <strong> Compromise Data Repositories:</strong> Hackers share existing compromised data on the
                          dark
                          web. This means when sites like Paypal, Netflix, Experian, Target are hacked this data is sold
                          on
                          the Dark Web.
                        </li>
                      </ul>
                      <div className="row text-left align-items-center pt-2 pb-md-0">
                        <div className="col-4 col-md-5">
                          <img
                            alt="noun_password"
                            className="img-fluid round-0"
                            src="https://chatfortress.s3.amazonaws.com/img/noun_password_2631249.png"
                          />
                        </div>
                        <div className="col-8 col-md-5 m-md-auto">
                          <h2>
                            <strong>But I have a Password? </strong>
                          </h2>
                          <p className="lead">
                            Unfortunately passwords security has evolved faster than most people change their passwords.
                            Users
                            will use the same of variation of the same password for multiple services. This makes it
                            easy
                            for
                            hackers to exploit these passwords. Software exists that allows hackers to test 1 million
                            passwords per second! This means cracking your password is just time. That’s why it’s
                            important to
                            use pass-phrases rather than passwords and always activate two-factor Authentication (2FA)
                            on
                            all
                            accounts.
                          </p>
                          <p/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block" data-block-type="contents" data-id={2} draggable="true">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    <div className="row justify-content-center">
                      <div className="col col-md-8 text-center">
                        <h1>81% of hacking related breaches leverage stolen or weak passwords!</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-4">
                <div className="container">
                  <div className="row justify-content-center">
                    <picture className="img-fluid mt-5 round-0">
                      <source
                        srcSet="https://chatfortress.s3.amazonaws.com/img/ATO-Inforgraphics-01.webp"
                        type="image/webp"
                      />
                      <source srcSet="https://chatfortress.s3.amazonaws.com/img/ATO-Inforgraphics-01.jpg"
                              type="image/jpeg"/>
                      <img
                        src="https://chatfortress.s3.amazonaws.com/img/ATO-Inforgraphics-01.jpg"
                        alt="Account Take Over Frauds"
                      />
                    </picture>
                    <div className="col-10 text-left">
                      <h1>Stolen Credentials are VALUABLE on the Dark Web! </h1>
                      <p className="lead">
                        Typical Dark Web prices range between $1-$8 for individual credentials. Keep that in mind that
                        the
                        average US company breach includes 38,500 credentials.
                        <i>This makes it a big pay day for any hacker!</i>
                      </p>
                      <h1>How ChatFortress Hacked Monitoring Protects You</h1>
                      <p className="lead">
                        ChatFortress scans over 11 billion dark web credential storing records to alert you if your data
                        has
                        been exposed in a data breach. This allows you to change your password and protect yourself from
                        account take-over threats. ChatFortress allows you to setup monitoring of your employees and
                        third
                        party vendors further protecting your against phishing attack threats.
                      </p>
                      <blockquote className="blockquote text-center">
                        <h1>CHATFORTRESS MONITORS THE DARK WEB TO ALERT YOU OF COMPROMISED DATA! </h1>
                        <p className="lead">ChatFortress Hacked Monitoring alerts you to new data breaches! </p>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-2">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    <div className="row justify-content-center">
                      <div className="col-10 text-center">
                        <h1>Usernames and Passwords are the KEYS to Your Digital Life! </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-4">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-10 text-left">
                      <h1>What can cyber criminals do with compromised credentials?</h1>
                    </div>
                    <ul className="lead">
                      <li>Steal user identities</li>
                      <li>
                        <strong>Compromise business networks or sensitive data</strong>
                      </li>
                      <li>Modify websites or software with malicious content</li>
                      <li>
                        <strong>Send spam emails</strong>
                      </li>
                      <li>Activate the Domino Effect to exploit people who trust you or your company</li>
                      <li>
                        <strong>Install malware on users computer or work networks</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-4">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    <div className="row justify-content-center">
                      <div className="col-10 text-center">
                        <h1>60% of SMB’s go out of business within 6 months of a cyber attack</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-4 text-center">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-10 text-center">
                      <h1>Account Take Over (ATO) costs more than $5.2 Billion dollars per year!</h1>
                      <div className="py-4 text-left">
                        <p className="lead">
                          Your data maybe compromised not because of a mistake you have made. But it could have been a
                          mistake
                          a company you interact has made. Validate if the companies you are interacting are safe and
                          secure.
                          Check which of the companies employees have been compromised so you can protect yourself from
                          hackers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-2">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    <div className="row justify-content-center">
                      <div className="col-10 text-center">
                        <h1>43% of cyber attacks are on Small and Medium Businesses</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block fp-active" data-block-type="contents" data-id={1} draggable="true">
                <div className="container">
                  <div className="row pb-3">
                    <div className="row justify-content-center">
                      <div className="col-10 text-center">
                        <h1>ChatFortress Hacked Monitoring scans 11,116,675,678 dark web compromised account
                          records!</h1>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-2 justify-content-center align-items-center">
                    <div className="col-3">
                      <div className="col text-center">
                        <p className="lead">We have a total of 7,816+ data breaches indexed.</p>
                      </div>
                    </div>
                    <div className="col-3 offset-1">
                      <div className="col text-center">
                        <p className="lead">121,667,244+ paste accounts</p>
                      </div>
                    </div>
                    <div className="col-3 offset-1">
                      <div className="col text-center">
                        <p className="lead">101,062+ pastes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-4 text-center">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-10 text-center">
                      <h1>
                        ChatFortress Hacked Monitoring Alerts You to Data Compromise, Helping You Be Pro-Active Against
                        Cyber
                        Attacks
                      </h1>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-4">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    <div className="row justify-content-center">
                      <div className="col-10 text-center">
                        <h1>60% of SMB’s go out of business within 6 months of a cyber attack!</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-4">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-4 mb-md-0 text-center">
                      <img
                        alt="leaks"
                        className="img-fluid "
                        src="https://chatfortress.s3.amazonaws.com/img/leaks.svg"
                        width="70%"
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 ml-md-auto text-left">
                      <h1>Protect Your Organization</h1>
                      <p className="lead">
                        Identify which employees are at risk and have been hacked. Ensure no compromised passwords are
                        being
                        used.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-4">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6 col-lg-5 ml-md-auto text-left">
                      <h1>Make Your Employees Aware</h1>
                      <p className="lead">
                        Show your employees their vulnerabilities and advise them on next steps to protect their
                        credentials.
                      </p>
                    </div>
                    <div className="col-12 col-md-6 mb-4 mb-md-0 text-center">
                      <picture className="img-fluid rounded-0 text-center">
                        <source
                          srcSet="https://chatfortress.s3.amazonaws.com/img/3rd+Party+Vendor+Monitoring-02.webp"
                          type="image/webp"
                        />
                        <source
                          srcSet="https://chatfortress.s3.amazonaws.com/img/3rd+Party+Vendor+Monitoring-02.jpg"
                          type="image/jpeg"
                        />
                        <img
                          src="https://chatfortress.s3.amazonaws.com/img/3rd+Party+Vendor+Monitoring-02.jpg"
                          alt="check if data compromised"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-4">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-4 mb-md-0 text-center">
                      <picture className="img-fluid rounded-0 text-center">
                        <source
                          srcSet="https://chatfortress.s3.amazonaws.com/img/3rd+Party+Vendor+Monitoring-01.webp"
                          type="image/webp"
                        />
                        <source
                          srcSet="https://chatfortress.s3.amazonaws.com/img/3rd+Party+Vendor+Monitoring-01.jpg"
                          type="image/jpeg"
                        />
                        <img
                          src="https://chatfortress.s3.amazonaws.com/img/3rd+Party+Vendor+Monitoring-01.jpg"
                          alt="check if data compromised"
                        />
                      </picture>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 ml-md-auto text-left">
                      <h1>Get Alerted When Your 3rd Party Vendors are Hacked!</h1>
                      <p className="lead">
                        Hackers exploit trust via their email phishing cyber attacks. Monitoring your vendors allows you
                        to be
                        alerted to threats and defend yourself from these attacks.
                        <i>Your vendors may not even realized they have been compromised!</i>
                        <strong>ChatFortress Hacked Monitoring Exlusive feature! </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block fp-active py-4">
                <div className="container">
                  <div className="row">
                    <div className="col text-left">
                      <h1>The Dark Web is Cybercriminals Disneyland </h1>
                      <p className="lead">
                        Over 50% of all websites on the dark web are used for criminal activities including the sales of
                        digital credentials. You may have had your digital credentials sold on the dark web because of a
                        data
                        breach and you wouldn’t even know.
                      </p>
                      <h1>How do credentials end up on the dark web? </h1>
                      <p className="lead">
                        Data breaches happen on a daily basis because of the third party vendors you and your company
                        uses.
                        With ChatFortress Hack Monitoring we detect if your credentials have been compromised due to a
                        third
                        party data breach on millions of websites like: HR &amp; Payroll, Email Services, CRM and
                        Marketing
                        Automation, Travel Sites, Banking &amp; Financial Services, Social Media sites.
                      </p>
                      <h1> How ChatFortress Hack Monitoring Helps You:</h1>
                      <h2>PREVENTION:</h2>
                      <h2>
                        <p className="lead">
                          Data breaches are inevitable but proactive monitoring of stolen data allows you to respond to
                          threats.
                        </p>
                      </h2>
                      <h2>MONITORING 24/7/365</h2>
                      <h2>
                        <p className="lead">
                          ChatFortress Hacked Monitoring scans over 11 billion data repositories, chat rooms, private
                          websites, peer-to-peer networks, botnets and black market websites.
                        </p>
                      </h2>
                      <h2>REPORTING</h2>
                      <h2>
                        <p className="lead">
                          80,000+ emails are compromised daily. Our ability to scan these data breaches allows you to
                          take
                          proactive steps to protecting your data security.
                        </p>
                      </h2>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block py-2">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-10 text-center">
                      <a href="/products/hacked">
                        <button type="button" className="btn btn-primary btn-lg btn-block">
                          Activate ChatFortress Hacked Data Monitoring
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fdb-block fp-active py-4">
                <div className="container">
                  <div className="row">
                    <div className="col text-left">
                      <h1>FAQ’s</h1>
                      <h2>How come there are no results?</h2>
                      <p className="lead">
                        Not all searches will yield results. Results will only be shown if the query you searched is
                        found
                        in
                        a data breach that we have indexed.Not everyone’s information has been exposed in a data breach
                        before
                        but a good majority of the general population have been exposed.
                      </p>
                      <p className="lead">
                        Just because your search does not yield results does not mean you are immune from attacks. You
                        should
                        still be implementing safe guards to protect yourself.
                      </p>
                      <h2>What is a “breach” and where has the data come from?</h2>
                      <p className="lead">
                        A “breach” is an incident where data is inadvertently exposed in a vulnerable system, usually
                        due
                        to
                        insufficient access controls or security weaknesses in the software. ChatFortress aggregates
                        breaches
                        and enables people to assess where their personal data has been exposed.
                      </p>
                      <h2>What is a “paste” and why include it?</h2>
                      <p className="lead">
                        A “paste” is information that has been “pasted” to a publicly facing website designed to share
                        content
                        such as Pastebin. These services are favored by hackers due to the ease of anonymously sharing
                        information and they’re frequently the first place a breach appears.
                      </p>
                      <p className="lead">
                        The ChatFortress systems searches through pastes that are broadcast by the @dumpmon Twitter
                        account
                        and reported as having emails that are a potential indicator of a breach. Finding an email
                        address
                        in
                        a paste does not immediately mean it has been disclosed as the result of a breach. Review the
                        paste
                        and determine if your account has been compromised then take appropriate action such as changing
                        passwords.
                      </p>
                      <h2>Is anything logged when people search for an account?</h2>
                      <p className="lead">
                        Nothing is explicitly logged by the website. The only logging of any kind is via Google
                        Analytics,
                        Application Insights performance monitoring and any diagnostic data implicitly collected if an
                        exception occurs in the system. Your email is added to our Email Autoresponder so we can provide
                        you
                        with Free resources to increase your awareness. But you can unsubscribe from those emails at any
                        point
                        in time.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-4">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    <div className="row justify-content-center align-items-center text-center pt-2">
                      <div className="col-12 col-sm-3">
                        <img
                          alt="icon-1"
                          className="img-fluid pb-2"
                          src="https://chatfortress.s3.amazonaws.com/img/calendar.png"
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <h2>
                          <strong>Speak with a ChatFortress Specialist</strong>
                        </h2>
                        <p className="lead">
                          Discover how ChatFortress can help your company reduce your risk and exposure without
                          installing
                          complicated systems that require your team to work harder. Schedule time with your
                          ChatFortress
                          Specialist now. Call <a href="“tel:3079997755”">307-999-7755</a>
                        </p>
                      </div>
                      <div className="col-12 col-sm-3">
                        <p>
                          <a className="btn btn-primary btn-lg" href="/demo" target="_blank">
                            Schedule Time!
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <br/>
              <br/>
            </div>
          </React.Fragment>)
      }
      }</Query>
  )
}

const styles = theme => ({
  root: {}
})

export default compose(
  withStyles(styles)
)(HackedReports)
