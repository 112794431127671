/** @format */

import React, { useState } from 'react'
import compose from 'recompose/compose'
import { useQuery, useMutation } from 'react-apollo'
import _ from 'lodash'
import moment from 'moment'

import { getTrainingCourse } from 'api/courses'
import { shareLesson } from 'api/lessons'
import { getUserLicenses } from 'api/userLicenses'

import withAppContext from 'lib/withAppContext'
import { withStyles, withTheme } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Avatar2 from 'lib/Avatar'
import Icon from '@material-ui/core/Icon'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Spinner from 'components/Spinner'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { Scrollbars } from 'react-custom-scrollbars'
import { Timeline, TimelineEvent } from 'react-event-timeline'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
var phoneNum = null

const Course = ({ classes, theme, match, history, location, context }) => {
  const { user, acl, refetch } = context
  const { data: { course = {} } = {}, loading } = useQuery(getTrainingCourse, { variables: match.params })
  const { data: { userLicenses = {} } = {} } = useQuery(getUserLicenses, { variables: {} })
  const [shareLessonMutation] = useMutation(shareLesson, {
    onCompleted() {
      refetch()
    }
  })
  if (loading) return <Spinner />
  const userStats = (course && course.userStats) || {}
  const lessons = (course && course.lessons) || []
  const { countCompletedLessons = 0, quizPoints = 0 } = userStats
  const featuredLesson = lessons.find(l => l.featured === true)
  const scheduledLesson = lessons.find(l => l.released !== true && moment(l.sheduleAt).isAfter(new Date()))

  user.subemployees = _.filter(user.subemployees, u => _.find(user.invites, inv => inv.email === u.email))

  let invites = _.filter(user.invites || [], { accepted: false })
  invites = invites.slice(0, 7 - user.subemployees.length)
  // [{id, action, data}]
  // TODO generate title based on action
  const activityLog = (course.trainingActivities || []).map(act => ({
    ...act,
    label: `Lesson "${lessons.find(l => (l.id = act.lessonId)).name.trim()}": ${act.action}`
  }))
  //const activityLog = [{ title: 'Access to course granted', createdAt: lessons[0] ? lessons[0].releasedAt : new Date() }]

  const sharedLessons = _.filter(lessons, l => l.schedule !== null && l.schedule.sent)
  const availableLessons = _.filter(lessons, l => l.released)
  const lastLesson = _.maxBy(sharedLessons, l => l.schedule.scheduleDate) || lessons[0]

  let invitesCnt = user.subemployees.length + invites.length

  return !loading ? (
    <div className="p-4">
      <div className="mt-4">
        <p>
          To If you have questions use the live chat to ask your question.
          <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e59270b2c7d3a7e9ae871be">
            Get help here
          </a>
        </p>
      </div>
      <Typography className="my-3" variant="h4">
        <Typography component="span" variant="h4" color="primary">
          {course.name}
        </Typography>
      </Typography>
      <Grid spacing={2} container>
        <Grid xs={12} lg={8} item>
          <Grid spacing={0} container>
            <Grid xs={12} item style={{ marginBottom: 16 }}>
              <Paper className={classes.card}>
                <Box display="flex" alignItems="center" justifyContent="space-around">
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h4">
                      {countCompletedLessons} / {availableLessons.length}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Lessons complete
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h4">{quizPoints}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Quiz score
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid spacing={2} container>
              <Grid xs={12} lg={6} item>
                {featuredLesson && (
                  <Paper className={classes.card}>
                    <Typography variant="h6">{featuredLesson.featureHeadline}</Typography>
                    <List>
                      <ListItem onClick={() => history.push(`${match.url}/lesson/${featuredLesson.id}`)} button>
                        <ListItemAvatar>
                          <Avatar alt={featuredLesson.name} src={featuredLesson.cartoonImage} />
                        </ListItemAvatar>

                        <ListItemText primary={featuredLesson.name} secondary={featuredLesson.summary} />
                      </ListItem>
                    </List>
                  </Paper>
                )}
              </Grid>
              <Grid xs={12} lg={6} item>
                {scheduledLesson && (
                  <Paper className={classes.card}>
                    <Typography variant="h6">{scheduledLesson.releaseHeadline}</Typography>
                    <Typography variant="h5" align="center">
                      {moment(scheduledLesson.sheduleAt).format('LL')}
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar alt={scheduledLesson.name} src={scheduledLesson.cartoonImage} />
                        </ListItemAvatar>
                        <ListItemText primary={scheduledLesson.name} secondary={scheduledLesson.summary} />
                      </ListItem>
                    </List>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className="my-2" variant="h6">
              Lessons
            </Typography>
            {lessons
              .filter(l => l.released)
              .map((lesson, index) => {
                const quizPoints = (lesson.userStats && lesson.userStats.quizPoints) || 0
                return (
                  <Paper
                    className={classes.card}
                    style={{ padding: 8, marginBottom: 8 }}
                    key={lesson.id + lesson.name}
                    onClick={() => history.push(`${match.url}/lesson/${lesson.id}`)}
                  >
                    <Grid container spacing={1}>
                      <Grid style={{ padding: 2 }} xs={12} md={2} item component={Box} display={{ xs: 'none', sm: 'block' }}>
                        <Box component="div" display="inline-block" style={{ verticalAlign: 'middle', marginRight: 20 }}>
                          <Avatar
                            style={{ height: 32, width: 32 }}
                            alt={lesson.name}
                            src={lesson.cartoonImage}
                            onClick={() => history.push(`${match.url}/lesson/${lesson.id}`)}
                          />
                        </Box>
                        <Box component="div" display="inline-block" style={{ verticalAlign: 'middle' }}>
                          <Typography variant="body2" color="textSecondary">
                            {moment(lesson.releasedAt).format('MM/DD/YYYY')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} md={4} item component={Box} style={{ textAlign: 'center' }}>
                        <Typography variant="h6">
                          {`Lesson #${lesson.order + 1}: ${lesson.name}`}{' '}
                          {lesson.userStats && (
                            <div style={{ zoom: '70%' }} className={'text-small alert-success'}>
                              Completed
                            </div>
                          )}
                        </Typography>
                      </Grid>
                      <Grid xs={6} md={3} item component={Box} style={{ textAlign: 'center' }}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="h5">
                            {quizPoints} / {lesson.quizPoints}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Quiz Points
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={6} md={3} item component={Box} style={{ textAlign: 'center', paddingTop: 12 }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => (document.location.href = `${match.url}/lesson/${lesson.idtemp}`)}
                        >
                          Open
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                )
              })}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={0}>
            <div className="mt-4">
              <p>
                To learn more about Invite 7,{' '}
                <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e5afeec04286364bc960ca3">
                  Get help here
                </a>
              </p>
            </div>
            <Grid xs={12} item style={{ marginBottom: acl.hasAccess('guests:invite') ? '2em' : '' }}>
              {acl.hasAccess('guests:invite') && (
                <Paper className={classes.card} style={{ backgroundColor: 'rgb(109,135,240)', color: 'white', boxShadow: 'none' }}>
                  <Typography variant="h6" style={{ fontSize: '1.5em', fontWeight: 600 }}>
                    Invite 7 Friends to Access Training for FREE!
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: '1.3em' }}>
                    You have already invited {user ? user.subemployees.length : 0} friend{user && user.subemployees.length > 1 ? 's' : ''}
                  </Typography>
                  {user && (
                    <div>
                      {user.subemployees.map(su => (
                        <div key={su.id} className={classes.avatarContainer}>
                          <div style={{ width: 40, display: 'inline-block' }}>
                            <Avatar2 size={40} src={su.avatar} {...su} style={{ border: 'solid 3px #9EF4CB' }} />
                          </div>
                          <div className={classes.avatarBelow}>
                            <div>{su.firstName || su.name}</div>
                            <div>{su.phone || su.email}</div>
                          </div>
                        </div>
                      ))}
                      {invites.map(su => (
                        <div key={su.email + su.phone} className={classes.avatarContainer}>
                          <div style={{ width: 40, display: 'inline-block' }}>
                            <Avatar2 size={40} {...su} style={{ border: 'solid 3px #9EF4CB', opacity: '0.3' }} />
                          </div>
                          <div className={classes.avatarBelow} style={{ color: 'rgba(255,255,255, 0.5)' }}>
                            <div>
                              <i>pending</i>
                            </div>
                            <div>{su.phone || su.email}</div>
                          </div>
                        </div>
                      ))}
                      {invitesCnt < 7 &&
                        Array.from(Array(7 - invitesCnt)).map((a, i) => (
                          <div key={i} className={classes.avatarContainer}>
                            <div style={{ width: 40, display: 'inline-block' }}>
                              <Avatar2 size={40} style={{ opacity: 0.25, border: 'solid 3px #9EF4CB' }} />
                            </div>
                            <div className={classes.avatarBelow}>
                              <div>&nbsp;</div>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                        ))}

                      {invitesCnt < 7 && (
                        <div className={classes.avatarContainer} style={{ width: 97 }}>
                          <Button
                            variant="outlined"
                            color="inherit"
                            style={{ height: 40, padding: 2 }}
                            onClick={() => {
                              MySwal.fire({
                                title: 'Enter phone OR email of invitee',
                                input: 'email',
                                inputAttributes: {
                                  placeholder: 'Email'
                                },
                                inputValidator: value => {
                                  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                  return re.test(String(value).toLowerCase()) || phoneNum ? null : 'Not a valid email'
                                },
                                html: (
                                  <PhoneInput
                                    defaultCountry={'us'}
                                    preferredCountries={['us', 'ca', 'au']}
                                    onChange={val => (phoneNum = val)}
                                    inputStyle={{
                                      width: '100%',
                                      padding: '18.5px 14px 18.5px 48px',
                                      height: '1.1875em',
                                      border: '1px solid rgba(0, 0, 0, 0.23)'
                                    }}
                                  />
                                ),
                                showCancelButton: true,
                                confirmButtonText: 'Send',
                                showLoaderOnConfirm: true,
                                preConfirm: async email => {
                                  shareLessonMutation({
                                    variables: {
                                      requestId: lastLesson.schedule ? lastLesson.schedule.requestId : null,
                                      data: { lessonId: lastLesson.id, phone: phoneNum, email }
                                    }
                                  })
                                }
                              }).then(result => {
                                if (result.value) {
                                  MySwal.fire({
                                    title: `Invite has been sent`
                                  })
                                }
                              })
                            }}
                          >
                            + Add Friend
                          </Button>
                        </div>
                      )}
                      <div style={{ clear: 'both' }} />
                    </div>
                  )}
                </Paper>
              )}
            </Grid>

            <Grid xs={12} item>
              <Paper className={classes.card}>
                <Typography variant="h6">Your feed</Typography>
                <Scrollbars autoHeightMin={360} autoHide autoHeight>
                  <Timeline>
                    {activityLog &&
                      activityLog.map((activity, index) => {
                        return (
                          <TimelineEvent
                            key={activity.id}
                            title={activity.label || activity.action}
                            createdAt={moment(activity.createdAt).format('hh:mma MM-DD-YYYY')}
                            icon={<Icon>done</Icon>}
                          ></TimelineEvent>
                        )
                      })}
                  </Timeline>
                  {(!activityLog || activityLog.length < 1) && <div>No activity on this course yet...</div>}
                </Scrollbars>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper className={classes.card}>
        <Typography variant="h6">Access Licences</Typography>
        <Grid spacing={2} container>
          {(userLicenses.data || []).map((l, i) => (
            <Grid xs={12} md={6} item key={i}>
              <ListItem onClick={() => history.push(`${match.url}/lesson/${featuredLesson.id}`)} button>
                <ListItemAvatar>
                  <Icon>policy</Icon>
                </ListItemAvatar>
                <ListItemText primary={l.name} />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  ) : (
    <Spinner />
  )
}

const styles = theme => ({
  lists: {
    width: '100%'
  },
  listItem: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0
    }
  },
  card: {
    padding: 16,
    borderRadius: 12,
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.1)'
  },
  avatarContainer: {
    marginTop: 16,
    width: 90,
    textAlign: 'center',
    float: 'left',
    display: 'inline-block'
  },
  avatarBelow: {
    overflow: 'hidden',
    textAlign: 'center',
    fontSize: '0.65em',
    color: '#9EF4CB'
  }
})

export default compose(
  withTheme,
  withAppContext,
  withStyles(styles)
)(Course)

/*
    <Grid style={{ flexGrow: 1 }} spacing={1} container>
      <Grid xs={8} item>
        <Route path={`${match.url}/lesson/:lessonId`} component={Lesson} />
      </Grid>
      <Grid xs={4} item>
        <Paper>
          <h3 className="text-center">Course Menu</h3>
          <List className={classes.root}>
            {Array.from(course.lessons || []).map(lesson => (
              <ListItem
                key={lesson.id}
                onClick={() => history.push(`${match.url}/lesson/${lesson.id}`)}
                selected={lesson.id === location.pathname.split('/')[5]}
                button
              >
                <ListItemText primary={lesson.name} secondary={lesson.status} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
*/
