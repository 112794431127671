/** @format */

import { handleActions as createReducer } from 'redux-actions'

import { toggleSidebar, hideSidebar, showSidebar } from './actions'

const appReducer = createReducer(
  {
    [toggleSidebar]: state => ({ ...state, sidebarVisible: !state.sidebarVisible }),
    [showSidebar]: state => ({ ...state, sidebarVisible: false }),
    [hideSidebar]: state => ({ ...state, sidebarVisible: true })
  },
  { sidebarVisible: true }
)

export default appReducer
