/** @format */

import React from 'react'
import brace from 'brace'
import AceEditor from 'react-ace'

import 'brace/mode/yaml'
import 'brace/theme/monokai'

import TextField from '@material-ui/core/TextField'

const EditorCustom = ({ inputRef, onChange, value, ...other }) => (
  <AceEditor width="100%" mode="yaml" theme="monokai" editorProps={{ $blockScrolling: true }} value={value} onChange={onChange} />
)

const EditorField = ({ input, meta: { touched, invalid, error }, ...rest }) => (
  <TextField
    margin="normal"
    fullWidth
    {...input}
    {...rest}
    error={touched && invalid}
    helperText={touched && error}
    InputProps={{
      inputComponent: EditorCustom,
      disableUnderline: true
    }}
  />
)

export default EditorField
