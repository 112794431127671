/** @format */

import gql from 'graphql-tag'

export const userLicenseFragment = gql`
  fragment userLicense on UserLicense {
    name
    userId
    licenseId
    quantity
  }
`
export const getUserLicenses = gql`
  query getUserLicenses($userId: String, $skip: Int, $limit: Int, $sort: [String]) {
    userLicenses(userId: $userId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        quantity
        status
      }
      count
      pages
    }
  }
`

export const getUserLicense = gql`
  query getUserLicense($userLicenseId: String!) {
    userLicense(id: $userLicenseId) {
      id
      ...userLicense
    }
  }
  ${userLicenseFragment}
`

export const getDataCreationUserLicense = gql`
  query getDataCreationUserLicense {
    licenses(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
        quantity
        usageCount
      }
    }
  }
`

export const addUserLicense = gql`
  mutation addUserLicense($data: UserLicenseInput!) {
    addUserLicense(data: $data) {
      id
      ...userLicense
    }
  }
  ${userLicenseFragment}
`

export const getDataUpdatingUserLicense = gql`
  query getDataUpdatingUserLicense($userLicenseId: String!) {
    userLicense(id: $userLicenseId) {
      id
      ...userLicense
    }
    licenses(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
      }
    }
  }
  ${userLicenseFragment}
`

export const updateUserLicense = gql`
  mutation updateUserLicense($userLicenseId: String!, $data: UserLicenseInput!) {
    updateUserLicense(id: $userLicenseId, data: $data) {
      id
      ...userLicense
    }
  }
  ${userLicenseFragment}
`

export const removeUserLicense = gql`
  mutation removeUserLicense($id: String!) {
    removeUserLicense(id: $id) {
      id
    }
  }
`

export const onCreateUserLicense = (cache, { data: { addUserLicense: userLicense } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { userLicenses } = cache.readQuery({ query: getUserLicenses, variables })
  cache.writeQuery({
    query: getUserLicenses,
    variables,
    data: {
      userLicenses: {
        ...userLicenses,
        data: [userLicense].concat(userLicenses.data)
      }
    }
  })
}

export const onRemoveUserLicense = (cache, { data: { removeUserLicense: userLicense } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { userLicenses } = cache.readQuery({ query: getUserLicenses, variables })
  cache.writeQuery({
    query: getUserLicenses,
    variables,
    data: {
      userLicenses: {
        ...userLicenses,
        data: userLicenses.data.filter(r => r.id !== userLicense.id)
      }
    }
  })
}
