/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation } from 'react-apollo'
// import withSweetAlert from 'lib/withSweetAlert'
// import withUserContext from 'lib/withUserContext'
// import numeral from 'numeral'
import moment from 'moment'

import { getRequests, sendRequest, voidRequest, approveRequest, removeRequest, onRemoveRequest } from 'api/requests'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import CheckboxTable from 'lib/tables/CheckboxTable'
import UserCell from 'lib/tables/UserCell'

const Requests = ({ classes, history, match }) => (
  <React.Fragment>
    <Mutation mutation={removeRequest}>
      {(remove, { loading: loadingRemove }) => (
        <Mutation mutation={sendRequest}>
          {(send, { loading: loadingSend }) => (
            <Mutation mutation={voidRequest}>
              {(archive, { loading: loadingVoid }) => (
                <Mutation mutation={approveRequest}>
                  {(approve, { loading: loadingApprove }) => (
                    <CheckboxTable
                      query={getRequests}
                      variables={{ ...match.params }}
                      initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
                      columns={[
                        {
                          Header: 'Date',
                          accessor: 'ceratedAt',
                          Cell: ({ original: request }) => moment(request.createdAt).format('L')
                        },
                        {
                          Header: 'Name',
                          accessor: 'name'
                        },
                        {
                          Header: 'From',
                          accessor: 'author.id',
                          Cell: ({ original: request }) => <UserCell user={request.author} />
                        },
                        {
                          Header: 'To',
                          accessor: 'user.id',
                          Cell: ({ original: request }) => <UserCell user={request.user} />
                        },
                        {
                          Header: 'Reference Number',
                          accessor: 'referenceNumber'
                        },
                        {
                          Header: 'Description',
                          accessor: 'description'
                        },
                        {
                          Header: 'Status',
                          accessor: 'status'
                        },
                        {
                          Header: 'Actions',
                          sortable: false,
                          filterable: false,
                          accessor: 'id',
                          Cell: ({ original: request }) => (
                            <div>
                              {[
                                <IconButton
                                  key="edit"
                                  onClick={() => history.push(`${match.url}/${request.id}/update`)}
                                  title="Edit Request"
                                >
                                  <Icon fontSize="small">edit</Icon>
                                </IconButton>,
                                <IconButton
                                  key="send"
                                  onClick={() => send({ variables: { id: request.id } })}
                                  title="Resend Request"
                                  disabled={loadingSend}
                                >
                                  <Icon fontSize="small">send</Icon>
                                </IconButton>,
                                <IconButton
                                  key="void"
                                  onClick={() => archive({ variables: { id: request.id } })}
                                  title="Void Request"
                                  disabled={loadingVoid}
                                >
                                  <Icon fontSize="small">archive</Icon>
                                </IconButton>,
                                <IconButton
                                  key="approve"
                                  onClick={() => approve({ variables: { id: request.id } })}
                                  title="Approve Request"
                                  disabled={loadingApprove}
                                >
                                  <Icon fontSize="small">playlist_add_check</Icon>
                                </IconButton>,
                                <IconButton
                                  key="delete"
                                  color="secondary"
                                  onClick={() => {
                                    //if (confirm('Are you sure you want to delete this Request?')) {
                                    remove({ variables: { id: request.id }, update: onRemoveRequest })
                                    //}
                                  }}
                                  title="Remove Request"
                                  disabled={loadingRemove}
                                >
                                  <Icon fontSize="small">delete</Icon>
                                </IconButton>
                              ]}
                            </div>
                          )
                        }
                      ]}
                      actions={[
                        <Button
                          key="create"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => history.push(`${match.url}/create`)}
                        >
                          <Icon className={classes.leftIcon}>add</Icon>
                          New Request
                        </Button>
                      ]}
                      // onDeleteAll={selection => removeMany({ variables: { requestIds: selection } })}
                      deleteMany={false}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  </React.Fragment>
)

const styles = theme => ({
  root: {},
  button: {
    marginLeft: 7
  }
})

export default compose(withStyles(styles))(Requests)
