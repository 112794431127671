/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormCard, Field } from 'lib/form'
import { TextField, Checkbox } from 'lib/form/fields'
import { required, numericality } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { createLesson, getDataCreationLesson } from 'api/lessons'

import withStyles from '@material-ui/core/styles/withStyles'

import { lessonTypeOptions } from 'data/options'

const LessonCreate = ({ match, history }) => (
  <Query query={getDataCreationLesson} variables={match.params}>
    {({ data: { course } = {} }, loading) =>
      !loading && (
        <FormCard
          title="Create Lesson"
          form="LessonCreate"
          mutation={[createLesson, { refetchQueries: ['getLessons'] }]}
          initialValues={{ courseId: match.params.courseId, type: 'fun', released: false }}
          btnSubmitText="Create Lesson"
          success="Lesson created successfully"
          onComplete={() =>
            history.push(
              match.url
                .split('/')
                .slice(0, -1)
                .join('/')
            )
          }
        >
          {({ doc, invalid, submitting }) => (
            <React.Fragment>
              <Field name="type" component={TextField} label="Type" options={lessonTypeOptions} validate={[required()]} select />
              <Field name="name" component={TextField} label="Name" validate={[required()]} />
              <Field name="summary" component={TextField} label="Summary" multiline />
              <Field name="released" component={Checkbox} label="Lesson Released" />
              {doc.released ? (
                <React.Fragment>
                  <Field
                    name="releasedAt"
                    type="date"
                    component={TextField}
                    label="Released Date"
                    InputLabelProps={{ shrink: true }}
                    validate={[required()]}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Field name="releaseHeadline" component={TextField} label="Release Headline" validate={[required()]} />
                  <Field
                    name="sheduleAt"
                    type="date"
                    component={TextField}
                    label="Shedule Release Date"
                    InputLabelProps={{ shrink: true }}
                    validate={[required()]}
                  />
                </React.Fragment>
              )}
              <Field name="paid" component={Checkbox} label="Paid Access" />
              {doc.paid && (
                <React.Fragment>
                  <Field
                    name="plans"
                    component={TextField}
                    label="Plans"
                    options={course && course.plans}
                    validate={[required()]}
                    multiple
                    select
                  />
                </React.Fragment>
              )}
              <Field name="ldGuestAccess" component={Checkbox} label="LD Guest Access" />
            </React.Fragment>
          )}
        </FormCard>
      )
    }
  </Query>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(LessonCreate)
