/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { Form, Field, FormSection, Address } from 'lib/form'
import { TextField, Birthdate } from 'lib/form/fields'
import { required } from 'lib/form/validators'
import { requestStatusOptions } from 'data/options'

import { getRequest, updateRequest } from 'api/requests'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const RequestUpdate = ({ classes, match }) => (
  <Query query={getRequest} variables={match.params}>
    {({ data: { request } = {}, loading }) =>
      !loading && (
        <Paper className="p-3">
          <Form
            title="Update Request"
            form="RequestUpdate"
            mutation={[updateRequest]}
            initialValues={request}
            btnSubmitText="Save"
            success="Request updated successfully"
          >
            {({ doc, submit, submitting }) => (
              <React.Fragment>
                <Typography color="primary" variant="h5">
                  Request Details
                </Typography>
                <Field name="name" component={TextField} label="Request Name" validate={[required()]} />
                <Field name="status" component={TextField} label="Status" options={requestStatusOptions} select validate={[required()]} />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Field name="profile.firstName" component={TextField} label="First Name" validate={[required()]} />
                  {/*<Field name="profile.middleName" component={TextField} label="Middle Name" />*/}
                  <Field className="ml-3" name="profile.lastName" component={TextField} label="Last Name" validate={[required()]} />
                </Box>
                <Field
                  name="profile.phone"
                  component={TextField}
                  label="Cell Phone"
                  validate={[required()]}
                  helperText="Mobile phone reccomended"
                />
                <Field name="profile.dob" component={Birthdate} label="Birthdate" />
                <FormSection name="profile.physicalAddress">
                  <Address title="Physical Address" address={doc.profile.physicalAddress} />
                </FormSection>
                <FormSection name="profile.mailAddress">
                  <Address title="Mail Address" address={doc.profile.mailAddress} />
                </FormSection>
                <Field
                  name="referenceNumber"
                  component={TextField}
                  label="Reference Number"
                  helperText="Optional. Include your unique identifier such as a file or loan number"
                />
                <Field name="description" component={TextField} label="Description" helperText="Optional" multiline />
                <Field name="profile.companyName" component={TextField} label="Company Name" />
                <Field name="profile.companyWebsite" component={TextField} label="Company Website" />
                <Button variant="contained" color="primary" onClick={submit} disabled={submitting}>
                  Update Request
                </Button>
              </React.Fragment>
            )}
          </Form>
        </Paper>
      )
    }
  </Query>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(RequestUpdate)
