import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Nav from 'components/Nav'

import PhoneNumbers from 'components/PhoneNumbers'
import Lists from 'components/Lists'
import Campaigns from 'components/Campaigns'

export default ({ match, location }) => (
  <React.Fragment>
    <Nav
      color="primary"
      items={[
       // { key: 'phone-numbers', title: 'Phone Numbers', to: `${match.url}/phone-numbers` },
        { key: 'campaigns', title: 'Campaigns', to: `${match.url}/campaigns` },
        { key: 'lists', title: 'Target Lists', to: `${match.url}/lists` }
      ]}
      activeKey={location.pathname.split('/')[2]}
    />
    <Switch>
      <Route path={`${match.path}/phone-numbers`} component={PhoneNumbers} />
      <Route path={`${match.path}/lists`} component={Lists} />
      <Route path={`${match.path}/campaigns`} component={Campaigns} />
      <Redirect to={`${match.url}/campaigns`} />
    </Switch>
  </React.Fragment>
)
