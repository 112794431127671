/** @format */

import React, { useCallback, useState } from 'react'
import compose from 'recompose/compose'
import { useDropzone } from 'react-dropzone'

import uploadToS3 from 'lib/s3'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Avatar from 'lib/Avatar'

const AvatarUpload = ({ classes, input, btnLabel = 'Upload Avatar', ...props }) => {
  const [uploading, setUploading] = useState(false)
  const onDrop = useCallback(async acceptedFiles => {
    await upload(acceptedFiles[0])
  }, [])
  const upload = useCallback(async file => {
    setUploading(true)
    const url = await uploadToS3(file)
    input.onChange(url)
    setUploading(false)
  }, [])
  const remove = useCallback(async () => {
    input.onChange('')
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const url = input.value || ''

  return (
    <React.Fragment>
      <div className="d-flex align-items-center">
        <Avatar avatar={url} size={100} shape="square" />
        {url && (
          <IconButton color="secondary" onClick={() => remove()} title="Remove Image" style={{ marginLeft: 7 }}>
            <Icon fontSize="small">delete</Icon>
          </IconButton>
        )}
      </div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className={classes.dropArea}>Drop the files here ...</p>
        ) : (
          <Button variant="contained" color="primary" disabled={uploading}>
            <Icon style={{ marginRight: 5 }}>cloud_upload</Icon> {btnLabel}
          </Button>
        )}
      </div>
    </React.Fragment>
  )
}

const styles = theme => ({
  dropArea: {
    backgroundColor: 'LightYellow',
    border: '2px dashed orange',
    padding: 10
  }
})

export default compose(withStyles(styles))(AvatarUpload)
