import React from 'react'
import _ from 'lodash'
import 'react-quill/dist/quill.snow.css'
import parse from 'html-react-parser';

import './style.css'

import { Card, CardHeader, CardIcon, CardBody, CardFooter } from 'components/Card'
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";






const Viewer = ({html='<p>Start typing here...</p>', data = {}}) => {

  const [loading, setLoading] = React.useState(true)
  const [body, setBody] = React.useState(true)

  const renderHTML = (htmlArr, lvl=0)=>{
    return _.map(htmlArr, (n,i)=>{
      let cloned = n
      if(cloned) {
        if(cloned.props && cloned.props.contentEditable){
          cloned = React.cloneElement(cloned, _.omit(n.props, ['contentEditable']))
        }
        if(cloned.props && cloned.props['data-type']){
          console.log(cloned)
          switch (n.props['data-type']) {
            case 'block':
              cloned=(<Card className="mt-5 mb-5">
                <CardHeader color="warning">
                  THIS IS THE COMPONENT BASED ON COLLECTED DATA: {' '}
                  "<strong>{n.props['data-label']}</strong>"
                </CardHeader>
              </Card>)
              //React.createElement('div', {style:{padding: 20}}, cloned.props['data-id'])
              break;
            case 'image':
              cloned=React.createElement('img', {src: n.props['data-image'], key: (new Date()) + '_' + i})
              break;
            case 'plain':
                cloned=React.createElement('span', {key: (new Date()) + '_' + i}, <span>
                  {data[n.props['data-id']] || `{{${n.props['data-label']}}}`}
                </span>)
              break;
          }

        }
        if (cloned.props && cloned.props.children) {

          let children = cloned.props.children||null
          if(!Array.isArray(children)) {
              if (typeof children === 'string' && cloned.type !== 'Symbol(react.fragment)') {
                //children = React.createElement('React.Fragment', {key: (new Date()) + '_' + i}, children)
                children = [children]
              } else {
                let child = React.cloneElement(children, {key: (new Date()) + '_' + i})
                children = [child]
              }

          }
          //renderHTML(children, lvl+1)
          if(children[0] && children[0].props && typeof children[0].props.children === 'undefined') {
            //console.log(children)
            children = []
          }
          cloned = React.cloneElement(n, {...cloned.props, children: renderHTML(children)})
         //console.log(n.props.children)
          //n.replaceChildren(renderHTML(n.props.children))
        }
      }
      return cloned
    })
  }

  React.useEffect(()=>{
    console.log('###React.useEffect')


    if(html) {
      setBody(renderHTML(parse(html)))
      setLoading(false)
    }
  },[html])
  return (
    <div>
      {loading && <div className={'flex text-center'}>Loading...</div>}

      {!loading && <div style={{all: 'unset'}} id={'editor'} className={'ql-editor'}>{body}</div>}
    </div>
  )
}

export default Viewer