/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useQuery } from 'react-apollo'
import { FormCard, FormSection, Company } from 'lib/form'

import HelpIcon from '@material-ui/icons/Help'

import { getDataUpdatingUser, updateUser } from 'api/users'

import withContext from 'lib/withAppContext'

const CompanyComponent = ({ context }) => {
  const { data = {}, loading } = useQuery(getDataUpdatingUser, { variables: { userId: context.user.id }, fetchPolicy: 'cache-and-network' })
  const { user = {} } = data

  return (
    !loading && (
      <FormCard
        form="Company"
        mutation={[updateUser, { variables: { userId: context.user.id }, refetchQueries: ['getDataUpdatingUser'] }]}
        initialValues={{ ...user }}
      >
        {({ doc }) => (
          <React.Fragment>
            <div>
              <p>
                If you have questions use the live chat to ask your question.
                <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e49774c04286364bc9568f3">
                  Get help about this page here
                </a>
              </p>
            </div>
            <FormSection name="company">
              <Company company={doc} />
            </FormSection>
          </React.Fragment>
        )}
      </FormCard>
    )
  )
}

export default compose(withContext)(CompanyComponent)
