/** @format */

import React from 'react'
import classnames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

import PhoneIcon from '@material-ui/icons/Call'
import MailIcon from '@material-ui/icons/MailOutline'

import Logo from 'components/Logo'

const Footer = ({ classes, wide }) => (
  <React.Fragment>
    <Hidden smUp implementation="css">
      <AppBar position="relative" color="default" className={classnames(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <Logo color="dark" />
          <Typography className={classes.textWithIcon}>
            <PhoneIcon className={classes.textIcon} /> (307) 999-77-55
          </Typography>
          <Typography className={classes.textWithIcon}>
            <MailIcon className={classes.textIcon} /> Help@ChatFortress.com
          </Typography>
          <Typography variant="overline">@ 2019 - All Rights Reserved.</Typography>
        </Toolbar>
      </AppBar>
    </Hidden>
    <Hidden xsDown implementation="css">
      <AppBar position="relative" color="default" className={classnames(wide ? classes.appBarWide : classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <Logo color="dark" />
          <Typography className={classes.textWithIcon}>
            <PhoneIcon className={classes.textIcon} /> (307) 999-77-55
          </Typography>
          <Typography className={classes.textWithIcon}>
            <MailIcon className={classes.textIcon} /> Help@ChatFortress.com
          </Typography>
          <Typography variant="overline">@ 2019 - All Rights Reserved.</Typography>
        </Toolbar>
      </AppBar>
    </Hidden>
  </React.Fragment>
)

const styles = theme => ({
  appBar: {
    backgroundColor: '#17181e',
    color: 'white',
    zIndex: 1
  },
  appBarWide: {
    backgroundColor: '#17181e',
    color: 'white',
    position: 'fixed',
    bottom: 0
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  textWithIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textIcon: {
    marginRight: 7
  }
})

export default withStyles(styles)(Footer)
