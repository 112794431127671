/** @format */

import gql from 'graphql-tag'

export const proposalFragment = gql`
  fragment proposal on Proposal {
    name
    type
    notes {
      body
      createdAt
      author {
        name
        avatar
        id
      }
    }
    template
    productId
    resellerId
    soldAsPOC
    setupFee
    msp
    items
    unitPrice
    additionalFee
    additionalFees
    additionalUnitPrice
    additionalUnitAmount
    guardianAddon
    guardianAmount
    guardianPrice
    canceled
    planId
    currency
    signature
    subscriptionId
    userId
    user {
      id
      signature
      signingPerson {
        position
      }
    }
    signingPerson {
      position
    }
    signedOffline
    coupon
    domain
    companyName
    couponComments
    email
    userToken
    phone
    ip
    firstName
    middleName
    lastName
    emailSystem
    emailCount
    techInquiry
    status
    comment
    trialTypeDate
    trialDays
    trialDate
  }
`

export const getProposals = gql`
  query getProposals($name: String, $skip: Int, $limit: Int, $sort: [String]) {
    proposals(name: $name, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        ...proposal
      }
      count
      pages
    }
  }
  ${proposalFragment}
`

export const getProposal = gql`
  query getProposal($proposalId: String!) {
    proposal(id: $proposalId) {
      id
      ...proposal
    }
  }
  ${proposalFragment}
`

export const getProposalData = gql`
  query getProposalData($proposalId: String!) {
    proposal(id: $proposalId) {
      id
      ...proposal
      productData
      couponData
    }
  }
  ${proposalFragment}
`

export const createProposal = gql`
  mutation createProposal($data: ProposalInput!) {
    createProposal(data: $data) {
      id
      ...proposal
    }
  }
  ${proposalFragment}
`

export const addProposalNote = gql`
  mutation addProposalNote($proposalId: ID!, $body: String) {
    addProposalNote(id: $proposalId, body: $body) {
      id
    }
  }
`

export const updateProposal = gql`
  mutation updateProposal($proposalId: String!, $data: ProposalInput!) {
    updateProposal(id: $proposalId, data: $data) {
      id
      ...proposal
    }
  }
  ${proposalFragment}
`

export const acceptProposal = gql`
  mutation acceptProposal($proposalId: String!, $data: ProposalInput!) {
    acceptProposal(id: $proposalId, data: $data) {
      token
      userId
    }
  }
`

export const acceptProposalTemplate = gql`
  mutation acceptProposalTemplate($proposalId: String!, $data: ProposalInput!) {
    acceptProposalTemplate(id: $proposalId, data: $data) {
      token
      userId
    }
  }
`

export const sendProposal = gql`
  mutation sendProposal($id: String!) {
    sendProposal(id: $id) {
      id
      ...proposal
    }
  }
  ${proposalFragment}
`

export const removeProposal = gql`
  mutation removeProposal($id: String!) {
    removeProposal(id: $id) {
      id
    }
  }
`

export const getDataCreationProposal = gql`
  query getDataCreationProposal {
    resellers: users(
      roles: [
        "admin"
        "RC-Reseller"
        "RC-Reseller-Pro"
        "RC-Reseller-Starter"
        "CF-Reseller-Starter"
        "CF-Reseller"
        "CF-Reseller-Pro"
        "RC-Vendor"
        "RC-Influencer"
        "RC-SalesTier1"
        "RC-SalesTier2"
        "CF-RC-SalesTier1"
        "CF-RC-SalesTier2"
      ]
      sort: ["name", "1"]
      skip: 0
      limit: 0
    ) {
      data {
        value: id
        label: name
      }
    }
    products: productsProposal(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
        plans {
          value: id
          label: name
        }
      }
    }
  }
`

export const onCreateProposal = (cache, { data: { createProposal: proposal } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { proposals } = cache.readQuery({ query: getProposals, variables })
  cache.writeQuery({
    query: getProposals,
    variables,
    data: {
      proposals: {
        ...proposals,
        data: [proposal].concat(proposals.data)
      }
    }
  })
}

export const onRemoveProposal = (cache, { data: { removeProposal: proposal } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { proposals } = cache.readQuery({ query: getProposals, variables })
  cache.writeQuery({
    query: getProposals,
    variables,
    data: {
      proposals: {
        ...proposals,
        data: proposals.data.filter(r => r.id !== proposal.id)
      }
    }
  })
}
