/** @format */

import { useState, useCallback } from 'react'

export default () => {
  let initial = document.documentElement.clientWidth > 1024
  const [visible, setVisible] = useState(initial)
  const open = () => {
    setVisible(true)
  }
  const close = () => {
    setVisible(false)
  }
  const toggle = useCallback(() => {
    setVisible(!visible)
  }, [visible])

  return {
    visible,
    open,
    close,
    toggle
  }
}
