/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { connect } from 'react-redux'
import { Form as ReduxForm, reduxForm, getFormValues } from 'redux-form'
import { withSnackbar } from 'notistack'
import _ from 'lodash'
import gql from 'graphql-tag'

export const onSubmitSuccessCommon = (result, dispatch, { enqueueSnackbar }) => {
  enqueueSnackbar('You have successfully saved', { variant: 'success' })
}

export const onSubmitFailCommon = (errors, dispatch, submitError, { enqueueSnackbar, ...rest }) => {
  const { graphQLErrors = [], message } = submitError || {}
  console.log('onSubmitFail', errors, submitError, rest, rest.touch(rest.form, ...Object.keys(rest.registeredFields)))
  if (graphQLErrors.length) {
    submitError.graphQLErrors.map(error => {
      return enqueueSnackbar(error.message, { variant: 'error' })
    })
  } else if (errors && errors._error) {
    enqueueSnackbar(errors._error, { variant: 'error' })
  } else if (message) {
    enqueueSnackbar(message, { variant: 'error' })
  }
}

export const submitForm = gql`
  mutation submitForm($data: Object) {
    submitForm(data: $data) @client
  }
`

const Form = ({ children, mutation = [], query, handleSubmit, onSubmit, match, history, location, ...props }) => {
  // const [queryQuery, queryProps] = mutation
  const [mutationQuery, mutationProps = {}] = mutation
  return (
    <React.Fragment>
      <Mutation mutation={mutationQuery || submitForm} {...mutationProps}>
        {mutation => (
          <ReduxForm
            onSubmit={handleSubmit(data =>
              onSubmit
                ? onSubmit({ ...props, ...mutationProps, data, mutation })
                : mutation({ variables: { ...match.params, ...mutationProps.variables, data } })
            )}
          >
            {children({ ...props, ...mutationProps, mutation: mutationQuery })}
          </ReduxForm>
        )}
      </Mutation>
    </React.Fragment>
  )
}

const enhancer = compose(
  withRouter,
  withSnackbar,
  connect(
    (
      state,
      {
        form,
        asyncBlurFields,
        asyncChangeFields,
        asyncValidate,
        destroyOnUnmount,
        enableReinitialize,
        forceUnregisterOnUnmount,
        getFormState,
        immutableProps,
        initialValues = {},
        keepDirtyOnReinitialize,
        updateUnregisteredFields,
        onChange,
        onSubmit,
        onSubmitFail = onSubmitFailCommon,
        onSubmitSuccess = onSubmitSuccessCommon,
        propNamespace,
        pure,
        shouldAsyncValidate,
        shouldError,
        shouldWarn,
        touchOnBlur,
        touchOnChange,
        persistentSubmitErrors,
        validate,
        warn
      }
    ) => ({
      form,
      asyncBlurFields,
      asyncChangeFields,
      asyncValidate,
      destroyOnUnmount,
      enableReinitialize,
      forceUnregisterOnUnmount,
      getFormState,
      immutableProps,
      keepDirtyOnReinitialize,
      updateUnregisteredFields,
      onChange,
      onSubmit,
      onSubmitFail,
      onSubmitSuccess,
      propNamespace,
      pure,
      shouldAsyncValidate,
      shouldError,
      shouldWarn,
      touchOnBlur,
      touchOnChange,
      persistentSubmitErrors,
      validate,
      warn,
      initialValues: _.omit(initialValues, 'id', 'createdAt', 'updatedAt'),
      doc: getFormValues(form)(state) || initialValues
    })
  ),
  reduxForm()
)

export default enhancer(Form)

/*
  variables,
  refetchQueries,
  update,
  ignoreResults,
  optimisticResponse,
  context,
  onCompleted,
  onError,*/
