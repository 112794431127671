/** @format */

import moment from 'moment-timezone'
import countries from './countries.json'
import states from './states.json'

export const timezoneOptions = moment.tz.names().map(timezone => ({
  label: timezone,
  value: timezone
}))

export const countriesOptions = countries.map(country => ({
  label: country.name,
  value: country.id
}))
countriesOptions.unshift({ label: 'None', value: '' })

export const statesOptions = states.map(state => ({
  label: state.name,
  value: state.id
}))
statesOptions.unshift({ label: 'None', value: '' })

export const requestStatusOptions = [
  { label: 'Pending Validation', value: 'pending' },
  { label: 'Send', value: 'send' },
  { label: 'Sent', value: 'sent' },
  { label: 'Validate', value: 'validated' },
  { label: 'Active', value: 'active' },
  { label: 'Closed', value: 'closed' },
  { label: 'Voided', value: 'voided' }
]

export const proposalSystemOptions = [
  { label: 'Office 365', value: 'Office 365' },
  { label: 'Google GSuite', value: 'Google GSuite' },
  { label: 'Other', value: 'Other' },
  { label: 'Hybrid', value: 'Hybrid' }
]

export const blockTypeOptions = [
  { label: 'Wire Instructions', value: 'wireInstructions' },
  { label: 'Seller Account', value: 'sellerAccount' },
  { label: 'Contract Info Exchange', value: 'contractInfoExchange' },
  { label: 'Text Block', value: 'text' },
  { label: 'Access Permission', value: 'accessPermission' }
]

export const lessonTypeOptions = [
  { label: 'Fun', value: 'fun' },
  { label: 'Corparate', value: 'corparate' },
  { label: 'Nano', value: 'nano' }
]

export const contractInfoExchangeOptions = [
  { label: 'Buyer', value: 'buyer' },
  { label: 'Seller', value: 'seller' },
  { label: 'PDF Consult', value: 'pdf' }
]

export const accessPermissionOptions = [
  { label: 'ID Match', value: 'idMatch' },
  { label: 'ID Validation', value: 'idValidation' },
  { label: 'ID Validation + Bio', value: 'idValidationBio' },
  { label: 'Bio', value: 'bio' }
]

export const intervalOptions = [
  { label: 'days', value: 'day' },
  { label: 'weeks', value: 'week' },
  { label: 'months', value: 'month' },
  { label: 'years', value: 'year' }
]

export const currencyOptions = [
  { label: '$ (US Dollars)', value: 'usd', symbol: '$' },
  { label: '€ (Euro)', value: 'eur', symbol: '€' },
  { label: '£ (Great Britain Pound)', value: 'gbp', symbol: '£' },
  { label: '¥ (Japanese Yen)', value: 'jpy', symbol: '¥' },
  { label: '$ (Australian Dollar)', value: 'aud', symbol: '$' },
  { label: '$ (Argentine Peso)', value: 'ars', symbol: '$' },
  { label: 'R$ (Brazil Real)', value: 'brl', symbol: 'R$' },
  { label: '$ (Canada Dollar)', value: 'cad', symbol: '$' },
  { label: 'CHF (Swiss Franc)', value: 'chf', symbol: 'CHF' },
  { label: '¥ (Chinese Yuan Renminbi)', value: 'cny', symbol: '¥' },
  { label: '$ (Hong Kong Dollar)', value: 'hkd', symbol: '$' },
  { label: '₹ (Indian Rupee)', value: 'inr', symbol: '₹' },
  { label: 'Rp (Indonesian Rupiah)', value: 'idr', symbol: 'Rp' },
  { label: '$ (Mexico Peso)', value: 'mxn', symbol: '$' },
  { label: 'RM (Malaysia Ringit)', value: 'myr', symbol: 'RM' },
  { label: '$ (New Zealand Dollar)', value: 'nzd', symbol: '$' },
  { label: 'kr (Norwegian Krone)', value: 'nok', symbol: 'kr' },
  { label: '₱ (Phillipine Dollars)', value: 'php', symbol: '₱' },
  { label: 'руб (Russia Ruble)', value: 'rub', symbol: 'руб' },
  { label: '$ (Singapore Dollars)', value: 'sgd', symbol: '$' },
  { label: 'R (South Africa Rand)', value: 'zar', symbol: 'R' },
  { label: 'kr (Swedish Krona', value: 'sek', symbol: 'kr' },
  { label: '฿ (Thai Baht', value: 'tbh', symbol: '฿' },
  { label: 'NT$ (Taiwan Dollar', value: 'twd', symbol: 'NT$' },
  { label: 'د.إ (UAE Dirham)', value: 'aed', symbol: 'د.إ' },
  { label: '₴ (Ukrainian Hryvnia)', value: 'uah', symbol: '₴' },
  { label: '₫ (Vietnamese Dong)', value: 'vnd', symbol: '₫' }
]

export const accountHolderTypeOptions = [
  { label: 'Individual', value: 'individual' },
  { label: 'Company', value: 'company' }
]

export const monitoringObjectTypeOptions = [
  { label: 'Email Address', value: 'email' },
  { label: 'Domain Name', value: 'domain' },
  { label: 'Vendor Domain', value: 'vendor' }
]

export const attendeeTypeOptions = [{ label: 'Employee', value: 'Employee' }]

export const scheduleOptions = [
  { label: 'Single Lesson', value: 'single' },
  { label: 'Course', value: 'course' }
]
export const scheduleTypeOptions = [
  { label: 'Exact Date', value: 'exact' },
  { label: 'Period in Days', value: 'period' }
]

export const monitoringObjectAccountTypeOptions = [
  { label: 'Personal', value: 'personal' },
  { label: 'Company', value: 'company' },
  { label: 'Vendor', value: 'vendor' }
]

export const userAccountTypeOptions = [
  { label: 'Client Related', optgroup: true },
  { label: 'Client', value: 'client' },
  { label: 'Admin', value: 'admin' },
  { label: 'Company', value: 'Company' },
  { label: 'Company Manager', value: 'Company Manager' },
  { label: 'Employee', value: 'Employee' },
  { label: 'Vendor', value: 'Vendor' },
  { label: 'subemployee-7', value: 'subemployee-7' },

  { label: 'ChatFortress Related', optgroup: true },
  { label: 'Common User', value: 'user' },
  { label: 'CF-Admin', value: 'CF-Admin' },
  { label: 'CF-Manager', value: 'CF-Manager' },
  { label: 'CF-Employee', value: 'CF-Employee' },
  { label: 'CF-Reseller', value: 'CF-Reseller' },
  { label: 'CF-Reseller-Starter', value: 'CF-Reseller-Starter' },
  { label: 'CF-Reseller-Growth', value: 'CF-Reseller-Growth' },
  { label: 'CF-Reseller-Pro', value: 'CF-Reseller-Pro' },
  { label: 'CF-Referral', value: 'CF-Referral' },
  { label: 'CF-Sub-Reseller', value: 'CF-Sub-Reseller' },
  { label: 'CF-Sales-Rep', value: 'CF-Sales-Rep' },

  { label: 'Secure Chat Related', optgroup: true },
  { label: 'SC-Person', value: 'SC-Person' },
  { label: 'SC-Company', value: 'SC-Company' },
  { label: 'SC-Vendor', value: 'SC-Vendor' },

  { label: 'Report Card Related', optgroup: true },
  { label: 'RC-Person-Prospect', value: 'RC-Person-Prospect' },
  { label: 'RC-Person-Starter', value: 'RC-Person-Starter' },
  { label: 'RC-Person-Growth', value: 'RC-Person-Growth' },
  { label: 'RC-Person-Scaling', value: 'RC-Person-Scaling' },
  { label: 'RC-Company', value: 'RC-Company' },
  { label: 'RC-Vendor', value: 'RC-Vendor' }
  //{ label: 'Admin', value: 'admin' }
]

export const wizardStepComponentOptions = [
  { label: 'Campaign Settings', value: 'CampaignSettings' },
  { label: 'Conversions Tracking', value: 'ConversionsTracking' },
  { label: 'Upload Your List', value: 'UploadList' },
  { label: 'Select Campaign Activation', value: 'CampaignActivation' },
  { label: 'Geofence Targets', value: 'GeofenceTargets' },
  { label: 'Geo Events', value: 'GeoEvents' },
  { label: 'Keywords', value: 'Keywords' },
  { label: 'Confirm Your Information', value: 'UserInformation' },
  { label: 'Creatives', value: 'Creatives' },
  { label: 'Activation Complete', value: 'ActivationComplete' }
]

export const cardReportStatusOptions = [
  { label: 'Pending', value: 'pending' },
  { label: 'Processing', value: 'processing' },
  { label: 'Ready', value: 'ready' },
  { label: 'Error', value: 'error' }
]

export const productFieldOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Email Address', value: 'email' },
  { label: 'Phone Number', value: 'phone' },
  { label: 'Domain', value: 'domain' }
]
