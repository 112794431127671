/** @format */

import gql from 'graphql-tag'

export const subscriptionFragment = gql`
  fragment subscription on SubscriptionStripe {
    id
    billing
    billing_cycle_anchor
    cancel_at_period_end
    canceled_at
    created
    current_period_end
    current_period_start
    days_until_due
    ended_at
    quantity
    start
    start_date
    status
    tax_percent
    trial_end
    trial_start
    commissionType
  }
`
export const getSubscriptions = gql`
  query getSubscriptions($userId: String, $skip: Int, $limit: Int, $sort: [String]) {
    subscriptions(userId: $userId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        ...subscription
      }
      count
      pages
    }
  }
  ${subscriptionFragment}
`

export const getSubscription = gql`
  query getSubscription($subscriptionId: String!) {
    subscription(id: $subscriptionId) {
      id
      ...subscription
    }
  }
  ${subscriptionFragment}
`

export const getSubscriptionsWithProduct = gql`
  query getSubscriptionsWithProduct($userId: String, $skip: Int, $limit: Int, $sort: [String]) {
    subscriptions(userId: $userId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        ...subscription
        plan {
          id
          name
          billTimes
          amount
          setupFee
          freq
          interval
        }
        product {
          id
          name
          plans {
            id
            name
            billTimes
            amount
            setupFee
            freq
            interval
          }
        }
      }
      count
      pages
    }
  }
  ${subscriptionFragment}
`

export const getSubscriptionForUpdating = gql`
  query getSubscriptionForUpdating($subscriptionId: String!) {
    subscription(id: $subscriptionId) {
      id
      quantity
      plan {
        id
      }
      product {
        id
        name
        plans {
          id
          name
          billTimes
          amount
          setupFee
          freq
          interval
        }
      }
    }
  }
`

export const createSubscription = gql`
  mutation createSubscription($userId: String!, $data: SubscriptionStripeInput!) {
    createSubscription(userId: $userId, data: $data) {
      id
    }
  }
`

export const updateSubscription = gql`
  mutation updateSubscription($subscriptionId: String!, $data: SubscriptionStripeInput!) {
    updateSubscription(id: $subscriptionId, data: $data) {
      id
    }
  }
`

export const updateSubscriptionPlan = gql`
  mutation updateSubscriptionPlan($subscriptionId: String!, $data: SubscriptionPlanInput!) {
    updateSubscriptionPlan(id: $subscriptionId, data: $data) {
      id
      ...subscription
      plan {
        id
        name
        billTimes
        amount
        setupFee
        freq
        interval
      }
      product {
        id
        name
        plans {
          id
          name
          billTimes
          amount
          setupFee
          freq
          interval
        }
      }
    }
  }
  ${subscriptionFragment}
`

export const cancelSubscription = gql`
  mutation cancelSubscription($id: String!) {
    cancelSubscription(id: $id) {
      id
    }
  }
`

export const removeSubscription = gql`
  mutation removeSubscription($id: String!) {
    removeSubscription(id: $id) {
      id
    }
  }
`

export const onCreateSubscription = (cache, { data: { createSubscription: subscription } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { subscriptions } = cache.readQuery({ query: getSubscriptions, variables })
  cache.writeQuery({
    query: getSubscriptions,
    variables,
    data: {
      subscriptions: {
        ...subscriptions,
        data: [subscription].concat(subscriptions.data)
      }
    }
  })
}

export const onRemoveSubscription = (cache, { data: { removeSubscription: subscription } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { subscriptions } = cache.readQuery({ query: getSubscriptions, variables })
  cache.writeQuery({
    query: getSubscriptions,
    variables,
    data: {
      subscriptions: {
        ...subscriptions,
        data: subscriptions.data.filter(r => r.id !== subscription.id)
      }
    }
  })
}
