/** @format */

import gql from 'graphql-tag'

export const getVisitors = gql`
  query getVisitors($lastLocation: String,$dateFrom: DateTime, $dateTo: DateTime, $skip: Int, $limit: Int, $sort: [String]) {
    visitors(lastLocation: $lastLocation, dateFrom: $dateFrom, dateTo: $dateTo, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        lastLocation
        prevLocation
        siteId
        ip
        lastVisit
        visits {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      count
      pages
    }
  }
`

export const getVisits = gql`
  query getVisits($location: String, $createdAt: String, $visitorId: String, $dateFrom: DateTime, $dateTo: DateTime, $skip: Int, $limit: Int, $sort: [String]) {
    visits(location: $location, createdAt: $createdAt, visitorId: $visitorId, dateFrom: $dateFrom, dateTo: $dateTo, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        siteId
        location
        status
        timeOnPage
        client
        sessionUrl
        experimentId
        variant
        ip
        scrollRate
        visitorId
        createdAt
        updatedAt
      }
      count
      pages
    }
  }
`


export const getVisitsSessions = gql`
  query getVisits($location: String, $createdAt: String, $sessionUrl: Object, $variant: String, $experimentId: String, $visitorId: String, $dateFrom: DateTime, $dateTo: DateTime, $skip: Int, $limit: Int, $sort: [String]) {
    visits(location: $location, createdAt: $createdAt, sessionUrl: $sessionUrl, variant: $variant, experimentId: $experimentId, visitorId: $visitorId, dateFrom: $dateFrom, dateTo: $dateTo, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        siteId
        location
        status
        timeOnPage
        client
        notes
        sessionUrl
        experimentId
        variant
        ip
        scrollRate
        visitorId
        createdAt
        updatedAt
      }
      count
      pages
    }
  }
`
export const addVisitNote = gql`
  mutation addVisitNote($visitId: String!, $body: String) {
    addVisitNote(id: $visitId, body: $body) {
       id
        siteId
        location
        status
        timeOnPage
        client
        notes
        sessionUrl
        experimentId
        variant
        ip
        scrollRate
        visitorId
        createdAt
        updatedAt
    }
  }
`
