import React from 'react'
import _ from 'lodash'
import { Route } from 'react-router-dom'
import { ClientDashboard } from 'components/Dashboard'
import menuContent from 'data/menu'
import ProductMatcher from 'lib/productMatcher'

export default props => {
  let p = _.cloneDeep(props)
  if (p.context && p.context.user) {
    let user = p.context.user
    let ps = _.filter(
      user.purchasedProducts.map(prod => {
        if (!ProductMatcher(prod.id)) return null
        else return ProductMatcher(prod.id)
      }),
      b => b
    )

    // report dash hook
    if (ps && ps.length > 0 && ps[0] && ps[0].name === 'report') {
      if (user.company && (!user.company.isDomainVerified || !user.company.isQuizCompleted || !user.company.isCore4Completed))
        p.component = ClientDashboard
    }
  }
  let scope = _.find(menuContent, { to: p.path }).scope
  if (!p.context.acl.hasAccess(scope)) p.component = ClientDashboard
  return <Route {...p} />
}
