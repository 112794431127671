/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import MonitoringObjects from './MonitoringObjects'
import MonitoringObjectAdd from './MonitoringObjectAdd'
import MonitoringObjectUpdate from './MonitoringObjectUpdate'

export default ({ match }) => (
  <Container maxWidth="lg">
    <Box my={2}>
      <Switch>
        <Route path={`${match.path}`} component={MonitoringObjects} />
      </Switch>
      <Route path={`${match.path}/add/:filter?`} component={MonitoringObjectAdd} />
      <Route path={`${match.path}/:monitoringObjectId/update`} component={MonitoringObjectUpdate} />
    </Box>
  </Container>
)
