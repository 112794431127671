/** @format */

import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

export default ({
  input: { value, onChange },
  meta: { touched, invalid, error },
  label,
  LabelProps = {},
  FormGroupProps = {},
  options = [],
  helperText,
  ...rest
}) => {
  value = Array.from(value || [])
  const handleChange = event => {
    const { value: targetValue, checked } = event.target
    if (checked) {
      return onChange(value.concat(targetValue))
    }
    return onChange(value.filter(v => v !== targetValue))
  }
  return (
    <FormControl component="fieldset" margin="normal" {...rest}>
      {label && (
        <FormLabel component="legend" {...LabelProps}>
          {label}
        </FormLabel>
      )}
      <FormGroup {...FormGroupProps}>
        {Array.from(options).map(({ value: optionValue, ...option }) => (
          <FormControlLabel
            key={optionValue}
            control={<Checkbox checked={value.includes(optionValue)} onChange={handleChange} value={optionValue} />}
            {...option}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText margin="dense">{helperText}</FormHelperText>}
      {touched && invalid && error && (
        <FormHelperText margin="dense" error>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}
