/** @format */

/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useQuery } from 'react-apollo'
import { FormCard, FormSection, CompanyInformation } from 'lib/form'

import { getDataUpdatingEmployee, updateEmployee } from 'api/employees'

import withContext from 'lib/withAppContext'

const CompanyComponent = ({ match }) => {
  const { employeeId } = match.params
  const { data = {}, loading } = useQuery(getDataUpdatingEmployee, { variables: { employeeId }, fetchPolicy: 'cache-and-network' })
  const { employee = {}, companies: { data: companies = [] } = {} } = data

  return (
    !loading && (
      <FormCard
        form="EmployeeCompany"
        mutation={[updateEmployee, { variables: { employeeId }, refetchQueries: ['getDataUpdatingEmployee'] }]}
        initialValues={{ ...employee }}
      >
        {({ doc }) => (
          <React.Fragment>
            <FormSection name="">
              <CompanyInformation doc={doc} companies={companies} />
            </FormSection>
          </React.Fragment>
        )}
      </FormCard>
    )
  )
}

export default compose(withContext)(CompanyComponent)
