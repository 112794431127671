/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ScrollRates from './ScrollRates'
import Sessions from './Sessions'
import Visitors from './Visitors'
import Visits from './Visits'
import Nav from "../Nav";

const Module = ({ match, location }) => {
  const tabs = [
    { key: 'visitors', title: 'Visitors', to: `${match.url}`},
    { key: 'sessions', title: 'Recorded Sessions', to: `${match.url}/sessions`},
    { key: 'scrollrate', title: 'Scrolling Stats', to: `${match.url}/scrollrate`},
  ]
  return (
    <div>
      <Nav color="primary" items={tabs} activeKey={location.pathname.split('/')[2] || 'visitors'} />
      <Switch>
        <Route path={`${match.path}/visits/:visitorId`} component={Visits} />
        <Route path={`${match.path}`} component={Visitors} exact={true} />
        <Route path={`${match.path}/sessions`} component={Sessions} />
        <Route path={`${match.path}/scrollrate`} component={ScrollRates} />
      </Switch>
    </div>
  )
}

export default Module
