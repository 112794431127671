/** @format */

import React from 'react'
import compose from 'recompose/compose'
import Loader from 'react-loader-spinner'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import { withStyles, withTheme } from '@material-ui/core/styles'

const Spinner = ({ classes, theme, ...props }) => (
  <div className={classes.root}>
    <Loader type="ThreeDots" color={theme.palette.primary.main} height={80} width={80} timeout={60 * 1000} {...props} />
  </div>
)

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    paddingTop: 50
  }
})

export default compose(withTheme, withStyles(styles))(Spinner)
