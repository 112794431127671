/** @format */

import React, { useState, useEffect, useRef } from 'react'
import { Field, change } from 'redux-form'

import { TextField } from 'lib/form/fields'
import FormGroup from './FormGroup'

import Grid from '@material-ui/core/Grid'

import { countriesOptions, statesOptions } from 'data/options'

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};



function Address({ meta: { dispatch }, label = '', address = {}, fields={
  streetAddress: "route",
  streetAddress2: "administrative_area_level_4",
  locality: "locality",
  cityOrState: "administrative_area_level_1",
  postalcode: "postal_code",
  country: "country"}, ...rest} ) {
  const [query, setQuery] = useState("");
  const [showFields, setShowFields] = useState(false);
  const [fieldsForState, setFieldsForState] = useState(null);
  const autoCompleteRef = useRef(null)
console.log(rest)

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      //{ types: ["(cities)"]}
    );
    //autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject);

    const fieldsForState = {
      streetAddress: '',
      streetAddress2: '',
      locality: '',
      cityOrState: '',
      postalcode: '',
      country: ''
    }


    if( addressObject.address_components ) {
      const addrComps = addressObject.address_components;
      var matchForStreet1 = addressObject.adr_address ? addressObject.adr_address.match(/<span class="street-address">(.*?)<\/span>/) : false;
      if (matchForStreet1 && matchForStreet1[1]) fieldsForState.streetAddress = matchForStreet1[1];
      Object.keys(addrComps).map((index) => {
        const addrType = addrComps[index].types[0];
        switch (addrType) {
          case fields.locality:
            fieldsForState.locality = addrComps[index].long_name;
            break;

          case fields.cityOrState:
            fieldsForState.cityOrState = addrComps[index].short_name;
            break;

          case fields.country:
            fieldsForState.country = addrComps[index].short_name;
            break;

          case fields.postalcode:
            fieldsForState.postalcode = addrComps[index].long_name;
            break;

          default:
        }
      });
      setFieldsForState(fieldsForState)
      setShowFields(true)

      if(fieldsForState.streetAddress) {
        dispatch(change('RequestUpdate', 'signingPerson.address.addressLine1', fieldsForState.streetAddress))
        setShowFields(false)
      }
      else
        setShowFields(true)

      if(fieldsForState.streetAddress2)
        dispatch(change('RequestUpdate', 'signingPerson.address.addressLine2', fieldsForState.streetAddress2))

      if(fieldsForState.cityOrState || fieldsForState.locality)
        dispatch(change('RequestUpdate', 'signingPerson.address.city', fieldsForState.locality||fieldsForState.cityOrState))
      else
        setShowFields(true)

      if(fieldsForState.cityOrState)
        dispatch(change('RequestUpdate', 'signingPerson.address.state', fieldsForState.cityOrState))
      else
        setShowFields(true)

      if(fieldsForState.cityOrState)
        dispatch(change('RequestUpdate', 'signingPerson.address.county', fieldsForState.cityOrState))
      else
        setShowFields(true)

      if(fieldsForState.postalcode)
        dispatch(change('RequestUpdate', 'signingPerson.address.zip', fieldsForState.postalcode))
      else
        setShowFields(true)

      if(fieldsForState.country)
        dispatch(change('RequestUpdate', 'signingPerson.address.country', fieldsForState.country))
      else
        setShowFields(true)
    }

  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyD_bKhB6wrX8n6F_2pylLYpczuV4aS-Rpo&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <FormGroup title={label}>

      <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">
          <div className="search-location-input" style={{width: '100%'}}>
            <input
              className={'MuiInputBase-input MuiOutlinedInput-input'}
              style={{width: '100%'}}
              ref={autoCompleteRef}
              onChange={event => setQuery(event.target.value)}
              value={query}
            />
          </div>
          <fieldset aria-hidden="true" className="PrivateNotchedOutline-root-255 MuiOutlinedInput-notchedOutline">
            <legend className="PrivateNotchedOutline-legendLabelled-257"><span>{label}</span></legend>
          </fieldset>
        </div>
      </div>


      <div style={{display: showFields?'block':'none'}}>
        <Field name="addressLine1" component={TextField} label="Address Line 1" margin="none" multiline />
        <Field name="addressLine2" component={TextField} label="Address Line 2" multiline />
        <Grid container>
          <Grid xs={12} sm={4} item>
            <Field name="city" component={TextField} label="City" />
          </Grid>
          <Grid className="px-sm-3" xs={12} sm={4} item>
            {address && address.country === 'US' ? (
              <Field name="state" component={TextField} label="State" options={statesOptions} select />
            ) : (
              <Field name="county" component={TextField} label="County" />
            )}
          </Grid>
          <Grid xs={12} sm={4} item>
            <Field name="zip" component={TextField} label="Postal Code" />
          </Grid>
        </Grid>
        <Field name="country" component={TextField} label="Country" options={countriesOptions} select />
      </div>
    </FormGroup>
  );
}

export default Address
