/** @format */

import gql from 'graphql-tag'

export const lessonFragment = gql`
  fragment lesson on Lesson {
    name
    type
    order
    summary
    video
    htmlBlock
    teaserVideo
    teaserDescription
    corporateVideo
    nanoVideo
    infographicImage
    cartoonImage
    promotionPosterImage
    teachableMomentImage
    nuggetImage
    quiz
    quizPoints
    quizSuccessMessage
    content
    releaseHeadline
    released
    sheduleAt
    releasedAt
    featured
    featureHeadline
    paid
    plans
    ldGuestAccess
    courseId
    metaData {
      title
      description
      keywords
      image
    }
  }
`

export const getLessons = gql`
  query getLessons($courseId: String, $q: String, $skip: Int, $limit: Int, $sort: Object) {
    lessons(courseId: $courseId, q: $q, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        order
        type
        paid
        plans
        released
        sheduleAt
        releasedAt
        featured
        quizPoints
        createdAt
      }
      count
      pages
    }
  }
`

export const getDataLessons = gql`
  query getDataLessons($courseId: String!) {
    course(id: $courseId) {
      id
      name
      product {
        id
        name
      }
      plans {
        id
        name
      }
    }
  }
`

export const getLesson = gql`
  query getLesson($lessonId: String!) {
    lesson(id: $lessonId) {
      id
      ...lesson
    }
  }
  ${lessonFragment}
`

export const getTrainingLesson = gql`
  query getTrainingLesson($lessonId: String!) {
    lesson(id: $lessonId) {
      id
      name
      order
      summary
      htmlBlock
      teaserVideo
      video
      teachableMomentImage
      infographicImage
      corporateVideo
      nanoVideo
      cartoonImage
      promotionPosterImage
      nuggetImage
      teaserDescription
      quiz(format: "json")
      quizPoints
      quizSuccessMessage
      content
      released
      releaseHeadline
      sheduleAt
      releasedAt
      featured
      featureHeadline
      paid
      plans
      ldGuestAccess
      userStats {
        id
        quizPoints
      }
      course {
        id
        name
        description
        cover
        paid
        status
        publishedAt
        countLessons
        order {
          id
          productId
          planId
          status
        }
        author {
          id
          name
          email
          phone
          avatar
        }
        userStats {
          id
          quizPoints
          countCompletedLessons
        }
        lessons {
          id
          name
        }
      }
    }
  }
`

export const getDataCreationLesson = gql`
  query getDataCreationLesson($courseId: String!) {
    course(id: $courseId) {
      id
      name
      product {
        id
        name
      }
      plans {
        value: id
        label: name
      }
    }
  }
`

export const shareLesson = gql`
  mutation shareLesson($requestId: String, $data: ShareInput!) {
    shareLesson(requestId: $requestId, data: $data) {
      id
    }
  }
`

export const createLesson = gql`
  mutation createLesson($data: LessonInput!) {
    createLesson(data: $data) {
      id
      ...lesson
    }
  }
  ${lessonFragment}
`

export const getDataUpdatingLesson = gql`
  query getDataUpdatingLesson($courseId: String!, $lessonId: String!) {
    lesson(id: $lessonId) {
      id
      ...lesson
    }
    course(id: $courseId) {
      id
      name
      product {
        id
        name
      }
      plans {
        value: id
        label: name
      }
    }
  }
  ${lessonFragment}
`

export const updateLesson = gql`
  mutation updateLesson($lessonId: String!, $data: LessonInput!) {
    updateLesson(id: $lessonId, data: $data) {
      id
      ...lesson
    }
  }
  ${lessonFragment}
`


export const updateLessonOrder = gql`
  mutation updateLessonOrder($courseId: String!, $data: [LessonOrderInput]) {
    updateLessonOrder(courseId: $courseId, data: $data) {
      id
    }
  }
`

export const removeLesson = gql`
  mutation removeLesson($id: String!) {
    removeLesson(id: $id) {
      id
    }
  }
`

export const onCreateLesson = (cache, { data: { createLesson: lesson } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { lessons } = cache.readQuery({ query: getLessons, variables })
  cache.writeQuery({
    query: getLessons,
    variables,
    data: {
      lessons: {
        ...lessons,
        data: [lesson].concat(lessons.data)
      }
    }
  })
}

export const onRemoveLesson = (cache, { data: { removeLesson: lesson } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { lessons } = cache.readQuery({ query: getLessons, variables })
  cache.writeQuery({
    query: getLessons,
    variables,
    data: {
      lessons: {
        ...lessons,
        data: lessons.data.filter(r => r.id !== lesson.id)
      }
    }
  })
}
