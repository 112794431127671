/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Employees from './Employees'
import Employee from './Employee'
import EmployeeCreate from './EmployeeCreate'
import EmployeeImport from './EmployeeImport'

const Module = ({ match }) => (
  <Container maxWidth="lg">
    <div className="mt-4">
      <p>
        Employees is how you create and manage your child (sub) accounts that are connected to your master account. Employees can login and
        access the ChatFortress system.
        <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e59114004286364bc95">
          Get help about this page here
        </a>
      </p>
    </div>
    <Box my={2}>
      <Switch>
        <Route path={`${match.path}/:employeeId/details`} component={Employee} />
        <Route path={`${match.path}`} component={Employees} />
      </Switch>
      <Switch>
        <Route path={`${match.path}/create`} component={EmployeeCreate} />
        <Route path={`${match.path}/import`} component={EmployeeImport} />
      </Switch>
    </Box>
  </Container>
)

export default Module
