/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useQuery, useMutation } from 'react-apollo'
import withConfirm from 'lib/withConfirm'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

import { getInvoices, removeInvoice } from 'api/invoices'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import CheckboxTable from 'lib/tables/CheckboxTable'
import UserCell from 'lib/tables/UserCell'
import SelectFilter from 'lib/tables/SelectFilter'

const Invoices = ({ classes, history, match, alert, enqueueSnackbar }) => {
  const [remove, { loading: loadingRemove }] = useMutation(removeInvoice, { refetchQueries: ['getInvoices'] })

  return (
    <React.Fragment>
      <CheckboxTable
        query={getInvoices}
        variables={match.params}
        initialVariables={{ sorted: [{ id: 'created', desc: true }] }}
        columns={[
          {
            Header: 'Owner',
            accessor: 'userId',
            width: 250,
            Cell: ({ original: invoice }) => <UserCell user={invoice.user} />
            //Filter: props => <SelectFilter {...props} data={users} />
          },
          {
            Header: 'Number',
            accessor: 'number',
            width: 250,
            Cell: ({ original: invoice }) => (
              <div>
                <strong>{invoice.name}</strong>
                <br />
                <span>{invoice.number}</span>
              </div>
            )
          },
          {
            Header: 'Status / Amount',
            accessor: 'status',
            width: 100,
            Cell: ({ original: invoice }) => (
              <div>
                <strong>{numeral(invoice.total).format('$0,0.00')}</strong>
                <br />
                <span>{invoice.status}</span>
              </div>
            ),
            Filter: props => (
              <SelectFilter {...props} data={['draft', 'open', 'paid', 'uncollectible', 'void'].map(s => ({ id: s, name: s }))} />
            )
          },
          {
            Header: 'Dates',
            id: 'created',
            accessor: 'created',
            Cell: ({ original: invoice }) => (
              <div>
                <div>
                  <em>Created on: </em>
                  <strong>{moment(invoice.created).format('ll')}</strong>
                </div>
                <div>
                  <em>Period is </em>
                  <strong>
                    {moment(invoice.period_start).format('ll')} - {moment(invoice.period_end).format('ll')}
                  </strong>
                </div>
              </div>
            )
          },
          {
            Header: 'Actions',
            sortable: false,
            filterable: false,
            accessor: 'id',
            Cell: ({ original: invoice }) => {
              const actions = [
                {
                  label: 'Receipt PDF',
                  color: 'default',
                  icon: 'save_alt',
                  href: invoice.hosted_invoice_url,
                  target: '_blank',
                  title: 'Download Receipt PDF',
                  show: invoice.hosted_invoice_url
                },
                {
                  label: 'Invoice PDF',
                  color: 'default',
                  icon: 'save_alt',
                  href: invoice.invoice_pdf,
                  target: '_blank',
                  title: 'Download Invoice PDF',
                  show: invoice.invoice_pdf
                }
              ]
              return (
                <div>
                  {actions.map(({ show, icon, ...action }, key) => {
                    return (
                      show && (
                        <Button size="small" color="primary" variant="contained" className={classes.actionButton} key={key} {...action}>
                          <Icon className={classes.icon}>{icon}</Icon> {action.label}
                        </Button>
                      )
                    )
                  })}
                </div>
              )
            }
          }
        ]}
        deleteMany={false}
      />
    </React.Fragment>
  )
}

const styles = theme => ({
  root: {},
  actionButton: {
    marginRight: 5
  },
  icon: {
    marginRight: 5
  }
})

export default compose(withConfirm, withStyles(styles))(Invoices)

/*
                {
                  color: 'default',
                  icon: 'build',
                  onClick: () => history.push(`${match.url}/${invoice.id}/update`),
                  title: 'Invoice Builder',
                  show: ['agency', 'admin'].includes(user.accountType) && invoice.status === 'draft'
                },
                {
                  color: 'default',
                  icon: 'lock',
                  onClick: () => finalize({ variables: { invoiceId: invoice.id } }),
                  title: 'Finalize Invoice',
                  disabled: locking,
                  show: ['agency', 'admin'].includes(user.accountType) && invoice.status === 'draft'
                },
                {
                  color: 'secondary',
                  icon: 'delete',
                  onClick: () =>
                    alert.confirm('Are you sure?', 'You will not be able to recover this invoice!', () =>
                      remove({ variables: { invoiceId: invoice.id } })
                    ),
                  title: 'Delete Invoice',
                  disabled: removing,
                  show: ['agency', 'admin'].includes(user.accountType) && invoice.status === 'draft'
                },
                {
                  color: 'default',
                  icon: 'send',
                  onClick: () => send({ variables: { invoiceId: invoice.id } }),
                  title: 'Send Invoice to Client',
                  disabled: sending,
                  show: ['agency', 'admin'].includes(user.accountType) && invoice.status === 'open'
                },
                {
                  color: 'default',
                  icon: 'payment',
                  onClick: () => history.push(`${match.url}/${invoice.id}/pay`),
                  title: 'Pay Invoice',
                  show: invoice.status === 'open'
                },
                {
                  color: 'secondary',
                  icon: 'cancel',
                  onClick: () =>
                    alert.confirm('Are you sure?', 'You will not be able to recover this invoice!', () =>
                      cancel({ variables: { invoiceId: invoice.id } })
                    ),
                  title: 'Void Invoice',
                  disabled: canceling,
                  show: ['agency', 'admin'].includes(user.accountType) && invoice.status === 'open'
                },
                {
                  color: 'default',
                  icon: 'send',
                  onClick: () => sendEmail({ variables: { invoiceId: invoice.id } }),
                  title: 'Re Send Paid Invoice to Client',
                  disabled: sending,
                  show: ['agency', 'admin'].includes(user.accountType) && invoice.status === 'paid'
                }
*/
