import React from 'react'
import moment from "moment/moment"
import CheckboxTable from 'lib/tables/CheckboxTable'
import _ from 'lodash'
import {
  Link,
  useHistory
} from "react-router-dom"
import { getVisitors } from 'api/visitors'

export default ({ match, location, history }) => {

  return (
    <React.Fragment>
      <div className="p-4">

        <h3>Visitors</h3>
        <CheckboxTable
          query={getVisitors}
          initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
          variables={{ }}
          columns={[
            {
              Header: 'IDs',
              accessor: 'name',
              Cell: ({ original: visitor }) => (
                <span>{visitor.id}</span>
              )
            },
            {
              Header: 'Total Visits',
              accessor: 'visits',
              Cell: ({ original: visitor }) => (
                <span>{visitor.visits.length}</span>
              )
            },
            {
              Header: 'First Visit',
              accessor: 'createdAt',
              Cell: ({ original: visitor }) => (
                <span>{new Date(visitor.createdAt).toLocaleString()}</span>
              )
            },
            {
              Header: 'Last Visit',
              accessor: 'visits',
              Cell: ({ original: visitor }) => (
                <span>{new Date((_.maxBy(visitor.visits, v=>v.updatedAt)||{}).updatedAt).toLocaleString()}</span>
              )
            },
            {
              Header: 'Last Location',
              accessor: 'lastLocation'
            },
            {
              Header: 'Actions',
              sortable: false,
              filterable: false,
              accessor: 'id',
              Cell: ({ original: visitor }) => (
                <div className={'pull-right'}>
                  <Link className={'btn btn-primary btn-sm mr-1'} to={`/visitors/visits/${visitor.id}`}>See visits</Link>
                </div>
              )
            }
          ]}
          deleteMany={false}
        />

      </div>
    </React.Fragment>
  )
}
