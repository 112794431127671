/** @format */

import gql from 'graphql-tag'

export const licenseFragment = gql`
  fragment license on License {
    name
    productId
    planId
    quantity
  }
`
export const getLicenses = gql`
  query getLicenses($skip: Int, $limit: Int, $sort: [String]) {
    licenses(skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        quantity
        status
        subscriptionStatus
        usageCount
        product {
          id
          name
        }
        plan {
          id
          name
        }
      }
      count
      pages
    }
  }
`

export const getLicense = gql`
  query getLicense($licenseId: String!) {
    license(id: $licenseId) {
      id
      ...license
    }
  }
  ${licenseFragment}
`

export const getDataCreationLicense = gql`
  query getDataCreationLicense {
    products(limit: 0, sort: ["name", "1"]) {
      data {
        id
        name
        couponUpfrontOnly
        allowLicenses
        plans {
          id
          name
          billTimes
          amount
          setupFee
          freq
          interval
          trial_period_days
        }
      }
    }
    sources(limit: 0) {
      data {
        id
        displayName
        object
        status
      }
    }
  }
`

export const addLicense = gql`
  mutation addLicense($data: LicenseInput!) {
    addLicense(data: $data) {
      id
      ...license
    }
  }
  ${licenseFragment}
`

export const getDataUpdatingLicense = gql`
  query getDataUpdatingLicense($licenseId: String!) {
    license(id: $licenseId) {
      id
      ...license
    }
    products(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
        allowLicenses
        plans {
          value: id
          label: name
        }
      }
    }
  }
  ${licenseFragment}
`

export const updateLicense = gql`
  mutation updateLicense($licenseId: String!, $data: LicenseInput!) {
    updateLicense(id: $licenseId, data: $data) {
      id
      ...license
    }
  }
  ${licenseFragment}
`

export const removeLicense = gql`
  mutation removeLicense($id: String!) {
    removeLicense(id: $id) {
      id
    }
  }
`

export const onCreateLicense = (cache, { data: { addLicense: license } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { licenses } = cache.readQuery({ query: getLicenses, variables })
  cache.writeQuery({
    query: getLicenses,
    variables,
    data: {
      licenses: {
        ...licenses,
        data: [license].concat(licenses.data)
      }
    }
  })
}

export const onRemoveLicense = (cache, { data: { removeLicense: license } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { licenses } = cache.readQuery({ query: getLicenses, variables })
  cache.writeQuery({
    query: getLicenses,
    variables,
    data: {
      licenses: {
        ...licenses,
        data: licenses.data.filter(r => r.id !== license.id)
      }
    }
  })
}
