/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

//import Container from '@material-ui/core/Container'
//import Box from '@material-ui/core/Box'

import AddLicense from 'components/Licenses/LicenseAdd'
import Courses from './Courses'
import Course from './Course'
import CourseBuy from './CourseBuy'
import Lesson from './Lesson'

const Module = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/buy/:courseId`} component={CourseBuy} />
    <Route path={`${match.path}/course/:courseId/lesson/:lessonId`} component={Lesson} />
    <Route path={`${match.path}/course/:courseId`} component={Course} />
    <Route path={`${match.path}`} component={Courses} />
  </Switch>
)

export default Module
