/** @format */

import gql from 'graphql-tag'

export const planFragment = gql`
  fragment plan on Plan {
    name
    productId
    usage_type
    billTimes
    amount
    setupFee
    freq
    interval
    trial_period_days
    roles
  }
`

export const getPlans = gql`
  query getPlans($productId: String, $skip: Int, $limit: Int, $sort: [String]) {
    plans(productId: $productId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        billTimes
        usage_type
        amount
        setupFee
        freq
        interval
        trial_period_days
        roles
        product {
          id
          name
        }
      }
      count
      pages
    }
  }
`

export const getPlan = gql`
  query getPlan($planId: String!) {
    plan(id: $planId) {
      id
      ...plan
    }
  }
  ${planFragment}
`

export const createPlan = gql`
  mutation createPlan($data: PlanInput!) {
    createPlan(data: $data) {
      id
      ...plan
    }
  }
  ${planFragment}
`

export const updatePlan = gql`
  mutation updatePlan($planId: String!, $data: PlanInput!) {
    updatePlan(id: $planId, data: $data) {
      id
      ...plan
    }
  }
  ${planFragment}
`

export const removePlan = gql`
  mutation removePlan($id: String!) {
    removePlan(id: $id) {
      id
    }
  }
`

export const onCreatePlan = (cache, { data: { createPlan: plan } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { plans } = cache.readQuery({ query: getPlans, variables })
  cache.writeQuery({
    query: getPlans,
    variables,
    data: {
      plans: {
        ...plans,
        data: [plan].concat(plans.data)
      }
    }
  })
}

export const onRemovePlan = (cache, { data: { removePlan: plan } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { plans } = cache.readQuery({ query: getPlans, variables })
  cache.writeQuery({
    query: getPlans,
    variables,
    data: {
      plans: {
        ...plans,
        data: plans.data.filter(r => r.id !== plan.id)
      }
    }
  })
}
