import gql from 'graphql-tag'

export const phoneNumberFragment = gql`
  fragment phoneNumber on IncomingPhoneNumber {
    id
    accountSid
    addressSid
    addressRequirements
    beta
    capabilities {
      voice
      sms
      mms
    }
    createdAt
    friendlyName
    identitySid
    phoneNumber
    origin
    smsApplicationSid
    smsFallbackMethod
    smsFallbackUrl
    smsMethod
    smsUrl
    statusCallback
    statusCallbackMethod
    trunkSid
    voiceApplicationSid
    voiceCallerIdLookup
    voiceFallbackMethod
    voiceFallbackUrl
    voiceMethod
    voiceUrl
    emergencyStatus
    emergencyAddressSid
    dialNumbers
    welcomeVoiceText
    smsNumbers
    welcomeSmsText
  }
`

export const availablePhoneNumberFragment = gql`
  fragment availablePhoneNumber on AvailablePhoneNumber {
    phoneNumber
    friendlyName
    lata
    rateCenter
    latitude
    longitude
    locality
    region
    postalCode
    isoCountry
    beta
    capabilities {
      voice
      SMS
      MMS
    }
  }
`

export const getPhoneNumbers = gql`
  query getPhoneNumbers($userId: String, $skip: Int, $limit: Int, $sort: [String]) {
    phoneNumbers(userId: $userId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        ...phoneNumber
        status
        releasedAt
      }
      count
      pages
    }
  }
  ${phoneNumberFragment}
`

export const getCalls = gql`
  query getCalls($phoneNumber: String, $from: String, $to: String, $status: String, $skip: Int, $limit: Int, $sort: [String]) {
    calls(phoneNumber: $phoneNumber, from: $from, to: $to, status: $status, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        from
        to
        direction
        duration
        status
        createdAt
      }
      count
      pages
    }
  }
`
export const getMessages = gql`
  query getMessages($phoneNumber: String, $from: String, $to: String, $status: String, $skip: Int, $limit: Int, $sort: [String]) {
    smses(phoneNumber: $phoneNumber, from: $from, to: $to, status: $status, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        from
        to
        status
        body
        createdAt
      }
      count
      pages
    }
  }
`

export const searchPhoneNumbers = gql`
  query searchPhoneNumbers($query: AvailablePhoneNumberQueryInput) {
    availablePhoneNumbers(query: $query) {
      ...availablePhoneNumber
    }
  }
  ${availablePhoneNumberFragment}
`

export const getPhoneNumber = gql`
  query getPhoneNumber($phoneNumberId: String!) {
    phoneNumber(id: $phoneNumberId) {
      id
      ...phoneNumber
    }
  }
  ${phoneNumberFragment}
`

export const buyPhoneNumber = gql`
  mutation buyPhoneNumber($data: PhoneNumberInput!) {
    buyPhoneNumber(data: $data) {
      id
      ...phoneNumber
    }
  }
  ${phoneNumberFragment}
`

export const updatePhoneNumber = gql`
  mutation updatePhoneNumber($phoneNumberId: String!, $data: PhoneNumberInput!) {
    updatePhoneNumber(id: $phoneNumberId, data: $data) {
      id
      friendlyName
      smsUrl
      voiceUrl
      dialNumbers
      welcomeVoiceText
      smsNumbers
      welcomeSmsText
    }
  }
`

export const restorePhoneNumber = gql`
  mutation restorePhoneNumber($phoneNumberId: String!) {
    restorePhoneNumber(id: $phoneNumberId) {
      id
    }
  }
`

export const releasePhoneNumber = gql`
  mutation releasePhoneNumber($phoneNumberId: String!) {
    releasePhoneNumber(id: $phoneNumberId) {
      id
    }
  }
`

export const removePhoneNumber = gql`
  mutation removePhoneNumber($id: String!) {
    removePhoneNumber(id: $id) {
      id
    }
  }
`
