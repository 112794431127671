import React, { useState } from 'react'
import compose from 'recompose/compose'
//import { Redirect } from 'react-router-dom'
import { useMutation, useQuery } from 'react-apollo'
import { FormDialog, Field, FormSection, PaymentMethod } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, numericality } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'
import PlanSummary from 'components/Plans/Summary'

import { getDataCreationLicense, addLicense } from 'api/licenses'
import { verifyCouponAction } from 'api/products'
import { createCCToken } from 'api/actions'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const LicenseAdd = ({ match }) => {
  const [coupon, setCoupon] = useState(null)
  const { data: { products: { data: products } = {}, sources: { data: sources } = {} } = {}, loading } = useQuery(getDataCreationLicense, {
    fetchPolicy: 'cache-and-network'
  })
  const [verifyCoupon] = useMutation(verifyCouponAction)

  const asyncValidate = async values => {
    const { coupon = '' } = values
    return verifyCoupon({ variables: { code: coupon } }).then(({ data: { verifyCoupon } }) => {
      if (coupon && (!verifyCoupon || !verifyCoupon.id)) {
        // eslint-disable-next-line
        throw { coupon: 'Coupon is invalid' }
      } else setCoupon(verifyCoupon)
    })
  }

  return (
    !loading && (
      <FormDialog
        title="Create License"
        form="LicenseAdd"
        mutation={[addLicense, { refetchQueries: ['getLicenses'] }]}
        onSubmit={({ data, mutation }) =>
          createCCToken(data.source).then(({ token }) => mutation({ variables: { data: { ...data, source: token } } }))
        }
        initialValues={{ quantity: 1, paymentMethod: sources && sources.length > 0 ? sources[0].id : null }}
        asyncValidate={asyncValidate}
        asyncBlurFields={['coupon']}
        btnSubmitText="Create"
        success="License created successfully"
      >
        {({ doc, invalid, submitting }) => {
          let product = null
          let plan = null
          if (doc.productId) {
            product = products.find(it => it.id === doc.productId)
          }
          if (product && doc.planId) {
            plan = product.plans.find(it => it.id === doc.planId)
          }

          return (
            <React.Fragment>
              <Typography variant="h5">License Information</Typography>
              <Field
                name="name"
                component={TextField}
                label="License Name"
                validate={[required()]}
                helperText="Enter a name for your reference that helps you understand who you are applying these licenses for. This name if for your reference only."
              />
              <Field
                name="productId"
                component={TextField}
                label="Product"
                options={products.filter(p => p.allowLicenses).map(({ id, name }) => ({ label: name, value: id }))}
                validate={[required()]}
                select
              />
              {doc.productId && (
                <Field
                  name="planId"
                  component={TextField}
                  label="Plan"
                  options={product && product.plans.map(({ id, name }) => ({ label: name, value: id }))}
                  validate={[required()]}
                  select
                />
              )}
              <Field
                name="quantity"
                type="number"
                component={TextField}
                label="Number of Licenses"
                validate={[required(), numericality()]}
                normalize={number}
              />
              <Field name="coupon" component={TextField} label="Coupon Code" placeholder="Left empty if you dont know" />
              {plan && product && <PlanSummary plan={plan} product={product} quantity={doc.quantity} coupon={coupon} />}

              <FormSection name="">
                <PaymentMethod sources={sources} paymentMethod={doc.paymentMethod} />
              </FormSection>
            </React.Fragment>
          )
        }}
      </FormDialog>
    )
  )
}

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(LicenseAdd)

// onComplete={() => (window.location.href = `/account/licenses/add`)}
//{/*<Redirect from={} to={} />*/}
/*
 <Redirect
          from={match.url}
          to={match.url
            .split('/')
            .slice(0, -1)
            .concat('credit-card-add')
            .join('/')}
        />*/
