/** @format */

import React from 'react'
import { Field } from 'redux-form'

import { TextField } from 'lib/form/fields'
import FormGroup from './FormGroup'

import Grid from '@material-ui/core/Grid'

import { countriesOptions, statesOptions } from 'data/options'

class Address extends React.Component {
  static defaultProps = {
    name: 'address'
  }

  render() {
    const { title = '', address = {} } = this.props

    return (
      <FormGroup title={title}>
        <Field name="addressLine1" component={TextField} label="Address Line 1" margin="none" multiline />
        <Field name="addressLine2" component={TextField} label="Address Line 2" multiline />
        <Grid container>
          <Grid xs={12} sm={4} item>
            <Field name="city" component={TextField} label="City" />
          </Grid>
          <Grid className="px-sm-3" xs={12} sm={4} item>
            {address && address.country === 'US' ? (
              <Field name="state" component={TextField} label="State" options={statesOptions} select />
            ) : (
              <Field name="county" component={TextField} label="County" />
            )}
          </Grid>
          <Grid xs={12} sm={4} item>
            <Field name="zip" component={TextField} label="Postal Code" />
          </Grid>
        </Grid>
        <Field name="country" component={TextField} label="Country" options={countriesOptions} select />
      </FormGroup>
    )
  }
}

export default Address
