/** @format */

import gql from 'graphql-tag'

export const courseFragment = gql`
  fragment course on Course {
    name
    type
    instantRoles
    isSequence
    description
    cover
    checkoutPageUrl
    paid
    status
    productId
    roles
  }
`

export const getCourses = gql`
  query getCourses($skip: Int, $limit: Int, $sort: [String]) {
    courses(skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        isSequence
        cover
        checkoutPageUrl
        type
        status
        countLessons
        publishedAt
        createdAt
      }
      count
      pages
    }
  }
`

export const getCourse = gql`
  query getCourse($courseId: String!) {
    course(id: $courseId) {
      id
      ...course
    }
  }
  ${courseFragment}
`

export const getTrainingCourse = gql`
  query getTrainingCourse($courseId: String!) {
    course(id: $courseId) {
      id
      name
      isSequence
      description
      cover
      instantRoles
      roles
      checkoutPageUrl
      paid
      status
      publishedAt
      countLessons
      order {
        id
        productId
        planId
        status
      }
      author {
        id
        name
        email
        phone
        avatar
      }
      userStats {
        id
        quizPoints
        countCompletedLessons
      }
      lessons {
        id
        idtemp
        name
        order
        summary
        teaserVideo
        video
        teachableMomentImage
        infographicImage
        corporateVideo
        nanoVideo
        cartoonImage
        promotionPosterImage
        nuggetImage
        teaserDescription
        quiz(format: "yaml")
        quizPoints
        quizSuccessMessage
        content
        released
        releaseHeadline
        sheduleAt
        releasedAt
        featured
        featureHeadline
        paid
        plans
        ldGuestAccess
        userStats {
          id
          quizPoints
        }
        schedule {
          id
          requestId
          scheduleDate
          sent
        }
      }
      trainingActivities(limit: 200, sort: ["createdAt", "-1"]) {
        id
        action
        lessonId
        data
        createdAt
      }
    }
  }
`

export const getDataBuyingCourse = gql`
  query getDataBuyingCourse($masterAccountId: ID!, $courseId: String!) {
    course(id: $courseId) {
      id
      name
      description
      cover
      paid
      productId
      status
      publishedAt
    }
    employees: users(masterAccountId: $masterAccountId, limit: 0, sort: ["name", "-1"], accountType: "Employee") {
      data {
        id
        name
        firstName
        lastName
        email
        phone
        avatar
        accountType
      }
    }
    products(limit: 0, sort: ["name", "1"]) {
      data {
        id
        name
        plans {
          id
          name
          billTimes
          amount
          setupFee
          freq
          interval
          trial_period_days
        }
      }
    }
    sources(limit: 0) {
      data {
        id
        displayName
        object
        status
      }
    }
  }
`

export const getDataCreationCourse = gql`
  query getDataCreationCourse {
    products(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
        plans {
          value: id
          label: name
        }
      }
    }
  }
`

export const createCourse = gql`
  mutation createCourse($data: CourseInput!) {
    createCourse(data: $data) {
      id
      ...course
    }
  }
  ${courseFragment}
`

export const getDataUpdatingCourse = gql`
  query getDataUpdatingCourse($courseId: String!) {
    course(id: $courseId) {
      id
      ...course
    }
    products(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
      }
    }
  }
  ${courseFragment}
`

export const updateCourse = gql`
  mutation updateCourse($courseId: String!, $data: CourseInput!) {
    updateCourse(id: $courseId, data: $data) {
      id
      ...course
    }
  }
  ${courseFragment}
`

export const publishCourse = gql`
  mutation publishCourse($courseId: String!) {
    publishCourse(id: $courseId) {
      id
      ...course
    }
  }
  ${courseFragment}
`

export const unpublishCourse = gql`
  mutation unpublishCourse($courseId: String!) {
    unpublishCourse(id: $courseId) {
      id
      ...course
    }
  }
  ${courseFragment}
`

export const removeCourse = gql`
  mutation removeCourse($id: String!) {
    removeCourse(id: $id) {
      id
    }
  }
`

export const onCreateCourse = (cache, { data: { createCourse: course } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { courses } = cache.readQuery({ query: getCourses, variables })
  cache.writeQuery({
    query: getCourses,
    variables,
    data: {
      courses: {
        ...courses,
        data: [course].concat(courses.data)
      }
    }
  })
}

export const onRemoveCourse = (cache, { data: { removeCourse: course } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { courses } = cache.readQuery({ query: getCourses, variables })
  cache.writeQuery({
    query: getCourses,
    variables,
    data: {
      courses: {
        ...courses,
        data: courses.data.filter(r => r.id !== course.id)
      }
    }
  })
}
