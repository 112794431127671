/** @format */

import React from 'react'
import moment from 'moment'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'

const defaultState = {
  month: '',
  day: '',
  year: ''
}

class Birthdate extends React.Component {
  // state = defaultState

  constructor(props) {
    super(props)

    const { month, day, year } = this.formatToObject(props.input.value, defaultState)

    this.state = { month, day, year }
  }

  onChange = property => event => {
    const {
      input: { onChange }
    } = this.props
    this.setState(
      {
        [property]: event.target.value
      },
      () => {
        const dateStr = this.formatToString(this.state)
        onChange(dateStr)
      }
    )
  }

  formatToString(value) {
    const dateM = moment({ ...value, month: value.month ? value.month - 1 : 0 })
    return dateM.isValid() ? dateM.format('YYYY-MM-DD') : ''
  }

  formatToObject(value, defaultvalue) {
    const dateM = moment(value)
    return dateM.isValid()
      ? {
          month: String(dateM.month() + 1),
          day: String(dateM.date()),
          year: String(dateM.year())
        }
      : defaultvalue
  }

  render() {
    const {
      input,
      meta: { touched, invalid, error },
      label,
      ...rest
    } = this.props
    const { month, day, year } = this.state //this.formatToObject(input.value, this.state)

    return (
      <FormControl margin="normal" fullWidth error={touched && invalid} {...rest}>
        <InputLabel style={{ position: 'relative', transform: 'none', margin: '10px 0' }} disableAnimation>
          {label}
        </InputLabel>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
          <TextField
            value={month}
            onChange={this.onChange('month')}
            type="number"
            label="Month"
            style={{ marginRight: 10, width: 100 }}
            inputProps={{ min: 1, max: 12 }}
          />
          <TextField
            value={day}
            onChange={this.onChange('day')}
            type="number"
            label="Day"
            style={{ marginRight: 10, width: 100 }}
            inputProps={{ min: 1, max: 31 }}
          />
          <TextField
            value={year}
            onChange={this.onChange('year')}
            type="number"
            label="Year"
            style={{ width: 100 }}
            inputProps={{ min: 1920, max: 2020 }}
          />
        </Box>
        {touched && error && (
          <FormHelperText margin="dense" error>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}
export default Birthdate

/*
export default ({ input: { value, ...input }, meta: { touched, invalid, error }, label, labelPlacement, ...rest }) => (
  <div>
    <label>{label}</label>
    <SketchPicker
      color={ value }
      onChangeComplete={ input.onChange }
    />
  </div>
)*/
