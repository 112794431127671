/** @format */

import React from 'react'
import { compose } from 'recompose'
import moment from 'moment'

import { getMessages } from 'api/phoneNumbers'

import { withStyles } from '@material-ui/core/styles'

import CheckboxTable from 'lib/tables/CheckboxTable'
import Typography from '@material-ui/core/Typography'
//import UserCell from 'lib/tables/UserCell'
//import SelectFilter from 'lib/tables/SelectFilter'

const Messages = ({ classes, match, history }) => (
  <React.Fragment>
    <Typography variant="h6">Messages</Typography>
    <CheckboxTable
      query={getMessages}
      variables={match.params}
      initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
      columns={[
        {
          Header: 'From',
          accessor: 'from'
        },
        {
          Header: 'To',
          accessor: 'to'
        },
        {
          Header: 'Body',
          accessor: 'body'
        },
        {
          Header: 'Status',
          accessor: 'status'
        },
        {
          Header: 'Created Date',
          accessor: 'createdAt',
          Cell: ({ original: message }) => moment(message.createdAt).format('LLL')
        },
        {
          Header: 'Actions',
          sortable: false,
          filterable: false,
          accessor: 'id',
          Cell: ({ original: message }) => <div></div>
        }
      ]}
      noDataText="There has currently been no text messages to the phone number recorded. This will update when a text message has been sent to the tracking number."
      actions={[]}
      deleteMany={false}
    />
  </React.Fragment>
)

const styles = theme => ({
  root: {}
})

const enhance = compose(withStyles(styles))

export default enhance(Messages)
