/** @format */

import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import compose from 'recompose/compose'
import withContext from 'lib/withAppContext'

import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Card, CardHeader, CardIcon, CardBody, CardFooter } from 'components/Card'
import Chip from '@material-ui/core/Chip'
import CheckboxTable from 'lib/tables/CheckboxTable'
import { Scrollbars } from 'react-custom-scrollbars'
import { withQuery } from 'react-apollo'
import { getBreaches } from 'api/breaches'
import { getMonitoringObjects } from 'api/monitoringObjects'

import dashboardStyle from './styles/dashboardStyle'
import { Timeline, TimelineEvent } from 'react-event-timeline'

class HackedDash extends React.Component {
  state = {}

  render() {
    const { classes, context, history, breaches = [], monitoringObjects = [] } = this.props
    const { user } = context
    let product = _.find(user.purchasedProducts, { id: 'iGvmrjNWNrLxXdWxEPXm' })
    let plan = _.get(product, 'plan')
    let emailObjects = _.filter(monitoringObjects, b => b.type === 'email').length
    let domains = _.get(plan, 'permissions.domains', 0)
    let domainObjects = _.filter(monitoringObjects, b => b.type === 'domain').length
    let vendor = _.get(plan, 'permissions.vendor', 0)
    let vendorObjects = _.filter(monitoringObjects, b => b.type === 'vendor').length
    let personalEmails = _.get(plan, 'permissions.personalEmails', 0)
    let personalEmailsCount = _.filter(breaches, b => b.type === 'email' && b.email).length
    let passwordCount = _.filter(breaches, b => b.password).length
    let usernameCount = _.filter(breaches, b => b.usernameCount).length
    let phoneCount = _.filter(breaches, b => b.phoneCount).length
    let lastPersonalExposure = _.maxBy(_.filter(breaches, { type: 'email' }), 'breachDate')
    lastPersonalExposure = lastPersonalExposure ? moment(lastPersonalExposure.breachDate).format('MM-DD-YYYY') : 'N/A'
    let lastCompanyExposure = _.maxBy(_.filter(breaches, { type: 'domain' }), 'breachDate')
    lastCompanyExposure = lastCompanyExposure ? moment(lastCompanyExposure.breachDate).format('MM-DD-YYYY') : 'N/A'

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={{ marginBottom: 0 }}>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon>web</Icon>
              </CardIcon>
              <h2 className={classes.cardTitle}>Hacked Monitoring Dashboard</h2>
              <p className={classes.cardTitle}>
                ChatFortress scans over 11 billion dark web credential storing records to alert you if your data has been exposed in a data
                breach. This allows you to change your password and protect yourself from account take-over threats. ChatFortress allows you
                to setup monitoring of your employees and third party vendors further protecting your against phishing attack threats. Your
                data maybe compromised not because of a mistake you have made. But it could have been a mistake a company you interact has
                made. Validate if the companies you are interacting are safe and secure. Check which of the companies employees have been
                compromised so you can protect yourself from hackers.
              </p>
              <Grid container spacing={2} justify="center">
                <Grid item xs={12} sm={6} md={3} className="p-2">
                  <Card>
                    <CardHeader style={{ backgroundColor: '#f4f4f4' }}>Personal Emails</CardHeader>
                    <CardBody>
                      <Grid container>
                        <Grid item sm={6}>
                          <Chip label={'Active Monitoring'} variant="outlined" color="primary" className={classes.chip} />
                        </Grid>
                        <Grid item sm={6} align={'right'}>
                          <Chip label={emailObjects} variant="outlined" color="secondary" className={classes.chip} />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={6}>
                          <Chip label={'Available By Plan'} variant="outlined" color="primary" className={classes.chip} />
                        </Grid>
                        <Grid item sm={6} align={'right'}>
                          <Chip label={personalEmails - emailObjects} variant="outlined" color="secondary" className={classes.chip} />
                        </Grid>
                      </Grid>
                      <Button variant="contained" color="primary" fullWidth onClick={() => history.push('/monitoring-objects/add/email')}>
                        Add Monitoring Email
                      </Button>
                    </CardBody>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="p-2">
                  <Card>
                    <CardHeader style={{ backgroundColor: '#f4f4f4' }}>Domains</CardHeader>
                    <CardBody>
                      <Grid container>
                        <Grid item sm={6}>
                          <Chip label={'Available By Plan'} variant="outlined" color="primary" className={classes.chip} />
                        </Grid>
                        <Grid item sm={6} align={'right'}>
                          <Chip label={domainObjects} variant="outlined" color="secondary" className={classes.chip} />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={6}>
                          <Chip label={'Active Monitoring'} variant="outlined" color="primary" className={classes.chip} />
                        </Grid>
                        <Grid item sm={6} align={'right'}>
                          <Chip label={domains - domainObjects} variant="outlined" color="secondary" className={classes.chip} />
                        </Grid>
                      </Grid>
                      <Button variant="contained" color="primary" fullWidth onClick={() => history.push('/monitoring-objects/add/domain')}>
                        Add Monitoring Domain
                      </Button>
                    </CardBody>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="p-2">
                  <Card>
                    <CardHeader style={{ backgroundColor: '#f4f4f4' }}>Vendors</CardHeader>
                    <CardBody>
                      <Grid container>
                        <Grid item sm={6}>
                          <Chip label={'Available By Plan'} variant="outlined" color="primary" className={classes.chip} />
                        </Grid>
                        <Grid item sm={6} align={'right'}>
                          <Chip label={vendorObjects} variant="outlined" color="secondary" className={classes.chip} />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={6}>
                          <Chip label={'Active Monitoring'} variant="outlined" color="primary" className={classes.chip} />
                        </Grid>
                        <Grid item sm={6} align={'right'}>
                          <Chip label={vendor - vendorObjects} variant="outlined" color="secondary" className={classes.chip} />
                        </Grid>
                      </Grid>
                      <Button variant="contained" color="primary" fullWidth onClick={() => history.push('/monitoring-objects/add/vendor')}>
                        Add Monitoring Vendor
                      </Button>
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </CardHeader>
          </Card>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Icon>email</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Personal Emails</p>
                  <h3 className={classes.cardTitle}>{personalEmailsCount}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>Breaches With Email Assets</div>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <Icon>vpn_key</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Passwords</p>
                  <h3 className={classes.cardTitle}>{passwordCount}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>Breaches With Exposed Passwords</div>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Icon>person</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Usernames</p>
                  <h3 className={classes.cardTitle}>{usernameCount}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>Breaches With Exposed Usernames</div>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <Icon>phone</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Phone Numbers</p>
                  <h3 className={classes.cardTitle}>{phoneCount}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>Breaches With Exposed Phone Numbers</div>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Icon>person_pin</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Last Personal Data Exposure</p>
                  <h3 className={classes.cardTitle}>{lastPersonalExposure}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>Breaches With Exposed Phone Numbers</div>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <Icon>supervisor_account</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Last Company Data Exposure</p>
                  <h3 className={classes.cardTitle}>{lastCompanyExposure}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>Breaches With Exposed Phone Numbers</div>
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Card style={{ margin: 0, marginTop: 20 }}>
            <CardHeader color="info">Your Breach History</CardHeader>
            <CardBody>
              <Scrollbars autoHeightMin={248} autoHide autoHeight>
                {_.filter(breaches, b => b.breachDate && b.description).length === 0 && <div className="text-center">No data</div>}
                {_.filter(breaches, b => b.breachDate && b.description).length > 0 && (
                  <Timeline>
                    {breaches &&
                      _.filter(breaches, b => b.breachDate && b.description).map(breach => {
                        return (
                          <TimelineEvent
                            key={breach.id}
                            title={breach.title}
                            createdAt={moment(breach.breachDate).format('MM-DD-YYYY')}
                            icon={<Icon>bug_report</Icon>}
                          >
                            <div className="small" dangerouslySetInnerHTML={{ __html: breach.description }}></div>
                          </TimelineEvent>
                        )
                      })}
                  </Timeline>
                )}
              </Scrollbars>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitleWhite}>Breaches</h4>
            </CardHeader>
            <CardBody>
              <CheckboxTable
                query={getBreaches}
                variables={{ userId: context.user.id }}
                deleteMany={false}
                initialVariables={{ sort: [{ id: 'createdAt', desc: true }] }}
                columns={[
                  {
                    Header: 'Breach Name',
                    accessor: 'title'
                  },
                  {
                    Header: 'Email',
                    accessor: 'email'
                  },
                  {
                    Header: 'Password',
                    accessor: 'password',
                    Cell: ({ original: breach }) => <div>{breach.password || breach.hashedPassword}</div>
                  },
                  {
                    Header: 'Additional Data',
                    accessor: 'active',
                    Cell: ({ original: breach }) => (
                      <div>
                        {breach.username ? <div>Username: {breach.username}</div> : ''}
                        {breach.phone ? <div>Phone: {breach.phone}</div> : ''}
                        {breach.address ? <div>Address: {breach.address}</div> : ''}
                        {breach.description ? (
                          <div>
                            Description:
                            <div className="small" dangerouslySetInnerHTML={{ __html: breach.description }}></div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )
                  }
                ]}
              />
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withContext,
  withStyles(dashboardStyle),
  withQuery(getBreaches, {
    name: 'breaches',
    options: props => {
      if (!props.context.user) return null
      return {
        variables: { userId: props.context.user.id, sort: ['breachDate'] }
      }
    },
    props: ({ loading, breaches: { breaches } }) => {
      return {
        breaches: breaches && breaches.data
      }
    }
  }),
  withQuery(getMonitoringObjects, {
    name: 'monitoringObjects',
    options: props => {
      if (!props.context.user) return null
      return {
        variables: { userId: props.context.user.id, sort: ['breachDate'] }
      }
    },
    props: ({ loading, monitoringObjects: { monitoringObjects } }) => {
      return {
        monitoringObjects: monitoringObjects && monitoringObjects.data
      }
    }
  })
)(HackedDash)
