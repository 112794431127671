/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, numericality } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'
import numeral from 'numeral'

import { getPlan, updatePlan } from 'api/plans'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import { userAccountTypeOptions } from 'data/options'

const PlanUpdate = ({ classes, match }) => (
  <React.Fragment>
    <Query query={getPlan} variables={match.params}>
      {({ data: { plan } = {}, loading }) =>
        !loading && (
          <FormDialog
            title={plan.name}
            form="PlanUpdate"
            mutation={[updatePlan]}
            refetchQueries={['getPlans']}
            initialValues={plan}
            success="Plan updated successfully"
          >
            {({ doc, submitting }) => (
              <React.Fragment>
                <Typography variant="h6" color="inherit">
                  Billing interval: <span>{`${numeral(plan.amount).format('$0.00')} per ${plan.interval} ${plan.freq}`}</span>
                </Typography>
                <Field name="name" component={TextField} label="Name" validate={[required()]} />
                <Field name="roles" component={TextField} label="Grant Roles" options={userAccountTypeOptions} select multiple />
                <Field name="setupFee" component={TextField} label="Setup Fee" validate={[required(), numericality()]} />
                <Field
                  name="billTimes"
                  component={TextField}
                  label="Billing Times"
                  helperText={`Rebill ${doc.billTimes ||
                    'infinite'} Times then Cancel Subscription. This means the subscription will stop after it has successfully billed this number of times.`}
                  placeholder="Leave blank if infinite billing"
                  validate={[numericality()]}
                  normalize={number}
                />
                <Field name="trial_period_days" component={TextField} label="Trial period" validate={[numericality()]} normalize={number} />
              </React.Fragment>
            )}
          </FormDialog>
        )
      }
    </Query>
  </React.Fragment>
)

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

const enhance = compose(withStyles(styles))

export default enhance(PlanUpdate)
