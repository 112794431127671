/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Users from './Users'
import UserCreate from './UserCreate'
import User from './User'
import ApplyCoupon from './ApplyCoupon'

export default ({ match }) => (
  <Container maxWidth="xl">
    <Box my={2}>
      <Switch>
        <Route path={`${match.path}/:userId/details`} component={User} />
        <Route path={`${match.path}`} component={Users} />
      </Switch>
      <Switch>
        <Route path={`${match.path}/create`} component={UserCreate} />
        <Route path={`${match.path}/:userId/apply-coupon`} component={ApplyCoupon} />
      </Switch>
    </Box>
  </Container>
)
