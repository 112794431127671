/** @format */

import React from 'react'

import SignatureCanvas from 'react-signature-canvas'

export default ({ input: { value, ...input }, meta: { touched, invalid, error }, label, labelPlacement, ...rest }) => (
  <div>
    <label>{label}</label>
    <SignatureCanvas
      onEnd={sign => {

        if (!sign.target.toDataURL && sign.target.childNodes[1]) input.onChange(sign.target.childNodes[1].toDataURL())
        if (!sign.target.toDataURL && !sign.target.childNodes[1]) input.onChange(document.getElementById('sign-canvas').toDataURL())
        if (sign.target.toDataURL) input.onChange(sign.target.toDataURL())
      }}
      penColor="#5980d4"
      canvasProps={{
        id: "sign-canvas",
        width: window.screen.width > 500 ? 500 : 250,
        height: window.screen.width > 500 ? 200 : 120,
        style: {
          borderRadius: 5,
          background: 'url(https://img.freepik.com/free-photo/white-crumpled-paper-texture-background_64749-706.jpg?size=626&ext=jpg)'
        }
      }}
    />
  </div>
)
