/** @format */

import gql from 'graphql-tag'

export const cardReportFragment = gql`
  fragment cardReport on CardReport {
    name
    domain
    status
    active
    companyId
  }
`
export const getCardReports = gql`
  query getCardReports($domain: String, $status: String, $userId: String, $skip: Int, $limit: Int, $sort: [String]) {
    cardReports(domain: $domain, status: $status, userId: $userId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        domain
        rank
        status
        token
        company {
          id
          name
          logo
          website
          email
          phone
        }
      }
      count
      pages
    }
  }
`

export const getCardReport = gql`
  query getCardReport($cardReportId: ID!) {
    cardReport(id: $cardReportId) {
      id
      ...cardReport
    }
  }
  ${cardReportFragment}
`

export const getCardReportByToken = gql`
  query getCardReportByToken($token: String!) {
    cardReportByToken(token: $token) {
      id
      ...cardReport
      rank
      scannerResult
      user {
        id
        name
        email
        avatar
      }
      updatedAt
      createdAt
    }
  }
  ${cardReportFragment}
`

export const getDataCreationCardReport = gql`
  query getDataCreationCardReport {
    companies(limit: 0, sort: ["name", "1"]) {
      data {
        id
        name
      }
    }
  }
`

export const setRemediated = gql`
  mutation setRemediated($reportId: String, $type: String, $note: String, $data: Object) {
    setRemediated(reportId: $reportId, type: $type, note: $note, data: $data)
  }
`
export const createCardReport = gql`
  mutation createCardReport($data: CardReportInput!) {
    createCardReport(data: $data) {
      id
      ...cardReport
    }
  }
  ${cardReportFragment}
`

export const getDataUpdatingCardReport = gql`
  query getDataUpdatingCardReport($cardReportId: ID!) {
    cardReport(id: $cardReportId) {
      id
      ...cardReport
    }
  }
  ${cardReportFragment}
`

export const updateCardReport = gql`
  mutation updateCardReport($cardReportId: ID!, $data: CardReportInput!) {
    updateCardReport(id: $cardReportId, data: $data) {
      id
      ...cardReport
      rank
      token
      user {
        id
        name
        email
        avatar
      }
    }
  }
  ${cardReportFragment}
`

export const removeCardReport = gql`
  mutation removeCardReport($id: ID!) {
    removeCardReport(id: $id) {
      id
    }
  }
`
