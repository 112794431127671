/** @format */

import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import cardIconStyle from './styles/cardIconStyle'

function CardIcon({ ...props }) {
  const { classes, className, children, color, ...rest } = props
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color + 'CardHeader']]: color,
    [className]: className !== undefined
  })
  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  )
}

/*
CardIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ])
};
*/

export default withStyles(cardIconStyle)(CardIcon)
