/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { FormDialog, FormSection, BankAccount } from 'lib/form'

import { createSource } from 'api/sources'
import { createACHToken } from 'api/actions'

import withStyles from '@material-ui/core/styles/withStyles'

const BankAccountAdd = ({ match }) => (
  <React.Fragment>
    <FormDialog
      title="Add Bank Account"
      form="BankAccountAdd"
      mutation={[createSource, { refetchQueries: ['getSources'] }]}
      initialValues={{
        bank_account: { object: 'bank_account', account_holder_type: 'individual', country: 'US', currency: 'usd' }
      }}
      btnSubmitText="Add"
      success="Bank Account added successfully"
    >
      {({ doc, invalid, submitting }) => (
        <React.Fragment>
          <FormSection name="bank_account">
            <BankAccount doc={doc} />
          </FormSection>
        </React.Fragment>
      )}
    </FormDialog>
  </React.Fragment>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(BankAccountAdd)
