/** @format */

import React from 'react'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'

const getValue = ({ type, input, select, multiple }) => {
  let value = input.value
  if (type === 'date' && value) {
    return format(parseISO(input.value), 'yyyy-MM-dd')
  } else if (multiple) {
    return value || []
  } else if (select) {
    return value || ''
  }
  return value
}

export default ({ input, type = 'text', meta: { touched, invalid, error }, select, options = [], multiple = false, ...rest }) => {
  const value = getValue({ type, input, select, multiple })
  if(options && typeof(options[0])==='string')
    options=options.map(o=>({
      label: o,
      value: o
    }))
  return (
    <TextField
      {...input}
      type={type}
      variant="outlined"
      margin="normal"
      value={value}
      error={touched && invalid}
      helperText={touched && error}
      select={select}
      SelectProps={{ multiple }}
      fullWidth
      {...rest}
    >
      {select &&
        Array.from(options || []).map(({ value, label, ...option }, index) =>
          option.optgroup ? (
            <ListSubheader key={index}>{label}</ListSubheader>
          ) : (
            <MenuItem key={index} value={value} {...option}>
              {label}
            </MenuItem>
          )
        )}
    </TextField>
  )
}
