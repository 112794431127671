/** @format */

import gql from 'graphql-tag'

export const breachFragment = gql`
  fragment breach on Breach {
    type
    title
    email
    domain
    password
    username
    hashedPassword
    address
    ipAddress
    phone
    sourceName
    serviceName
    description
    breachDate
    count
    tags
  }
`
export const getBreaches = gql`
  query getBreaches($userId: String!, $q: String, $skip: Int, $limit: Int, $sort: [String]) {
    breaches(userId: $userId, q: $q, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        ...breach
        creator {
          id
          name
          email
          avatar
        }
      }
      count
      pages
    }
  }
  ${breachFragment}
`

export const getBreach = gql`
  query getBreach($breachId: String!) {
    breach(id: $breachId) {
      id
      ...breach
    }
  }
  ${breachFragment}
`

export const getDataCreationBreach = gql`
  query getDataCreationBreach {
    sources(limit: 0) {
      data {
        id
        displayName
        object
        status
      }
    }
  }
`

export const addBreach = gql`
  mutation createBreach($data: BreachInput!) {
    createBreach(data: $data) {
      id
      ...breach
    }
  }
  ${breachFragment}
`

export const getDataUpdatingBreach = gql`
  query getDataUpdatingBreach($breachId: String!) {
    breach(id: $breachId) {
      id
      ...breach
    }
  }
  ${breachFragment}
`

export const updateBreach = gql`
  mutation updateBreach($breachId: String!, $data: BreachInput!) {
    updateBreach(id: $breachId, data: $data) {
      id
      ...breach
    }
  }
  ${breachFragment}
`

export const removeBreach = gql`
  mutation removeBreach($id: String!) {
    removeBreach(id: $id) {
      id
    }
  }
`

export const onCreateBreach = (cache, { data: { addBreach: breach } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { breaches } = cache.readQuery({ query: getBreaches, variables })
  cache.writeQuery({
    query: getBreaches,
    variables,
    data: {
      breaches: {
        ...breaches,
        data: [breach].concat(breaches.data)
      }
    }
  })
}

export const onRemoveBreach = (cache, { data: { removeBreach: breach } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { breaches } = cache.readQuery({ query: getBreaches, variables })
  cache.writeQuery({
    query: getBreaches,
    variables,
    data: {
      breaches: {
        ...breaches,
        data: breaches.data.filter(r => r.id !== breach.id)
      }
    }
  })
}
