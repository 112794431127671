/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getSourceForUpdating, updateSource } from 'api/sources'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

import { accountHolderTypeOptions } from 'data/options'

const SourceUpdate = ({ classes, match }) => (
  <React.Fragment>
    <Query query={getSourceForUpdating} variables={match.params}>
      {({ data: { source = {} } = {}, loading }) =>
        !loading && (
          <FormDialog
            title="Update Source"
            form="SourceUpdate"
            mutation={[updateSource]}
            initialValues={source}
            success="Source updated successfully"
          >
            {({ doc, invalid, submitting }) => (
              <React.Fragment>
                {source.object === 'card' && (
                  <React.Fragment>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Field name="name" component={TextField} label="Name" />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Field name="exp_month" component={TextField} label="MM" validate={[required()]} normalize={number} />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Field name="exp_year" component={TextField} label="YYYY" validate={[required()]} normalize={number} />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Field name="address_zip" component={TextField} label="Zip" />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                {source.object === 'bank_account' && (
                  <React.Fragment>
                    <Field name="account_holder_name" component={TextField} label="Account Holder Name" />
                    <Field
                      name="account_holder_type"
                      component={TextField}
                      label="Account Holder Type"
                      validate={[required()]}
                      options={accountHolderTypeOptions}
                      select
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </FormDialog>
        )
      }
    </Query>
  </React.Fragment>
)

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(SourceUpdate)
