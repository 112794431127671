/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
// import JWPlayer from 'lib/jwplayer'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import Markdown from 'react-markdown'
import Linkify from 'lib/Linkify'
import { getColorByValue, getGradientByValue, chartOptions } from 'lib/chartHelpers'

//import Layout from 'layouts/report'

//import DataBreachCalculator from './DataBreachCalculator'
//import DataPrivacyCalculator from './DataPrivacyCalculator'

//import CompanyRankingComparison from 'components/CompanyRankingComparison'
import FeaturedCompanies from './FeaturedCompanies'
import ApexChart from './ApexChart'
import SpeakChatFortressSpecialist from './SpeakChatFortressSpecialist'
import ReportFAQ from './ReportFAQ'
import FinalGrade from './FinalGrade'
import categories from 'api/categories.json'
import withAppContext from 'lib/withAppContext'
import Spinner from 'components/Spinner'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import PhoneInput from 'react-phone-input-2'
import { TextField } from '@material-ui/core'
import { withMutation } from 'react-apollo'
import { setRemediated } from 'api/cardReports'
import Avatar from 'lib/Avatar'

const MySwal = withReactContent(Swal)

const getReportData = gql`
  query getReportData($token: String!) {
    cardReportByToken(token: $token) {
      id
      name
      rank
      remediations
      historyRank
      scannerResult
      status
      domain
      monitoringObject
      breaches
      updatedAt
      createdAt
      scannedAt
      company {
        id
        name
        domain
        sponsorBox
        noComparison
        noFeaturedComparison
        noSensitiveComparison
        rank
        thumbnail
        categories
        totalRank
        rankPoints
        rankLetter
        rankingData {
          rank
          totalRank
          categoryRank
          totalCategoryRank
          minPoints
          avgPoints
          maxPoints
          minCategoryPoints
          avgCategoryPoints
          maxCategoryPoints
        }
        user {
          id
          name
          email
          phone
          rcSettings {
            logo
            phone
            businessPhone
            email
            recommendations
            scheduleLink
            scheduleBtnText
            scheduleHeadline
            scheduleDescription
            btnText1
            btnText2
            faqs
            ads
          }
        }
      }
    }
  }
`

const levels = {
  critical: 20,
  high: 10,
  medium: 6,
  low: 3,
  info: 1
}

const nl2br = str => {
  if (typeof str !== 'string') {
    return str
  }

  return str.split(/(\r\n|\r|\n)/g).map((line, index) => {
    if (line.match(/(\r\n|\r|\n)/g)) {
      return React.createElement('br', { key: index })
    }
    return line
  })
}

const securityCategories = {
  dnsHealth: {
    description:
      'DNS Health report is generated from 40+ control items which are collected from online services like IntoDNS, Robtex, Netcraft and HackerTarget. Since DNS queries are recursive, it is almost impossible to detect a hacker footprints from the DNS servers.\n' +
      '\n' +
      'The online services and ChatFortress search engine send a number of totally legal and non-intrusive queries to the corresponding DNS servers. Each service gives different information about a domain or DNS server. SOA serial number, SPF, DMARC, recursive queries, zone transfer are some of the checklist items in this section. Name Server (NS), Mail Exchange (MX), Start of Authority (SOA), A records are checked.'
  },
  emailSecurity: {
    description:
      'This section checks some SMTP vulnerabilities and misconfiguration on email servers. This section reports all MX records and their blacklist statuses, SMTP diagnostics, IP Addresses and/or Hostnames, potential SMTP misconfigurations like poor connection time, DKIM & DMARC & SPF Records, Reverse DNS Test, Valid Hostname, Webmail HTTPS Control etc.'
  },
  IPDomain: {
    description:
      'Blacklists contain lists of IPs or domains that pose a threat to consumers. Staying on top of your reputation by regularly monitoring your presence on blacklists and analyzing your engagement metrics is important. Asset reputation lists the IPs or domains that are blacklisted or that are used for sophisticated APT attacks. '
  },
  leakedCredentials: {
    description:
      'ChatFortress scans the dark web to assess if your personal information is compromised in a data breach. These data breaches expose your data to cybercriminals, which increases your risk of cyberattacks. These targeted attacks include email phishing, spear phishing, ransomware and malware exploits. This data includes but is not limited to Email Address, Phone Number, Passwords, PIN codes, Credit Card Information, Medical Information, and Personal Identifiable information PII.\n' +
      '\n' +
      'In the simplest form, email list of employees can be used for phishing attack. Most of internet users use same passwords for different web application accounts. Leaked e-mails and passwords are very dangerous even if it is used in account for different company. Attackers can try to enter the corporate system using the same credentials. There is a great chance that the same email / password combination may work for corporate applications. Attackers can also communicate or trade the hacked account and can gather some private information easily.\n' +
      '\n' +
      'Hackers will compile data to increase the data’s value. For example they will start with and email then add a name, then add demographic data or an address. Every time they compile more data on a personal they increase the value of the data when it can be sold on the dark web. For example your name and email can sell for $1-$8. However your Social Security Number or Medical information can sell for $50 per record. Online services will also compile data or create a user profile. When your data has been compromised on these services it can mean your entire user profile has been compromised. \n' +
      '\n' +
      'Our Dark web scan does a quick scan of the dark web to determine  if your accounts have been compromised and which ones. This is not a complete exhaustive scan of the dark web. But indicates you need to apply caution to your accounts. '
  },
  fraudulentDomains: {
    description:
      'Fraudulent domains and subdomains are used to run phishing campaigns by attackers. Attackers register a domain with a name similar to the target company. Attackers can develop a web site using the logo, design or any other content that actually belongs to a company and can share this web site on the internet. Then, the attacker can phish the customers by redirecting them to malicious domain via a link. It may be hard to detect malicious domain for customers, if they have insufficient knowledge. Customers can easily be fooled to this type of attack which may result in compromise of personally identifiable information (PII).\n' +
      '\n' +
      'There are more than 300 million registered domains and billions of subdomains. Some of these sites have malicious purposes. ChatFortress searches the internet, detect fraudulent web sites by calculating the Levenshtein distance, radial distance and run a sophisticated machine learning algorithm to classify a domain.'
  },
  patchManagement: {
    description:
      'Out-of-date systems that are accessible from the Internet may have the following vulnerabilities those are either related with the application servers or the application framework. They can be a design flaws or an implementation bugs that enable attackers to compromise system itself or applications. Hackers look for weak links in company cyber defenses. Obsolete systems are easy targets for them. A successful exploitation may result in data loss, bad reputation, loss of credibility or financial problems.'
  },
  informationDisclosure: {
    description:
      'Company employees may disclose Local IPs, email addresses, version numbers, whois privacy records or even misconfigure a service in a way that it may expose sensitive information to the internet.\n' +
      '\n' +
      'Information disclosure is when an application fails to properly protect sensitive information from parties that are not supposed to have access to such information in normal circumstances. These type of issues are not exploitable in most cases. However they are considered as security issues because they allow attackers to gather information which can be used later in the attack lifecycle, in order to achieve more than they could if they didn’t get access to such information.'
  },
  websiteSecurity: {
    description:
      'This is a special analysis of the company’s main website. The findings are collected from SSL/TLS Strength, Application Security and Security Headers. This category is designed to help developers, system administrators, and security professionals to configure their sites safely and securely. It includes a set of controls to analyze your website and informs you if you are utilizing the many available methods to secure it.'
  },
  webRanking: {
    description:
      'Web Ranking is not considered in the calculation of your cybersecurity report card. Website Ranking data is provided directly from Google and should be used to optimize your website to improve the user experience. This information is provided as an additional value add to help you quantify your digital footprint. Your website ranking does not protect you from a cyber attack. Your business will be compromised regardless of your website rank if there are vulnerabilities. Cybercriminals are equal opportunity attackers. \n' +
      '\n' +
      'Cisco, Alexa and Majestic track web sites and rank them according to popularity, back-links, references, etc. This subcategory shows Alexa and Majestic trends, Google Page insight speed test results as well as Web Content Accessibility Guidelines (WCAG) 2.0 parsing compliance findings. \n' +
      '\n' +
      "Alexa ranks websites based on a combined measure of pageviews and unique site users. Alexa creates a list of most popular websites based on this ranking time-averaged over three-month periods. Only the site's highest-level domain is recorded, aggregating any subdomains.\n" +
      '\n' +
      "Cisco creates a list of most popular brands and websites. The popularity list contains our most queried domains based on passive DNS usage across Cisco Umbrella global network of more than 100 Billion requests per day with 65 million unique active users, in more than 165 countries. Unlike Alexa, the metric is not based on only browser based 'http' requests from users but rather takes into account the number of unique client IPs invoking this domain relative to the sum of all requests to all domains.\n" +
      '\n' +
      'Majestic ranks every website in the world based on the number citations from OTHER websites (Links). The Majestic Million is a list of the top 1 million website in the world, ordered by the number of referring subnets / backlinks.'
  },
  cybersecurityCulture: {
    description:
      'Cybersecurity Culture is a quiz that assesses your companies approach to create a safe and secure environment for your employees and your data. Including how you educate your employees about cyberattacks and the systems you have in place to detect and respond to cyber-attacks. Your employees will be your best defense in the war against cybercriminals. Therefore creating a culture where people know how to detect and respond to threats is essential. Use this Cybersecurity Culture quiz to determine what you can do to improve your culture and protect your people from attack. '
  },
  performance: {
    description:
      'Using the Googles PageSpeed Insights, we review your website according to Google performance recommendations. These recommendations are used for Google to determine your rank and SEO for your website.' +
      '\n' +
      'PageSpeed Insights (PSI) reports on the performance of a page on both mobile and desktop devices and provides suggestions on how that page may be improved.' +
      '\n' +
      'PSI provides both lab and field data about a page. Lab data is useful for debugging performance issues, as it is collected in a controlled environment. Use this data to optimize your website user experience.'
  },
  seo: {
    description:
      'Using the Google PSI api, we assess your websites SEO and what can be done to optimize the ranking of your website.' +
      '\n' +
      'Search engine optimization (SEO) is often about making small modifications to parts of your website. When viewed individually, these changes might seem like incremental improvements, but when combined with other optimizations, they could have a noticeable impact on your website user experience and performance in organic search results. You are likely already familiar with many of the topics in this guide because they are essential ingredients for any web page, but you may not be making the most out of them.'
  },
  compliance: {
    description:
      'This section of the Cybersecurity Report Card assess how you are managing your compliance with GDPR, CCPA and ePrivacy laws. Most business owners are un-aware of their liability and risk associated to the data they are collecting from consumers.\n' +
      '\n' +
      "Almost every website uses 3rd parties such as pixels or social, but in many regions, until the user has consented, you may not load those scripts. You need to block and unblock these 3rd-party scripts to ensure unsanctioned 3rd parties don't get you in trouble. \n" +
      '\n' +
      'Elements used as part of this section are GDPR, CCPA, ePrivacy assessments. Examples of what we are assessing are what type of cookies are you using on your website and is there a consent request for use of cookies. ChatFortress provides clients with access to the consent management platform as part of the Cybersecurity Report Card Fortified program. Helping you to remain compliant to these data laws. The consent management platform allows the user to manage their consent for data processing transparently and subsequently signals this information across the advertising supply chain as well as any other custom vendor list.\n' +
      '\n' +
      'Refer to the Data Compliance Calculator to understand your financial liability.'
  },
  ada: {
    description:
      'The Americans with Disabilities Act (ADA) was established to ensure equal treatment and non-discrimination for people with\n' +
      'disabilities. Web Content Accessibility Guidelines (WCAG) 2.1 outline the requirements for creating a website that’s ADA-compliant and widely accessible to people with disabilities. \n' +
      '\n' +
      'Many companies have faced lawsuits in recent years for ADA-noncompliant websites that barred differently-abled individuals from fully experiencing the site. Refer to this article for more information: https://chatfortress.com/adawebsitecompliance \n' +
      '\n' +
      'User accessibility is critical and many companies don’t realize their website is non-compliant until it’s too late. We check your site against international Web Content Accessibility Guidelines to assess your compliance and identify any elements that don’t meet legal goals.'
  }
}

export const defaultRcSettings = {
  phone: '(307) 999-7755',
  logo: 'https://chatfortress.s3.amazonaws.com/img/CF-logo-v2-NO-BGCrop.png',
  recommendations: `
# Recommendations
<p>Below are the recommendations based on your Cybersecurity Report Card. Our recommendations include strategies that allow you to detect and respond to threats in real-time—providing you with an early warning system against attacks, therefore, reducing your exposure and possible down-time. </p>

<p>Here are the recommendations based on your Cybersecurity Report Card. If you would like to speak with a cybersecurity specialist, you can schedule a conversation using this <a href="/reportcarddiscoverysession/">link.</a> Speak to a ChatFortress agent, call <a href="tel:+1 307-999-7755">+1 307-999-7755</a> or email <a href="mailto:Help@ChatFortress.com">Help@ChatFortress.com</a></p>

<p>Watch this short video below to understand the biggest mistakes that companies make with their cybersecurity programs. This video demonstrates how companies are failing to protect their most valued
assets and using a costly outdated approach to cybersecurity</p>

# Your Recommendations
----------------------------------

## **1. Review your vulnerabilities and create a plan with your IT team**.
Part of your Cybersecurity Defense Plan should include receiving your Cybersecurity Report Card every month. This provides you with an early warning system against attacks and new threats. Learn more about the ChatFortress Fortified monitoring programs here or ask your specialist.
_You can gain access to monthly cybersecurity report cards with our [Fortified program.](/fortified?company=DiGpKddAjr9EWe6RjDx6)_

Have your IT Team immediately resolve any issues classified as high or critical that was discovered as part of your Cybersecurity Report Card. Vulnerabilities and remediation blueprint defined below.

## **2. Review your compromised accounts and change all passwords.**
This includes changing passwords on social media accounts, online software solutions, not just your computer or network access. The best structured passwords to use, is to change all your **passwords to paraphrases** to promote increased security. To learn more about this, review this article at [https://chatfortress.com/blog/ccscday1passwords](https://chatfortress.com/blog/ccscday1passwords)

## **3. Activate a solution that detects and mitigates email phishing attacks from your inbox.**
This will help reduce human error; therefore, reducing the chances of ransomware, malware or
compromising your email. ***9 out of 10 cyber attacks occur via email***, therefore you need a system that can detect and remove bad emails.  We recommend the Email Guardian solution and offer Small Business Scholarships to ensure your email can be protected against cybercriminals. To learn more visit [https://chatfortress.com/emailphishing](https://chatfortress.com/emailphishing)

<video src="https://content.jwplatform.com/videos/Sw5t8tTW-gLqksrpw.mp4" preload="auto" controls="" style="width: 100%; height: 100%;"></video>
`,
  scheduleHeadline: 'Speak with a ChatFortress Specialist',
  scheduleDescription: `
Discover how ChatFortress can help your company reduce your risk and exposure without installing complicated systems that require your team to work harder. Schedule time with your ChatFortress Specialist now. Call [307-999-7755](tel:3079997755)
`,
  scheduleLink: 'https://chatfortress.com/reportcarddiscoverysession',
  scheduleBtnText: 'Schedule Time!',
  btnText1: 'Chat with Cybersecurity Specialist',
  btnText2: 'Schedule Your Discovery Session Now!'
}

const VulnSection = ({ category, company, vulns, table, open, onOpen, total, banner, definition, beforeDescriptipon }) => {
  let name = _.find(categories, { key: vulns[0].category }) ? _.find(categories, { key: vulns[0].category }).name : category
  return (
    <div
      id="compliance"
      className="list-group-item list-group-item-action flex-column align-items-start"
      style={{
        backgroundColor: open ? '#f8fdff' : 'white',
        borderColor: getColorByValue(0),
        cursor: 'pointer',
        color: 'black'
      }}
    >
      <div onClick={onOpen} onKeyPress={e => e} role="contentinfo">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">
            {!open && <i className="fas fa-lg fa-plus-circle" />}
            {open && <i className="fas fa-lg fa-minus-circle" />}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{name}
          </h5>
          <strong className="d-none d-md-block">{total}</strong>
          {banner}
        </div>
        <p className="mb-1 mt-3 fadeInUp">
          {beforeDescriptipon}
          <Linkify properties={{ target: '_blank' }}>
            {securityCategories[category] ? securityCategories[category].description : ''}
          </Linkify>
        </p>
        {!open && (
          <button type="button" className="btn btn-sm btn-info">
            Show More...
          </button>
        )}
      </div>

      {open && (
        <div className="mt-5">
          {vulns.length < 1 && (
            <div>
              <div className="alert alert-success">You have no vulnerabilities under this category. </div>
            </div>
          )}
          {vulns.length > 0 && (
            <div>
              {vulns.map(vuln => (
                <div>
                  <div className="alert alert-danger">
                    <div className="row">
                      <div className="col-md-8 col-sm-12">
                        <h4>{vuln.threat}</h4>
                      </div>
                      <div className="col-md-4 col-sm-12 text-right">
                        <strong>Level: {vuln.level}</strong>
                      </div>
                    </div>
                    <div>{definition || vuln.definition}</div>
                  </div>
                  <div className="alert alert-success" style={{ marginTop: -20, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
                    <p>
                      <strong>Remediation</strong>
                    </p>
                    <div>
                      <Linkify properties={{ target: '_blank' }}>{vuln.remediation}</Linkify>
                    </div>
                  </div>
                  {table && table()}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const PrivateReportCard = ({ context, remediate }) => {
  const [openArray, setOpen] = useState({})
  const { user } = context
  const token = user.company && user.company.report && user.company.report.token
  const { loading, refetch, data: { cardReportByToken: report = {} } = {} } = useQuery(getReportData, {
    variables: { token: token || 'test' }
  })
  const company = (report && report.company) || {}
  const rankPoints = (report && report.rank && report.rank.points) || 0
  const rcSettings = _.defaults(
    _.omitBy((company && company.user && company.user.rcSettings) || {}, v => !v),
    defaultRcSettings
  )

  const isRemediated = vuln => {
    //console.log(typeof report.remediations,report.remediations)
    if (
      (typeof report.remediations === 'object' || typeof report.remediations === 'Object') &&
      report.remediations !== null &&
      report.remediations.length > 0
    ) {
      let key = vuln.category + ':' + vuln.threat.split(':')[0].replace(/\([0-9]+\)/, '([NUM])')
      //console.log('notRemediated',Boolean(report.remediations.find(r=>r.threatId===key)), key)
      return report.remediations.find(r => r.threatId === key)
    }
    return false
  }

  const setRemediated = async (remediated, leaked, data) => {
    MySwal.fire({
      title: remediated ? 'Please write a note about remediation' : 'Please write a note why it does not need a remediation',
      input: 'textarea',
      inputAttributes: {
        placeholder: 'Note'
      },
      inputValidator: value => {
        return value.length > 3 ? null : 'Please write a note about remediation or why it does need one'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      preConfirm: async note => {
        if (leaked) {
          console.log('Leaked credential', note, data)
          await remediate({ variables: { reportId: report.id, type: 'credential', data, note } })
            .then(({ data }) => {
              console.log('success', data)
              refetch()
            })
            .catch(e => {
              MySwal.fire({
                icon: 'error',
                title: `Error!`,
                text: e.message
              })
            })
        } else {
          //console.log('Vulnerability', key, data)
          await remediate({ variables: { reportId: report.id, type: 'vulnerability', data, note } })
            .then(({ data }) => {
              console.log('success', data)
              refetch()
            })
            .catch(e => {
              MySwal.fire({
                icon: 'error',
                title: `Error!`,
                text: e.message
              })
            })
        }
      }
    }).then(result => {
      if (result.value) {
        MySwal.fire({
          title: `Saved!`
        })
      }
    })
  }

  if (loading) {
    return (
      <div className={'bg-white'}>
        <section className="fdb-block py-4">
          <div className="col justify-content-center">
            <div className="text-center">
              <img
                src="https://chatfortress.s3.amazonaws.com/img/CRCLogo-v1.png"
                alt="Cybersecurity Report Card Logo - cybersecurity assessment"
                className="m-0"
                style={{ width: '50%' }}
              />
            </div>
          </div>
          <div className="container align-items-end justify-content-center d-flex text-success">
            <h2>Your ChatFortress Cybersecurity Report Card is Loading... Please wait something awesome is happening!</h2>
          </div>
        </section>
      </div>
    )
  } else if (!report) {
    return (
      <div>
        <section className="fdb-block py-4">
          <div className="col justify-content-center">
            <div className="text-center">
              <img
                src="https://chatfortress.s3.amazonaws.com/img/CRCLogo-v1.png"
                alt="Cybersecurity Report Card Logo - cybersecurity assessment"
                className="m-0"
                style={{ width: '50%' }}
              />
            </div>
          </div>
          <div className="container text-center  text-danger">
            <h2>
              Please contact the Cybersecurity Report Card Team to claim access to this Report Card. This link token has expired. Use the
              Live chat in the button right hand corner to obtain a new link.
            </h2>
          </div>
        </section>
      </div>
    )
  }

  let chartOptionsCloneMain = _.cloneDeep(chartOptions)
  _.set(chartOptionsCloneMain, 'plotOptions.radialBar.dataLabels.name.color', getColorByValue(rankPoints))
  _.set(chartOptionsCloneMain, 'fill.gradient.colorStops', getGradientByValue(rankPoints))

  let likelihoodSeries = {
    'Email Phishing': 90,
    'Social Media Phishing': 75,
    Smishing: 40,
    Vishing: 55,
    'Social Engineering': 80,
    Malware: 80,
    Ransomware: 75,
    'Zero Day Exploit': 35,
    'Account Compromise': 80,
    'Business Email Compromise': 70
  }

  let likelihoodOptions = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: Object.keys(likelihoodSeries)
    }
  }

  let prevRank = {
    rank: {
      points: 0
    },
    scannedAt: null
  }
  if (report && report.historyRank && report.historyRank.length) {
    prevRank = report.historyRank.pop()
  }

  const totalVulns = Array.from(report.scannerResult || []).length
  const complianceVuln = _.find(Array.from(report.scannerResult || []), { category: 'compliance' })
  if (complianceVuln) {
    complianceVuln.remediation =
      'Activate a consent management platform that allows the user to manage their consent for data processing transparently and subsequently signals this information across the advertising supply chain as well as any other custom vendor list.\n' +
      '\n' +
      "Almost every website uses 3rd parties such as pixels or social, but in many regions, until the user has consented, you may not load those scripts. You need to block and unblock these 3rd-party scripts to ensure unsanctioned 3rd parties don't get you in trouble. "
    complianceVuln.cookies = JSON.parse(complianceVuln.definition)
  }
  const adaVulns = _.filter(Array.from(report.scannerResult || []), { category: 'ada' }).map(v => ({
    ...v,
    remediation:
      'Review these issues identified and provide to your website design team for applying updates to your website. Confirm that your team understand the compliance requirements associated with ADA/WCAG/Section 508 Compliance \n' +
      v.remediation
  }))

  return (
    !loading && (
      <div data={rcSettings}>
        <div>
          <section className="fdb-block py-4">
            <div className="container align-items-end justify-content-center d-flex">
              {report.status === 'processing' && (
                <div className="alert alert-info">Your report card is processing now, it may take up to 30 minutes to be done...</div>
              )}
            </div>
          </section>
          <section className="fdb-block py-4">
            <div className="container align-items-end justify-content-center d-flex">
              <div className="row align-items-top text-left">
                <div className="col-md-12 col-lg-6">
                  <h2 style={{ color: '#1a349a', marginBottom: 0 }}>{company.name}</h2>
                  <p style={{ color: '#818181', marginBottom: 0 }}>{report.domain}</p>
                  <h3 style={{ color: '#333333', marginBottom: 0 }}>Total Vulnerabilities Found: {totalVulns}</h3>
                  <p style={{ color: '#818181', marginBottom: 0 }}>Last scanned on: {moment(report.scannedAt).format('ll')}</p>
                  <img
                    src={'https://chatfortress.s3.amazonaws.com/img/CRCLogo-v1.png'}
                    alt="Cybersecurity Report Card Logo - cybersecurity assessment"
                    className="m-0"
                    style={{ width: '100%', maxWidth: 946 }}
                  />
                  <p>
                    Report Card Sponsored by{' '}
                    <img
                      src={rcSettings.logo}
                      alt="Cybersecurity Report Card Logo - cybersecurity assessment"
                      className="m-0"
                      style={{ height: 50 }}
                    />
                  </p>
                  <div className="row mb-5 mt-4">
                    <div className="col-3 text-center">
                      <img
                        src="/assets/VERIFIED.png"
                        style={{
                          width: '100%',
                          filter: company.isDomainVerified ? '' : 'grayscale(100%) opacity(50%)'
                        }}
                        data-content={`Domain and Company are ${company.isDomainVerified ? '' : 'NOT'} Verified`}
                        data-toggle="popover"
                        data-trigger="hover"
                        alt=""
                      />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        src="/assets/FORTIFIED.png"
                        style={{
                          width: '100%',
                          filter: company.isDomainVerified ? '' : 'grayscale(100%) opacity(50%)'
                        }}
                        data-content={`${company.isDomainVerified ? '' : 'NOT'}  Fortified by ChatFortress`}
                        data-toggle="popover"
                        data-trigger="hover"
                        alt=""
                      />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        src="/assets/CYBERSECURITY  CULTURE.png"
                        style={{ width: '100%', filter: company.isQuizCompleted ? '' : 'grayscale(100%) opacity(50%)' }}
                        data-content={`CyberSecurity Quiz is ${company.isQuizCompleted ? '' : 'NOT'} Completed`}
                        data-toggle="popover"
                        data-trigger="hover"
                        alt=""
                      />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        src="/assets/CYBERSECURITY CORE 4.png"
                        style={{
                          width: '100%',
                          filter: company.isCore4Completed ? '' : 'grayscale(100%) opacity(50%)'
                        }}
                        data-content={`CORE 4 Cybersecurity Awareness Training is ${company.isCore4Completed ? '' : 'NOT'} Completed`}
                        data-toggle="popover"
                        data-trigger="hover"
                        alt=""
                      />
                    </div>
                  </div>
                  {prevRank && prevRank.rank.points > 0 && (
                    <div className="text-center">
                      <h2>Report Card History</h2>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ padding: 10, backgroundColor: '#fafafa' }}
                      >
                        <div>
                          <h1 style={{ color: getColorByValue(prevRank.rank.points), fontSize: '4em', margin: 0 }}>
                            {prevRank.rank.letter}
                          </h1>
                          <h2 style={{ color: '#172DA6', fontSize: 26, margin: 0 }}>{numeral(prevRank.rank.points).format('0,0')}</h2>
                          <div className="text-muted" style={{ fontSize: '110%' }}>
                            {moment(prevRank.scannedAt || report.createdAt).format('LL')}
                          </div>
                        </div>
                        <div>
                          <i className="fas fa-arrow-right fa-3x" style={{ color: '#ccc' }}></i>
                        </div>
                        <div>
                          <h1 style={{ color: getColorByValue(rankPoints), fontSize: '4em', margin: 0 }}>{report.rank.letter}</h1>
                          <h2 style={{ color: '#172DA6', fontSize: 26, margin: 0 }}>{numeral(rankPoints).format('0,0')}</h2>
                          <div className="text-muted" style={{ fontSize: '110%' }}>
                            {moment(report.scannedAt || new Date()).format('LL')}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-12 col-lg-6 text-center">
                  <h1>Final Grade for:</h1>
                  <h2 style={{ color: '#172DA6' }}>{report.domain}</h2>
                  <div style={{ marginTop: -50, height: 350 }}>
                    <FinalGrade points={rankPoints} letter={report.rank.letter} width={300} height={400} />
                  </div>
                  <a className="btn btn-primary btn-lg btn-block" href={rcSettings.scheduleLink}>
                    {rcSettings.btnText1}
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="fdb-block py-1">
            <div className="container align-items-end justify-content-center">
              <div className="row align-items-center">
                <div className="col align-self-center">
                  <div className="text-center">
                    <img
                      src="https://chatfortress.s3.amazonaws.com/img/cybercrime-hackersview.png"
                      alt="Cybersecurity Report Card- cybersecurity assessment"
                      className="m-0"
                      style={{ width: '80%' }}
                    />
                  </div>
                </div>
                <div class="col align-self-center">
                  <div className="text-center">
                    <h1>
                      Understand your cybersecurity risk in 60 seconds and see what the hacker sees with your Cybersecurity Report Card!
                    </h1>
                  </div>
                  <div className="text-left">
                    <p>
                      The NEW standard for starting conversations about your cybersecurity risk. Your ChatFortress Cybersecurity Report Card
                      gives you the <strong>“hacker's perspective"</strong> on how these vulnerabilities can expose you to data breaches,
                      ransomware and other cybercrimes. Fix your vulnerabilities before cybercriminals exploit them! Cyber-attacks cause
                      financial harm, reputation loss and damage to all of your intellectual property.
                    </p>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col align-self-center">
                  <div className="text-center">
                    <h1>If your Cybersecurity Report Card grade is between C and F...</h1>
                    <h2>You're FIVE times more likely to experience a data breach or cyber-attack!</h2>
                  </div>
                  <div className="text-left">
                    <p>
                      Smaller companies under 1,000 employees are 80.2% more likely to be compromised by cyber criminals than larger
                      companies!
                    </p>
                  </div>
                </div>
                <div class="col align-self-center">
                  <div className="text-center">
                    <img
                      src="https://chatfortress.s3.amazonaws.com/img/ReportCardDirectoryAd3.png"
                      alt="Cybersecurity Report Card- cybersecurity assessment"
                      className="m-0"
                      style={{ width: '80%' }}
                    />
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col align-self-center">
                  <div className="text-center">
                    <img
                      src="https://chatfortress.s3.amazonaws.com/img/ReportCardDirectoryAd5.png"
                      alt="Cybersecurity Report Card- cybersecurity assessment"
                      className="m-0"
                      style={{ width: '80%' }}
                    />
                  </div>
                </div>
                <div class="col align-self-center">
                  <div className="text-center">
                    <h1>Cybersecurity Report Card uses public domain information!</h1>
                  </div>
                  <div className="text-left">
                    <p>
                      This means hackers already have access to all of this information to use against you. If they are not already, it’s
                      common for cybercriminals to exploit your systems for six months before they launch their attack. We want to help you
                      win the Cybersecurity war by sharing with you what the hackers already know about you.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <h2>Get Your Cybersecurity Report Card Questions Answered </h2>
                <a href={rcSettings.scheduleLink} className="btn btn-lg btn-block btn-primary mx-auto" style={{ maxWidth: 450 }}>
                  {rcSettings.btnText2}
                </a>
              </div>
            </div>
          </section>
          <section className="fdb-block py-4">
            <div className="container">
              <h1 className="display-4">Cybersecurity Report Card Executive Summary</h1>
              <p>
                Your Cybersecurity Report Card Score is calculated based on the following variables. Each variable can be independently
                measured and optimized.<i>Click on the title to learn more</i>
              </p>
              <div className="card-group my-0 mt-5">
                {report.rank.categories.map(cat => {
                  return (
                    <div key={cat.name} className="col-sm-12 col-md-6 col-lg-3 m-0 p-0">
                      <div
                        className="card chart-card"
                        style={{
                          filter: cat.name === 'Cybersecurity Culture' && cat.points === 100 ? 'grayscale(100%) opacity(100%)' : ''
                        }}
                      >
                        <div className="card-body">
                          <h3 className="card-title text-center">
                            <a href={`#${cat.name}`}>{cat.name}</a>
                          </h3>
                          <div className="card-text">
                            {cat.name === 'Cybersecurity Culture' && cat.points === 100 ? (
                              <div className="text-center">
                                <div style={{ marginTop: -50, height: 230 }}>
                                  <FinalGrade points={0} letter={''} width={210} />
                                </div>
                                <div style={{ position: 'absolute', bottom: 10 }}>
                                  <a className="btn btn-primary btn-block" href="/fortified">
                                    Upgrade to Fortified Program
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  marginTop: -40,
                                  height: 220
                                }}
                              >
                                <FinalGrade points={cat.points} letter={cat.letter} width={250} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          <section className="fdb-block py-4 mt-3">
            <div className="container">
              <h1 className="display-4 mb-5">Cybersecurity Risk Likelihood Assessment</h1>

              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <p className="lead">
                    Based on your Cybersecurity Report Card Score, your likelihood of experiencing a cyber-attack is translated below…
                  </p>
                </div>
                <div className="col-sm-12 col-md-4 text-center">
                  <h3>Likelihood of Cyber Attack</h3>
                  <h1>
                    {rankPoints >= 86 && <span className="text-info">MEDIUM</span>}
                    {rankPoints <= 85 && <span className="text-danger">HIGH</span>}
                  </h1>
                </div>
              </div>
              <h2 className="mt-3">How you may experience a cyber attack</h2>
              <p>
                Below, is the probability of the type of attacks being used against your company to compromise your systems and steal your
                data.
              </p>

              <ApexChart
                options={likelihoodOptions}
                series={[
                  {
                    name: 'Likelihood',
                    data: Object.values(likelihoodSeries)
                  }
                ]}
                type="bar"
                height="350"
              />

              <h2 className="mt-3">How a cyber attack could impact your business</h2>
              <p>
                Here is how a cyberattack can impact your business. Please note that your cyber liability insurance may not cover all the
                losses incurred, therefore they will be out-of-pocket expenses.
              </p>
              <div className="lead">
                <ul>
                  <li>Loss of your brand reputation.</li>
                  <li>
                    Loss or the exposure of your <span className="h3">intellectual property</span>.
                  </li>
                  <li>Loss or the exposure of your trade secrets.</li>
                  <li>
                    Loss of data or limited access to data or systems that prevent business operation or significantly reduces your ability
                    to process operate your business.
                  </li>
                </ul>
              </div>
              <p>
                <span className="h3">
                  <strong> NOTE: You Could Be Voiding Your Insurance! </strong>
                </span>
                Your cyber liability insurance may not cover any losses incurred. For instance: Your insurance coverage may not cover your
                particular data breach or loses incurred during a cyber-attack. Your insurance claim can be denied and/or reduced due to the
                type of attack. Your insurance can be rejected and/or reduced due to the type of attack. Therefore, all costs will be
                out-of-pocket expenses!
              </p>
            </div>
          </section>
          <section className="fdb-block py-4 mt-5">
            <div className="container">
              <h1 className="display-4">Accounts Compromised on the Dark Web</h1>
              <p>
                ChatFortress scans the dark web to assess any data breach that has compromised your personal information. Data breaches
                expose your information to cybercriminals, which also increases your risk of cyberattacks. These targeted attacks include
                email phishing, spear phishing, ransomware, malware exploits, etc. This data includes, but is not limited to, Email
                Addresses, Phone Numbers, Passwords, PIN codes, Credit Card Information, Medical Information, and Personal Identifiable
                information PII. You can activate monthly dark web monitoring as part of the{' '}
                <a href={`/fortified?company=${company.id}`}>Fortified program.</a>
              </p>
              <h1>Has your data been exposed to hackers on the dark web?</h1>
              <div className="text-center">
                {report.breaches && report.breaches.length > 0 ? (
                  <h1 style={{ color: 'red' }}>Yes, your data has been exposed!</h1>
                ) : (
                  <h1 style={{ color: 'green' }}>NO, At this time, your data has not been exposed…</h1>
                )}
              </div>
              <div className="text-center">
                <h3>If we can find your username and passwords so can cybercriminals!</h3>
              </div>
              <p>
                However, keep in mind that our data analysts report that if your data is exposed on the dark web,
                <strong>you're 4.4 times more likely to experience a data breach</strong>. Your information is outlined in detail in the
                Leaked Credentials section of your Report card.
              </p>
              {report.breaches && report.breaches.length > 0 && (
                <div className="row text-left justify-content-center pt-2">
                  <div className="col-12 col-md-6 col-lg-5 m-sm-auto">
                    <div className="row">
                      <div className="col-3">
                        <img
                          alt="noun_schedule"
                          className="img-fluid"
                          src="https://chatfortress.s3.amazonaws.com/img/noun_schedule_527999.png"
                          width="100%"
                        />
                      </div>
                      <div className="col-9">
                        <div className="alert alert-danger" role="alert">
                          <h3>
                            <strong>
                              Last Data Exposure:
                              <br />
                              {new Date(
                                _.maxBy(report.breaches, b => b.breachDate || new Date()).breachDate || report.createdAt
                              ).toLocaleString()}
                            </strong>
                          </h3>
                        </div>
                        <p>This is how recent your data was exposed on the dark web.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5 m-sm-auto pt-3 pt-sm-5 pt-md-0">
                    <div className="row">
                      <div className="col-3">
                        <img
                          alt="noun_Login"
                          className="img-fluid"
                          src="https://chatfortress.s3.amazonaws.com/img/noun_Login_2544997.png"
                          width="100%"
                        />
                      </div>
                      <div className="col-9">
                        <div className="alert alert-danger" role="alert">
                          <h3>
                            <strong>
                              Total Personal Records Exposed: <br />
                              {/*report.breaches.length*/}
                              {_.filter(report.scannerResult, { categoryName: 'Leaked Credentials' }).length > 0
                                ? report.breaches.length
                                : 0}
                            </strong>
                          </h3>
                        </div>

                        <p>This is the total number of times we found your email address associated with a data breach on the dark web.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <img src="/img_41.png" alt="img" style={{ width: '100%' }} />
                  </div>
                </div>
              )}
            </div>
          </section>

          <section className="fdb-block py-4 mt-5">
            <div className="container">
              <Markdown escapeHtml={false} skipHtml={false}>
                {rcSettings.recommendations}
              </Markdown>
            </div>
          </section>

          <section className="fdb-block py-4 mt-5">
            <div className="container">
              <div className="container">
                <h1 className="col-10 col-12 text-center mb-3"> Do You Know What a Data Breach Could Cost You? </h1>
                <h3 className="col-10 col-12 text-center mb-3">
                  <span className="text-danger">It could be everything!</span> Calculate Your Liability!
                </h3>
                <div className="mt-4 col-10 col-12 justify-content-lg-left">
                  <div className="row align-self-center offset-1">
                    <div className="col-sm-2 col-12">
                      <div className="justify-content-center align-items-center text-center">
                        <img
                          alt="ChatFortress logo"
                          className="img-fluid rounded-0"
                          src="https://chatfortress.s3.amazonaws.com/img/noun_small business_1508285.svg"
                        />
                      </div>
                    </div>
                    <div className="col-md-8 col-12">
                      <h3 className="text-left">SMBs account for 43% of data breaches</h3>
                      <p className="lead">
                        Lack of time, resources and education are three major factors that put small to medium-sized businesses (SMBs) at
                        risk.
                        <small>
                          <i> 2019 Data Breach Investigations Report</i>
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="row align-self-center offset-1">
                    <div className="col-sm-2 col-12">
                      <div className="justify-content-center align-items-center text-center">
                        <img
                          alt="ChatFortress logo"
                          className="img-fluid rounded-0"
                          src="https://chatfortress.s3.amazonaws.com/img/noun_funds_2820009.svg"
                        />
                      </div>
                    </div>
                    <div className="col-md-8 col-12">
                      <h3 className="text-left">83% of SMBs lack the funds to recover</h3>
                      <p className="lead">
                        What's worse? A quarter of those who can't afford it didn't even realize there would be any recovery costs involved.
                        <small>
                          <i>2018 InsuranceBee Cybercrime Survey</i>
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="row align-self-center offset-1">
                    <div className="col-sm-2 col-12">
                      <div className="justify-content-center align-items-center text-center">
                        <img
                          alt="ChatFortress logo"
                          className="img-fluid rounded-0"
                          src="https://chatfortress.s3.amazonaws.com/img/noun_phishing_1182493.svg"
                        />
                      </div>
                    </div>
                    <div className="col-md-8 col-12">
                      <h3 className="text-left">Phishing emails account for 66% of ransomware cases </h3>
                      <p className="lead">
                        Even when 65% of MSPs reported having spam filters in place, cybercriminals can still get in. The takeaway? A
                        layered security approach is no longer optional.
                        <small>
                          <i>2018 Datto State of Ransomware Report</i>
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-10 col-12 justify-content-left">
                    <h3> How to Calculate Your Data Breach Cost </h3>
                    <p>
                      <strong>Each type of data impacts the possible cost of loss</strong>. If you have more than one type you need to
                      calculate each of these. Enter the number of customers' data you have access to and choose between three types of data
                      breaches:
                    </p>
                    <ul className="">
                      <li>
                        <span className="h3"> Payment Card Industry (PCI):</span> How much could a breach of credit card information cost
                        you?
                      </li>
                      <li>
                        <span className="h3"> Protected Health Information (PHI):</span> How much could losing patient data cost you?
                      </li>
                      <li>
                        <span className="h3"> Personally Identifiable Information (PII):</span> How much could losing Social Security
                        numbers or email addresses cost?
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="fdb-block py-4 mt-5">
            <div className="container">
              <h1 className="display-4">Found Vulnerabilities By Category</h1>
              <p className="lead">
                Here is your vulnerabilities blueprint. This section of your Cybersecurity Report Card explains the vulnerabilities found
                and how to remediate them. <i>Click on the show more button to expand the section to review the found vulnerabilities.</i>{' '}
              </p>
              <div className="list-group mt-5">
                {report.rank.categories.map((cat, index) => {
                  let open = openArray[cat.name] || false
                  let vulns = _.sortBy(_.filter(report.scannerResult, { categoryName: cat.name }), v => -1 * levels[v.level])

                  return (
                    <div
                      id={cat.name}
                      key={cat.name + cat.points}
                      className="list-group-item list-group-item-action flex-column align-items-start"
                      style={{
                        backgroundColor: open ? '#f8fdff' : 'white',
                        borderColor: getColorByValue(cat.points),
                        cursor: 'pointer',
                        color: 'black'
                      }}
                    >
                      <div onClick={() => setOpen({ ...openArray, [cat.name]: !open })} onKeyPress={e => e} role="contentinfo">
                        <div className="d-flex w-100 justify-content-between">
                          <h5 className="mb-1">
                            {!open && <i className="fas fa-lg fa-plus-circle" />}
                            {open && <i className="fas fa-lg fa-minus-circle" />}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{cat.name}
                          </h5>
                          <strong className="d-none d-md-block">
                            Total Vulnerabilities Found:{' '}
                            {cat.key === 'leakedCredentials' ? report.breaches && report.breaches.length : vulns.length}
                          </strong>
                          <div
                            style={{
                              backgroundColor: getColorByValue(cat.points),
                              color: cat.points > 70 ? 'black' : 'white',
                              borderRadius: '50%',
                              width: 60,
                              height: 60,
                              paddingTop: 15,
                              fontSize: 20,
                              textTransform: 'uppercase',
                              textAlign: 'center'
                            }}
                          >
                            {cat.letter.length > 1 ? '\u00A0' + cat.letter : cat.letter}
                          </div>
                        </div>
                        <small className="d-block d-md-none">
                          Total Vulnerabilities Found:{' '}
                          {cat.key === 'leakedCredentials' ? report.breaches && report.breaches.length : vulns.length}
                        </small>
                        <p className="mb-1 mt-3 fadeInUp">
                          {securityCategories[cat.key] ? nl2br(securityCategories[cat.key].description) : ''}
                        </p>
                        {!open && (
                          <button type="button" className="btn btn-sm btn-info">
                            Show More...
                          </button>
                        )}
                      </div>

                      {open && (
                        <div className="mt-5">
                          {cat.key !== 'leakedCredentials' && vulns.length < 1 && (
                            <div>
                              <div className="alert alert-success">You have no vulnerabilities under this category. </div>
                            </div>
                          )}
                          {vulns.length > 0 && (
                            <div>
                              {vulns.map(vuln => (
                                <div key={vuln.threat}>
                                  <div className="alert alert-danger">
                                    <div className="row">
                                      <div className="col-md-8 col-sm-12">
                                        <h4>{vuln.threat}</h4>
                                      </div>
                                      <div className="col-md-4 col-sm-12 text-right">
                                        <strong>Level: {vuln.level}</strong>
                                      </div>
                                    </div>
                                    <div>
                                      <Linkify properties={{ target: '_blank' }}>{vuln.definition || ''}</Linkify>
                                    </div>
                                  </div>
                                  {vuln.remediation && (
                                    <div
                                      className="alert alert-success"
                                      style={{ marginTop: -20, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                                    >
                                      <div>
                                        <strong>Remediation</strong>
                                        {cat.key !== 'leakedCredentials' && (
                                          <div className="float-right pl-3 pb-3">
                                            {!isRemediated(vuln) ? (
                                              <div className="weight-500 font-italic mb-1" style={{ color: 'black' }}>
                                                No remediation applied
                                                {/*<div className="mb-1">
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-success"
                                                    onClick={() => setRemediated(false, false, vuln)}
                                                  >
                                                    Set as "No Remediation Required"
                                                  </button>
                                                </div>*/}
                                                <div>
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-success"
                                                    onClick={() => setRemediated(true, false, vuln)}
                                                  >
                                                    Set as "Remediated"
                                                  </button>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="weight-500 font-italic mb-1" style={{ color: 'black' }}>
                                                <strong style={{ color: 'darkgreen' }}>Remediated</strong>
                                                {isRemediated(vuln).author && (
                                                  <div>
                                                    <Avatar alt={isRemediated(vuln).author.name} src={isRemediated(vuln).author.avatar} />
                                                    <strong>{isRemediated(vuln).author.name}</strong>
                                                    <div className="font-italic small">
                                                      {moment(isRemediated(vuln).createdAt).format('LLLL')}
                                                    </div>
                                                  </div>
                                                )}
                                                {'Notes: ' + isRemediated(vuln).note}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <div>
                                        <Linkify properties={{ target: '_blank' }}>{vuln.remediation || ''}</Linkify>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                          {report.breaches && report.breaches.length > 0 && cat.name === 'Leaked Credentials' && (
                            <div>
                              <h4>Leaked Credential List</h4>

                              <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="d-none d-sm-block">
                                        #
                                      </th>
                                      <th scope="col">Date Added</th>
                                      <th scope="col">Email</th>
                                      <th scope="col">Password</th>
                                      <th scope="col">IP</th>
                                      <th scope="col">Source</th>
                                      <th scope="col">Additional data</th>
                                      <th scope="col">Remediation</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {report.breaches.map((breach, i) => (
                                      <tr key={i}>
                                        <th scope="row" className="d-none d-sm-block small">
                                          {i + 1}
                                        </th>
                                        <td className="small">{new Date(breach.breachDate || report.createdAt).toLocaleString()}</td>
                                        <td className="small">{breach.email}</td>
                                        <td className="small">
                                          {breach.password
                                            ? breach.password.slice(0, breach.password.length - Math.round(breach.password.length / 2)) +
                                              '***'
                                            : breach.hashedPassword
                                            ? 'hash:' + breach.hashedPassword.slice(0, 4) + '***'
                                            : 'N/A'}
                                        </td>
                                        <td className="small">{breach.ipAddress || 'N/A'}</td>
                                        <td className="small">{breach.title}</td>
                                        <td className="small">
                                          {breach.username ? <div>Username: {breach.username}</div> : ''}
                                          {breach.phone ? <div>Phone: {breach.phone}</div> : ''}
                                          {breach.address ? <div>Address: {breach.address}</div> : ''}
                                          {breach.description ? (
                                            <div>
                                              Description:
                                              <div
                                                className="small"
                                                dangerouslySetInnerHTML={{
                                                  __html: breach.description.replace('ihavebeenpwned', 'monitoring service')
                                                }}
                                              ></div>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </td>
                                        <td className="small">
                                          {breach.remediated ? (
                                            <div>
                                              <strong>Remediated:</strong>
                                              <div className="small font-italic">{breach.remediationNote}</div>
                                              {breach.remediationAuthor && (
                                                <div>
                                                  <Avatar
                                                    alt={breach.remediationAuthor.name}
                                                    src={breach.remediationAuthor.avatar}
                                                    size={32}
                                                  />
                                                  <strong>{breach.remediationAuthor.name}</strong>
                                                  <div className="font-italic small">
                                                    {moment(breach.remediationAuthor.createdAt).format('LLLL')}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-success"
                                              onClick={() => setRemediated(true, true, breach)}
                                            >
                                              Set as "Remediated"
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )
                })}
                {complianceVuln && (
                  <VulnSection
                    total={'Total Cookies Found: ' + complianceVuln.cookies.length}
                    category="compliance"
                    definition="&nbsp;"
                    beforeDescriptipon={
                      <div className="text-center">
                        {['GDPR', 'CCPA', 'ePrivacy'].map(v => (
                          <div
                            style={{ display: 'inline-block' }}
                            className={
                              'mr-5 alert alert-' + (complianceVuln.cookies.length > 0 ? 'warning' : 'info') + ' text-dark font-weight-bold'
                            }
                          >
                            {v} {complianceVuln.cookies.length > 0 ? 'NOT' : ''} Compliant
                          </div>
                        ))}
                      </div>
                    }
                    banner={
                      <span
                        className={
                          'alert alert-' + (complianceVuln.cookies.length > 0 ? 'danger' : 'success') + ' text-dark font-weight-bold'
                        }
                      >
                        {complianceVuln.cookies.length > 0 ? 'NOT' : ''} Compliant
                      </span>
                    }
                    vulns={[complianceVuln]}
                    open={openArray['compliance']}
                    onOpen={() =>
                      setOpen({
                        ...openArray,
                        compliance: !openArray['compliance']
                      })
                    }
                    table={() => (
                      <div>
                        {complianceVuln.cookies && complianceVuln.cookies.length > 0 && (
                          <div>
                            <h4>Found Cookies List</h4>
                            <small className="text-muted">
                              This is the list of cookies and third party tracking pixels that we found on your website when the scan was
                              completed. You need to review the laws to understand if these need permission to activate.
                            </small>
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col-2">Name</th>
                                    <th scope="col-2">Domain</th>
                                    <th scope="col-2">Provider</th>
                                    <th scope="col-4">Description</th>
                                    <th scope="col-2">Expiry</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {complianceVuln.cookies.map((cookie, i) => (
                                    <tr key={i}>
                                      <td className="small">{cookie.name}</td>
                                      <td className="small">{cookie.domain}</td>
                                      <td className="small">{(cookie.common || {}).provider || 'Other'}</td>
                                      <td className="small">{(cookie.common || {}).descr}</td>
                                      <td className="small" style={{ whiteSpace: 'nowrap' }}>
                                        {(cookie.common || {}).duration || cookie.expiry
                                          ? moment(cookie.expiry * 1000)
                                              .fromNow()
                                              .replace('in', '')
                                              .replace('ago', '')
                                          : ''}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  />
                )}

                {adaVulns && adaVulns.length > 0 && (
                  <VulnSection
                    category="ada"
                    vulns={adaVulns}
                    total={'Total Issues Found: ' + adaVulns.length}
                    open={openArray['ada']}
                    banner={
                      <span className={'alert alert-' + (adaVulns.length > 0 ? 'danger' : 'success') + ' text-dark font-weight-bold'}>
                        {adaVulns.length > 0 ? 'NOT' : ''} Compliant
                      </span>
                    }
                    onOpen={() =>
                      setOpen({
                        ...openArray,
                        ada: !openArray['ada']
                      })
                    }
                  />
                )}
              </div>
            </div>
          </section>

          <ReportFAQ data={rcSettings.faqs} />
          <SpeakChatFortressSpecialist data={rcSettings} />
          <br />
          <br />
          <br />
        </div>
      </div>
    )
  )
}

export default withAppContext(
  withMutation(setRemediated, {
    name: 'remediate',
    refetchQueries: ['getReportData']
  })(PrivateReportCard)
)
