/** @format */
import React from 'react'


const tpl = ({ proposal, flatPrice, unitPrice, unitsAmount }) => {
  return (
    <div>
      <section className="fdb-block py-4">
        <div className="container">
          <h1> Goal of the Email Guardian Proof of Concept (POC)- 30-day Free Trial</h1>
          <p className="lead">
            {' '}
            The goal of the Email Guardian Proof of Concept (POC) is to show you (the client), the power and value of the Email Guardian
            Virtual Security Analyst program, by offering a full Free 30-day trial. Understand, no one else in the industry offers a true free
            30-day trial, that is how confident we are in our technology and process.
          </p>
          <p className="lead">
            {' '}
            All Email Guardian POC’s require a valid form of payment to be attached to the acceptance agreement. This can be either a credit
            card or bank account ACH.{' '}
          </p>
          <p className="lead">
            {' '}
            During the 30-day trial of the Email Guardian Proof of Concept (POC), you will have access to the full/complete Email Guardian
            cyber security system. Keep in mind, this is not a limited featured/functionality system test, but a full functioning/featured
            system along with Human Virtual Security Analyst interactions.{' '}
          </p>
          <h2> Measuring the success of Email Guardian POC? </h2>
          <p className="lead"> Here is what we do for you - We measure a successful POC by using all of the following metrics: </p>
          <ul className="lead">
            <li> Successful installation of the email Guardian system and identifying any malware/phishing attacks. </li>
            <li>
              {' '}
              Actively scan, clean and remove any infections/threats from your network and each of the listed email accounts from the last 12
              months.{' '}
            </li>
            <li> Create a personal company risk profile to show any phishing attacks, impersonation attacks, malware attacks, etc. </li>
            <li>
              {' '}
              Implementing an educational cyber security awareness program each month for 12 months to enlighten your employee’s cyber
              security awareness within the company. (NOTE: this cyber security training will successfully address/identify phishing type
              emails along with the dangers of social engineering and how it all affects everyone personally/professionally within the
              company.){' '}
            </li>
          </ul>
          <p className="lead">
            {' '}
            Because it only takes one email click to make your company a risk for a malware or ransomware attack, our goal will be to
            eliminate this risk via automated detection and human based awareness detection. So, by day 28 of our 30-day Email Guardian POC
            free trial, we will have established the actual intrinsic value of the Email Guardian program and show you how our Virtual
            Security Analyst program dramatically reduced your companies risk profile at no cost to you.
          </p>
          <h2>Are there any set up costs for the Email Guardian POC 30-day free trial?</h2>
          <p className="lead">
            {' '}
            NO…there is no upfront cost to activate the 30-day free trial of the Email Guardian POC. Our team will activate your FULL Email
            Guardian account for up to 500 active email accounts (more upon request). However, all that is required from you, is select a
            valid form of payment to be attached to the POC acceptance agreement. This payment method will not be charged until the free
            30-day trial is over. After day 30, your account will be set up to be billed per month (from the payment method that was selected)
            until you cancel the Email Guardian monthly subscription. Refer to <a href="/termsofservice">Terms of Service</a> and{' '}
            <a href="/privacy">Privacy Policy</a>{' '}
          </p>
          <h2> How do I contact ChatFortress? </h2>
          <p className="lead"> You can contact the ChatFortress team using the following communication channels: </p>
          <ol className="lead">
            <li> Website Live Chat at www.ChatFortress.com</li>
            <li> Email: help@ChatFortress.com </li>
            <li> Phone: USA (307) 999-7755</li>
          </ol>
          <h2> How do I cancel the Email Guardian POC? </h2>
          <p className="lead">
            {' '}
            To cancel the Email Guardian POC you need to provide written notice to Team@ChatFortress.com on day 28 of your Trial. If you want
            to cancel an active subscription you need to provide 30 days written notice to cancel your ChatFortress account. There are no
            refunds, or partial credits, or pro-rata accounting for amounts paid.
          </p>
          <h2>Training and Awareness Disclaimer.</h2>
          <p className="lead">
            You understand and accept that ChatFortress will conduct phishing training and awareness of all of the active emails that have
            been attached to your ChatFortress account for the purpose of the ChatFortress Email Guardian program.{' '}
          </p>
          <ul className="lead">
            <li>
              This means but not limited to you accept and give permission for ChatFortress to send phishing emails to your employees,
              contractors, independent contracts and individuals using the active email address you have provided ChatFortress via the Email
              Guardian program.
            </li>
            <li>
              These emails may be received on any device that the user accesses emails. This includes but is not limited to: mobile (cell)
              phones, tablets, desktops, personal and work devices.{' '}
            </li>
            <li>
              You will inform your company members that phishing awareness training is in place on an ongoing basis while you have an active
              ChatFortress subscription.{' '}
            </li>
            <li>
              These phishing emails are for the purpose of training and education only. Any data or information that is collected during this
              training is for training purposes only and will remain confidential. Any and all information that is collected will be reported
              to the Client directly and deleted from the ChatFortress system.{' '}
            </li>
            <li>
              All of the ChatFortress Iron School training and awareness phishing emails will be indicated visually once a person clicks on
              the email. This will display a training video for users to learn from the phishing awareness test.{' '}
            </li>
          </ul>
          <h3>
            The success of the ChatFortress Phishing training and awareness program is increased when your leadership teams verbally show
            support to the program.
          </h3>
          <p className="lead">The following recommendations are in place: </p>
          <ul className="lead">
            <li>Verbally share the lessons you learn with your team., </li>
            <li>
              Have leadership share their results good and bad with their fellow team members. This promotes trust and awareness amongst your
              team.{' '}
            </li>
            <li>Do not ridicule or embarrass team members who have successfully been phished. </li>
            <li>
              Remember 75% of people will be, can be phished at any point in time. Phishing manipulated human behaviors. The goal of this
              program is to increase awareness of phishing attacks and ensure your team know what do to if they have been attacked, or how to
              report an email attack.
            </li>
            <li>ChatFortress does not recommend allowing any user to opt-out of the ChatFortress phishing awareness training. </li>
          </ul>
          <h2>Indemnity and Hold Harmless</h2>
          <ul className="lead">
            <li>
              The client indemnifies and holds harmless ChatFortress from any and all liabilities, losses, damages, costs and expenses
              (including reasonable attorneys’ and experts’ fees) incurred by them pursuant to any of the iron school phishing awareness email
              training.
            </li>
            <li>
              ChatFortress is not liability or responsible for action that users take when they receive emails used as part of the phishing
              awareness training.
            </li>
          </ul>
          <h2>Cyber Security Awareness Monthly</h2>
          <ul className="lead">
            <li>
              You authorize and allow ChatFortress to deliver to your employees, contractors, and users added to your account a text message
              (sms) for the consumption of the training content as part of the Cyber Security Awareness Monthly program.{' '}
            </li>
            <li>You authorize the deliver of up to one (1) text message conversation for this content each week.</li>
            <li>You authorize that you have permission to opt-in your employees, contractors and users to this for of content. </li>
            <li>
              Your users can opt-out of receiving these training messages each week. You will be informed via the training dashboard which
              users have opt-out of text message notifications.{' '}
            </li>
            <li>
              You consent to send 1 email to your email each month alerting your team members of the Cyber Security Awareness Monthly training
              release.{' '}
            </li>
          </ul>
          <div className="text-center">
            <a href="/termsofservice">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default tpl