/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required } from 'lib/form/validators'

import { createLink } from 'api/links'

import withStyles from '@material-ui/core/styles/withStyles'

const LinkCreate = ({ match }) => (
  <React.Fragment>
    <FormDialog
      title="Create Link"
      form="LinkCreate"
      mutation={[createLink, { refetchQueries: ['getLinks'] }]}
      initialValues={{}}
      btnSubmitText="Create"
      success="Link created successfully"
    >
      {({ doc, invalid, submitting }) => (
        <React.Fragment>
          <Field name="title" component={TextField} label="Link Title" validate={[required()]} />
          <Field name="url" component={TextField} label="Link Url" validate={[required()]} />
        </React.Fragment>
      )}
    </FormDialog>
  </React.Fragment>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(LinkCreate)
