  /** @format */

import Papa from 'papaparse'

export const parseCsvFile = (file, options = {}) =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader(header) {
        console.log(header)
        return header
      },
      complete(results) {
        resolve(results)
      },
      error(error) {
        reject(error)
      },
      ...options
    })
  })

export const saveCsvFile = (filename, data) =>
  new Promise((resolve, reject) => {
   let csv = Papa.unparse(data);
    var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var csvURL =  null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, 'download.csv');
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
    resolve(true)
  })
