/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Route, Switch } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Requests from './Requests'
import RequestCreate from './RequestCreate'
import RequestUpdate from './RequestUpdate'

const Module = ({ match }) => (
  <Container maxWidth="lg">
    <Box my={2}>
      <Switch>
        <Route path={`${match.path}/create`} component={RequestCreate} />
        <Route path={`${match.path}/:requestId/update`} component={RequestUpdate} />
        <Route path={`${match.path}`} component={Requests} />
      </Switch>
    </Box>
  </Container>
)

const styles = theme => ({
  root: {
    width: '100%'
  }
})

export default compose(withStyles(styles))(Module)
