/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles/index'


import templates from './templates'

let GoalsComponent = templates.goalsTemplates.EmailGuardianGoals

const Module = ({ match, proposal }) => {
  if(proposal && (proposal.productId==='dNetH2TMph6E96efZgYK' || proposal.productId==='9N2ejyGd9xEPyEHAwMF9'))
    GoalsComponent = templates.goalsTemplates.EmailGuardianGoalsSMB
  if(proposal && (proposal.productId==='jRgZQA7y5Sn5q4zJEB3p'))
    GoalsComponent = templates.goalsTemplates.ReportCardResellerGoals
  return (
    <GoalsComponent proposal={proposal} />
  )
}

const styles = theme => ({
  root: {
    width: '100%'
  }
})

export default compose(withStyles(styles))(Module)
