/** @format */

import React from 'react'
import compose from 'recompose/compose'
import {Mutation, useMutation} from 'react-apollo'
import CheckboxTable from 'lib/tables/CheckboxTable'
import withConfirm from 'lib/withConfirm'

import { getCampaigns, removeCampaign, activateCampaign, stopCampaign } from 'api/campaigns'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import {removeList} from "../../api/lists";

const Campaigns = ({ classes, history, match, confirm }) => {

  const [activate] = useMutation(activateCampaign, { refetchQueries: ['getCampaigns'] })
  const [stop] = useMutation(stopCampaign, { refetchQueries: ['getCampaigns'] })

  return (
    <React.Fragment>
      <Mutation mutation={removeCampaign} refetchQueries={['getCampaigns']}>
        {(remove, { loading: loadingRemove }) => (
          <CheckboxTable
            query={getCampaigns}
            initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
            columns={[
              {
                Header: 'Name',
                accessor: 'name',
                width: 400,
                Cell: ({ original: campaign }) => (
                  <div>
                    <strong>{campaign.name}</strong>
                  </div>
                )
              },
              {
                Header: 'Status',
                accessor: 'status',
                width: 100
              },
              {
                Header: 'Actions',
                sortable: false,
                filterable: false,
                accessor: 'id',
                Cell: ({ original: campaign }) => (
                  <div>
                    {campaign.status==='Pending' && <IconButton onClick={() => activate({ variables: { campaignId: campaign.id } })} title="Activate Campaign">
                      <Icon fontSize="small">not_started</Icon>
                    </IconButton>}
                    {campaign.status==='Active' && <IconButton onClick={() => stop({ variables: { campaignId: campaign.id } })} title="Stop Campaign">
                      <Icon fontSize="small">stop</Icon>
                    </IconButton>}
                    {campaign.status==='Pending' && <IconButton onClick={() => history.push(`${match.url}/${campaign.id}/update`)} title="Edit Campaign">
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>}
                    <IconButton onClick={() => history.push(`${match.url}/${campaign.id}/stats`)} title="Statistics">
                      <Icon fontSize="small">visibility</Icon>
                    </IconButton>
                    {campaign.status==='Pending' && campaign.message1 && <IconButton onClick={() => history.push(`${match.url}/${campaign.id}/test`)} title="Send Test Message">
                      <Icon fontSize="small">email</Icon>
                    </IconButton>}
                    <IconButton
                      color="secondary"
                      onClick={confirm(() => remove({ variables: { id: campaign.id } }), {
                        title: 'Campaign Remove',
                        description: 'Are you sure you want to delete this Campaign?'
                      })}
                      title="Remove Campaign"
                      disabled={loadingRemove}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            ]}
            actions={[
              <Button key="create" variant="contained" color="primary" onClick={() => history.push(`${match.url}/create`)}>
                <Icon>add</Icon>
                Create Campaign
              </Button>
            ]}
            deleteMany={false}
          />
        )}
      </Mutation>
    </React.Fragment>
  )
}

const styles = theme => ({
  root: {}
})

const enhance = compose(withConfirm, withStyles(styles))

export default enhance(Campaigns)
