/** @format */

import gql from 'graphql-tag'

export const employeeFragment = gql`
  fragment employee on User {
    name
    firstName
    lastName
    email
    phone
    workPhone
    avatar
    timezone
    mailAddress {
      addressLine1
      addressLine2
      city
      state
      county
      zip
      country
    }
    companyId
    company {
      id
      name
      website
      email
      phone
      logo
      address {
        addressLine1
        addressLine2
        city
        state
        county
        zip
        country
      }
      representativeEmail
      representativeName
      representativePhone
    }
    notifyByEmail
    notifyBySMS
  }
`

export const getEmployees = gql`
  query getEmployees($masterAccountId: ID, $skip: Int, $limit: Int, $sort: [String]) {
    employees: users(masterAccountId: $masterAccountId, skip: $skip, limit: $limit, sort: $sort, accountType: "Employee") {
      data {
        id
        name
        firstName
        lastName
        email
        phone
        avatar
        accountType
        notifyByEmail
        notifyBySMS
        company {
          id
          name
          logo
          website
          email
          phone
        }
      }
      count
      pages
    }
  }
`

export const getEmployee = gql`
  query getEmployee($employeeId: String!) {
    employee: user(id: $employeeId) {
      id
      ...employee
    }
  }
  ${employeeFragment}
`

export const getDataCreationEmployee = gql`
  query getDataCreationEmployee {
    products(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
        plans {
          value: id
          label: name
        }
      }
    }
  }
`

export const getDataUpdatingEmployee = gql`
  query getDataUpdatingEmployee($employeeId: String!) {
    employee: user(id: $employeeId) {
      id
      ...employee
    }
    companies(limit: 0, sort: ["name", "1"]) {
      data {
        id
        name
      }
    }
  }
  ${employeeFragment}
`

export const createEmployee = gql`
  mutation createEmployee($data: UserInput!) {
    createUser(data: $data) {
      id
      ...employee
    }
  }
  ${employeeFragment}
`

export const createEmployees = gql`
  mutation createEmployees($data: [UserInput]!, $licenseId: ID!) {
    createEmployees(data: $data, licenseId: $licenseId) {
      createdCount
    }
  }
`
export const updateEmployee = gql`
  mutation updateEmployee($employeeId: String!, $data: UserInput!) {
    updateUser(id: $employeeId, data: $data) {
      id
      ...employee
    }
  }
  ${employeeFragment}
`

export const sendWelcomeEmail = gql`
  mutation sendWelcomeEmail($employeeId: ID!) {
    sendWelcomeEmail(id: $employeeId) {
      id
    }
  }
`

export const removeEmployee = gql`
  mutation removeEmployee($id: String!) {
    removeUser(id: $id) {
      id
    }
  }
`
