/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Nav from 'components/Nav'

import Sources from 'components/Sources'
import Subscriptions from 'components/Subscriptions'
import RCResseler from 'components/Account/RCResseler'
import UserProfile from './UserProfile'
import UserCompany from './UserCompany'

export default ({ match, history, location }) => (
  <React.Fragment>
    <Nav
      color="primary"
      items={[
        { key: 'profile', title: 'Profile', to: `${match.url}/profile` },
        { key: 'company', title: 'Company', to: `${match.url}/company` },
        { key: 'rc-resseler', title: 'Report Card Reseller', to: `${match.url}/rc-resseler` },
        { key: 'billing', title: 'Billing', to: `${match.url}/billing` },
        { key: 'subscriptions', title: 'Subscriptions', to: `${match.url}/subscriptions` }
      ]}
      activeKey={location.pathname.split('/')[4]}
    />
    <Switch>
      <Route path={`${match.path}/profile`} component={UserProfile} />
      <Route path={`${match.path}/company`} component={UserCompany} />
      <Route path={`${match.path}/rc-resseler`} component={RCResseler} />
      <Route path={`${match.path}/billing`} component={Sources} />
      <Route path={`${match.path}/subscriptions`} component={Subscriptions} />
    </Switch>
  </React.Fragment>
)
