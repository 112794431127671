/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useMutation } from 'react-apollo'
import moment from 'moment'
import CheckboxTable from 'lib/tables/CheckboxTable'
import CompanyCell from 'lib/tables/CompanyCell'
import SelectFilter from 'lib/tables/SelectFilter'
import withConfirm from 'lib/withConfirm'
import withAppContext from 'lib/withAppContext'

import { getCardReports, removeCardReport } from 'api/cardReports'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

import { cardReportStatusOptions } from 'data/options'

const CardReports = ({ classes, history, match, context, confirm }) => {
  const { acl } = context
  const [remove, { loading: loadingRemove }] = useMutation(removeCardReport, { refetchQueries: ['getCardReports'] })
  return (
    <React.Fragment>
      <Typography variant="h6">Card Reports</Typography>
      <CheckboxTable
        query={getCardReports}
        variables={{}}
        initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
        columns={[
          {
            Header: 'Domain',
            accessor: 'domain',
            Cell: ({ original: cardReport }) => (
              <div style={{ color: cardReport.status === 'ready' ? 'green' : 'red' }}>
                <div>{cardReport.domain}</div>
              </div>
            )
          },
          {
            Header: 'Company',
            accessor: 'company.name',
            Cell: ({ original: cardReport }) => <CompanyCell company={cardReport.company} />
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ original: cardReport }) => <div>{cardReport.status}</div>,
            Filter: props => (
              <SelectFilter {...props} data={cardReportStatusOptions.map(({ label, value }) => ({ id: value, name: label }))} />
            )
          },
          {
            Header: 'Total Rank',
            accessor: 'rank.points',
            Cell: ({ original: cardReport }) => (
              <div>
                {cardReport.rank && cardReport.rank.points > 0 ? (
                  <div>
                    {cardReport.rank.letter} ({cardReport.rank.points})
                  </div>
                ) : (
                  <div>N/A</div>
                )}
              </div>
            )
          },
          {
            Header: 'Actions',
            sortable: false,
            filterable: false,
            accessor: 'id',
            Cell: ({ original: cardReport }) => {
              return (
                <div>
                  {cardReport.token && (
                    <IconButton
                      onClick={() => window.open(`${process.env.REACT_APP_GATSBY_URL}/report/${cardReport.token}`, '_blank')}
                      title="View Report"
                    >
                      <Icon>report</Icon>
                    </IconButton>
                  )}
                  {acl.hasAccess('cardReports:edit') && (
                    <IconButton onClick={() => history.push(`${match.url}/${cardReport.id}/update`)} title="Edit Report">
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                  )}
                  {acl.hasAccess('cardReports:remove') && (
                    <IconButton
                      color="secondary"
                      onClick={confirm(() => remove({ variables: { id: cardReport.id } }), {
                        title: 'Card Report Remove',
                        description: 'Are you sure you want to delete this Report?'
                      })}
                      title="Remove Report"
                      disabled={loadingRemove}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  )}
                </div>
              )
            }
          }
        ]}
        actions={[
          acl.hasAccess('cardReports:add') && (
            <Button
              key="add"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                history.push(`${match.url}/add`)
              }}
            >
              <Icon>add</Icon>
              Add Report
            </Button>
          )
        ].filter(v => !!v)}
        deleteMany={false}
        noDataText={`Please create your first Report by clicking the "Add Report" button.`}
      />
    </React.Fragment>
  )
}

const styles = theme => ({
  root: {}
})

export default compose(withAppContext, withConfirm, withStyles(styles))(CardReports)
