/** @format */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { Form, Field } from 'lib/form'
import { TextField, PhoneNumber, RadioGroup, CodeField } from 'lib/form/fields'
import { required, phone, password, email } from 'lib/form/validators'

import { send2FaCode, login, onLogin } from 'api/auth'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const Login = ({ classes, location, match, history }) => {
  const [mode, setMode] = useState('phone')
  return (
    <Paper className={classes.root}>
      <Mutation mutation={send2FaCode}>
        {(sendCode, { loading: sendingCode }) => (
          <Form
            form="login"
            mutation={[login]}
            initialValues={{}}
            onSubmitSuccess={({
              data: {
                login: { token }
              }
            }) => {
              onLogin(token)
            }}
          >
            {({ doc, submitting, submit }) => (
              <React.Fragment>
                <div className="col justify-content-center">
                  <div className="text-center">
                    <img
                      src="https://chatfortress.s3.amazonaws.com/img/CF-logo-v2-NO-BGCrop.png"
                      alt="ChatFortress Application Login - cybersecurity assessment"
                      className="m-0"
                      style={{ width: '70%' }}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <p>
                    If you have questions use the live chat to ask your question.{' '}
                    <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e5aee3e04286364bc960c79">
                      Get help here.
                    </a>
                  </p>
                  <h4>How to Login </h4>
                  <p>
                    Use the cell phone number or email address attached to your account then click "send me a code" button. Get the 6 digit
                    one-time use code from your cell phone (text message) or email and enter it into the form below.
                  </p>
                </div>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Box p={1}>
                    <Typography variant="h5">Sign-in using your Cell Phone or Email</Typography>
                  </Box>
                  {/*<Box p={1}>
              <Link to="/auth/signup">Sign Up Now</Link>
            </Box>*/}
                </Box>

                {mode === 'phone' && (
                  <React.Fragment>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
                      <Field name="phone" component={PhoneNumber} label="Phone Number" margin="none" validate={[required()]} />
                      <Button
                        style={{ top: -10 }}
                        variant="contained"
                        color="primary"
                        onClick={() => sendCode({ variables: { data: { phone: doc.phone } } })}
                        disabled={!doc.phone || submitting || sendingCode}
                      >
                        Send Me a Code
                      </Button>
                    </Box>
                    <Typography>
                      <a href="#" onClick={() => setMode('email')}>
                        Use Email Address
                      </a>
                    </Typography>
                  </React.Fragment>
                )}

                {mode === 'email' && (
                  <React.Fragment>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <Field
                        type="email"
                        name="email"
                        component={TextField}
                        label="Email Address"
                        margin="none"
                        validate={[required(), email()]}
                        variant="standard"
                        //fullWidth={false}
                        style={{ maxWidth: 350 }}
                      />
                      <Button
                        style={{ marginLeft: 8 }}
                        variant="contained"
                        color="primary"
                        onClick={() => sendCode({ variables: { data: { email: doc.email } } })}
                        disabled={!doc.email || submitting || sendingCode}
                      >
                        Send Me a Code
                      </Button>
                    </Box>
                    <Typography>
                      <a href="#" onClick={() => setMode('phone')}>
                        Use Phone Number
                      </a>
                    </Typography>
                  </React.Fragment>
                )}

                <Field name="code" component={CodeField} label="Please input the one-time code provided to you" validate={[required()]} />
                {/*<Field name="email" component={TextField} label="Email Address" validate={[required(), email()]} />
              <Field name="password" type="password" component={TextField} label="Password" validate={[required(), password()]} />*/}
                <div className="col justify-content-center text-center mt-2">
                  <Button fullWidth variant="contained" color="primary" onClick={submit} disabled={submitting}>
                    Sign In
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Form>
        )}
      </Mutation>
    </Paper>
  )
}

const styles = () => ({
  root: {
    padding: 10
  }
})

export default withStyles(styles)(Login)
