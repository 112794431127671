/** @format */

import React from 'react'
import { Field } from 'redux-form'

import { TextField } from 'lib/form/fields'

import Typography from '@material-ui/core/Typography'

class MetaDataPage extends React.Component {
  static defaultProps = {
    name: 'metaData'
  }

  render() {
    const { title = '' } = this.props

    return (
      <React.Fragment>
        <Typography variant="h6">{title}</Typography>
        <Field name="title" component={TextField} label="Title" margin="none" multiline />
        <Field name="description" component={TextField} label="Description" multiline />
        <Field name="keywords" component={TextField} label="keywords" multiline />
        <Field name="image" component={TextField} label="Image" />
      </React.Fragment>
    )
  }
}

export default MetaDataPage
