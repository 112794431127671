/** @format */

import React from 'react'
import _ from 'lodash'
import { useQuery } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField, TagsField } from 'lib/form/fields'
import { required } from 'lib/form/validators'

import { getPhoneNumber, updatePhoneNumber } from 'api/phoneNumbers'

import withStyles from '@material-ui/core/styles/withStyles'

const PhoneNumberUpdate = ({ classes, match }) => {
  const { loading, data: { phoneNumber = {} } = {} } = useQuery(getPhoneNumber, { variables: match.params })

  return (
    !loading && (
      <FormDialog
        title="Update PhoneNumber"
        form="PhoneNumberUpdate"
        mutation={[updatePhoneNumber, { refetchQueries: ['getPhoneNumbers'] }]}
        initialValues={_.pick(phoneNumber, ['country',
'type',
'phoneNumber',
'areaCode',
'friendlyName',
'smsUrl',
'voiceUrl',
'dialNumbers',
'welcomeVoiceText',
'smsNumbers',
'welcomeSmsText',
'owner'])}
        btnSubmitText="Save"
        success="PhoneNumber updated successfully"
      >
        {({ doc, invalid, submitting }) => (
          <React.Fragment>
            <Field name="friendlyName" component={TextField} label="Friendly Name" validate={[required()]} />
            <Field
              name="dialNumbers"
              component={TagsField}
              label="Forward Phone Numbers"
              validate={[required()]}
              placeholder="Add More Phone Numbers, Press Enter or Tab after each"
            />
            <Field name="welcomeVoiceText" component={TextField} label="Welcome Voice Text" helperText="Welcome Voice Text." multiline />
            <Field
              name="smsNumbers"
              component={TagsField}
              label="Forward SMS Phone Numbers"
              validate={[required()]}
              placeholder="Add More Phone Numbers, Press Enter or Tab after each"
            />
            <Field name="welcomeSmsText" component={TextField} label="Welcome SMS Text" helperText="Welcome SMS Text." multiline />
          </React.Fragment>
        )}
      </FormDialog>
    )
  )
}

const styles = () => ({
  root: {}
})

export default withStyles(styles)(PhoneNumberUpdate)
