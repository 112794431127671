import React from 'react'
import numeral from 'numeral'

const PlanSummary = ({ product, plan, coupon, quantity = 1 }) => {
  let setupFee = plan.setupFee || product.setupFee || 0
  let amount = plan.amount || 0
  if (coupon) {
    if (product.couponUpfrontOnly) {
      setupFee = coupon.amount_off > 0 ? setupFee - coupon.amount_off / 100 : setupFee - (setupFee * coupon.percent_off) / 100
    } else {
      amount = coupon.amount_off > 0 ? amount - coupon.amount_off / 100 : amount - (amount * coupon.percent_off) / 100
    }
  }
  if (setupFee < 0) {
    setupFee = 0
  }
  if (amount < 0) {
    amount = 0
  }
  let totalAmount = setupFee + amount * quantity

  return (
    plan && (
      <div className="form-group">
        <div>Total Charge Today is as follows:</div>
        <div>
          Setup Fee: <strong>{numeral(setupFee).format('$0,0.00')}</strong>
        </div>
        <div>
          Product Access:{' '}
          <strong>
            {numeral(amount * quantity).format('$0,0.00')} per {plan.interval} {plan.freq}
          </strong>
        </div>
        <div>
          Number of Licenses: <strong>{quantity}</strong>
        </div>
        <div>
          Total Due Today: <strong>{numeral(totalAmount).format('$0,0.00')}</strong>
        </div>
        {plan.billTimes > 0 && (
          <div>
            Bill: <strong>{numeral(plan.billTimes).format('0,0')} times</strong>
          </div>
        )}
        {plan.trial_period_days > 0 && (
          <div>
            Trial Period: <strong>{numeral(plan.trial_period_days).format('0,0')} days</strong>
          </div>
        )}
      </div>
    )
  )
}

export default PlanSummary
