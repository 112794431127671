/** @format */

import React from 'react'
import { Field } from 'redux-form'

import { TextField } from 'lib/form/fields'
import { required, numericality } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import {acl} from 'lib/acl'

import { userAccountTypeOptions } from 'data/options'

const ProductCommissionsField = ({ classes, fields, meta, label, ...props }) => (
  <div>
    <Typography variant="h5" color="textSecondary" gutterBottom>
      {label}
    </Typography>
    {fields.map((member, index) => (
      <Grid key={index} spacing={2} justify="center" container>
        <Grid xs={3} item>
          <Field
            name={`${member}.role`}
            component={TextField}
            label="User Role"
            options={Object.keys(acl.roles).map(r=>({label: r, value: r}))}
            validate={[required()]}
            select
            margin="none"
          />
        </Grid>
        <Grid xs={2} item>
          <Field
            name={`${member}.masterRole`}
            component={TextField}
            label="Master Role"
            options={[{ label: 'None', value: '' }].concat(Object.keys(acl.roles).map(r=>({label: r, value: r})))}
            select
            margin="none"
          />
        </Grid>
        <Grid xs={2} item>
          <Field
            name={`${member}.setupFee`}
            component={TextField}
            label="Setup Fee %"
            margin="none"
            validate={[numericality()]}
            normalize={number}
          />
        </Grid>
        <Grid xs={2} item>
          <Field
            name={`${member}.subscription`}
            component={TextField}
            label="Subscripton %"
            margin="none"
            validate={[numericality()]}
            normalize={number}
          />
        </Grid>
        <Grid xs={2} item>
          <Field
            name={`${member}.payoutNumber`}
            component={TextField}
            label="Number of payouts"
            normalize={number}
            options={[{ label: 'Unlimited', value: 0 }].concat(Array.from(Array(12)).map((v,r)=>({label: r+1, value: r+1})))}
            select
            margin="none"
          />
        </Grid>
        <Grid xs={1} item>
          <IconButton onClick={() => fields.remove(index)}>
            <Icon>delete</Icon>
          </IconButton>
        </Grid>
      </Grid>
    ))}
    <IconButton onClick={() => fields.push({})}>
      <Icon>add_circle</Icon>
    </IconButton>
  </div>
)

const styles = theme => ({
  root: {}
})

export default withStyles(styles)(ProductCommissionsField)
