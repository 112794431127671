/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import HackedReports from './HackedReports'
import HackedReportAdd from './HackedReportAdd'
import HackedReportUpdate from './HackedReportUpdate'

export default ({ match }) => (
  <Container maxWidth="lg">
    <Box my={2}>
      <Switch>
        <Route path={`${match.path}`} component={HackedReports} />
      </Switch>
      <Route path={`${match.path}/add`} component={HackedReportAdd} />
      <Route path={`${match.path}/:hackedReportId/update`} component={HackedReportUpdate} />
    </Box>
  </Container>
)
