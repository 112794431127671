/** @format */

import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import cardTextStyle from './styles/cardTextStyle'

function CardText({ ...props }) {
  const { classes, className, children, color, ...rest } = props
  const cardTextClasses = classNames({
    [classes.cardText]: true,
    [classes[color + 'CardHeader']]: color,
    [className]: className !== undefined
  })
  return (
    <div className={cardTextClasses} {...rest}>
      {children}
    </div>
  )
}

/*
CardText.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['warning', 'success', 'danger', 'info', 'primary', 'rose'])
}
*/

export default withStyles(cardTextStyle)(CardText)
