/** @format */

import gql from 'graphql-tag'

export const companyFragment = gql`
  fragment company on Company {
    name
    website
    domain
    email
    phone
    logo
    notes {
      body
      createdAt
      author {
        name
        avatar
        id
      }
    }
    featuredInCategory
    noComparison
    hiddenByOwner
    noFeaturedComparison
    noSensitiveComparison
    isDomainVerified
    isQuizCompleted
    screenshot
    thumbnail
    categories
    charitableCommitment
    fundraisingEfficiency
    privateDonations
    totalRevenue
    formattedAddress
    address {
      addressLine1
      addressLine2
      city
      state
      county
      zip
      country
    }
    representativeName
    representativeEmail
    representativePhone
    owner
    sponsorBox
    sponsorName
    sponsorLogo
    sponsorWebsite
    sponsorPhone
    sponsorEmail
    createdAt
  }
`

export const getCompanies = gql`
  query getCompanies($name: String, $categories: String, $website: String, $skip: Int, $limit: Int, $sort: [String]) {
    companies(name: $name, categories: $categories, website: $website, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        shortUrl
        publicShortUrl
        website
        email
        notes {
          body
          createdAt
          author {
            name
            avatar
            id
          }
        }
        hiddenByOwner
        noFeaturedComparison
        noSensitiveComparison
        phone
        logo
        thumbnail
        categories
        formattedAddress
        charitableCommitment
        fundraisingEfficiency
        privateDonations
        totalRevenue
        address {
          addressLine1
          addressLine2
          city
          state
          county
          zip
          country
        }
        representativeName
        breachesCount
        rank
        totalRank
        categoryRank
        totalCategoryRank
        rankPoints
        rankLetter
        vulnCount
        report {
          id
          token
          status
        }
      }
      count
      pages
    }
  }
`
export const getCompaniesShort = gql`
  query getCompanies($name: String, $categories: String, $website: String, $selector: Boolean) {
    companies(name: $name, categories: $categories, website: $website, selectorField: $selector, limit: 20, sort: ["name", "1"]) {
      data {
        id
        name
        email
        website
        phone
        logo
        thumbnail
      }
      count
      pages
    }
  }
`

export const getCompany = gql`
  query getCompany($companyId: ID!) {
    company(id: $companyId) {
      id
      ...company
    }
  }
  ${companyFragment}
`

export const getCompanyDataForCreation = gql`
  query getCompanyDataForCreation {
    categories(sort: ["name", "1"]) {
      id
      name
      parent
    }
    resellers: users(
      roles: [
        "admin"
        "RC-Reseller"
        "RC-Reseller-Pro"
        "RC-Reseller-Starter"
        "CF-Reseller-Starter"
        "CF-Reseller"
        "CF-Reseller-Pro"
        "RC-Vendor"
        "RC-Influencer"
        "RC-SalesTier1"
        "RC-SalesTier2"
        "CF-RC-SalesTier1"
        "CF-RC-SalesTier2"
      ]
      sort: ["name", "1"]
      skip: 0
      limit: 0
    ) {
      data {
        value: id
        label: name
      }
    }
  }
`

export const getCompanyDataForUpdating = gql`
  query getCompanyDataForUpdating($companyId: ID!) {
    company(id: $companyId) {
      id
      ...company
    }
    categories(sort: ["name", "1"]) {
      id
      name
      parent
    }
    resellers: users(
      roles: [
        "admin"
        "RC-Reseller"
        "RC-Reseller-Pro"
        "RC-Reseller-Starter"
        "CF-Reseller-Starter"
        "CF-Reseller"
        "CF-Reseller-Pro"
        "RC-Vendor"
        "RC-Influencer"
        "RC-SalesTier1"
        "RC-SalesTier2"
        "CF-RC-SalesTier1"
        "CF-RC-SalesTier2"
      ]
      sort: ["name", "1"]
      skip: 0
      limit: 0
    ) {
      data {
        value: id
        label: name
      }
    }
  }
  ${companyFragment}
`

export const createCompany = gql`
  mutation createCompany($data: CompanyInput!) {
    createCompany(data: $data) {
      id
      ...company
    }
  }
  ${companyFragment}
`
export const createCompanies = gql`
  mutation createCompanies($data: [CompanyInput], $owner: String) {
    createCompanies(data: $data, owner: $owner) {
      id
    }
  }
`

export const updateCompany = gql`
  mutation updateCompany($companyId: ID!, $data: CompanyInput!) {
    updateCompany(id: $companyId, data: $data) {
      id
      ...company
    }
  }
  ${companyFragment}
`
export const addCompanyNote = gql`
  mutation addCompanyNote($companyId: ID!, $body: String) {
    addCompanyNote(id: $companyId, body: $body) {
      id
      ...company
    }
  }
  ${companyFragment}
`
export const updateCompanyBulk = gql`
  mutation updateCompanyBulk($companyIds: [ID]!, $data: CompanyInput!) {
    updateCompanyBulk(ids: $companyIds, data: $data) {
      id
    }
  }
`

export const removeCompany = gql`
  mutation removeCompany($id: ID!) {
    removeCompany(id: $id) {
      id
    }
  }
`
