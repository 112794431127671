import _ from 'lodash'

const ranking = [
  {
    color: '#10FF00',
    range: [101, 97]
  },
  {
    color: '#30FF00',
    range: [97, 93]
  },
  {
    color: '#60FF00',
    range: [93, 90]
  },
  {
    color: '#90FF00',
    range: [90, 87]
  },
  {
    color: '#D0FF00',
    range: [87, 83]
  },
  {
    color: '#F0FF00',
    range: [83, 80]
  },
  {
    color: '#FFF000',
    range: [80, 77]
  },
  {
    color: '#FFD000',
    range: [77, 73]
  },
  {
    color: '#FFC000',
    range: [73, 70]
  },
  {
    color: '#FFB000',
    range: [70, 67]
  },
  {
    color: '#FFA000',
    range: [67, 63]
  },
  {
    color: '#FF9000',
    range: [63, 60]
  },
  {
    color: '#FF0000',
    range: [60, 0]
  }
]

const hexToRgb = hex => {
  hex = hex.replace('#', '')
  var bigint = parseInt(hex, 16)
  var r = (bigint >> 16) & 255
  var g = (bigint >> 8) & 255
  var b = bigint & 255

  return 'rgb(' + r + ',' + g + ',' + b + ')'
}

const getRankingColorByPoints = (points = 0) => {
  let color = '#FF0000'
  for (let grade of ranking) {
    if (points < grade.range[0] && points >= grade.range[1]) {
      color = grade.color
    }
  }
  return color
}

const getColorByValue = value => {
  value = parseInt(value)
  return hexToRgb(getRankingColorByPoints(value))
  /*if (!value || value < 0) return 'rgb(255,0,0)'
  let base = 55
  let v = ((value - 45) / base) * 100
  if (v < 0) v = 0

  let r,
    g,
    b = 0
  if (v < 50) {
    r = 255
    g = Math.round(5.1 * v)
  } else {
    g = 255
    r = Math.round(510 - 5.1 * v)
  }

  if (value < 85 && value > 75) {
    g = Math.round(g * 0.95)
    r = Math.round(r * 1.15)
  }

  if (value < 75 && value > 65) {
    g = Math.round(g * 0.85)
    r = Math.round(r * 1.15)
  }

  if (r > 255) r = 255
  if (g > 255) g = 255

  return `rgb(${r},${g},${b})`*/
}

const getGradientByValue = value => {
  let gradient = Array.from({ length: 100 }, (v, i) => i).map(v => ({
    opacity: 1,
    offset: v,
    color: getColorByValue(v)
  }))

  gradient = _.filter(gradient, item => item.offset <= value)
  gradient = _.uniqBy(
    gradient.map((item, k) => ({ ...item, offset: k * (100 / gradient.length) })),
    d => d.color
  )
  return gradient
}

const rgbToHex = rgb => {
  let [r, g, b] = rgb
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map(a => a * 1)
  return (
    '#' +
    [r, g, b]
      .map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
      })
      .join('')
  )
}

const chartOptions = {
  chart: {
    width: 100,
    offsetY: -40
  },
  plotOptions: {
    radialBar: {
      startAngle: -120,
      endAngle: 120,
      track: {
        show: true,
        startAngle: undefined,
        endAngle: undefined,
        background: '#F2FCFF',
        strokeWidth: '97%',
        opacity: 1,
        margin: 5,
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 3,
          opacity: 0.5
        }
      },
      hollow: {
        background: '#F2FCFF'
      },
      dataLabels: {
        name: {
          fontSize: '5em',
          color: getColorByValue(0),
          offsetY: 20
        },
        value: {
          offsetY: 50,
          fontSize: '26px',
          color: '#172DA6',
          formatter: function(val) {
            return val
          }
        }
      }
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'diagonal1',
      shadeIntensity: 0.5,
      colorStops: [],
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  stroke: {
    dashArray: 2
  }
}

const barChartOptions = {
  legend: { show: false },
  annotations: {
    points: [
      {
        seriesIndex: 0,
        label: {
          borderColor: '#172DA6',
          offsetY: 0,
          style: {
            color: '#fff',
            background: '#172DA6'
          },
          text: 'You Are Here'
        }
      }
    ]
  },
  chart: {
    height: 350,
    type: 'bar'
  },
  plotOptions: {
    bar: {
      columnWidth: '50%',
      endingShape: 'flat',
      distributed: true
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    width: 2
  },

  grid: {
    row: {
      colors: ['#fff', '#f2f2f2']
    }
  },
  xaxis: {
    labels: {
      rotate: -45
    }
  },
  yaxis: {
    title: {
      text: 'Security Points'
    }
  },
  fill: {
    type: 'solid'
  }
}

export { getColorByValue, getGradientByValue, rgbToHex, chartOptions, barChartOptions }
