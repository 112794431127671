/** @format */

import acl from './AccessControl'
import Roles from './Roles'
import Resources from './Resources'

acl
  .deny(Roles.PUBLIC)
  .resource('*')
  .action('*')
  .onFields('*')
  .grant(Roles.PUBLIC)
  .scope('auth:*')

acl
  .grant(Roles.ADMIN)
  .resource('*')
  .action('*')
  .onFields('*')

acl
  .grant(Roles.USER)
  .inherits([Roles.PUBLIC])
  .scope('dashboard:*')
  .scope('account:*')
  .scope('training:*')
  .scope('account.profile:*')

// Client Related
acl
  .grant(Roles.CLIENT)
  .inherits([Roles.USER])
  .scope('training:*')
  .scope('account.company:*')
  .scope('account.notifications:*')
  .scope('account.billing:*')
  .scope('account.plans:*')
  .scope('account.employees:*')
  .scope('account.licenses:*')
  .scope('monitoringObjects:add')
  .scope('monitoringObjects:read')
  .scope('monitoringObjects:update')
  .scope('monitoringObjects:remove')
  .scope('hackedReports:*')
  //.scope('cardReports:read')
  .scope('reportCard:read')
  .deny(Roles.CLIENT)
  .scope('dashboard:*')

acl
  .grant(Roles.EMPLOYEE)
  .inherits([Roles.USER])
  .scope('training:*')
  .scope('guests:invite')
  .deny(Roles.EMPLOYEE)
  .scope('dashboard:*')

acl
  .grant(Roles.PROSPECT)
  .inherits([Roles.CLIENT])
  .scope('dashboard:*')
  .scope('monitoringObjects:*')
acl
  .grant(Roles.COMPANY)
  .inherits([Roles.CLIENT])
  .scope('dashboard:*')
  .scope('monitoringObjects:*')
acl
  .grant(Roles.COMPANY_MANAGER)
  .inherits([Roles.COMPANY])
  .scope('dashboard:*')
  //.scope('activityLog:*')
  .scope('monitoringObjects:*')
  .scope('trainingActivity:*')
  .scope('companies:*')
acl
  .grant(Roles.TF_Client)
  .inherits([Roles.COMPANY_MANAGER])
  .scope('trainingActivity:*')
acl
  .grant(Roles.VENDOR)
  .inherits([Roles.COMPANY])
  .scope('dashboard:*')
  .scope('monitoringObjects:*')

// ChatFortress Related
acl
  .grant(Roles.ChatFortress)
  .inherits([Roles.CLIENT])
  .scope('dashboard:*')
  .scope('monitoringObjects:*')
acl.grant(Roles.CF_ADMIN).inherits([Roles.ADMIN])
acl.grant(Roles.CF_MANAGER).inherits([Roles.ChatFortress])
acl.grant(Roles.CF_EMPLOYEE).inherits([Roles.ChatFortress])
acl
  .grant(Roles.CF_RESELLER)
  .inherits([Roles.ChatFortress])
  .scope('dashboard.reseller:*')
  .scope('account.services:*')
  .scope('account.rc-resseler:*')
  .scope('commissions:*')
  .scope('companies:*')
  .scope('hackedReports:*')
  .scope('monitoringObjects:*')
  .scope('proposals:*')
  .deny(Roles.CF_RESELLER)
  .scope('companies:setOwner')
acl.grant(Roles.CF_RESELLER_STARTER).inherits([Roles.CF_RESELLER]).scope('marketing:*')
acl.grant(Roles.CF_RESELLER_GROWTH).inherits([Roles.CF_RESELLER]).scope('marketing:*')
acl.grant(Roles.CF_RESELLER_PRO).inherits([Roles.CF_RESELLER]) .scope('marketing:*')
acl.grant(Roles.CF_REFERRAL).inherits([Roles.CF_RESELLER])
acl.grant(Roles.CF_SUB_RESELLER).inherits([Roles.CF_RESELLER])
acl.grant(Roles.CF_SALES_REP).inherits([Roles.CF_RESELLER])

// Secure Chat Related
acl
  .grant(Roles.SecureChat)
  .inherits([Roles.USER])
  .scope('dashboard:*')
  .scope('messages:*')
  .scope('requests:*')
  .scope('blocks:*')
acl.grant(Roles.SC_PERSON).inherits([Roles.SecureChat])
acl.grant(Roles.SC_COMPANY).inherits([Roles.SecureChat])
acl.grant(Roles.SC_VENDOR).inherits([Roles.SecureChat])

// Report Card Related
acl
  .grant(Roles.ReportCard)
  .inherits([Roles.USER])
  .scope('dashboard:*')
  .scope('hackedReports:*')
  .scope('monitoringObjects:*')
  .scope('reportCard:read')
 // .scope('cardReports:*')
acl.grant(Roles.RC_PERSON_PROSPECT).inherits([Roles.ReportCard])
acl.grant(Roles.RC_PERSON_STARTER).inherits([Roles.RC_PERSON_PROSPECT])
acl.grant(Roles.RC_PERSON_GROWTH).inherits([Roles.RC_PERSON_PROSPECT])
acl.grant(Roles.RC_PERSON_SCALING).inherits([Roles.RC_PERSON_PROSPECT])
acl.grant(Roles.RC_COMPANY).inherits([Roles.ReportCard])

acl.grant(Roles.RC_VENDOR)
  .scope('marketing:*')
  .scope('account:*')
  .scope('account.services:*')
  .scope('account.profile:*')
  .scope('account.company:*')
  .scope('account.notifications:*')
  .scope('account.billing:*')
  .scope('account.plans:*')
  .scope('training:*')
  .scope('commissions:*')
  .scope('proposals:*')
  .scope('companies:*')
  .scope('cardReport:*')
  .scope('marketing:*')
  .scope('hackedReports:*')
  .scope('monitoringObjects:*')
  .scope('companies:setOwner')

acl.grant(Roles.RC_INFUENCER).inherits([Roles.RC_VENDOR])

acl.grant(Roles.CF_RC_SalesTier1)
  //.inherits([Roles.ChatFortress])
  .scope('marketing:*')
  .scope('account:*')
  .scope('account.services:*')
  .scope('account.profile:*')
  .scope('account.company:*')
  .scope('account.notifications:*')
  .scope('account.billing:*')
  .scope('account.plans:*')
  .scope('training:*')
  .scope('commissions:*')
  .scope('proposals:*')
  .scope('companies:*')
  .scope('cardReport:*')
  .scope('marketing:*')
  .scope('hackedReports:*')
  .scope('monitoringObjects:*')
  .scope('companies:setOwner')


acl.grant(Roles.RC_SalesTier1).inherits([Roles.CF_RC_SalesTier1]).scope('dashboard:*')
acl.grant(Roles.RC_SalesTier2).inherits([Roles.CF_RC_SalesTier1]).scope('dashboard:*')
acl.grant(Roles.CF_SALES_REP).inherits([Roles.CF_RC_SalesTier1]).scope('dashboard:*')

acl.grant(Roles.CF_RC_SalesTier2).inherits([Roles.CF_RC_SalesTier1])


acl
  .grant(Roles.CMMC_CLIENT)
  .inherits([Roles.USER])
  .scope('training:*')
acl
  .grant(Roles.EC_CMMC_L1)
  .inherits([Roles.USER])
  .scope('training:*')
acl
  .grant(Roles.EC_CMMC_L2)
  .inherits([Roles.EC_CMMC_L1])
acl
  .grant(Roles.EC_CMMC_L3)
  .inherits([Roles.EC_CMMC_L1])
acl
  .grant(Roles.EC_CMMC_L4)
  .inherits([Roles.EC_CMMC_L1])
acl
  .grant(Roles.EC_CMMC_L5)
  .inherits([Roles.EC_CMMC_L1])


export { Roles, Resources, acl }
