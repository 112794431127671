import React from 'react'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, length } from 'lib/form/validators'

import { createList } from 'api/lists'

import withStyles from '@material-ui/core/styles/withStyles'

import { userAccountTypeOptions } from 'data/options'

const ListCreate = ({ classes, match }) => (
  <FormDialog
    title="Create List"
    form="ListCreate"
    mutation={[createList, { refetchQueries: ['getLists'] }]}
    initialValues={{}}
    btnSubmitText="Create"
    success="List created successfully"
  >
    {({ doc, invalid, submitting }) => (
      <React.Fragment>
        <Field name="name" component={TextField} label="List Name" validate={[required(), length({ min: 2 })]} />
      </React.Fragment>
    )}
  </FormDialog>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(ListCreate)
