/** @format */
import React from 'react'

const tpl = ({ proposal, flatPrice, unitPrice, unitsAmount }) => {
  return (
    <div>
      <section className="py-4">
        <div className="container">
          <h1>Email Guardian Small Business Scholarship Program</h1>
          <h2>What’s included in the Email Guardian Small Business Program?</h2>

          <p>Your Email Guardian Small Business will activate on the acceptance of this agreement and include:</p>
          <h2></h2>
          <h2>
            Threat Assessment: <i>*Scaling Plan</i>
          </h2>
          <ul>
            <li>Attack simulation and threat assessment testing.</li>
          </ul>
          <h2>Advanced Threat Detection:</h2>
          <ul>
            <li>Advanced Malware and URL/Link Protection.</li>
            <li>Advanced Protection Against Business Email Compromise (BEC).</li>
          </ul>
          <h2>SECOPS:</h2>
          <ul>
            <li>Automated Email Phishing Investigation, Orchestration &amp; Response.</li>
            <li>Automated &amp; Collaborative Phishing Campaign Detection. Crowd sourced SECOP’s threat mitigation.</li>
            <li>An AI-Powered Virtual Email Security Analyst</li>
            <li>Human Virtual Email Security Analyst response times vary based on choosen plan.</li>
          </ul>
          <p>
            ChatFortress extends the functionality of the cyber security system by layering a remote and Virtual Security Analyst team to
            monitor and manage your account. Our team will identify and respond to any email incidents 24/7 so you don’t have to
            ChatFortress acts as your personal SECOP’s team within your company.
          </p>
          <p>
            Just so you know, it’s common for SECOP’s teams to receive 500+ email tickets per day from employees. This task of verifying and
            validating every incident, usually results in company SECOP teams being backlogged for weeks or months. However, the
            ChatFortress team responds and validates these incidences within minutes to eliminate any security threats from all of your
            email inboxes.
          </p>
          <h2>Email Guardian Security Training </h2>
          <p>ChatFortress Email Guardian includes real-time security awareness training such as:</p>
          <ol>
            <li>Monthly Webinars with recordings posted to the ChatFortress Clients Area.</li>
            <li>
              Real-time Email Phishing Simulation: ChatFortress will send specialized phishing emails to your active email accounts to test
              response/reporting time awareness. If users click on these emails, they will be presented with a short training video
              explaining how they could have identified this email as a phishing email.<i>*Scaling Plan only</i>
            </li>
            <li>
              Delivering monthly result reports from phishing tests to the account managers. <i>*Scaling Plan</i>
            </li>
            <li>
              Cyber Security Awareness Monthly: one training video and quiz each month on a current Cybersecurity Attack.
              <i>*Scaling Plan</i>
            </li>
          </ol>
          <h2>
            Real-time email phishing simulation: <i>*Scaling Plan</i>
          </h2>
          <p>
            The goal of your phishing simulation and training program is to improve your team members awareness and response rates to these
            types of email attacks. The ChatFortress team will use emails that are current attack vectors within the industry. This provides
            real-time simulation of emails that are received on a daily basis.
          </p>
          <h2>Cybersecurity awareness monthly:</h2>
          <p>
            The goal of our Cyber Security Awareness Monthly training program is to keep your team informed and aware of current attack
            vectors used by hackers. This awareness training is delivered via SMS to your team/employees and has been designed to be
            consumed in 7 minutes or less. Each of our videos are connected to a quiz to test employee knowledge, retention and progress
            followed up with a training awareness analytics report.{' '}
          </p>
          <p>
            ChatFortress provides the latest in gamified Cybersecurity Awareness training. Our program has also been designed to provide
            micro lessons with quick micro-engagements. Users can expect up to 1 text message per week. This ensures your team is creating a
            culture of security and awareness.
          </p>
          <p>
            Our customized ChatFortress Cybersecurity Awareness Monthly requires no software to be installed. Your employees can access via
            their desktop, tablet or mobile devices. This promotes consumption of content.
          </p>
          <h1>Your Email Guardian Small Business Program Investment: </h1>
          <p>
            The section below explains the proposal of your ChatFortress Email Guardian Small Business investment on acceptance of this
            agreement. You understand that this is a monthly subscription investment for the Email Gardian cybersecurity protection services
            and your payment source (that you choose – credit card, ACH, etc.) will be billed on acceptance of this agreement. Your
            subscription will continue until canceled. in writing
          </p>

          <table className="table table-bordered" style={{ fontSize: '1.3em', fontWeight: 600 }}>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>DESCRIPTION</th>
                <th>QTY</th>
                <th>Price/Total Unit</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Email Guardian Small Business Scholarship Virtual Security Analyst Service</td>
                <td>1</td>
                <td>$0.00</td>
                <td style={{ textAlign: 'right' }}>$0.00</td>
              </tr>

              <tr>
                <td>Email Guardian Small Business Program</td>
                <td>1</td>
                <td>${unitPrice}/Email Account/Month</td>
                <td style={{ textAlign: 'right' }}>${unitPrice}</td>
              </tr>
              <tr>
                <td>Account Setup and Activation</td>
                <td>1</td>
                <td>${flatPrice}</td>
                <td style={{ textAlign: 'right' }}>${flatPrice}</td>
              </tr>

              <tr>
                <td>Total Active Email Account Licenses</td>
                <td>{proposal.emailCount}</td>
                <td>${unitPrice}/Email Account/Month</td>
                <td style={{ textAlign: 'right' }}>
                  ${proposal.emailCount > 1 ? Math.round(100 * (proposal.emailCount * unitPrice)) / 100 : 0}
                </td>
              </tr>

              <tr>
                <td colSpan={4}>
                  {proposal.couponData && (
                    <div style={{ float: 'right', color: 'green', weight: 600 }}>Coupon "{proposal.couponData.name}" applied</div>
                  )}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td style={{ textAlign: 'right' }} colSpan={2}>
                  TOTAL INVESTMENT TODAY
                </td>
                <td style={{ textAlign: 'right' }}>
                  {proposal.couponData && (
                    <span>
                      <span style={{ textDecoration: 'line-through', color: 'red' }}>
                        ${flatPrice + (proposal.emailCount > 1 ? proposal.emailCount * unitPrice : 0)}
                      </span>{' '}
                      <span style={{ color: 'green', weight: 600 }}>
                        $
                        {proposal.couponData.amount_off &&
                          Math.round(
                            100 *
                              (flatPrice - proposal.couponData.amount_off/100 + (proposal.emailCount > 1 ? proposal.emailCount * unitPrice : 0))
                          ) / 100}
                        {proposal.couponData.percent_off &&
                          Math.round(
                            100 *
                              (flatPrice * ((100 - proposal.couponData.percent_off) / 100) +
                                ((100 - proposal.couponData.percent_off) / 100) *
                                  (proposal.emailCount > 1 ? proposal.emailCount * unitPrice : 0))
                          ) / 100}
                      </span>
                    </span>
                  )}
                  {!proposal.couponData && (
                    <span>${Math.round(100 * (flatPrice + (proposal.emailCount > 1 ? proposal.emailCount * unitPrice : 0))) / 100}</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td style={{ textAlign: 'right' }} colSpan={2}>
                  TOTAL INVESTMENT MONTHLY
                </td>
                <td style={{ textAlign: 'right' }}>
                  {proposal.couponData && (
                    <span>
                      <span style={{ textDecoration: 'line-through', color: 'red' }}>
                        ${flatPrice + (proposal.emailCount > 1 ? proposal.emailCount * unitPrice : 0)}
                      </span>{' '}
                      <span style={{ color: 'green', weight: 600 }}>
                        $
                        {proposal.couponData.amount_off &&
                          Math.round(
                            100 *
                              (flatPrice - proposal.couponData.amount_off/100 + (proposal.emailCount > 1 ? proposal.emailCount * unitPrice : 0))
                          ) / 100}
                        {proposal.couponData.percent_off &&
                          Math.round(
                            100 *
                              (flatPrice * ((100 - proposal.couponData.percent_off) / 100) +
                                ((100 - proposal.couponData.percent_off) / 100) *
                                  (proposal.emailCount > 1 ? proposal.emailCount * unitPrice : 0))
                          ) / 100}
                      </span>
                    </span>
                  )}
                  {!proposal.couponData && (
                    <span>${proposal.emailCount > 1 ? Math.round(100 * (proposal.emailCount * unitPrice)) / 100 : 0}</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            ChatFortress is a monthly subscription service. Users must give 30 days notice to cancel their ChatFortress account. There are
            no refunds or partial credits. Clients are charged based on the number of mailboxes within their email system and paid 30 days
            in advance.
          </p>
        </div>
      </section>
      {proposal.couponData && proposal.couponComments && (
        <section className="py-4">
          <div className="container">
            <h2>Notes about Discounted Price</h2>
            <p>{proposal.couponComments}</p>
          </div>
        </section>
      )}

      <br />
      <br />
      <section className="py-2">
        <div className="container">
          <div className="row justify-content-center text-center">
            <a href="/termsofservice">Terms of Service</a> &nbsp;and&nbsp; <a href="/privacy">Privacy Policy</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default tpl
