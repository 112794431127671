/** @format */

import React, { useState } from 'react'
import _ from 'lodash'
import {Form, Field, FormSection, Address, CreditCard, SignatureField, AddressField} from 'lib/form'
import { TextField, Birthdate } from 'lib/form/fields'
import { required } from 'lib/form/validators'
import { proposalSystemOptions } from 'data/options'
import { updateProposal } from 'api/proposals'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import { createACHToken, createCCToken } from 'api/actions'

import withStyles from '@material-ui/core/styles/withStyles'

class AcceptanceForm extends React.Component {
  render() {
    const { classes, match, proposal, setForm, step, onTemplateSubmitSuccess } = this.props
    return (
      <Form
        title="Update Request"
        form="RequestUpdate"
        initialValues={proposal}
        onChange={form => setForm(_.pick(form, ['signature', 'signingPerson', 'ach', 'cc']))}
        onSubmitSuccess={(result, dispatch, { enqueueSnackbar }) => onTemplateSubmitSuccess(result, dispatch, enqueueSnackbar)}
        btnSubmitText="Save"
        success="Request updated successfully"
      >
        {({ doc, change, dispatch, submit, invalid }) => {
          this.dispatch = dispatch
          this.submit = submit
          this.invalid = invalid
          return (
            <React.Fragment>
              <section className="fdb-block py-4">
                <div className="container">
                  {step.hasPayment && (
                    <div>
                      {!doc.ach && (
                        <div>
                          <label>
                            <h3>Credit Card</h3>
                            <div style={{ padding: 10 }}>
                              <img alt="Trust Seals" src="https://s3.amazonaws.com/chatfortress/img/trust-seals.png" width="352" />
                            </div>
                          </label>

                          <Paper style={{ textAlign: 'center', padding: 10 }}>
                            {!doc.signedOffline && <Field name="cc" component={CreditCard} label="Credit Card" />}
                            <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              onClick={() =>
                                createCCToken(doc)
                                  .then(cc => change('cc', cc))
                                  .catch(e => console.log(e))
                              }
                            >
                              Add Credit Card Method
                            </Button>
                            {doc.cc && doc.cc.token && <div>Credit Card payment method added</div>}
                          </Paper>
                        </div>
                      )}
                      <br />
                      {!doc.cc && (
                        <div>
                          <label>
                            <h3>ACH</h3>
                          </label>
                          <Paper style={{ textAlign: 'center', padding: 10 }}>
                            <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              onClick={() =>
                                createACHToken()
                                  .then(ach => change('ach', ach))
                                  .catch(e => console.log(e))
                              }
                            >
                              Add ACH Payment Method
                            </Button>
                            {doc.ach && <div>ACH payment method added</div>}
                          </Paper>
                        </div>
                      )}
                    </div>
                  )}
                  <br />
                  <br />
                  <h2>Sign and Accept Proposal</h2>
                  {proposal.signedOffline && <Paper style={{ padding: 10 }}>Signed Offline</Paper>}
                  {!proposal.signedOffline && (
                    <Paper style={{ padding: 10 }}>
                      <p className="lead">
                        Your signature is confirmation of your acceptance of this proposal and the terms associated with this proposal.
                        Please click to sign proposal for acceptance using your mouse or finger to sign if using a touch display. You
                        confirm you hold a position within the company that allows you to bind the company to this agreement.{' '}
                      </p>
                      <Field name="signature" label="" component={SignatureField} />
                      <div>
                        <FormSection name="signingPerson">
                          <Field name="name" component={TextField} label="Signing Person Name" validate={[required()]} />
                          <Field name="position" component={TextField} label="Signing Person Position" />
                          <Field name="company" component={TextField} label="Signing Person Company" />
                          <FormSection name="address">
                            <Field
                              name="address"
                              nested
                              component={AddressField}
                              address={doc.signingPerson && doc.signingPerson.address}
                              label="Signing Person Address"
                            />
                          </FormSection>
                        </FormSection>
                        <br />
                        Date: {new Date().toLocaleString()}
                        <br />
                        IP Address: {proposal.ip}
                      </div>
                      <p className="lead">
                        Please wait 60 seconds after clicking the Accept button, while we create your account and advance to the next step.
                        If there are any issues please <a href="tel:3079997755"> Call or Text (307)-999-7755 </a>or email{' '}
                        <a href="mailto:help@ChatFortress.com">help@ChatFortress.com</a>
                      </p>
                    </Paper>
                  )}
                </div>
              </section>
            </React.Fragment>
          )
        }}
      </Form>
    )
  }
}

const styles = () => ({
  root: {}
})

export default withStyles(styles)(AcceptanceForm)
