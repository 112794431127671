/** @format */

import React, { useState, lazy } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import _ from 'lodash'
import { getProposalData, acceptProposal, acceptProposalTemplate } from 'api/proposals'
import { withStyles } from '@material-ui/core/styles'
import { withMutation } from 'react-apollo'
import compose from 'recompose/compose'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import { withSnackbar } from 'notistack'
import { Query } from 'react-apollo'

import ElevationScroll from 'components/ElevationScroll'
import EmailGuardianStatic from './EmailGuardianStatic'
import TermsStatic from './TermsStatic'
import CostsStatic from './CostsStatic'
import AcceptanceForm from './AcceptanceForm'
import TrainingStatic from './TrainingStatic'
import Footer from 'components/Footer'
import '../../styles/gatsby.css'
import templates from 'components/Proposals/templates.json'
import Form from 'components/Proposal/Form'
import Loadable from 'react-loadable'
import qs from 'qs'

import { createCCToken } from 'api/actions'

import { onLogin } from 'api/auth'

class ProposalLayout extends React.Component {
  state = {
    activeStep: 0,
    submitting: false,
    printable: false
  }
  constructor(props) {
    super(props)
    this.form = React.createRef()
  }

  render() {
    const { classes, match, enqueueSnackbar, acceptProposal, acceptProposalTemplate, location } = this.props
    const { activeStep, submitting } = this.state
    const steps = ['Goal of Service', 'Costs', 'Terms of Service', 'Training and Awareness Disclaimer ', 'Acceptance + Payment']

    const onTemplateSubmit = async () => {
      let { form } = this.state
      console.log(form, this.form)
      if (this.form.current) await this.form.current.submit('RequestUpdate')
      if (this.form.submit) await this.form.submit('RequestUpdate')
    }

    const onTemplateSubmitSuccess = async (result, dispatch, enqueueSnackbar) => {
      this.setState({ submitting: true })
      let { form } = this.state
      if (!form || (!form.signature && !form.signedOffline))
        return enqueueSnackbar('You need to sign the proposal in order to proceed', { variant: 'error' })
      if (!form) return false
      if (!form.ach && !form.cc && result.data.submitForm.number && result.data.submitForm.cvc) {
        let cc = await createCCToken(result.data.submitForm).then(cc => cc)
        if (!cc.token && form.template!=='EGS') return false
        form.cc = cc
      }
      let data = _.pick(form, ['signature', 'signingPerson'])
      if ((form.cc && form.cc.token) || (form.ach && form.ach.token)) {
        data.stripeToken = (form.cc && form.cc.token) || null
        data.plaidToken = (form.ach && form.ach.token) || null
        data.plaidAccount = (form.ach && form.ach.account_id) || null
      }
      acceptProposalTemplate({ variables: { proposalId: match.params.proposalId, data: _.omit(data, ['user']) } })
        .then(({ data }) => {
          console.log(data)
          enqueueSnackbar('SUCCESS', { variant: 'success' })
          this.setState({ submitting: false })
          setTimeout(() => onLogin(data.acceptProposalTemplate.token, '/dashboard'), 3000)
        })
        .catch(e => {
          this.setState({ submitting: false })
          enqueueSnackbar(e.message, { variant: 'error' })
        })
    }

    const onSubmit = async () => {
      this.setState({ submitting: true })
      let { form } = this.state
      if (!form.signature && !form.signedOffline)
        return enqueueSnackbar('You need to sign the proposal in order to proceed', { variant: 'error' })
      if (!form.ach && form.number && form.cvc) {
        let cc = await createCCToken(form).then(cc => cc)
        form.cc = cc
      }
      if ((form.cc && form.cc.token) || (form.ach && form.ach.token)) {
        let data = _.pick(form, ['signature', 'lastName', 'firstName', 'address'])
        data.stripeToken = (form.cc && form.cc.token) || null
        data.plaidToken = (form.ach && form.ach.token) || null
        data.plaidAccount = (form.ach && form.ach.account_id) || null

        acceptProposal({ variables: { proposalId: match.params.proposalId, data: _.omit(data, ['user']) } })
          .then(({ data }) => {
            console.log(data)
            enqueueSnackbar('SUCCESS', { variant: 'success' })
            this.setState({ submitting: false })
            setTimeout(() => onLogin(data.acceptProposal.token, '/dashboard'), 500)
            //setTimeout(() => (document.location.href = 'https://chatfortress.com/guardianpocsuccess'), 3000)
          })
          .catch(e => {
            this.setState({ submitting: false })
            enqueueSnackbar(e.message, { variant: 'error' })
          })
      } else {
        this.setState({ submitting: false })
        enqueueSnackbar('You need to attach at least 1 payment method', { variant: 'error' })
      }
    }

    const setActiveStep = activeStep => {
      this.setState({ activeStep })
      window.scrollTo(0, 0)
    }

    const drawTemplate = (template, proposal) => {
      const step = template.steps[activeStep]
      console.log(this.state.printable)
      const Component = Loadable({
        loader: () => import(`components/Proposal/templates/${step.src}`),
        loading: () => <div>Loading...</div>
      })
      let children = []
      if (this.state.printable) {
        children = template.steps.map(step =>
          Loadable({
            loader: () => import(`components/Proposal/templates/${step.src}`),
            loading: () => <div>Loading...</div>
          })
        )
      }

      return (
        <div>
          {!this.state.printable && <Component proposal={proposal} template={template}/>}
          {this.state.printable && (
            <div>
              {children.map(Comp => (
                <Comp proposal={proposal} />
              ))}
              <section className="fdb-block py-5">
                <div className="container">
                  <div className="row justify-content-center pt-0">
                    <div className="col-12 col-md-10" style={{ fontSize: '150%' }}>
                      <h2>Signed By:</h2>
                      <hr />
                      <p>
                        <strong>First Name:</strong> {proposal.firstName}
                      </p>
                      <p>
                        <strong>Last Name:</strong> {proposal.lastName}
                      </p>
                      {proposal.signingPerson && (
                        <p>
                          <strong>Position:</strong> {proposal.signingPerson.position}
                        </p>
                      )}
                      {!proposal.signingPerson && proposal.user.signingPerson && (
                        <p>
                          <strong>Position:</strong> {proposal.user.signingPerson.position}
                        </p>
                      )}
                      <p>
                        <strong>Signature:</strong> <img src={proposal.user && proposal.user.signature} alt={''} />
                      </p>
                      <hr />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
          {step.hasForm && (
            <Form
              ref={this.form}
              proposal={proposal}
              step={step}
              onTemplateSubmitSuccess={onTemplateSubmitSuccess}
              setForm={(form, obj) => {
                console.log(obj, form)
                this.setState({ form })
                // this.form = obj
              }}
            />
          )}
          <div style={{ textAlign: 'center' }} className={'footer-buttons'}>
            <hr />
            {activeStep !== 0 && (
              <Button
                size="large"
                style={{ marginRight: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Back
              </Button>
            )}
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={submitting}
              onClick={activeStep < template.steps.length - 1 ? () => setActiveStep(activeStep + 1) : onTemplateSubmit}
            >
              {submitting ? 'Loading...' : step.nextButtonLabel || 'Next'}
            </Button>
          </div>
        </div>
      )
    }

    return (
      <Query query={getProposalData} variables={{ proposalId: match.params.proposalId }}>
        {({ data, loading }) => {
          const proposal = data ? data.proposal : {}
          const template = proposal.type === 'template' ? _.find(templates, { id: proposal.template }) : null

          const query = qs.parse(location.search.slice(1))
          if (!loading && query.print && query.print === proposal.userToken) {
            if (!this.state.printable) this.setState({ printable: true })
          }
          if (!loading && proposal.canceled)
            return (
              <Container style={{ paddingTop: 120, paddingBottom: 120 }} className={'text-center'} maxWidth="xl">
                <h3>This agreement is no longer valid please contact our team to get a new agreement</h3>
              </Container>
            )
          if (!loading && proposal.userId && proposal.subscriptionId)
            return (
              <Container style={{ paddingTop: 120, paddingBottom: 120 }} className={'text-center'} maxWidth="xl">
                <h3>This agreement is already accepted</h3>
              </Container>
            )
          return (
            !loading && (
              <div className={classes.root + ' ' + (this.state.printable && 'printable')}>
                <ElevationScroll>
                  <AppBar style={{ backgroundColor: 'white', width: '100%' }}>
                    <Toolbar style={{ display: 'block' }}>
                      <div style={{ color: 'black', textAlign: 'center' }}>
                        <Hidden smDown>
                          <img
                            style={{ float: 'left' }}
                            height={60}
                            src="https://d33wubrfki0l68.cloudfront.net/3b2ac12bd90f391d57ce2a39c6071fb7c977e41d/dc5f2/logo.png"
                          />
                          <h2 style={{ display: 'inline-block', marginLeft: -260 }}>{proposal.name || 'Email Guardian Proposal'}</h2>
                        </Hidden>
                        <Hidden mdUp>
                          <img
                            style={{ float: 'left', marginTop: -16, marginBottom: 16 }}
                            height={60}
                            src="https://s3.amazonaws.com/chatfortress/img/bulletpoint.png"
                          />
                          <h3 style={{ marginTop: 16 }}>{proposal.name || 'Email Guardian Proposal'}</h3>
                        </Hidden>
                      </div>
                      <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        style={{ padding: '0px 0px 8px 0px', backgroundColor: 'transparent', width: '100%' }}
                      >
                        {proposal.type !== 'template' &&
                          _.filter(steps, (v, it) => it !== 2 && it !== 3).map((label, k) => (
                            <Step key={label} onClick={() => null}>
                              <StepLabel>
                                <Hidden smDown>{label}</Hidden>
                              </StepLabel>
                            </Step>
                          ))}

                        {proposal.type === 'template' &&
                          template.steps.map(step => (
                            <Step key={step.label} onClick={() => null}>
                              <StepLabel>
                                <Hidden smDown>{step.label}</Hidden>
                              </StepLabel>
                            </Step>
                          ))}
                      </Stepper>
                    </Toolbar>
                  </AppBar>
                </ElevationScroll>
                <Container style={{ paddingTop: 120, paddingBottom: 120 }} className={'cont1'} maxWidth="xl">
                  {proposal.type === 'template' && <Box my={2}>{drawTemplate(template, proposal)}</Box>}
                  {proposal.type !== 'template' && (
                    <Box my={2}>
                      {(this.state.printable || activeStep === 0) && (
                        <div>
                          <EmailGuardianStatic proposal={proposal} />
                          <hr />
                          <div style={{ textAlign: 'center' }}>
                            <Button size="large" variant="contained" color="primary" onClick={() => setActiveStep(1)}>
                              Next
                            </Button>
                          </div>
                        </div>
                      )}
                      {(this.state.printable || activeStep === 1) && (
                        <div>
                          <CostsStatic proposal={proposal} />
                          <hr />
                          <div style={{ textAlign: 'center' }}>
                            <Button
                              size="large"
                              style={{ marginRight: 20 }}
                              variant="contained"
                              color="secondary"
                              onClick={() => setActiveStep(0)}
                            >
                              Back
                            </Button>
                            <Button size="large" variant="contained" color="primary" onClick={() => setActiveStep(4)}>
                              Next
                            </Button>
                          </div>
                        </div>
                      )}
                      {(this.state.printable || activeStep === 2) && (
                        <div>
                          <TermsStatic proposal={proposal} />
                          <hr />
                          <div style={{ textAlign: 'center' }}>
                            <Button
                              size="large"
                              style={{ marginRight: 20 }}
                              variant="contained"
                              color="secondary"
                              onClick={() => setActiveStep(1)}
                            >
                              Back
                            </Button>
                            <Button size="large" variant="contained" color="primary" onClick={() => setActiveStep(3)}>
                              Accept Terms
                            </Button>
                          </div>
                        </div>
                      )}
                      {(this.state.printable || activeStep === 3) && (
                        <div>
                          <TrainingStatic proposal={proposal} />
                          <hr />
                          <div style={{ textAlign: 'center' }}>
                            <Button
                              size="large"
                              style={{ marginRight: 20 }}
                              variant="contained"
                              color="secondary"
                              onClick={() => setActiveStep(2)}
                            >
                              Back
                            </Button>
                            <Button size="large" variant="contained" color="primary" onClick={() => setActiveStep(4)}>
                              Next
                            </Button>
                          </div>
                        </div>
                      )}
                      {(this.state.printable || activeStep === 4) && (
                        <div>
                          <AcceptanceForm proposal={proposal} setForm={form => this.setState({ form })} />
                          <hr />
                          <div style={{ textAlign: 'center' }}>
                            <Button
                              size="large"
                              style={{ marginRight: 20 }}
                              variant="contained"
                              color="secondary"
                              onClick={() => setActiveStep(1)}
                            >
                              Back
                            </Button>
                            <Button size="large" type="submit" variant="contained" color="primary" disabled={submitting} onClick={onSubmit}>
                              {submitting ? 'Loading...' : 'Accept & Submit'}
                            </Button>
                          </div>
                        </div>
                      )}
                    </Box>
                  )}
                </Container>
                <Hidden smDown>
                  <Footer wide={true} />
                </Hidden>
              </div>
            )
          )
        }}
      </Query>
    )
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#FCFCFC'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    width: '100%'
  }
})
export default compose(
  withMutation(acceptProposal, {
    name: 'acceptProposal'
  }),
  withMutation(acceptProposalTemplate, {
    name: 'acceptProposalTemplate'
  }),
  withSnackbar,
  withStyles(styles)
)(ProposalLayout)
