/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useQuery, useMutation } from 'react-apollo'
import withConfirm from 'lib/withConfirm'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

import { getSubscriptionsWithProduct, cancelSubscription, removeSubscription } from 'api/subscriptions'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Spinner from 'components/Spinner'

const Subscriptions = ({ classes, history, match, confirm }) => {
  const [cancel, { loading: loadingCancel }] = useMutation(cancelSubscription, { refetchQueries: ['getSubscriptionsWithProduct'] })
  const [remove, { loading: loadingRemove }] = useMutation(removeSubscription, { refetchQueries: ['getSubscriptionsWithProduct'] })
  const { data, loading } = useQuery(getSubscriptionsWithProduct, { variables: match.params, fetchPolicy: 'cache-and-network' })
  const subscriptions = _.get(data, 'subscriptions.data', [])

  return loading ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <div className={classes.cards}>
        {subscriptions.map(subscription => (
          <Card key={subscription.id} className={classes.card}>
            <CardHeader title={subscription.product.name} subheader={subscription.product.description} />
            <CardContent>
              <Typography gutterBottom variant="h6">
                Current Plan: {subscription.plan.name}
              </Typography>
              <div>
                Terms:{' '}
                <strong>
                  {numeral(subscription.plan.amount || 0).format('$0,0.00')} per {subscription.plan.interval} {subscription.plan.freq}
                </strong>
              </div>
              <div>
                Licenses: <strong>{subscription.quantity}</strong>
              </div>
              <div>
                Total:{' '}
                <strong>
                  {numeral(subscription.plan.amount * subscription.quantity || 0).format('$0,0.00')} per {subscription.plan.interval}{' '}
                  {subscription.plan.freq}{' '}
                  <em>
                    ({subscription.quantity} licenses x {numeral(subscription.plan.amount || 0).format('$0,0.00')})
                  </em>
                </strong>
              </div>
              <div>
                <div>
                  Status: <strong>{subscription.status}</strong>
                </div>
                {subscription.canceled_at ? (
                  <div>
                    <strong>Cancel on {moment(subscription.canceled_at).format('LLL')}</strong>
                  </div>
                ) : subscription.status === 'trialing' ? (
                  <div>
                    Trial period is:{' '}
                    <strong>
                      {moment(subscription.trial_start).format('ll')} - {moment(subscription.trial_end).format('ll')}
                    </strong>
                  </div>
                ) : (
                  <div>
                    Current Period:{' '}
                    <strong>
                      {moment(subscription.current_period_start).format('ll')} - {moment(subscription.current_period_end).format('ll')}
                    </strong>
                  </div>
                )}
              </div>
            </CardContent>
            <CardActions>
              {subscription.status !== 'canceled' ? (
                <React.Fragment>
                  <IconButton onClick={() => history.push(`${match.url}/${subscription.id}/update`)} title="Update Subscription">
                    <Icon fontSize="small">edit</Icon>
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={confirm(() => cancel({ variables: { id: subscription.id } }), {
                      title: 'Subscription Cancel',
                      description: 'Are you sure you want to cancel this Subscription?'
                    })}
                    title="Cancel Subscription"
                    disabled={loadingCancel}
                  >
                    <Icon fontSize="small">cancel</Icon>
                  </IconButton>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <IconButton
                    color="secondary"
                    onClick={confirm(() => remove({ variables: { id: subscription.id } }), {
                      title: 'Subscription Remove',
                      description: 'Are you sure you want to remove this Subscription?'
                    })}
                    title="Remove Subscription"
                    disabled={loadingRemove}
                  >
                    <Icon fontSize="small">cancel</Icon>
                  </IconButton>
                </React.Fragment>
              )}
            </CardActions>
          </Card>
        ))}
      </div>
    </React.Fragment>
  )
}

const styles = theme => ({
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  card: {
    maxWidth: 300,
    minWidth: 300,
    marginRight: 10,
    marginBottom: 10
  }
})

export default compose(withConfirm, withStyles(styles))(Subscriptions)
