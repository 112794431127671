/** @format */

import _ from 'lodash'
import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import {FormDialog, Field, FieldArray, FormGroup} from 'lib/form'
import { TextField, Checkbox } from 'lib/form/fields'
import { required, url, numericality } from 'lib/form/validators'

import { getExperiment, updateExperiment } from 'api/experiments'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"
import Grid from "@material-ui/core/Grid"
import {number} from "../../lib/form/normalizers";



export const VariationsField = ({ fields, meta, label }) => (
  <div className='p-2'>
    {fields.map((member, index) => (
      <Grid container key={index} spacing={2} className={' border-bottom'}>
        <Grid item xs={10}>
          <Field name={`${member}.name`} component={TextField} label="Variant Name (used in components)" validate={[required()]} />
          <Field name={`${member}.description`} component={TextField}  label="Description" rows={2} multiline margin="none" />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="secondary" className='mt-3' onClick={() => fields.remove(index)}>
            <Icon>delete</Icon>
          </Button>
        </Grid>
      </Grid>
    ))}
    <div className='mt-3'>
      <Button variant="contained" color="primary" onClick={() => fields.push({})}>
        <Icon className="mr-1">add_circle</Icon> Add Variant
      </Button>
    </div>
  </div>
)


const ExperimentUpdate = ({ classes, match }) => (
  <React.Fragment>
    <Query query={getExperiment} variables={match.params}>
      {({data: {experiment} = {}, loading}) => {
        if(!loading)
          _.forOwn({weight: 40, goalType: 'Conversion'}, function (value, key) {
            if (experiment[key] === undefined || experiment[key] === null)
              experiment[key] = value;
          })
        else return null
        return !loading && (
          <FormDialog
            title={`Experiment`}
            form="ExperimentUpdate"
            mutation={[updateExperiment]}
            initialValues={_.omit(experiment, ['id'])}
            success="Experiment updated successfully"
          >
            {({doc, invalid, submitting, ...rest}) =>
              (
                <React.Fragment>
                  <fieldset>
                    <legend>Labels</legend>
                    <Field name="name" component={TextField} label="Experiment Name" validate={[required()]}/>
                    <Field name="hypothesis" component={TextField} multiline rows={2} label="Experiment Hypothesis"/>
                    <Field name="description" component={TextField} multiline rows={4} label="Experiment Description"/>
                  </fieldset>
                  <fieldset>
                    <legend>Settings</legend>
                    <Field
                      name="enabled"
                      component={Checkbox}
                      label="Enabled (running)"
                    />
                    <Field name="url" component={TextField} label="Page Url" validate={[required(), url()]}/>
                    <Field name="weight" normalize={number} component={TextField} label="Weight (Measured views)" type={'number'}
                           validate={[required()]}/>
                    <Field name="goalType" component={TextField} label="Goal"
                           options={['Conversion', 'Page View', 'Bounce Rate', 'Scroll Rate', 'Time on Site'].map(t => ({
                             label: t,
                             value: t
                           }))} select validate={[required()]}/>
                  </fieldset>
                  <fieldset>
                    <legend>Variations</legend>
                    <FieldArray name="variations" component={VariationsField} label="Variations" />
                  </fieldset>
                </React.Fragment>
              )
            }
          </FormDialog>
        )}
      }
    </Query>
  </React.Fragment>
)

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(ExperimentUpdate)
