/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Lessons from 'components/Lessons/Lessons'
import LessonCreate from 'components/Lessons/LessonCreate'
import LessonUpdate from 'components/Lessons/LessonUpdate'
import LessonQuiz from 'components/Lessons/LessonQuiz'

export default ({ match }) => (
  <React.Fragment>
    <Switch>
      <Route path={`${match.path}/create`} component={LessonCreate} />
      <Route path={`${match.path}/:lessonId/update`} component={LessonUpdate} />
      <Route path={`${match.path}/:lessonId/quiz`} component={LessonQuiz} />
      <Route path={`${match.path}`} component={Lessons} />
    </Switch>
  </React.Fragment>
)
