/** @format */

import React from 'react'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, length } from 'lib/form/validators'

import { createProduct } from 'api/products'

import withStyles from '@material-ui/core/styles/withStyles'

import { userAccountTypeOptions } from 'data/options'

const ProductCreate = ({ classes, match }) => (
  <FormDialog
    title="Create Product"
    form="ProductCreate"
    mutation={[createProduct, { refetchQueries: ['getProducts'] }]}
    initialValues={{}}
    btnSubmitText="Create"
    success="Product created successfully"
  >
    {({ doc, invalid, submitting }) => (
      <React.Fragment>
        <Field name="name" component={TextField} label="Product Name" validate={[required(), length({ min: 2 })]} />
        <Field name="roles" component={TextField} label="All Supported Roles" options={userAccountTypeOptions} select multiple />
        <Field name="description" component={TextField} label="Product Description" multiline />
      </React.Fragment>
    )}
  </FormDialog>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(ProductCreate)
