import gql from 'graphql-tag'

export const campaignFragment = gql`
  fragment campaign on Campaign {
    id
    name
    status
    link
    replyPhone
    lists
    listId
    triggerBy
    triggerDate
    triggerTime
    triggerTimezone
    message1
    message2
    message3
    sendingMessage
    owner
    updatedAt
    createdAt
  }
`

export const getCampaigns = gql`
  query getCampaigns($text: String, $userId: ID, $status: String, $skip: Int, $limit: Int, $sort: [String]) {
    campaigns(text: $text, userId: $userId, status: $status, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        message1
        status
        createdAt
      }
      count
      pages
    }
  }
`
export const getCampaignStats = gql`
  query getCampaignStats($campaignId: ID!) {
    campaignStats(id: $campaignId)
  }
`

export const getCampaign = gql`
  query getCampaign($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      ...campaign
    }
  }
  ${campaignFragment}
`

export const createCampaign = gql`
  mutation createCampaign($data: CampaignInput!) {
    createCampaign(data: $data) {
      id
      ...campaign
    }
  }
  ${campaignFragment}
`

export const testCampaign = gql`
  mutation testCampaign($campaignId: ID!, $data: Object!) {
    testCampaign(id: $campaignId, data: $data) {
      id
    }
  }
`

export const updateCampaign = gql`
  mutation updateCampaign($campaignId: ID!, $data: CampaignInput!) {
    updateCampaign(id: $campaignId, data: $data) {
      id
      ...campaign
    }
  }
  ${campaignFragment}
`

export const activateCampaign = gql`
  mutation activateCampaign($campaignId: ID!) {
    activateCampaign(id: $campaignId) {
      id
      ...campaign
    }
  }
  ${campaignFragment}
`

export const pauseCampaign = gql`
  mutation pauseCampaign($campaignId: ID!, $data: CampaignInput!) {
    pauseCampaign(id: $campaignId, data: $data) {
      id
      ...campaign
    }
  }
  ${campaignFragment}
`

export const stopCampaign = gql`
  mutation stopCampaign($campaignId: ID!) {
    stopCampaign(id: $campaignId) {
      id
      ...campaign
    }
  }
  ${campaignFragment}
`

export const removeCampaign = gql`
  mutation removeCampaign($id: ID!) {
    removeCampaign(id: $id) {
      id
    }
  }
`
export const resendMessage = gql`
  mutation resendMessage($id: ID!) {
    resendMessage(id: $id) {
      id
    }
  }
`
