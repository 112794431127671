/** @format */

import React from 'react'
import { Form, Field, FormSection, Address } from 'lib/form'
import { TextField, RadioGroup, Birthdate, PhoneNumber } from 'lib/form/fields'
import { required } from 'lib/form/validators'

import { createRequest, onCreateRequest } from 'api/requests'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const RequestCreate = ({ classes, match }) => (
  <Paper className="p-3">
    <Form
      title="Create Request"
      form="RequestCreate"
      mutation={[createRequest, { update: onCreateRequest }]}
      initialValues={{ type: 'confirm', profile: {} }}
      btnSubmitText="Create"
      success="Request created successfully"
    >
      {({ doc, submit, submitting }) => (
        <React.Fragment>
          <Typography color="primary" variant="h5">
            Request Details
          </Typography>
          <Field
            name="type"
            component={RadioGroup}
            label="Request Type"
            options={[
              {
                label: (
                  <span>
                    Confirm <i>(Verify the accuracy of someone else’s wiring information)</i>
                  </span>
                ),
                value: 'confirm'
              },
              {
                label: (
                  <span>
                    Send <i>(Share your campany’s wiring information)</i>
                  </span>
                ),
                value: 'send'
              }
            ]}
          />
          <Field name="name" component={TextField} label="Request Name" validate={[required()]} />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Field name="profile.firstName" component={TextField} label="First Name" validate={[required()]} />
            {/*<Field name="profile.middleName" component={TextField} label="Middle Name" />*/}
            <Field className="ml-3" name="profile.lastName" component={TextField} label="Last Name" validate={[required()]} />
          </Box>
          <Field
            name="profile.phone"
            component={PhoneNumber}
            label="Cell Phone"
            validate={[required()]}
            helperText="Mobile phone reccomended"
          />
          <Field name="profile.dob" component={Birthdate} label="Birthdate" />
          <FormSection name="profile.physicalAddress">
            <Address title="Physical Address" address={doc.profile.physicalAddress} />
          </FormSection>
          <FormSection name="profile.mailAddress">
            <Address title="Mail Address" address={doc.profile.mailAddress} />
          </FormSection>
          <Field
            name="referenceNumber"
            component={TextField}
            label="Reference Number"
            helperText="Optional. Include your unique identifier such as a file or loan number"
          />
          <Field name="description" component={TextField} label="Description" helperText="Optional" multiline />
          <Field name="profile.companyName" component={TextField} label="Company Name" />
          <Field name="profile.companyWebsite" component={TextField} label="Company Website" />
          <Button variant="contained" color="primary" onClick={submit} disabled={submitting}>
            Create Request
          </Button>
        </React.Fragment>
      )}
    </Form>
  </Paper>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(RequestCreate)
