/** @format */

import gql from 'graphql-tag'

export const linkFragment = gql`
  fragment link on Link {
    title
    url
    tags
  }
`
export const getLinks = gql`
  query getLinks($title: String, $url: String, $skip: Int, $limit: Int, $sort: [String]) {
    links(title: $title, url: $url, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        title
        url
        shortUrl
        countClicks
        tags
      }
      count
      pages
    }
  }
`

export const getLink = gql`
  query getLink($linkId: String!) {
    link(id: $linkId) {
      id
      ...link
    }
  }
  ${linkFragment}
`

export const createLink = gql`
  mutation createLink($data: LinkInput!) {
    createLink(data: $data) {
      id
      ...link
    }
  }
  ${linkFragment}
`

export const updateLink = gql`
  mutation updateLink($linkId: String!, $data: LinkInput!) {
    updateLink(id: $linkId, data: $data) {
      id
      ...link
    }
  }
  ${linkFragment}
`

export const removeLink = gql`
  mutation removeLink($id: String!) {
    removeLink(id: $id) {
      id
    }
  }
`

export const onCreateLink = (cache, { data: { createLink: link } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { links } = cache.readQuery({ query: getLinks, variables })
  cache.writeQuery({
    query: getLinks,
    variables,
    data: {
      links: {
        ...links,
        data: [link].concat(links.data)
      }
    }
  })
}

export const onRemoveLink = (cache, { data: { removeLink: link } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { links } = cache.readQuery({ query: getLinks, variables })
  cache.writeQuery({
    query: getLinks,
    variables,
    data: {
      links: {
        ...links,
        data: links.data.filter(r => r.id !== link.id)
      }
    }
  })
}
