/** @format */

import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import cardAvatarStyle from './styles/cardAvatarStyle'

function CardAvatar({ ...props }) {
  const { classes, children, className, plain, profile, testimonial, testimonialFooter, ...rest } = props
  const cardAvatarClasses = classNames({
    [classes.cardAvatar]: true,
    [classes.cardAvatarProfile]: profile,
    [classes.cardAvatarPlain]: plain,
    [classes.cardAvatarTestimonial]: testimonial,
    [classes.cardAvatarTestimonialFooter]: testimonialFooter,
    [className]: className !== undefined
  })
  return (
    <div className={cardAvatarClasses} {...rest}>
      {children}
    </div>
  )
}

export default withStyles(cardAvatarStyle)(CardAvatar)
