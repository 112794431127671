/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ActivityLog from './ActivityLog'
import ActivityLogUser from './ActivityLogUser'

const Module = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:userId`} component={ActivityLogUser} />
    <Route path={`${match.path}`} component={ActivityLog} />
  </Switch>
)

export default Module
