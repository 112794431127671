import React from 'react'
import moment from "moment/moment"
import CheckboxTable from 'lib/tables/CheckboxTable'
import UserCell from 'lib/tables/UserCell'

import { getActivityLogs } from 'api/activityLog'
import Avatar from "@material-ui/core/Avatar";

export default ({ match, location, history }) => (
  <React.Fragment>
    <div className="p-4">

        <h3>Activity log</h3>
        <CheckboxTable
          query={getActivityLogs}
          initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
          variables={{ }}
          columns={[
            {
              Header: 'User',
              accessor: 'user',
              id: 'name',
              width: 300,
              Cell: ({ original: item }) => (
                <div>
                  <Avatar alt={item.userLabel} src={item.userAvatar} />
                  <span style={{ margin: '0 7px' }}>{item.userLabel}</span>
                </div>
              )
            },
            {
              sortable: true,
              filterable: false,
              Header: 'Date & Time',
              accessor: 'eventDate',
              width: 300,
              Cell: ({ original: item }) => (
                <span>
                  {moment(item.eventDate).format('LLL')}
                </span>
              )
            },
            {
              sortable: true,
              filterable: false,
              Header: 'Object',
              accessor: 'objectType',
              Cell: ({ original: item }) => (
                <div>
                  {item.objectType}: {item.objectLabel}
                </div>
              )
            },
            {
              sortable: true,
              filterable: false,
              Header: 'Action',
              accessor: 'actionType'
            }
          ]}
          deleteMany={false}
        />

    </div>
  </React.Fragment>
)
