/** @format */
import React from 'react'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { Form, Field } from 'lib/form'
import { TextField, PhoneNumber, RadioGroup } from 'lib/form/fields'
import { required, email, password } from 'lib/form/validators'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const signup = gql`
  mutation signup($data: SignUpDataInput!) {
    signup(data: $data) {
      userId
      token
    }
  }
`

const SignUp = ({ classes, location, match, history }) => (
  <Paper className={classes.root}>
    <Form
      form="signup"
      mutation={[signup]}
      initialValues={{ method: 'sms' }}
      onSubmitSuccess={({
        data: {
          login: { token }
        }
      }) => {
        localStorage.setItem('loginToken', token)
        history.push('/dashboard')
      }}
    >
      {({ doc, submitting, submit }) => (
        <React.Fragment>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box p={1}>
              <Typography variant="h5">Sign Up</Typography>
            </Box>
            <Box p={1}>
              <Link to="/auth/login">Login</Link>
            </Box>
          </Box>
          <Field name="email" component={TextField} label="Email Address" validate={[required(), email()]} />
          <Field name="password" type="password" component={TextField} label="Password" validate={[required(), password()]} />
          <Field name="name" component={TextField} label="Name" validate={[required()]} />
          <Field name="phone" component={PhoneNumber} label="Phone" />
          <Field
            name="method"
            component={RadioGroup}
            label="Select a method for your second step of authentication"
            options={[
              { label: 'SMS (text message)', value: 'sms' },
              { label: 'Voice Call', value: 'call' },
              { label: 'Google authenticator (preferred)', value: 'google' }
            ]}
          />
          <ButtonGroup variant="contained" color="primary" onClick={submit} disabled={submitting} fullWidth>
            <Button>Sign Up</Button>
          </ButtonGroup>
        </React.Fragment>
      )}
    </Form>
  </Paper>
)

const styles = () => ({
  root: {
    padding: 10
  }
})

export default withStyles(styles)(SignUp)
