/** @format */

import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import Loader from 'react-loaders'
import { getTemplate } from 'api/templates'
import Paper from "@material-ui/core/Paper"
import withStyles from '@material-ui/core/styles/withStyles'
import Button from "@material-ui/core/Button"
import Viewer from 'components/RichEditor/Viewer'



const TemplateUpdate = ({ classes, match, history }) => {
  return (
    <React.Fragment>
      <Query query={getTemplate} variables={match.params}>
        {({data: {template} = {}, loading}) => {
          if(loading)
            return <Loader />
          return (
            <div>
              <Button className={'ml-5 mt-5'} color="secondary" variant="contained" onClick={() => history.push('/templates')}>
                Back
              </Button>
              <Paper className="p-2 mt-2" style={{ margin:'auto', maxWidth: 595*2}}>
                <Paper style={{all: 'unset', maxWidth: 595*2}}>
                  <Viewer html={template.body} data={{CompanyName: 'Caster Concepts'}}/>
                </Paper>
              </Paper>
            </div>
          )}
        }
      </Query>
    </React.Fragment>
  )
}

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(TemplateUpdate)
