/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormCard, Field } from 'lib/form'
import { TextField, Checkbox } from 'lib/form/fields'
import { required, email } from 'lib/form/validators'
import HelpIcon from '@material-ui/icons/Help'

import { getUser, updateUser } from 'api/users'

import withContext from 'lib/withAppContext'

const UserNotifications = ({ context }) => (
  <Query query={getUser} variables={{ userId: context.user.id }}>
    {({ data: { user } = {}, loading }) =>
      !loading && (
        <FormCard
          form="UserNotifications"
          title="Notifications"
          mutation={[updateUser, { variables: { userId: context.user.id } }]}
          initialValues={{ ...user }}
        >
          {({ doc }) => (
            <React.Fragment>
              <div>
                {' '}
                <p>
                  If you have questions use the live chat to ask your question.
                  <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e49777604286364bc9568f6">
                    {' '}
                    Get help about this page here
                  </a>{' '}
                </p>
              </div>
              <Field name="notifyByEmail" component={Checkbox} label="Notify by Email" />
              <Field name="notifyBySMS" component={Checkbox} label="Notify by SMS" />
            </React.Fragment>
          )}
        </FormCard>
      )
    }
  </Query>
)

export default compose(withContext)(UserNotifications)
