/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { FormCard, Field, FormSection, Profile } from 'lib/form'
import { TextField, SelectField } from 'lib/form/fields'
import { required } from 'lib/form/validators'

import { getUser, updateUser, getUsers } from 'api/users'

import withStyles from '@material-ui/core/styles/withStyles'

import { userAccountTypeOptions } from 'data/options'
import {acl} from 'lib/acl'
import Grid from "@material-ui/core/Grid";

const UserProfile = ({ classes, match }) => (
  <Query query={getUser} variables={match.params}>
    {({ data: { user } = {}, loading }) =>
      !loading && (
        <FormCard
          form="UserProfile"
          mutation={[updateUser]}
          initialValues={user}
          success="User updated successfully"
          btnSubmitText="Save User Profile"
        >
          {({ doc, submit, submitting }) => (
            <React.Fragment>
              <Field
                name="accountType"
                component={TextField}
                label="Account Type"
                options={userAccountTypeOptions}
                validate={[required()]}
                select
              />
              <Field
                name="roles"
                component={TextField}
                label="Roles"
                options={Object.keys(acl.roles).map(r=>({label: r, value: r}))}
                validate={[required()]}
                select
                multiple
              />
              {acl.hasAccess("admin") &&  <Field
                name="masterAccountId"
                component={SelectField}
                label="Master Account"
                fld="users"
                variables={{roles:['admin','RC-Vendor','CF-Reseller']}}
                query={getUsers}
              />}
              {acl.hasAccess("admin") && user.accountType==='client' &&
              <Grid xs={3} item>
                <Field name="schedulePeriod" component={TextField} label="Schedule period"
                                       options={[14, 30].map(v => ({label: v, value: v}))} select />
              </Grid>}
              <FormSection name="">
                <Profile doc={doc} />
              </FormSection>
            </React.Fragment>
          )}
        </FormCard>
      )
    }
  </Query>
)

const styles = theme => ({
  root: {}
})

export default withStyles(styles)(UserProfile)

/*
 */
