import React from 'react'
import _ from 'lodash'
import {useMutation, useQuery} from 'react-apollo'
import moment from 'moment'

import {getCampaignStats, resendMessage} from 'api/campaigns'

import Paper from "@material-ui/core/Paper"
import Avatar from "../../lib/Avatar"

import Typography from "@material-ui/core/Typography";
import Papa from "papaparse"
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";


const CampaignUpdate = ({ classes, match }) => {
  const { loading, data: { campaignStats = {} } = {} } = useQuery(getCampaignStats, { variables: match.params })
  const [resend] = useMutation(resendMessage, { refetchQueries: ['getCampaignStats'] })

  if(!campaignStats)
    return <div>NO stats yet...</div>
  const { campaign, messages, links=[], smses=[], calls=[] } = campaignStats
  let messagesTable = []

  if(!loading)
    messagesTable = messages.map(message => ({
      ...message,
      name: `${message.contact.firstName} ${message.contact.lastName||''}`,
      phone: String(message.contact.phone),
      scheduledAt: moment(message.scheduledAt).format('LLLL'),
      sentAt: message.sentAt?moment(message.sentAt).format('LLLL'):"Never",
      clicks: (message.sentAt && message.linkId)?_.find(links,{_id: message.linkId}).countClicks:"",
      calls: message.sentAt?_.filter(calls,{from: message.contact.phone.replace(/[ \(\)\-]+/g,'')}).length:"",
      SMS: message.sentAt?_.filter(smses,{from: message.contact.phone.replace(/[ \(\)\-]+/g,'')}).length:""
       }
      )
    )

  const downloadCSV = ()=>{
    let data = messagesTable.map(m=>_.pick(m, ['name','phone','scheduledAt','sentAt','clicks','calls','SMS']))
    let csv = Papa.unparse(data);
    var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var csvURL =  null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, 'sample.csv');
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'sample.csv');
    tempLink.click();
  }

  return (
    !loading && (
      <Paper style={{ padding: 20 }}>
        <h2>Campaign: {campaign.name}</h2>
        <h4>Scheduled on: {moment(campaign.triggerDate).format("LL")} {campaign.triggerTime}</h4>

        <a href={'#'} onClick={downloadCSV}>Export CSV</a>

        <table className="table table-bordered" style={{ fontSize: '1.3em', fontWeight: 600 }}>
          <thead>
          <tr>
            <th scope="col">Recipient</th>
            <th scope="col">Status</th>
            <th scope="col">Scheduled At</th>
            <th scope="col">Sent At</th>
            <th scope="col">Clicks</th>
            <th scope="col">Calls</th>
            <th scope="col">SMS Replies</th>
            <th scope="col">&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {messagesTable.map(message => {
            return (
              <tr key={message._id}>
                <td>
                  <Avatar style={{float: 'left', marginRight: 10}} name={`${message.contact.firstName} ${message.contact.lastName||''}`}/> {message.contact.firstName} {message.contact.lastName||''}
                <div><small>{message.contact.phone} </small></div>
                </td>
                <td><span style={{textTransform:'capitalize'}}>{message.sms?(message.sms.status):(message.sentAt!=='Never'?"sent":"scheduled")}</span>
                  {message.sms && message.sms.errorMessage?<div>Error: {message.sms.errorMessage}</div>:''}</td>
                <td>{message.scheduledAt}</td>
                <td>{message.sentAt}</td>
                <td>{message.clicks}</td>
                <td>{message.calls}</td>
                <td>{message.SMS}</td>
                <td>{message.sentAt!=='Never' && <Button key="create" variant="contained" color="secondary" onClick={() => resend({ variables: {id: message._id}})}>
                  <Icon>refresh</Icon>
                  Resend
                </Button>}</td>
              </tr>
            )
          })}
          </tbody>
        </table>

          {messages.length === 0 && <Typography align="center">No Messages</Typography>}

      </Paper>
    )
  )
}



export default CampaignUpdate
