/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useQuery } from 'react-apollo'
import { FormDialog, FormSection, FormGroup, Field, Address } from 'lib/form'
import { TextField, PhoneNumber, Checkbox, AvatarUploadField } from 'lib/form/fields'
import { required, email } from 'lib/form/validators'

import { getCompanyDataForUpdating, updateCompany } from 'api/companies'

import withStyles from '@material-ui/core/styles/withStyles'

import withAppContext from 'lib/withAppContext'

const CompanyUpdate = ({ classes, match, context }) => {
  const { loading, data: { company = {}, categories = [], resellers: { data: resellers = [] } = {} } = {} } = useQuery(
    getCompanyDataForUpdating,
    {
      variables: match.params
    }
  )
  const { acl } = context

  return (
    !loading && (
      <FormDialog
        title={company.name}
        form="CompanyUpdate"
        mutation={[updateCompany, { refetchQueries: ['getCompanies'] }]}
        refetchQueries={['getCompanies']}
        initialValues={company}
        success="Company updated successfully"
      >
        {({ doc, submitting }) => (
          <React.Fragment>
            <FormGroup title="Company Information">
              {categories && categories.length > 0 && (
                <Field
                  name="categories"
                  component={TextField}
                  label="Categories"
                  options={categories.map(({ id, name, parent }) => ({
                    label: name,
                    value: id,
                    optgroup: !parent && categories.filter(c => c.parent === name).length > 0
                  }))}
                  select
                  multiple
                />
              )}
              <Field name="logo" component={AvatarUploadField} label="Company Logo" btnLabel="Upload Logo" />
              <Field name="name" component={TextField} label="Company Name" validate={[required()]} />
              <Field name="website" component={TextField} label="Company Website" />
              <Field name="screenshot" component={TextField} label="Company Screenshot Website" />
              <p className="text-muted">1280x720 size</p>
              <Field name="thumbnail" component={TextField} label="Company Thumbnail Website" />
              <p className="text-muted">320x180 size</p>
              <Field name="email" component={TextField} label="Company Email Address" validate={[email()]} />
              <Field name="phone" component={PhoneNumber} label="Company Cell Phone" />
            </FormGroup>
            <FormGroup title="Report Settings">
              {acl.hasAccess('companies:setOwner') && (
              <Field name="featuredInCategory" component={Checkbox} label="Featured In Category" />
              )}
              <Field name="noFeaturedComparison" component={Checkbox} label="No `Featured` comparison" />
              <Field name="noComparison" component={Checkbox} label="No category comparisons" />
              <Field
                name="noSensitiveComparison"
                component={Checkbox}
                label="Remove all Sex, Politics, and Religion companies from comparison"
              />
              <Field name="hiddenByOwner" component={Checkbox} label="Hidden at request of domain owner" />
            </FormGroup>
            {/*<Field name="formattedAddress" component={TextField} label="Full Address" />*/}
            <FormSection name="address">
              <Address title="Company Address" address={(doc && doc.address) || {}} />
            </FormSection>

            <FormGroup title="Sponsor Box">
              <Field name="sponsorBox" component={Checkbox} label="Activation Sponsor Box" />
              {doc && doc.sponsorBox && (
                <React.Fragment>
                  {acl.hasAccess('companies:setOwner') && (
                    <Field name="owner" component={TextField} label="Sponsor Reseller" options={resellers} select validate={[required()]} />
                  )}
                  {/*<Field name="sponsorName" component={TextField} label="Sponsor Name" margin="none" />
                  <Field name="sponsorEmail" component={TextField} label="Sponsor Email Address" validate={[email()]} />
                  <Field name="sponsorPhone" component={PhoneNumber} label="Sponsor Phone Number" />
                  <Field name="sponsorWebsite" component={TextField} label="Sponsor Website" />
                  <Field name="sponsorLogo" component={TextField} label="Sponsor Logo" />*/}
                </React.Fragment>
              )}
            </FormGroup>
            <FormGroup title="Company Representative">
              <Field name="representativeName" component={TextField} label="Name" margin="none" />
              <Field name="representativeEmail" component={TextField} label="Email Address" validate={[email()]} />
              <Field name="representativePhone" component={PhoneNumber} label="Phone Number" />
            </FormGroup>
          </React.Fragment>
        )}
      </FormDialog>
    )
  )
}

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withAppContext, withStyles(styles))(CompanyUpdate)
