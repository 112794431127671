/** @format */

import React from 'react'
import TagsInput from 'react-tagsinput'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import './tags.css'

const TagsCustom = ({ placeholder, ...props }) => {
  return <TagsInput {...props} inputProps={{ placeholder }} />
}

const TagsField = ({ input, meta: { touched, invalid, error }, label, ...rest }) => (
  <FormControl margin="normal" fullWidth error={touched && invalid}>
    <label>{label}</label>
    <TagsCustom {...rest} {...input} value={input.value || []} />
    {touched && error && (
      <FormHelperText margin="dense" error>
        {error}
      </FormHelperText>
    )}
  </FormControl>
)

export default TagsField
