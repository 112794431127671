import gql from 'graphql-tag'

export const contactFragment = gql`
  fragment contact on Contact {
    id
    name
    firstName
    lastName
    email
    phone
    listId
  }
`

export const getContacts = gql`
  query getContacts($listId: ID, $userId: ID, $skip: Int, $limit: Int, $sort: [String]) {
    contacts(listId: $listId, userId: $userId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        firstName
        lastName
        email
        phone
        listId
      }
      count
      pages
    }
  }
`

export const getContact = gql`
  query getContact($contactId: ID!) {
    contact(id: $contactId) {
      id
      ...contact
    }
  }
  ${contactFragment}
`

export const createContact = gql`
  mutation createContact($data: ContactInput!) {
    createContact(data: $data) {
      id
      ...contact
    }
  }
  ${contactFragment}
`
export const createContacts = gql`
  mutation createContacts($data: [ContactInput]) {
    createContacts(data: $data) {
      id
      ...contact
    }
  }
  ${contactFragment}
`

export const updateContact = gql`
  mutation updateContact($contactId: ID!, $data: ContactInput!) {
    updateContact(id: $contactId, data: $data) {
      id
      ...contact
    }
  }
  ${contactFragment}
`

export const removeContact = gql`
  mutation removeContact($id: ID!) {
    removeContact(id: $id) {
      id
    }
  }
`
