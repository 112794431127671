/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useMutation, Mutation } from 'react-apollo'
import withConfirm from 'lib/withConfirm'
import withAppContext from 'lib/withAppContext'

import { loginAs, onLogin } from 'api/auth'
import { getEmployees, updateEmployee, sendWelcomeEmail as sendWelcomeEmailAction, removeEmployee } from 'api/employees'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import CheckboxTable from 'lib/tables/CheckboxTable'
import UserCell from 'lib/tables/UserCell'
import CompanyCell from 'lib/tables/CompanyCell'

const Employees = ({ classes, history, context: { user = {} } = {}, match, confirm }) => {
  const [update] = useMutation(updateEmployee, { refetchQueries: ['getEmployees'] })
  const [loginAsUser, { loading: logging }] = useMutation(loginAs, {
    onCompleted({ loginAs: { token } }) {
      onLogin(token)
    }
  })
  const [sendWelcomeEmail, { loading: sending }] = useMutation(sendWelcomeEmailAction)

  return (
    <React.Fragment>
      <Mutation mutation={removeEmployee} refetchQueries={['getEmployees']}>
        {(remove, { loading: loadingRemove }) => (
          <CheckboxTable
            query={getEmployees}
            initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
            variables={{ masterAccountId: user.id }}
            columns={[
              {
                Header: 'Employee',
                accessor: 'name',
                Cell: ({ original: employee }) => <UserCell user={employee} />
              },
              {
                Header: 'Company',
                accessor: 'company.name',
                Cell: ({ original: employee }) => <CompanyCell company={employee.company} />
              },
              {
                Header: 'Notifications',
                accessor: 'notifications',
                sortable: false,
                filterable: false,
                Cell: ({ original: employee }) => (
                  <div>
                    <FormControlLabel
                      checked={employee.notifyByEmail}
                      control={<Checkbox />}
                      label="Notify by Email"
                      onChange={event => update({ variables: { employeeId: employee.id, data: { notifyByEmail: event.target.checked } } })}
                    />
                    <FormControlLabel
                      checked={employee.notifyBySMS}
                      control={<Checkbox />}
                      label="Notify by SMS"
                      onChange={event => update({ variables: { employeeId: employee.id, data: { notifyBySMS: event.target.checked } } })}
                    />
                  </div>
                )
              },
              {
                Header: 'Actions',
                sortable: false,
                filterable: false,
                accessor: 'id',
                Cell: ({ original: employee }) => (
                  <div>
                    <IconButton
                      onClick={() => loginAsUser({ variables: { userId: employee.id } })}
                      title="Login as Employee"
                      disabled={logging}
                    >
                      <Icon fontSize="small">streetview</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => history.push(`${match.url}/${employee.id}/details/profile`)}
                      title="Edit Employee"
                      disabled={logging}
                    >
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => history.push(`${match.url}/${employee.id}/details/licenses`)}
                      title="Employee Licenses"
                      disabled={logging}
                    >
                      <Icon fontSize="small">launch</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => sendWelcomeEmail({ variables: { employeeId: employee.id } })}
                      title="Send Welcome Email"
                      disabled={sending}
                    >
                      <Icon fontSize="small">send</Icon>
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={confirm(() => remove({ variables: { id: employee.id } }), {
                        title: 'Employee Remove',
                        description: 'Are you sure you want to delete this Employee?'
                      })}
                      title="Remove Employee"
                      disabled={loadingRemove || logging}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            ]}
            actions={[
              <Button className="mr-2" key="import" variant="contained" color="primary" onClick={() => history.push(`${match.url}/import`)}>
                <Icon className="mr-1">import_contacts</Icon>
                Import Employees
              </Button>,
              <Button key="create" variant="contained" color="primary" onClick={() => history.push(`${match.url}/create`)}>
                <Icon className="mr-1">add</Icon>
                Create Employee
              </Button>
            ]}
            deleteMany={false}
          />
        )}
      </Mutation>
    </React.Fragment>
  )
}

const styles = theme => ({
  root: {}
})

const enhance = compose(withAppContext, withConfirm, withStyles(styles))

export default enhance(Employees)
