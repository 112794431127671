/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Proposals from './Proposals'
import ProposalCreate from './ProposalCreate'
import ProposalUpdate from './ProposalUpdate'

const Module = ({ match }) => (
  <Container maxWidth="lg">
    <Box my={2}>
      <Route path={`${match.path}`} component={Proposals} />
      <Route path={`${match.path}/create`} component={ProposalCreate} />
      <Route path={`${match.path}/:proposalId/update`} component={ProposalUpdate} />
    </Box>
  </Container>
)

const styles = theme => ({
  root: {
    width: '100%'
  }
})

export default compose(withStyles(styles))(Module)
