/** @format */

import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import Loader from 'react-loaders'
import {addExperimentNote, getExperimentStats, updateExperiment} from 'api/experiments'
import Paper from "@material-ui/core/Paper"
import withStyles from '@material-ui/core/styles/withStyles'
import Button from "@material-ui/core/Button"
import Fab from "@material-ui/core/Fab"
import Icon from "@material-ui/core/Icon"
import Sessions from "components/Visitors/Sessions"
import {Field, Form} from "../../lib/form";
import {TextField} from "../../lib/form/fields";
import {required} from "../../lib/form/validators";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";


const ExperimentUpdate = ({ classes, match, history }) => {
  const [expanded, seExpanded] = React.useState(null)
  return (
    <React.Fragment>
      <Query query={getExperimentStats} variables={match.params}>
        {({data: {experiment} = {}, loading}) => {
          if(loading)
            return <Loader />
          return (
            <div>
              <Button className={'ml-5 mt-5'} color="secondary" variant="contained" onClick={() => history.push('/experiments')}>
                Back
              </Button>
              <div className={'m-5'}>
                <p><i>{experiment.hypothesis}</i></p>
                <p>{experiment.description}</p>
              </div>
              <Paper className={'m-5'}>
                <table className="table table-bordered">
                  <thead>
                  <tr>
                    <th scope="col">Variation name</th>
                    <th scope="col">Visits</th>
                    <th scope="col">Unique Visitors</th>
                    <th scope="col">Conversion Rate</th>
                    <th scope="col">Bounce Rate</th>
                    <th scope="col">Average Scroll Rate</th>
                    <th scope="col">Average Time on Page</th>
                  </tr>
                  </thead>
                  <tbody>
                  {(experiment.variations||[]).map(v=>{
                    let byVariant = _.filter(experiment.visits, {variant: v.name})
                    let cntConversion = _.filter(byVariant, {status: 'conversion'}).length
                    let cntBounce = _.filter(byVariant, {status: 'bounce'}).length
                    console.log(_.filter(byVariant, {status: 'conversion'}))
                    return (
                      <React.Fragment  key={v.name}>
                        <tr >
                          <td>
                            {(!expanded || expanded!==v.name) && <Fab size="small" style={{marginLeft: -32, marginRight: 10}} color="primary" onClick={() => seExpanded(v.name)}>
                              <Icon>add</Icon>
                            </Fab>}
                            {expanded && expanded===v.name &&
                              <Fab size="small" style={{marginLeft: -32, marginRight: 10}} color="secondary" onClick={() => seExpanded(null)}>
                                <Icon>remove</Icon>
                              </Fab>
                            }
                            <strong>{v.name}</strong>
                            <span className={'float-right'}>
                      <Button size={'small'} color="default" variant="contained" onClick={() => window.open(experiment.url+'?show-variant='+v.name, "_blank")}>
                        View
                      </Button>
                    </span>
                          </td>
                          <td className=''>{byVariant.length}</td>
                          <td>{_.uniqBy(byVariant, v=>v.visitorId).length}</td>
                          <td>{byVariant.length?(Math.round(100*(cntConversion/byVariant.length))):0}% ({cntConversion})</td>
                          <td>{byVariant.length?(Math.round(100*(cntBounce/byVariant.length))):0}% ({cntBounce})</td>
                          <td>{Math.round(100*_.mean(byVariant.map(v=>(v.scrollRate||0)*1)))/100}%</td>
                          <td>{byVariant.length?moment(_.mean(_.filter(byVariant, v=>v.timeOnPage).map(v=>v.timeOnPage||0))).utc().format('HH:mm:ss'):'N/A'}</td>
                        </tr>
                        {expanded && expanded===v.name &&
                          <tr key={v.name+'-expanded'}>
                            <td colSpan={7}>

                              <Sessions filter={{variant: v.name, experimentId: experiment.id}} />

                            </td>
                          </tr>
                        }
                      </React.Fragment>)
                  })}
                  </tbody>
                </table>
                <div className={'p-3'}>
                  {experiment.notes && experiment.notes.length>0 &&<div className={'text-left'}>
                    <h5>Notes:</h5>
                    <List>
                      {_.reverse(_.sortBy([...Array.from(experiment.notes || []), ...Array.from(experiment.sessionNotes || [])], n=>n.createdAt)).map(note=>(
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar alt={note.author.name} src={note.author.avatar} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={note.author.name}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {(new Date(note.createdAt)).toLocaleString()}
                                </Typography>
                                <p className='lead'>{note.body}</p>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>}
                  <Form
                    form="VisitUpdate"
                    mutation={[addExperimentNote]}
                    refetchQueries={['getExperiment']}
                    initialValues={{}}
                    success="Note Added"
                    onSubmit={({ data: { body }, mutation, ...props })=> {
                      return mutation({ variables: { experimentId: experiment.id, body} })
                    }}
                    onSubmitSuccess={(result, dispatch, { enqueueSnackbar, reset, ...rest }) =>{
                      enqueueSnackbar("Note Added", { variant: 'success' })
                      reset()
                    }}
                  >
                    {({doc, submit, submitting}) => (
                      <React.Fragment>
                        <Field name="body" component={TextField} label="Note" multiline rows={2} validate={[required()]} style={{width:'calc(100% - 130px)'}} />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={submit}
                          disabled={submitting}
                          className={'mt-3'}
                        >
                          <Icon>add</Icon>
                          Add Note
                        </Button>
                      </React.Fragment>
                    )}
                  </Form>
                </div>
              </Paper>
            </div>
          )}
        }
      </Query>
    </React.Fragment>
  )
}

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(ExperimentUpdate)
