/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation } from 'react-apollo'
import moment from 'moment'
import CheckboxTable from 'lib/tables/CheckboxTable'
import withConfirm from 'lib/withConfirm'

import { getUserLicenses, removeUserLicense } from 'api/userLicenses'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const UserLicenses = ({ classes, history, match, confirm }) => (
  <React.Fragment>
    <Mutation mutation={removeUserLicense} refetchQueries={['getUserLicenses']}>
      {(remove, { loading: loadingRemove }) => (
        <CheckboxTable
          query={getUserLicenses}
          variables={{ userId: match.params.employeeId }}
          initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
          columns={[
            {
              Header: 'Name',
              accessor: 'name'
            },
            /*
            {
              Header: 'Product',
              accessor: 'product.name',
              Cell: ({ original: userLicense }) => (
                <div>
                  <div>{userLicense.product.name}</div>
                  <div>{userLicense.plan.name}</div>
                </div>
              )
            },
            */
            {
              Header: 'Status',
              accessor: 'status'
            },
            {
              Header: 'Actions',
              sortable: false,
              filterable: false,
              accessor: 'id',
              Cell: ({ original: userLicense }) => {
                return (
                  <div>
                    <IconButton
                      color="secondary"
                      onClick={confirm(() => remove({ variables: { id: userLicense.id } }), {
                        title: 'User License Revoke',
                        description: 'Are you sure you want to revoke this User License?'
                      })}
                      title="Revoke User License"
                      disabled={loadingRemove}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            }
          ]}
          actions={[
            <Button
              key="add"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => history.push(`${match.url}/add`)}
            >
              <Icon>add</Icon>
              Add License to User
            </Button>
          ]}
          deleteMany={false}
        />
      )}
    </Mutation>
  </React.Fragment>
)

const styles = theme => ({
  root: {}
})

export default compose(
  withConfirm,
  withStyles(styles)
)(UserLicenses)
