/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation } from 'react-apollo'
import CheckboxTable from 'lib/tables/CheckboxTable'
import withConfirm from 'lib/withConfirm'

import { getLicenses, removeLicense } from 'api/licenses'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const Licenses = ({ classes, history, match, confirm }) => (
  <React.Fragment>
    <div>
      <p>
        Licenses are used to manage your user permissions to the different products within the ChatFortress ecosystem. You need to purchase
        licenses then you can assign them to users. This allows you control who each license is assigned at any point in time. All licenses
        are billed monthly. If you have questions use the live chat to ask your question.
        <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e496e092c7d3a7e9ae7d0da">
          Get help about this page here
        </a>
      </p>
    </div>
    <Mutation mutation={removeLicense} refetchQueries={['getLicenses']}>
      {(remove, { loading: loadingRemove }) => (
        <CheckboxTable
          query={getLicenses}
          variables={{}}
          initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
          columns={[
            {
              Header: 'Name',
              accessor: 'name'
            },
            {
              Header: 'Product',
              accessor: 'product.name',
              Cell: ({ original: license }) => (
                <div>
                  <div>{license.product && license.product.name}</div>
                  <div>{license.plan && license.plan.name}</div>
                </div>
              )
            },
            {
              Header: 'Number of Licenses',
              accessor: 'quantity',
              Cell: ({ original: license }) => (
                <div>
                  <div>
                    <b>Total Purchased</b>: {license.quantity}
                  </div>
                  <div>
                    <b>Used</b>: {license.usageCount}
                  </div>
                  <div>
                    <b>Left</b>: {license.quantity - license.usageCount}
                  </div>
                </div>
              )
            },
            {
              Header: 'Status',
              accessor: 'subscriptionStatus'
            },
            {
              Header: 'Actions',
              sortable: false,
              filterable: false,
              accessor: 'id',
              Cell: ({ original: license }) => {
                return (
                  <div>
                    <IconButton onClick={() => history.push(`${match.url}/${license.id}/update`)} title="Edit License">
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={confirm(() => remove({ variables: { id: license.id } }), {
                        title: 'License Remove',
                        description: 'Are you sure you want to delete this License?'
                      })}
                      title="Remove License"
                      disabled={loadingRemove}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            }
          ]}
          actions={[
            <Button
              key="add"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                history.push(`${match.url}/add`)
                //history.push(`${match.url}/credit-card-add`)
              }}
            >
              <Icon>add</Icon>
              Add License
            </Button>
          ]}
          deleteMany={false}
        />
      )}
    </Mutation>
  </React.Fragment>
)

const styles = theme => ({
  root: {}
})

export default compose(withConfirm, withStyles(styles))(Licenses)
