/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import { acl } from 'lib/acl'

import Auth from 'components/Auth'
import Proposal from 'components/Proposal'
import Connect from 'components/Connect'
import CardReportPublic from 'components/CardReports/CardReportPublic'
import DefaultLayout from 'layouts/Default'

import AppContext from 'AppContext'
import 'styles/main.scss'
import HackedResults from "components/HackedReports/HackedResults"

const getContext = gql`
  query getContext {
    user: me {
      id
      name
      email
      phone
      avatar
      companyId
      accountType
      role
      roles
      apiKeyPublic
      stripeCustomerId
      customerId
      apiKeyPrivate
      publicPages {
        title
        shortUrl
        type
      }
      invites {
        phone
        email
        accepted
      }
      subemployees {
        id
        name
        firstName
        email
        phone
        avatar
        accountType
        timezone
        companyName
        companyWebsite
      }
      mailAddress {
        addressLine1
        addressLine2
        city
        state
        county
        zip
        country
      }
      purchasedProducts {
        id
        name
        plan {
          id
          name
          permissions
        }
      }
      services {
        stripe {
          token_type
          stripe_publishable_key
          scope
          stripe_user_id
          refresh_token
          access_token
        }
      }
      company {
        id
        name
        website
        email
        phone
        logo
        address {
          addressLine1
          addressLine2
          city
          state
          county
          zip
          country
        }
        report {
          id
          token
        }
        isDomainVerified
        isQuizCompleted
        isCore4Completed
      }
    }
  }
`

const App = ({ match, location }) => (
  <Query query={getContext}>
    {({ data: { user } = {}, loading, refetch }) => {
      if (loading) {
        return null
      }
      // Context
      const context = { user, refetch }

      // Init ACL with context
      acl.initialize(context)

      return (
        <AppContext.Provider value={{ ...context, acl }}>
          <Switch>
            <Route
              path={`${match.path}auth`}
              render={props => (user && props.location.pathname.indexOf('token')===-1 ? <Redirect from={props.location.pathname} to="/dashboard" /> : <Auth {...props} />)}
            />
            <Route path={`${match.path}report/:token`} component={CardReportPublic} />
            <Route path={`${match.path}proposal/:proposalId`} render={props => <Proposal {...props} />} />
            <Route path={`${match.path}hacked-report-results`} render={props => <HackedResults {...props} />} />
            <Route path={`${match.path}services/connect/:service`} component={Connect} />
            <Route
              path={`${match.path}`}
              render={props =>
                user ? (
                  <DefaultLayout {...props} />
                ) : (
                  <Redirect
                    from={props.location.pathname}
                    to={{
                      pathname: '/auth/login',
                      state: { referrer: props.location.pathname }
                    }}
                  />
                )
              }
            />
          </Switch>
        </AppContext.Provider>
      )
    }}
  </Query>
)

export default compose(withRouter)(App)