/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { FormDialog, FormSection, CreditCard } from 'lib/form'

import { createSource } from 'api/sources'
import { createCCToken } from 'api/actions'

import withStyles from '@material-ui/core/styles/withStyles'

const CreditCardAdd = ({ match, onComplete }) => (
  <React.Fragment>
    <FormDialog
      title="Add Credit Card"
      form="CreditCardAdd"
      mutation={[createSource, { refetchQueries: ['getSources'] }]}
      initialValues={{ card: { object: 'card' } }}
      btnSubmitText="Add"
      success="Credit Card added successfully"
      onSubmit={({ data, mutation }) =>
        createCCToken(data.card).then(({ token }) => mutation({ variables: { ...match.params, data: { source: token } } }))
      }
      onComplete={onComplete}
    >
      {({ doc, invalid, submitting }) => (
        <React.Fragment>
          <FormSection name="card">
            <CreditCard />
          </FormSection>
        </React.Fragment>
      )}
    </FormDialog>
  </React.Fragment>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(CreditCardAdd)
