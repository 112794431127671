/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Route, Switch, Redirect } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Nav from 'components/Nav'

import withAppContext from 'lib/withAppContext'

import Licenses from 'components/Licenses'
import Employees from 'components/Employees'
import Subscriptions from 'components/Subscriptions'
//import BankAccounts from 'components/BankAccounts'
import Profile from './Profile'
import Company from './Company'
import Billing from './Billing'
import Notifications from './Notifications'
import Services from './Services'
import RCResseler from './RCResseler'

const Module = ({ context: { acl }, match, location }) => {
  const tabs = [
    { key: 'profile', title: 'Profile', to: `${match.url}/profile`, scope: 'account.profile' },
    { key: 'company', title: 'Company', to: `${match.url}/company`, scope: 'account.company' },
    { key: 'plans', title: 'Plans', to: `${match.url}/plans`, scope: 'account.plans' },
    { key: 'billing', title: 'Billing', to: `${match.url}/billing`, scope: 'account.billing' },
    { key: 'notifications', title: 'Notifications', to: `${match.url}/notifications`, scope: 'account.notifications' },
    { key: 'services', title: 'Services', to: `${match.url}/services`, scope: 'account.services' },
    { key: 'licenses', title: 'Licenses', to: `${match.url}/licenses`, scope: 'account.licenses' },
    { key: 'employees', title: 'Employees', to: `${match.url}/employees`, scope: 'account.employees' },
    { key: 'rc-resseler', title: 'Report Card Reseller', to: `${match.url}/rc-resseler`, scope: 'account.rc-resseler' }
  ].filter(item => acl.hasAccess(item.scope))

  return (
    <Container maxWidth="lg">
      <Nav color="primary" items={tabs} activeKey={location.pathname.split('/')[2] || 'profile'} />
      <Box my={3}>
        <Switch>
          <Route path={`${match.path}/profile`} component={Profile} />
          <Route path={`${match.path}/company`} component={Company} />
          <Route path={`${match.path}/plans`} component={Subscriptions} />
          <Route path={`${match.path}/billing`} component={Billing} />
          <Route path={`${match.path}/notifications`} component={Notifications} />
          <Route path={`${match.path}/services`} component={Services} />
          <Route path={`${match.path}/licenses`} component={Licenses} />
          <Route path={`${match.path}/employees`} component={Employees} />
          <Route path={`${match.path}/rc-resseler`} component={RCResseler} />
          <Redirect from={`${match.path}`} to={`${match.path}/profile`} />
        </Switch>
      </Box>
    </Container>
  )
}

const styles = theme => ({
  root: {
    width: '100%'
  }
})

export default compose(withAppContext, withStyles(styles))(Module)
