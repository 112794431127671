import React from 'react'
import moment from "moment/moment"
import CheckboxTable from 'lib/tables/CheckboxTable'

import { getTrainingReportUser } from 'api/training'

export default ({ match, location }) => (
  <React.Fragment>
    <div className="p-4">

      <div>
        <h3>Cybersecurity Awareness Monthly</h3>
        <CheckboxTable
          query={getTrainingReportUser}
          initialVariables={{ sorted: [{ id: 'order', desc: false }] }}
          variables={{ courseId: 's3zZy57Lc2JRLYnWZErb', userId: match.params.userId }}
          columns={[
            {
              Header: 'Lesson Name',
              accessor: 'name',
              id: 'name',
              width: 400,
              Cell: ({ original: item }) => (
                <div>
                  <strong>#{item.order+1}. {item.name}</strong>
                </div>
              )
            },
            {
              sortable: false,
              filterable: false,
              Header: 'Completed',
              accessor: 'completed',
              Cell: ({ original: item }) => item.completed?"Yes":"No",
            },
            {
              sortable: false,
              filterable: false,
              Header: 'Released',
              accessor: 'released',
              Cell: ({ original: item }) => item.released?"Yes":"No",
            },
            {
              sortable: false,
              filterable: false,
              Header: 'Quiz result',
              accessor: 'quizPointsUser'
            }
          ]}
          deleteMany={false}
        />
        <br/>
        <h3>﻿Cybersecurity CORE 4</h3>
        <CheckboxTable
          query={getTrainingReportUser}
          initialVariables={{ sorted: [{ id: 'order', desc: false }] }}
          variables={{ courseId: 'mtyXTpwkt7ogjSu36mKx', userId: match.params.userId }}
          columns={[
            {
              Header: 'Lesson Name',
              accessor: 'name',
              id: 'name',
              width: 400,
              Cell: ({ original: item }) => (
                <div>
                  <strong>#{item.order+1}. {item.name}</strong>
                </div>
              )
            },
            {
              sortable: false,
              filterable: false,
              Header: 'Completed',
              accessor: 'completed',
              Cell: ({ original: item }) => item.completed?"Yes":"No",
            },
            {
              sortable: false,
              filterable: false,
              Header: 'Released',
              accessor: 'released',
              Cell: ({ original: item }) => item.released?"Yes":"No",
            },
            {
              sortable: false,
              filterable: false,
              Header: 'Quiz result',
              accessor: 'quizPointsUser'
            }
          ]}
          deleteMany={false}
        />

      </div>
    </div>
  </React.Fragment>
)
