/** @format */

import React from 'react'

import Icon from '@material-ui/core/Icon'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import PeopleIcon from '@material-ui/icons/People'
import BarChartIcon from '@material-ui/icons/BarChart'
import LayersIcon from '@material-ui/icons/Layers'
import CardGiftCardIcon from '@material-ui/icons/CardGiftcard'

export default [
  {
    title: 'Dashboard',
    to: '/dashboard',
    icon: <DashboardIcon />,
    scope: 'dashboard'
  },
  /*{
    title: 'Messages',
    to: '/messages',
    icon: <ShoppingCartIcon />,
    scope: 'messages'
  },*/
  {
    title: 'Requests',
    to: '/requests',
    icon: <PeopleIcon />,
    scope: 'requests'
  },
  {
    title: 'Proposals',
    to: '/proposals',
    icon: <PeopleIcon />,
    scope: 'proposals'
  },
  /*{
    title: 'Secure Blocks',
    to: '/blocks',
    icon: <BarChartIcon />,
    scope: 'blocks'
  },*/
  {
    title: 'Courses',
    to: '/courses',
    icon: <CardGiftCardIcon />,
    scope: 'courses'
  },
  {
    title: 'Training',
    to: '/training',
    icon: <CardGiftCardIcon />,
    scope: 'training'
  },
  {
    title: 'Products',
    to: '/products',
    icon: <CardGiftCardIcon />,
    scope: 'products'
  },
  /*{
    title: 'Wizards',
    to: '/wizards',
    icon: <CardGiftCardIcon />,
    scope: 'wizards'
  },*/
  {
    title: 'Ledger',
    to: '/commissions',
    icon: <CardGiftCardIcon />,
    scope: 'commissions'
  },
  {
    title: 'Short Links',
    to: '/links',
    icon: <CardGiftCardIcon />,
    scope: 'links'
  },
  {
    title: 'Monitoring Objects',
    to: '/monitoring-objects',
    icon: <Icon>policy</Icon>,
    scope: 'monitoringObjects:read'
  },
  {
    title: 'Hacked Reports',
    to: '/hacked-reports',
    icon: <Icon>report</Icon>,
    scope: 'hackedReports'
  },
  {
    title: 'Report Cards',
    to: '/card-reports',
    icon: <Icon>report</Icon>,
    scope: 'cardReports:read'
  },
  {
    title: 'Report Card',
    to: '/report-card',
    icon: <Icon>report</Icon>,
    scope: 'reportCard:read'
  },
  {
    title: 'Marketing',
    to: '/marketing',
    icon: <Icon>money</Icon>,
    scope: 'marketing'
  },
  {
    title: 'Companies',
    to: '/companies',
    icon: <CardGiftCardIcon />,
    scope: 'companies'
  },
  {
    title: 'Users',
    to: '/users',
    icon: <CardGiftCardIcon />,
    scope: 'users'
  },
  {
    title: 'Smishing Simulator',
    to: '/smishing-simulator',
    icon: <CardGiftCardIcon />,
    scope: 'smishingSimulator'
  },
  {
    title: 'Training Activity',
    to: '/training-activity',
    icon: <Icon>library_books</Icon>,
    scope: 'trainingActivity'
  },
  {
    title: 'Activity Log',
    to: '/activity-log',
    icon: <Icon>people</Icon>,
    scope: 'activityLog'
  },
  {
    title: 'Visitors',
    to: '/visitors',
    icon: <Icon>public</Icon>,
    scope: 'visitors'
  },
  {
    title: 'Split Tests',
    to: '/experiments',
    icon: <Icon>call_split</Icon>,
    scope: 'experiments'
  },
  {
    title: 'Account',
    to: '/account',
    icon: <LayersIcon />,
    scope: 'account'
  },
  {
    title: 'EC Templates',
    to: '/ec-templates',
    icon: <Icon>description</Icon>,
    scope: 'admin'
  }
]
