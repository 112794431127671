import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import withContext from 'lib/withAppContext'

import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Card, CardHeader, CardIcon, CardBody, CardFooter } from 'components/Card'

class Dashboard extends React.Component {
  state = {}

  render() {
    const { classes, context } = this.props
    const { user } = context

    console.log(user)

    return (
      <div className="p-2">
        <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon color="warning">
              <Icon>people</Icon>
            </CardIcon>
            <p className={classes.cardCategory}>Visitors</p>
            <h3 className={classes.cardTitle}>0</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>All Time</div>
          </CardFooter>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>
                  Orders
                  <Button variant="contained" color="primary">
                    <Icon className={classes.icon}>add</Icon> Create New Order
                  </Button>
                </h4>
              </CardHeader>
              <CardBody>Card Body</CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = () => ({
  root: {}
})

export default compose(withContext, withStyles(styles))(Dashboard)
