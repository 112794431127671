/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation } from 'react-apollo'
import numeral from 'numeral'
import moment from 'moment'
import CheckboxTable from 'lib/tables/CheckboxTable'
import withConfirm from 'lib/withConfirm'

import { getPlans, removePlan } from 'api/plans'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const Plans = ({ classes, history, match, confirm }) => (
  <React.Fragment>
    <Mutation mutation={removePlan} refetchQueries={['getPlans']}>
      {(remove, { loading: loadingRemove }) => (
        <CheckboxTable
          query={getPlans}
          variables={{ productId: match.params.productId }}
          initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
          columns={[
            {
              Header: 'Name',
              accessor: 'name'
            },
            {
              Header: 'Billing',
              accessor: 'amount',
              Cell: ({ original: plan }) => (
                <div>
                  {`${numeral(plan.amount).format('$0.00')} per ${plan.interval} ${plan.freq}`}
                  <br />
                  <em>Setup Fee: {numeral(plan.setupFee).format('$0.00')}</em>
                </div>
              )
            },
            {
              Header: 'Bill Times',
              id: 'billTimes',
              accessor: plan => plan.billTimes || 'Infinity'
            },
            {
              Header: 'Roles',
              accessor: 'roles',
              Cell: ({ value: roles }) => Array.from(roles || []).join(', ')
            },
            /*
            {
              Header: 'Date Created',
              id: 'createdAt',
              accessor: plan => moment(plan.createdAt).format('LLL')
            },
            */
            {
              Header: 'Actions',
              sortable: false,
              filterable: false,
              accessor: 'id',
              Cell: ({ original: plan }) => {
                return (
                  <div>
                    <IconButton onClick={() => history.push(`${match.url}/${plan.id}/update`)} title="Edit Plan">
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={confirm(() => remove({ variables: { id: plan.id } }), {
                        title: 'Plan Remove',
                        description: 'Are you sure you want to delete this Plan?'
                      })}
                      title="Remove Plan"
                      disabled={loadingRemove}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            }
          ]}
          actions={[
            <Button
              key="create"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => history.push(`${match.url}/create`)}
            >
              <Icon>add</Icon>
              Create Plan
            </Button>
          ]}
          deleteMany={false}
        />
      )}
    </Mutation>
  </React.Fragment>
)

const styles = theme => ({
  root: {}
})

export default compose(withConfirm, withStyles(styles))(Plans)
