/** @format */

import React from 'react'
import { Field, FormSection, CreditCard } from 'lib/form'
import { RadioGroup } from 'lib/form/fields'
import { required } from 'lib/form/validators'
import { Typography } from '@material-ui/core'

const PaymentMethod = ({ title = 'Payment Method', sources = [], paymentMethod }) => {
  return (
    <React.Fragment>
      <Typography variant="h5">{title}</Typography>
      {sources && sources.length > 0 ? (
        <React.Fragment>
          <Field
            name="paymentMethod"
            component={RadioGroup}
            label="Select Your Source"
            validate={[required()]}
            options={sources
              .map(({ id, displayName }) => ({ label: displayName, value: id }))
              .concat([{ label: 'New Credit Card', value: 'new' }])}
          />
          {paymentMethod === 'new' && (
            <FormSection name="source">
              <CreditCard title="" />
            </FormSection>
          )}
        </React.Fragment>
      ) : (
        <FormSection name="source">
          <CreditCard title="" />
        </FormSection>
      )}
    </React.Fragment>
  )
}

PaymentMethod.defaultProps = {
  name: ''
}

export default PaymentMethod
