/** @format */

import gql from 'graphql-tag'
import {employeeFragment} from "./employees";

export const getTemplates = gql`
  query getTemplates($name: String, $skip: Int, $limit: Int, $sort: [String]) {
    templates(name: $name,skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        description
        body
        createdAt
        updatedAt
      }
      count
      pages
    }
  }
`

export const getTemplate = gql`
  query getTemplate($templateId: ID!) {
    template(id: $templateId) {
        id
        name
        description
        body
        createdAt
        updatedAt
    }
  }
`
export const createTemplate = gql`
  mutation createTemplate($data: TemplateInput!) {
    createTemplate(data: $data) {
      id
    }
  }
`
export const updateTemplate = gql`
  mutation updateTemplate($templateId: ID!, $data: TemplateInput!) {
    updateTemplate(templateId: $templateId, data: $data) {
      id
    }
  }
`

export const removeTemplate = gql`
  mutation removeTemplate($id: ID!) {
    removeTemplate(id: $id) {
      id
    }
  }
`