import React from 'react'
import compose from 'recompose/compose'
import { Switch, Route, useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from 'react-apollo'
import withConfirm from 'lib/withConfirm'

import { getLists, removeList } from 'api/lists'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Avatar from 'lib/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import BackIcon from '@material-ui/icons/ArrowBack'

import ListCreate from './ListCreate'
import ListUpdate from './ListUpdate'

const Lists = ({ confirm }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { loading, data: { lists: { data: lists = [] } = {} } = {} } = useQuery(getLists, { variables: { limit: 0 } })
  const [remove] = useMutation(removeList, { refetchQueries: ['getLists'] })

  return (
    !loading && (
      <Paper>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <strong>Lists</strong>
                <IconButton onClick={() => history.push(`${match.url}/create`)} edge="end" aria-label="add">
                  <AddIcon />
                </IconButton>
              </Box>
              <hr/>
            </ListSubheader>
          }
        >
          {lists.map(list => {
            return (
              <ListItem
                button
                selected={list.id === location.pathname.split('/')[3]}
                onClick={() => history.push(`${match.url}/${list.id}`)}
                key={list.id}
              >
                <ListItemAvatar>
                  <Avatar avatar={list.cover} name={list.name}>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={list.name} secondary={list.description} />
                <ListItemSecondaryAction>
                  <IconButton aria-label="edit" onClick={() => history.push(`${match.url}/update/${list.id}`)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="secondary"
                    onClick={confirm(() => remove({ variables: { id: list.id } }), {
                      title: 'List Remove',
                      description: 'Are you sure you want to delete this List?'
                    })}
                    title="Remove List"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
          {lists.length === 0 && <Typography align="center">No Lists</Typography>}
        </List>
        <Switch>
          <Route path={`${match.path}/create`} component={ListCreate} />
          <Route path={`${match.path}/update/:listId`} component={ListUpdate} />
        </Switch>
      </Paper>
    )
  )
}

export default compose(withConfirm)(Lists)
