/** @format */

import React from 'react'
import { Form } from 'lib/form'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import useQuiz from './useQuiz'
import QuestionInput from './QuestionInput'

const Quiz = ({ ignoreCorrect = false, classes, title, questions, message = 'Thank you for answers', onComplete }) => {
  const { numQuestion, currentQuestion, totalQuestions, attempts, setAttempts, next } = useQuiz({ questions, onComplete })
  console.log(questions, numQuestion, totalQuestions)
  return (
    <Paper className="p-3">
      <Typography variant="h4">{title}</Typography>
      {numQuestion < totalQuestions ? (
        <React.Fragment>
          {currentQuestion ? (
            <Form
              form={currentQuestion.id}
              onSubmitSuccess={({ data: { submitForm: data } }) => {
                setAttempts(0)
                if (!ignoreCorrect) next({ ...data, questionId: currentQuestion.id })
                else next({ ...data, questionId: currentQuestion.id, qData: currentQuestion })
              }}
              onSubmitFail={(...args) => setAttempts(attempts + 1)}
              forceUnregisterOnUnmount={true}
              //asyncBlurFields={[]}
              //asyncChangeFields={[]}
            >
              {({ submit, invalid }) => (
                <React.Fragment>
                  <Typography color="textSecondary" gutterBottom>
                    questions {numQuestion + 1} of {totalQuestions}
                  </Typography>
                  <Typography variant="h5">{currentQuestion.question}</Typography>
                  <QuestionInput ignoreCorrect={ignoreCorrect} question={currentQuestion} />
                  <Box display="flex">
                    <Button color="primary" variant="contained" onClick={submit}>
                      Next
                    </Button>
                  </Box>
                  {attempts >= 3 && invalid && (
                    <div className="mt-3">
                      <p>The incorrect answer on this quiz is the following:</p>
                      <ul>
                        {currentQuestion.answers
                          .filter(ans => ans.correct === false)
                          .map((ans, index) => (
                            <li key={index}>
                              <Typography color="secondary">{ans.label}</Typography>
                            </li>
                          ))}
                      </ul>
                      <p>Please update your answer and try again.</p>
                    </div>
                  )}
                </React.Fragment>
              )}
            </Form>
          ) : (
            <Typography>error</Typography>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant="body1">{message}</Typography>
        </React.Fragment>
      )}
    </Paper>
  )
}

const styles = theme => ({
  root: {}
})

export default withStyles(styles)(Quiz)
