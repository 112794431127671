/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField, PhoneNumber } from 'lib/form/fields'
import { required, length, email } from 'lib/form/validators'

import { applyCouponUser } from 'api/users'

import withStyles from '@material-ui/core/styles/withStyles'

const UserCreate = ({ classes, match }) => (
  <FormDialog
    title="Apply Coupon User"
    form="ApplyCoupon"
    mutation={[applyCouponUser, { refetchQueries: ['getUsers'] }]}
    initialValues={{}}
    btnSubmitText="Apply Coupon"
    success="Coupon applied successfully"
  >
    {({ doc, invalid, submitting }) => (
      <React.Fragment>
        <Field name="coupon" component={TextField} label="Coupon Code" validate={[required(), length({ min: 2 })]} />
      </React.Fragment>
    )}
  </FormDialog>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(UserCreate)
