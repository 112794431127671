/** @format */

import React from 'react'
import qs from 'qs'

class Connect extends React.Component {
  async componentDidMount() {
    const { match, history, location } = this.props
    const { service } = match.params
    const query = qs.parse(location.search.slice(1))

    const token = localStorage.getItem('loginToken')
    const method = 'POST'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const body = {
      service,
      ...query,
      ...match.params
    }
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const options = {
      method,
      headers,
      body: JSON.stringify(body)
    }

    const result = await fetch(`${process.env.REACT_APP_API_URL}/v1/services/connect`, options).then(function(res) {
      if (!res.error) return res.json()
      throw new Error(res.error)
    })
    console.log('result', result)

    window.location.href = '/account/services'
  }

  render() {
    return <p>Connecting...</p>
  }
}

export default Connect
