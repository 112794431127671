import React from 'react'
import _ from 'lodash'

import { getColorByValue, getGradientByValue } from 'lib/chartHelpers'

export default ({ height, width, letter, value }) => {
  const barPositionByDegree = (centerX, centerY, radius, angleInDegrees) => {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    }
  }

  const drawArc = (x, y, radius, startAngle, endAngle) => {
    let start = barPositionByDegree(x, y, radius, endAngle)
    let end = barPositionByDegree(x, y, radius, startAngle)
    let arcSweep = endAngle - startAngle <= 180 ? '0' : '1'
    return ['M', start.x, start.y, 'A', radius, radius, 0, arcSweep, 0, end.x, end.y].join(' ')
  }

  let maxDim = _.min([width, height])
  let strokeWidth = maxDim / 10
  let startX = width / 2
  let startY = height / 2
  let radius = maxDim / 3
  let startAngle = -120
  let endAngle = 120
  let curAngle = 2.4 * value - 120

  let curColor = getColorByValue(value)
  let colorArray = getGradientByValue(value)

  return (
    <div>
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <linearGradient id={'cflg' + value} gradientTransform="rotate(36)">
          {colorArray &&
          colorArray.map(color => (
            <stop
              key={`stop-${color.offset}`}
              stopOpacity={color.opacity}
              stopColor={color.color}
              offset={color.offset ? Math.round(color.offset) / 100 : 0}
            />
          ))}
        </linearGradient>
        <path
          d={drawArc(startX, startY, radius, startAngle, endAngle)}
          fill="none"
          fillOpacity="1"
          stroke="#e9fcff"
          strokeOpacity="1"
          strokeLinecap="butt"
          strokeWidth={strokeWidth}
          strokeDasharray="0"
        />
        <path
          d={drawArc(startX, startY, radius, startAngle, curAngle)}
          fill="none"
          fillOpacity="0.85"
          stroke={`url(#${'cflg' + value})`}
          strokeOpacity="1"
          strokeLinecap="butt"
          strokeWidth={strokeWidth}
          strokeDasharray="2"
        />
        <path
          d={drawArc(startX, startY, radius, startAngle, endAngle)}
          fill="none"
          fillOpacity="1"
          stroke="#e9fcff"
          strokeOpacity="1"
          strokeLinecap="butt"
          strokeWidth={strokeWidth + 2}
          strokeDasharray="1000"
          strokeDashoffset={value * 10}
          className="dash1"
        />
        <circle r={radius - strokeWidth + 5} cx={startX} cy={startY} fill="#e9fcff" />
        <text
          x={startX}
          y={startY + maxDim / 20}
          textAnchor="middle"
          dominantBaseline="auto"
          fontSize={maxDim / 5}
          fontWeight="regular"
          fill={curColor}
          className="apexcharts-text apexcharts-datalabel-label"
        >
          {letter}
        </text>
        <text
          x={startX}
          y={startY + maxDim / 4.6}
          textAnchor="middle"
          dominantBaseline="auto"
          fontSize={maxDim / 12.5}
          fontWeight="regular"
          fill="#373d3f"
          className="apexcharts-text apexcharts-datalabel-value"
        >
          {value}
        </text>
      </svg>
    </div>
  )
}
