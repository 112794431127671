/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation } from 'react-apollo'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import CheckboxTable from 'lib/tables/CheckboxTable'
import UserCell from 'lib/tables/UserCell'
import withConfirm from 'lib/withConfirm'
import withContext from 'lib/withAppContext'
import { acl } from "lib/acl"

import { getHackedReports, removeHackedReport } from 'api/hackedReports'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'


const HackedReports = ({ classes, history, match, confirm, context }) => (
  <React.Fragment>
    {!context.user.stripeCustomerId && !context.user.customerId && <div className='alert alert-warning font-weight-bold'>
      You have no payment method attached on profile, please add one in order to use paid services.
      <Button
        variant="outlined"
        color="default"
        style={{top: 4, right: 8, position: 'absolute'}}
        onClick={() => {
          history.push(`/account/billing/add-credit-card`)
        }}
      >
        Add Payment Method
      </Button>
    </div>}
    <Mutation mutation={removeHackedReport} refetchQueries={['getHackedReports']}>
      {(remove, { loading: loadingRemove }) => (
        <CheckboxTable
          query={getHackedReports}
          variables={{}}
          initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
          columns={[
            {
              Header: 'Object',
              accessor: 'q',
              Cell: ({ original: hackedReport }) => (
                <div>
                  <div>{hackedReport.reportEmail}</div>
                  <div>{hackedReport.reportDomain}</div>
                </div>
              )
            },
            {
              Header: 'Client',
              accessor: 'user.name',
              Cell: ({ original: hackedReport }) => (
                <UserCell user={{ ...hackedReport, name: `${hackedReport.firstName} ${hackedReport.lastName}` }} />
              )
            },
            {
              Header: 'Result',
              accessor: 'breachesCnt',
              sortable: false,
              width: 50,
              filterable: false,
              Cell: ({ original: hackedReport }) => (
                <strong style={{color: hackedReport.breachesCnt?"red":"green"}}>{hackedReport.breachesCnt?"YES":"NO"}</strong>
              )
            },
            {
              Header: 'Actions',
              sortable: false,
              filterable: false,
              accessor: 'id',
              Cell: ({ original: hackedReport }) => {
                return (
                  <div>
                    <CopyToClipboard text={hackedReport.link}>
                      <IconButton title="Copy Link">
                        <Icon fontSize="small">file_copy</Icon>
                      </IconButton>
                    </CopyToClipboard>

                    <IconButton
                      onClick={()=>{
                        fetch(`${process.env.REACT_APP_API_URL}/v1/get-pdf`, {
                          method: 'post',
                          body: JSON.stringify({ url: hackedReport.link }),
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                          }
                        })
                          .then(r => r.json())
                          .then((res) => {
                            let link = document.createElement('a')
                            link.href = 'data:application/pdf;base64,' + res.pdf
                            link.download = 'hacked-report.pdf'
                            link.click()
                          })
                          .catch(e => console.log(e))
                      }}
                      title="get PDF"
                    >
                      <Icon fontSize="small">picture_as_pdf</Icon>
                    </IconButton>
                    <IconButton onClick={() => history.push(`${match.url}/${hackedReport.id}/update`)} title="Edit Report">
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={confirm(() => remove({ variables: { id: hackedReport.id } }), {
                        title: 'Hacked Report Removal',
                        description: 'Are you sure you want to delete this Report?'
                      })}
                      title="Remove Report"
                      disabled={loadingRemove}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            }
          ]}
          actions={[
            <Button
              key="add"
              disabled={!context.user.stripeCustomerId && !context.user.customerId}
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                history.push(`${match.url}/add`)
              }}
            >
              <Icon>add</Icon>
              Add Report
            </Button>
          ]}
          deleteMany={false}
        />
      )}
    </Mutation>
  </React.Fragment>
)

const styles = theme => ({
  root: {}
})

export default compose(
  withConfirm,
  withContext,
  withStyles(styles)
)(HackedReports)
