/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { FormCard, FormSection, CompanyInformation } from 'lib/form'

import { getDataUpdatingUser, updateUser } from 'api/users'

import withStyles from '@material-ui/core/styles/withStyles'

const UserCompany = ({ classes, match }) => (
  <Query query={getDataUpdatingUser} variables={match.params}>
    {({ data: { user = {}, companies: { data: companies } = {} } = {}, loading }) =>
      !loading && (
        <FormCard
          title=""
          form="UserCompany"
          mutation={[updateUser]}
          initialValues={user}
          success="User updated successfully"
          btnSubmitText="Save User Company"
        >
          {({ doc, submit, submitting }) => (
            <React.Fragment>
              <FormSection name="">
                <CompanyInformation doc={doc} companies={companies} />
              </FormSection>
            </React.Fragment>
          )}
        </FormCard>
      )
    }
  </Query>
)

const styles = theme => ({
  root: {}
})

export default withStyles(styles)(UserCompany)
