/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation, useMutation } from 'react-apollo'
import withConfirm from 'lib/withConfirm'
import moment from 'moment'

import { loginAs, onLogin } from 'api/auth'
import { getUsers, removeUser } from 'api/users'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import CheckboxTable from 'lib/tables/CheckboxTable'
import UserCell from 'lib/tables/UserCell'
import CompanyCell from 'lib/tables/CompanyCell'
import SelectFilter from 'lib/tables/SelectFilter'

import { userAccountTypeOptions } from 'data/options'

const Users = ({ classes, history, match, confirm }) => {
  const [loginAsUser, { loading: logging }] = useMutation(loginAs, {
    onCompleted({ loginAs: { token } }) {
      onLogin(token)
    }
  })

  return (
    <React.Fragment>
      <Mutation mutation={removeUser} refetchQueries={['getUsers']}>
        {(remove, { loading: loadingRemove }) => (
          <CheckboxTable
            query={getUsers}
            initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: 'User',
                accessor: 'name',
                Cell: ({ original: user }) => <UserCell user={user} />
              },
              {
                Header: 'Master Account',
                accessor: 'masterAccount.id',
                Cell: ({ original: user }) => <UserCell user={user.masterAccount} />
              },
              {
                Header: 'Company',
                accessor: 'company.name',
                Cell: ({ original: user }) => <CompanyCell company={user.company} />
              },
              {
                Header: 'Role',
                accessor: 'role',
                Cell: ({ original: user }) => Array.from(user.roles || []).map(role => <span key={role} key={role} className="badge badge-primary">{role}</span>),
                Filter: props => <SelectFilter {...props} data={userAccountTypeOptions.map(it => ({ id: it.value, name: it.label }))} />
              },
              {
                Header: 'Actions',
                sortable: false,
                filterable: false,
                accessor: 'id',
                Cell: ({ original: user }) => (
                  <div>
                    <IconButton onClick={() => loginAsUser({ variables: { userId: user.id } })} title="Login as User" disabled={logging}>
                      <Icon fontSize="small">streetview</Icon>
                    </IconButton>
                    <IconButton onClick={() => history.push(`${match.url}/${user.id}/details/profile`)} title="Profile User">
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                    <IconButton onClick={() => history.push(`${match.url}/${user.id}/details/billing`)} title="Billing User">
                      <Icon fontSize="small">credit_card</Icon>
                    </IconButton>
                    <IconButton onClick={() => history.push(`${match.url}/${user.id}/apply-coupon`)} title="Apply Coupon">
                      <Icon fontSize="small">attach_money</Icon>
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={confirm(() => remove({ variables: { id: user.id } }), {
                        title: 'User Remove',
                        description: 'Are you sure you want to delete this User?'
                      })}
                      title="Remove User"
                      disabled={loadingRemove}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            ]}
            actions={[
              <Button key="create" variant="contained" color="primary" onClick={() => history.push(`${match.url}/create`)}>
                <Icon>add</Icon>
                Create User
              </Button>
            ]}
            deleteMany={false}
          />
        )}
      </Mutation>
    </React.Fragment>
  )
}

const styles = theme => ({
  root: {}
})

const enhance = compose(withConfirm, withStyles(styles))

export default enhance(Users)
