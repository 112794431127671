/** @format */

import gql from 'graphql-tag'

export const trainingActivityFragment = gql`
  fragment trainingActivity on TrainingActivity {
    action
    data
  }
`

export const getTrainingActivities = gql`
  query getTrainingActivities($userId: ID, $courseId: ID, $lessonId: ID, $dates: [String], $skip: Int, $limit: Int, $sort: [String]) {
    trainingActivities(userId: $userId, courseId: $courseId, lessonId: $lessonId, dates: $dates, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        action
        data
        createdAt
      }
      count
      pages
    }
  }
`

export const getTrainingReport = gql`
  query getTrainingReport($name: String, $userId: ID, $courseId: ID, $skip: Int, $limit: Int, $sort: [String]) {
    trainingReport(name: $name, userId: $userId, courseId: $courseId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        user {
          id
          name
          email
          phone
        }
        lessonsCompleted
        lessonsReleasedDue
        lessonsDue
      }
      count
      pages
    }
  }
`

export const getTrainingReportUser = gql`
  query getTrainingReportUser($userId: ID, $courseId: ID, $skip: Int, $limit: Int, $sort: [String]) {
    trainingReportUser(userId: $userId, courseId: $courseId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        order
        released
        completed
        completedAt
        quizPoints
        quizPointsUser
      }
      count
      pages
    }
  }
`

export const sendLink = gql`
  mutation sendLink($lessonId: String!) {
    sendLink(lessonId: $lessonId) {
      id
    }
  }
`

export const sendTrainingActivity = gql`
  mutation sendTrainingActivity($data: TrainingActivityInput!) {
    sendTrainingActivity(data: $data) {
      id
      action
      data
      createdAt
    }
  }
`
