import React from 'react'
import { useQuery } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import {TextField, Checkbox, DateTimeField, PhoneNumber} from 'lib/form/fields'
import { required, length } from 'lib/form/validators'
import moment from 'moment-timezone'

import { getCampaign, updateCampaign } from 'api/campaigns'
import { getLists } from "api/lists";
import {timezoneOptions} from "../../data/options";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";


const CampaignUpdate = ({ classes, match }) => {
  const { loading, data: { campaign = {} } = {} } = useQuery(getCampaign, { variables: match.params })
  const { loading2, data: { lists: { data: lists = [] } = {} } = {} } = useQuery(getLists, { variables: { limit: 0 } })

  if(!campaign.triggerTimezone)
    campaign.triggerTimezone = moment.tz.guess()
  return (
    !loading && !loading2 && (
      <FormDialog
        title="Update Campaign"
        form="CampaignUpdate"
        mutation={[updateCampaign]}
        initialValues={campaign}
        btnSubmitText="Save"
        success="Campaign updated successfully"
      >
        {({ doc, invalid, submitting }) => (
          <React.Fragment>
            <Field name="name" component={TextField} label="Name" validate={[required()]} />
            <Field name="status" component={TextField} label="Status" validate={[required()]} options={['Pending', 'Active', 'Stopped']} select />
            <Field name="listId" component={TextField} label="List" validate={[required()]} options={lists.map(l=>({label: l.name, value: l.id}))} select />
            <Field name="link" component={TextField} label="Link"/>
            <Field name="replyPhone" component={PhoneNumber} label="Reply Phone Number" />
            <Field name="sendNow" component={Checkbox} label="Send Now" />
            {!doc.sendNow && <fieldset>
              <legend>Sending Date & Time Options</legend>
              <Field name="triggerDate" component={DateTimeField} dateOnly label="Send Date" />
              <Field name="triggerTime" component={DateTimeField} timeOnly label="Send Time" />
              <Field name="triggerTimezone" component={TextField} label="Timezone" validate={[required()]} options={timezoneOptions} select />
            </fieldset>}
            <fieldset>
              <legend>Messages</legend>
              <small>Possible merge variables: [firstName]</small>
              <Field name="message1" component={TextField} multiline inputProps={{ maxLength: 160 }} label="Message #1" validate={[required()]} />
              <small style={{float:'right'}}>{(doc.message1||'').length}/160</small>
              <Field name="message2" component={TextField} multiline inputProps={{ maxLength: 160 }}  label="Message #2 (optional)" />
              <small style={{float:'right'}}>{(doc.message2||'').length}/160</small>
              <Field name="message3" component={TextField} multiline inputProps={{ maxLength: 160 }}  label="Message #3 (optional"  />
              <small style={{float:'right'}}>{(doc.message3||'').length}/160</small>
            </fieldset>
          </React.Fragment>
        )}
      </FormDialog>
    )
  )
}



export default CampaignUpdate
