/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Commissions from 'components/Commissions/Commissions'
///import CommissionCreate from 'components/Commissions/CommissionCreate'
//import CommissionUpdate from 'components/Commissions/CommissionUpdate'

export default ({ match }) => (
  <React.Fragment>
    <Switch>
      <Route path={`${match.path}`} component={Commissions} />
    </Switch>
    {/*<Route path={`${match.path}/create`} component={CommissionCreate} />
    <Route path={`${match.path}/:commissionId/update`} component={CommissionUpdate} />*/}
  </React.Fragment>
)
