/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { FormDialog, Field, FormGroup, FormSection, Address, CompanyInformation } from 'lib/form'
import { TextField, PhoneNumber } from 'lib/form/fields'
import { required, length, email } from 'lib/form/validators'

import { createUser, getDataCreationUser } from 'api/users'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

import { userAccountTypeOptions } from 'data/options'

const UserCreate = ({ classes, match }) => (
  <Query query={getDataCreationUser}>
    {({ data: { products: { data: products } = {}, companies: { data: companies } = {} } = {}, loading }) =>
      !loading && (
        <FormDialog
          title="Create User"
          form="UserCreate"
          mutation={[createUser, { refetchQueries: ['getUsers'] }]}
          initialValues={{ accountType: 'client' }}
          btnSubmitText="Create"
          success="User created successfully"
        >
          {({ doc, invalid, submitting }) => (
            <React.Fragment>
              <Field
                name="accountType"
                component={TextField}
                label="Account Type"
                options={userAccountTypeOptions}
                validate={[required()]}
                select
              />

              <FormGroup title="Contact Information">
                <Grid spacing={1} container>
                  <Grid xs={6} item>
                    <Field name="firstName" component={TextField} label="First Name" validate={[required()]} margin="none" />
                  </Grid>
                  <Grid xs={6} item>
                    <Field name="lastName" component={TextField} label="Last Name" validate={[required()]} margin="none" />
                  </Grid>
                </Grid>
                <Field name="email" component={TextField} label="User Email" validate={[required(), email()]} />
                <Grid spacing={1} container>
                  <Grid xs={6} item>
                    <Field name="phone" component={PhoneNumber} label="Cell Phone" validate={[required()]} margin="none" />
                  </Grid>
                  <Grid xs={6} item>
                    <Field name="workPhone" component={PhoneNumber} label="Work Phone" margin="none" />
                  </Grid>
                </Grid>
              </FormGroup>

              <FormSection name="">
                <CompanyInformation doc={doc} companies={companies} />
              </FormSection>

              <FormSection name="mailAddress">
                <Address title="Address" address={doc.mailAddress} />
              </FormSection>
            </React.Fragment>
          )}
        </FormDialog>
      )
    }
  </Query>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(UserCreate)
