/** @format */

import _ from 'lodash'
import { AccessControlPlus } from 'accesscontrol-plus-sync'
import Roles from './Roles'

class AccessControl extends AccessControlPlus {
  initialize(context) {
    this.context = context
  }

  checkAccess(scope, context, user) {
    user = user || this.context.user
    if (!user || !user.id) {
      throw new Error('you must be logged in')
    }
    const denyAccess = this.denyAccess(scope, context, user)
    if (denyAccess) {
      throw new Error(`Your token does not have required scope "${scope}"`)
    }
    return true
  }

  denyAccess(scope, context, user) {
    return this.disallow(this.getRolesFromUser(user), scope, context)
  }

  hasAccess(scope, context, user) {
    return this.allow(this.getRolesFromUser(user), scope, context)
  }

  allow(roles, scope, context) {
    const permission = this.can(roles, scope, context)
    if (permission.granted) {
      return true
    }
    return false
  }

  disallow(roles, scope, context) {
    const permission = this.can(roles, scope, context)
    if (permission.granted) {
      return false
    }
    return true
  }

  getRolesFromUser(user) {
    user = user || this.context.user
    return (user && _.compact(_.union(user.roles && user.roles.length>0?[]:[user.accountType], [user.role], user.roles))) || Roles.PUBLIC
  }
}

export default new AccessControl()
