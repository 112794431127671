/** @format */

const getSignedRequest = file =>
  fetch(`${process.env.REACT_APP_API_URL}/v1/s3/sign`, {
    method: 'post',
    body: JSON.stringify({ fileName: file.name, fileType: file.type }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`)
    }
    return response.json()
  })

const uploadFile = (file, signedUrl, url) =>
  fetch(signedUrl, {
    method: 'PUT',
    body: file
  }).then(response => {
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`)
    }
    return url
  })

const uploadToS3 = file =>
  getSignedRequest(file)
    .then(({ signedUrl, url }) => uploadFile(file, signedUrl, url))
    .catch(err => {
      console.error(err)
      return null
    })

export default uploadToS3
