/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query, Mutation, useApolloClient } from 'react-apollo'
import moment from 'moment'
import CheckboxTable from 'lib/tables/CheckboxTable'
import withConfirm from 'lib/withConfirm'

import { getLessons, getDataLessons, removeLesson, updateLessonOrder } from 'api/lessons'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import Paper from '@material-ui/core/Paper'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import arrayMove from 'array-move'


import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc'
import {useMutation} from "react-apollo/lib/index";
const DragHandle = sortableHandle(() => <TableCell style={{cursor:'pointer'}} width={50} title={'Drag to change the order'} label={'Drag to change the order'} alt={'Drag to change the order'}>
  <Icon>drag_indicator</Icon></TableCell>);

const SortableItem = SortableElement(({lesson, course, match, remove, loadingRemove, confirm, history}) => (
  <TableRow>
    <DragHandle />
    <TableCell>
      {lesson.order+1}. {lesson.name} {lesson.featured && <span className="badge badge-primary">Featured</span>}
    </TableCell>
    <TableCell>
      {lesson.paid ? 'Yes' : 'No'}
    </TableCell>
    <TableCell>
      {Array.from(lesson.plans || []).map(id => {
        const plan = course.plans.find(plan => plan.id === id)
        return plan ? <div key={plan.id}>{plan.name}</div> : null
      })}
    </TableCell>
    <TableCell>
      {lesson.released ? (
        <span>Released on {moment(lesson.releasedAt).format('LL')}</span>
      ) : (
        <span>Schedule on {moment(lesson.sheduleAt).format('LL')}</span>
      )}
    </TableCell>
    <TableCell>
      <IconButton onClick={() => history.push(`${match.url}/${lesson.id}/update`)} title="Edit Lesson">
        <Icon fontSize="small">edit</Icon>
      </IconButton>
      <IconButton onClick={() => history.push(`${match.url}/${lesson.id}/quiz`)} title="Test Quiz Lesson">
        <Icon fontSize="small">help</Icon>
      </IconButton>
      <IconButton
        color="secondary"
        onClick={confirm(() => remove({ variables: { id: lesson.id } }), {
          title: 'Lesson Remove',
          description: 'Are you sure you want to delete this Lesson?'
        })}
        title="Remove Lesson"
        disabled={loadingRemove}
      >
        <Icon fontSize="small">delete</Icon>
      </IconButton>
    </TableCell>
  </TableRow>
  )
)

const SortableList = SortableContainer(({items, course, ...props}) => {
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component='th'>&nbsp;</TableCell>
            <TableCell component='th'>Name</TableCell>
            <TableCell component='th'>Paid</TableCell>
            <TableCell component='th'>Plans</TableCell>
            <TableCell component='th'>Release</TableCell>
            <TableCell component='th'>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((lesson, index) => {
            console.log(lesson.order || index)
            return (
              <SortableItem {...props} course={course} key={`item-${lesson.id}`} index={lesson.order || index} lesson={lesson} />
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
})

const Lessons = ({ classes, history, match, confirm }) => {

    const client = useApolloClient()
    const [update] = useMutation(updateLessonOrder, { refetchQueries: ['getLessons'] })
    const runQuery = async () => {

      setLoading(true)

      const {data: {course} = {}} = await client.query({
        query: getDataLessons,
        variables: match.params
      })
      const {data: {lessons: {data: lessons} = {}} = {}} = await client.query({
        query: getLessons,
        variables: {courseId: course.id, limit: 1000, sort: [['order', "1"], ['released', "-1"], ['releasedAt', "1"]]}
      })

      setCourse(course)
      setLessons(lessons)
      setLoading(false)
    }

    const onSortEnd = ({oldIndex, newIndex})=>{
      if(oldIndex!==newIndex) {
        let newLessons = arrayMove(lessons, oldIndex, newIndex).map((v, i)=>({...v, order: i}))
        setLessons(newLessons)
        update({variables: {courseId: course.id, data: newLessons.map(a=>({id: a.id, order: a.order}))}})
      }
    }

    const [lessons, setLessons] = React.useState([]);
    const [course, setCourse] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
      runQuery()
    }, [lessons.length])

    let remove = null
    let loadingRemove = null

    if(loading)
      return "Loading..."
    else
      return (
        <div>
          <div className='pb-3 text-right'>
            <Button
              key="create"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => history.push(`${match.url}/create`)}
            >
              <Icon>add</Icon>
              Create Lesson
            </Button>
          </div>
          <SortableList history={history} confirm={confirm} match={match} remove={remove} loadingRemove={loadingRemove} course={course} items={lessons} onSortEnd={onSortEnd} useDragHandle />
        </div>
      )

}


/*const LessonsOld = ({ classes, history, match, confirm }) => (
  <Query query={getDataLessons} variables={match.params}>
    {({ data: { course } = {}, loading }) =>
      !loading && (
        <React.Fragment>
          <Mutation mutation={removeLesson} refetchQueries={['getLessons']}>
            {(remove, { loading: loadingRemove }) => {
              return (
                <Query query={getLessons} variables={{courseId: course.id, limit: 1000, sort: [['order', "1"], ['released', "-1"], ['releasedAt', "1"]]}}>
                  {({ data: { lessons: { data: lessons } = {} } = {}, loading }) => {
                    if(loading)
                      return "Loading..."
                    else
                      return <SortableList history={history} confirm={confirm} match={match} remove={remove} loadingRemove={loadingRemove} course={course} items={lessons} onSortEnd={onSortEnd} useDragHandle />
                  }}
                </Query>
              )
              return (
                <CheckboxTable
                  dndSortable={true}
                  query={getLessons}
                  variables={{ courseId: match.params.courseId }}
                  initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'q',
                      Cell: ({ original: lesson }) => (
                        <div>
                          {lesson.name} {lesson.featured && <span className="badge badge-primary">Featured</span>}
                        </div>
                      )
                    },
                    {
                      Header: 'Paid',
                      id: 'paid',
                      accessor: lesson => (lesson.paid ? 'Yes' : 'No')
                    },
                    {
                      Header: 'Plans',
                      accessor: 'plans',
                      Cell: ({ original: lesson }) =>
                        Array.from(lesson.plans || []).map(id => {
                          const plan = course.plans.find(plan => plan.id === id)
                          return plan ? <div key={plan.id}>{plan.name}</div> : null
                        })
                    },
                    {
                      Header: 'Release',
                      accessor: 'released',
                      Cell: ({ original: lesson }) => (
                        <div>
                          {lesson.released ? (
                            <span>Released on {moment(lesson.releasedAt).format('LL')}</span>
                          ) : (
                            <span>Schedule on {moment(lesson.sheduleAt).format('LL')}</span>
                          )}
                        </div>
                      )
                    },
                    {
                      Header: 'Actions',
                      sortable: false,
                      filterable: false,
                      accessor: 'id',
                      Cell: ({ original: lesson }) => {
                        return (
                          <div>
                            <IconButton onClick={() => history.push(`${match.url}/${lesson.id}/update`)} title="Edit Lesson">
                              <Icon fontSize="small">edit</Icon>
                            </IconButton>
                            <IconButton onClick={() => history.push(`${match.url}/${lesson.id}/quiz`)} title="Test Quiz Lesson">
                              <Icon fontSize="small">help</Icon>
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={confirm(() => remove({ variables: { id: lesson.id } }), {
                                title: 'Lesson Remove',
                                description: 'Are you sure you want to delete this Lesson?'
                              })}
                              title="Remove Lesson"
                              disabled={loadingRemove}
                            >
                              <Icon fontSize="small">delete</Icon>
                            </IconButton>
                          </div>
                        )
                      }
                    }
                  ]}
                  actions={[
                    <Button
                      key="create"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => history.push(`${match.url}/create`)}
                    >
                      <Icon>add</Icon>
                      Create Lesson
                    </Button>
                  ]}
                  deleteMany={false}
                />
              )
            }}
          </Mutation>
        </React.Fragment>
      )
    }
  </Query>
)
*/
const styles = theme => ({
  root: {}
})

export default compose(
  withConfirm,
  withStyles(styles)
)(Lessons)
