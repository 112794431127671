import React from 'react'
import moment from "moment/moment"
import CheckboxTable from 'lib/tables/CheckboxTable'
import _ from 'lodash'
import { getVisitsSessions, addVisitNote } from 'api/visitors'
import {Field, Form} from "../../lib/form";
import {TextField} from "../../lib/form/fields";
import {required} from "../../lib/form/validators";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";

export default ({ match, location, history, filter={sessionUrl: {exists: true}} }) => {
  console.log(filter)
  const [sessionUrl, setSessionUrl] = React.useState(null)
  const [visit, setVisit] = React.useState(null)
  return (
    <React.Fragment>
      <div className="p-4">
        {sessionUrl && <div className='modal fade show' style={{display: "block"}}>
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-body text-center overflow-auto" style={{maxHeight: '90vh'}}>
                <h4>Session Record Viewer</h4>
                {visit.notes && visit.notes.length>0 &&<div className={'text-left'}>
                  <h5>Notes:</h5>
                  <List>
                    {Array.from(visit.notes || []).map(note=>(
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar alt={note.author.name} src={note.author.avatar} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={note.author.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {(new Date(note.createdAt)).toLocaleString()}
                              </Typography>
                              <p className='lead'>{note.body}</p>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>}  <iframe src={sessionUrl} style={{width:'100%', height: '100%', minWidth: 1100, minHeight: 667}}></iframe>

                <Form
                  form="VisitUpdate"
                  mutation={[addVisitNote]}
                  refetchQueries={['getVisitsSessions']}
                  initialValues={visit}
                  success="Note Added"
                  onSubmit={({ data: { body }, mutation, ...props })=> {
                    return mutation({ variables: { visitId: visit.id, body} })
                  }}
                  onSubmitSuccess={(result, dispatch, { enqueueSnackbar, reset, ...rest }) =>{
                    enqueueSnackbar("Note Added", { variant: 'success' })
                    reset()
                    setVisit(result.data.addVisitNote)
                  }}
                >
                  {({doc, submit, submitting}) => (
                    <React.Fragment>
                <Field name="body" component={TextField} label="Note" multiline rows={2} validate={[required()]} style={{width:'85%'}} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  disabled={submitting}
                  className={'mt-3'}
                >
                  <Icon>add</Icon>
                  Add Note
                </Button>
                    </React.Fragment>
                )}
                </Form>
                <div className={'btn btn-secondary mt-3'} onClick={()=>{ setVisit(null); setSessionUrl(null)}}>Close</div>
              </div>
            </div>
          </div>
        </div>
        }
        <h3>Session Records</h3>
        <CheckboxTable
          query={getVisitsSessions}
          initialVariables={{ ...filter, sorted: [{ id: 'createdAt', desc: true }] }}
          variables={filter}
          columns={[
            {
              Header: 'IDs',
              accessor: 'name',
              Cell: ({ original: visit }) => (
                <span>{visit.id}</span>
              )
            },
            {
              Header: 'Date & Time',
              accessor: 'createdAt',
              Cell: ({ original: visit }) => (
                <span>{new Date(visit.createdAt).toLocaleString()}</span>
              )
            },
            {
              Header: 'Location',
              accessor: 'location'
            },
            {
              Header: 'Status',
              accessor: 'status'
            },
            {
              Header: 'Scroll Rate',
              accessor: 'scrollRate'
            },
            {
              Header: 'Time On Page',
              accessor: 'timeOnPage',
              Cell: ({ original: visit }) => (
                <div>{visit.timeOnPage?moment(visit.timeOnPage).utc().format('HH:mm:ss'):"N/A"}</div>
              )
            },
            {
              Header: 'Actions',
              sortable: false,
              filterable: false,
              accessor: 'id',
              Cell: ({ original: visit }) => (
                <div className={'pull-right'}>
                  {visit.sessionUrl && <div className={'btn btn-primary btn-sm mr-1'} onClick={()=>{ setVisit(visit); setSessionUrl(`${process.env.REACT_APP_GATSBY_URL}/lp/lp-replay?key=${visit.id}`)}}>Watch Session Record</div>}
                </div>
              )
            }
          ]}
          deleteMany={false}
        />

      </div>
    </React.Fragment>
  )
}
