/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

//import CreditCardAdd from 'components/Sources/CreditCardAdd'
import Licenses from './Licenses'
import LicenseAdd from './LicenseAdd'
import LicenseUpdate from './LicenseUpdate'

export default ({ match }) => (
  <Container maxWidth="lg">
    <Box my={2}>
      <Route path={`${match.path}/add`} component={LicenseAdd} />
      <Route path={`${match.path}/:licenseId/update`} component={LicenseUpdate} />
      <Switch>
        <Route path={`${match.path}`} component={Licenses} />
      </Switch>
    </Box>
  </Container>
)
