/** @format */

import gql from 'graphql-tag'

export const getActivityLogs = gql`
  query getActivityLogs($dateFrom: DateTime, $dateTo: DateTime, $skip: Int, $limit: Int, $sort: [String]) {
    activityLogs(dateFrom: $dateFrom, dateTo: $dateTo, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        userId
        userLabel
        userAvatar
        objectId
        objectType
        actionType
        objectLabel
        eventDate
        description
        ip
      }
      count
      pages
    }
  }
`

