/** @format */

import React from 'react'

import Grid from '@material-ui/core/Grid'

import ActivityFeed from 'components/Widgets/ActivityFeed'
import Alerts from 'components/Widgets/Alerts'
import NewMessages from 'components/Widgets/NewMessages'
import Requests from 'components/Widgets/Requests'

const Dashboard = () => (
  <div className='p-5'>
    <Grid spacing={1} container>
      <Grid xs={8} item>
        <Grid spacing={1} container>
          <Grid xs={6} item>
            {/*<Alerts />*/}
          </Grid>
          <Grid xs={6} item>
            {/*<NewMessages />*/}
          </Grid>
        </Grid>
        <br />
        {/*<Requests />*/}
      </Grid>
      <Grid xs={4} item>
        <ActivityFeed />
      </Grid>
    </Grid>
  </div>
)

export default Dashboard
