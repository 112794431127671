/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Nav from 'components/Nav'

import UserLicenses from 'components/UserLicenses'
import EmployeeProfile from './EmployeeProfile'
import EmployeeCompany from './EmployeeCompany'
import EmployeeNotifications from './EmployeeNotifications'

export default ({ match, history, location }) => (
  <React.Fragment>
    <Nav
      color="primary"
      items={[
        { key: 'profile', title: 'Profile', to: `${match.url}/profile` },
        { key: 'company', title: 'Company', to: `${match.url}/company` },
        { key: 'notifications', title: 'Notifications', to: `${match.url}/notifications` },
        { key: 'licenses', title: 'Licenses', to: `${match.url}/licenses` }
      ]}
      activeKey={location.pathname.split('/')[5]}
    />
    <Switch>
      <Route path={`${match.path}/profile`} component={EmployeeProfile} />
      <Route path={`${match.path}/company`} component={EmployeeCompany} />
      <Route path={`${match.path}/notifications`} component={EmployeeNotifications} />
      <Route path={`${match.path}/licenses`} component={UserLicenses} />
    </Switch>
  </React.Fragment>
)
