/** @format */

import { useState, useCallback } from 'react'

export default ({ questions, onComplete = () => true }) => {
  const [numQuestion, setNumQuestion] = useState(0)
  const [answers, setAnswers] = useState([])
  const [attempts, setAttempts] = useState(0)
  const totalQuestions = questions.length
  const currentQuestion = questions[numQuestion]

  // data {questionId, answer}
  const addAnswer = data => {
    let newAnswers = [...answers]
    const isExists = newAnswers.find(item => item.questionId === data.questionId)
    return isExists ? newAnswers.map(item => (item.questionId === data.questionId ? { ...item, ...data } : item)) : newAnswers.concat(data)
  }

  const next = useCallback(
    answer => {
      let newAnswers = addAnswer(answer)
      setAnswers(newAnswers)
      if (numQuestion < totalQuestions) {
        setNumQuestion(numQuestion + 1)
      }
      if (numQuestion + 1 >= totalQuestions) {
        onComplete({ answers: newAnswers, questions })
      }
    },
    [numQuestion, answers]
  )

  return {
    numQuestion,
    setNumQuestion,
    currentQuestion,
    totalQuestions,
    answers,
    attempts,
    setAnswers,
    addAnswer,
    setAttempts,
    next
  }
}
