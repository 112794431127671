import React from 'react'
import compose from 'recompose/compose'
import { Switch, Route, useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from 'react-apollo'
import withConfirm from 'lib/withConfirm'

import { getList} from 'api/lists'
import {getContacts, removeContact} from 'api/contacts'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import ImportIcon from '@material-ui/icons/Publish'
import DeleteIcon from '@material-ui/icons/Delete'
import Avatar from 'lib/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import BackIcon from '@material-ui/icons/ArrowBack'

import ListItemAdd from './ListItemAdd'
import ListItemImport from './ListItemImport'

const ListContants = ({ confirm, listId }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { loading, data: { list = {} } = {} }= useQuery(getList, { variables: { listId } })
  const { loading2, data: { contacts: { data: contacts = [] } = {} } = {} }= useQuery(getContacts, { variables: { listId } })
  const [remove] = useMutation(removeContact, { refetchQueries: ['getLists', 'getContacts'] })

  return (
    !loading && !loading2 && (
      <Paper>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <strong>Targets</strong>
                <div>
                  <IconButton onClick={() => history.push(`${match.url}/${listId}/add`)} edge="end" aria-label="add">
                    <AddIcon />
                  </IconButton>
                  <IconButton onClick={() => history.push(`${match.url}/${listId}/import`)} edge="end" aria-label="import">
                    <ImportIcon />
                  </IconButton>
                </div>
              </Box>
              <hr/>
            </ListSubheader>
          }
        >
          {contacts.map(target => {
            return (
              <ListItem
                key={target.id}
              >
                <ListItemAvatar>
                  <Avatar email={target.email} name={target.name}/>
                </ListItemAvatar>
                <ListItemText primary={`${target.firstName} ${target.lastName}`} secondary={target.phone} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="secondary"
                    onClick={confirm(() => remove({ variables: { id: target.id } }), {
                      title: 'Target Remove',
                      description: 'Are you sure you want to delete this Target?'
                    })}
                    title="Remove Target"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
          {contacts.length === 0 && <Typography align="center">No Targets</Typography>}
        </List>
        <Switch>
          <Route path={`${match.path}/:listId/add`} component={ListItemAdd} />
          <Route path={`${match.path}/:listId/import`} component={ListItemImport} />
        </Switch>
      </Paper>
    )
  )
}

export default compose(withConfirm)(ListContants)
