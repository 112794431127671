import React from 'react'
import Markdown from 'react-markdown'

const defaultData = {
  scheduleHeadline: 'Speak with a ChatFortress Specialist',
  scheduleDescription: `
Discover how ChatFortress can help your company reduce your risk and exposure without installing complicated systems that require your team to work harder. Schedule time with your ChatFortress Specialist now. </br> Call [307-999-7755](tel:3079997755)
`,
  scheduleLink: 'https://chatfortress.com/reportcarddiscoverysession',
  scheduleBtnText: 'Schedule a Time!',
  btnText1: 'Chat with Cybersecurity Specialist',
  btnText2: 'Schedule Your Discovery Session Now!'
}

export default ({ data }) => {
  if (!data) {
    data = defaultData
  }

  return (
    <section className="pb-4">
      <div className="container">
        <div className="alert alert-primary py-4">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-sm-3 text-center">
              <img alt="date" className="img-fluid" src="https://chatfortress.s3.amazonaws.com/img/date.png" />
            </div>
            <div className="col-12 col-sm-6">
              <h2>
                <strong>{data.scheduleHeadline}</strong>
              </h2>
              <Markdown escapeHtml={false}>{data.scheduleDescription}</Markdown>
            </div>
            <div className="col-12 col-sm-3 text-center">
              <a className="btn btn-primary btn-lg" href={data.scheduleLink} target="_blank" rel="noopener noreferrer">
                {data.scheduleBtnText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
