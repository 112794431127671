/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation } from 'react-apollo'
// import withSweetAlert from 'lib/withSweetAlert'
// import withUserContext from 'lib/withUserContext'
// import numeral from 'numeral'

import { getProposals, sendProposal, removeProposal, onRemoveProposal } from 'api/proposals'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import CheckboxTable from 'lib/tables/CheckboxTable'

const Proposals = ({ classes, history, match }) => (
  <React.Fragment>
    <Mutation mutation={removeProposal}>
      {(remove, { loading: loadingRemove }) => (
        <Mutation mutation={sendProposal}>
          {(send, { loading: loadingSend }) => (
            <CheckboxTable
              query={getProposals}
              variables={{ ...match.params }}
              initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name'
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Cell: ({ original: proposal }) => (
                    <span>{proposal.canceled ? 'Canceled' : proposal.userId ? 'Accepted' : proposal.status}</span>
                  )
                }/*,
                {
                  Header: 'Email System',
                  accessor: 'emailSystem'
                }*/,
                {
                  Header: 'Type',
                  accessor: 'type',
                  Cell: ({ original: proposal }) => {
                    if(proposal.type==='template')
                      return proposal.template
                    return proposal.type
                  }
                },
                {
                  Header: 'Quantity',
                  accessor: 'emailCount',
                  Cell: ({ original: proposal }) => {
                    if(proposal.template){
                      return <div>
                        {proposal.template==='CybersecuritySystem' && <div><small>Setup Fee:</small> $250</div>}
                        {proposal.setupFee>0 && <div><small>Additional Setup Fee:</small> ${proposal.setupFee}</div>}
                        {proposal.additionalFee>0 && <div><small>Additional Fee:</small> ${proposal.additionalFee}</div>}
                        {proposal.additionalUnitAmount>0 && <div><small>BYOD Additional Units:</small> {proposal.additionalUnitAmount}</div>}
                        {proposal.guardianAmount>0 && <div><small>Guardian add-on:</small> {proposal.guardianAmount}</div>}
                        {proposal.emailCount>0 && <div><small>Units:</small> {proposal.emailCount}</div>}
                      </div>
                    }
                    return proposal.emailCount
                  }
                },
                {
                  Header: 'Actions',
                  sortable: false,
                  filterable: false,
                  accessor: 'id',
                  Cell: ({ original: proposal }) => (
                    <div>
                      {[
                        proposal.userId ? (
                          <IconButton
                            key="print"
                            onClick={async () => {
                              //window.open(`/proposal/${proposal.id}?print=${proposal.userToken}`)
                              let res = await fetch(`${process.env.REACT_APP_API_URL}/v1/get-pdf`, {
                                method: 'post',
                                body: JSON.stringify({
                                  url: `https://app.chatfortress.com/proposal/${proposal.id}?print=${proposal.userToken}`
                                }),
                                headers: {
                                  Accept: 'application/json',
                                  'Content-Type': 'application/json'
                                }
                              })
                                .then(r => r.json())
                                .catch(e => console.log(e))
                              //console.log('result', res)
                              let link = document.createElement('a')
                              link.href = 'data:application/pdf;base64,' + res.pdf
                              link.download = 'proposal.pdf'
                              link.click()
                            }}
                            title="Open Proposal"
                            disabled={loadingSend}
                          >
                            <Icon fontSize="small">print</Icon>
                          </IconButton>
                        ) : (
                          <div key="print" style={{ width: 42, height: 42, display: 'inline-block' }}>
                            &nbsp;
                          </div>
                        ),
                        <IconButton key="edit" onClick={() => history.push(`${match.url}/${proposal.id}/update`)} title="Edit Proposal">
                          <Icon fontSize="small">edit</Icon>
                        </IconButton>,
                        <IconButton
                          key="send"
                          onClick={() => send({ variables: { id: proposal.id } })}
                          title="Send Proposal"
                          disabled={loadingSend}
                        >
                          <Icon fontSize="small">send</Icon>
                        </IconButton>,
                        <IconButton
                          key="link"
                          onClick={() => window.open(`/proposal/${proposal.id}`)}
                          title="Open Proposal"
                          disabled={loadingSend}
                        >
                          <Icon fontSize="small">link</Icon>
                        </IconButton>,
                        <IconButton
                          key="delete"
                          color="secondary"
                          onClick={() => {
                            if (window.confirm('Are you sure you want to delete this Proposal?')) {
                             remove({ variables: { id: proposal.id }, update: onRemoveProposal })
                            }
                          }}
                          title="Remove Proposal"
                          disabled={loadingRemove}
                        >
                          <Icon fontSize="small">delete</Icon>
                        </IconButton>
                      ]}
                    </div>
                  )
                }
              ]}
              actions={[
                <Button
                  key="create"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push(`${match.url}/create`)}
                >
                  <Icon className={classes.leftIcon}>add</Icon>
                  Create Proposal
                </Button>
              ]}
              // onDeleteAll={selection => removeMany({ variables: { proposalIds: selection } })}
              deleteMany={false}
            />
          )}
        </Mutation>
      )}
    </Mutation>
  </React.Fragment>
)

const styles = theme => ({
  root: {},
  button: {
    marginLeft: 7
  }
})

export default compose(withStyles(styles))(Proposals)
