/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import Form from './Form'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const FormCard = ({ classes, title, subtitle, onComplete, success, buttons, btnSubmitText = 'Save', children, ...formProps }) => (
  <Form
    onSubmitSuccess={(result, dispatch, { enqueueSnackbar }) => {
      if (success) {
        enqueueSnackbar(success, { variant: 'success' })
      }
      return onComplete ? onComplete(result, dispatch, { enqueueSnackbar }) : result
    }}
    {...formProps}
  >
    {props => (
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            {subtitle}
          </Typography>
          <div>{children(props)}</div>
        </CardContent>
        <CardActions>
          {buttons
            ? buttons(props)
            : [
                <Button color="primary" variant="contained" onClick={props.submit} key="submit" disabled={props.submitting}>
                  {props.submitting ? 'Submitting...' : btnSubmitText}
                </Button>
              ]}
        </CardActions>
      </Card>
    )}
  </Form>
)

const styles = () => ({
  card: {
    minWidth: 275
  },
  subtitle: {
    fontSize: 14
  }
})

export default compose(
  withRouter,
  withStyles(styles)
)(FormCard)
