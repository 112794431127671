import React from 'react'
import { useQuery } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, length } from 'lib/form/validators'

import { getList, updateList } from 'api/lists'

import withStyles from '@material-ui/core/styles/withStyles'

const ListUpdate = ({ classes, match }) => {
  const { loading, data: { list = {} } = {} } = useQuery(getList, { variables: match.params })

  return (
    !loading && (
      <FormDialog
        title="Update List"
        form="ListUpdate"
        mutation={[updateList]}
        initialValues={list}
        btnSubmitText="Save"
        success="List updated successfully"
      >
        {({ doc, invalid, submitting }) => (
          <React.Fragment>
            <Field name="name" component={TextField} label="Name" validate={[required()]} />
          </React.Fragment>
        )}
      </FormDialog>
    )
  )
}

const styles = () => ({
  root: {}
})

export default withStyles(styles)(ListUpdate)
