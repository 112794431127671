/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField, Checkbox } from 'lib/form/fields'
import { required, length } from 'lib/form/validators'
import {acl} from 'lib/acl'

import { createCourse, getDataCreationCourse } from 'api/courses'

import withStyles from '@material-ui/core/styles/withStyles'

const CourseCreate = ({ classes, match }) => (
  <Query query={getDataCreationCourse}>
    {({ data: { products: { data: products } = {} } = {}, loading }) =>
      !loading && (
        <FormDialog
          title="Create Course"
          form="CourseCreate"
          mutation={[createCourse, { refetchQueries: ['getCourses'] }]}
          initialValues={{ paid: false }}
          btnSubmitText="Create"
          success="Course created successfully"
        >
          {({ doc, invalid, submitting }) => (
            <React.Fragment>
              <Field name="productId" component={TextField} label="Product" options={products} validate={[required()]} select />
              <Field name="roles" component={TextField} label="Roles" options={Object.keys(acl.roles).map(r=>({label: r, value: r}))} validate={[required()]} select multiple />
              <Field name="name" component={TextField} label="Course Name" validate={[required(), length({ min: 2 })]} />
              <Field name="description" component={TextField} label="Course Description" multiline />
              <Field name="paid" component={Checkbox} label="Paid Course" />
            </React.Fragment>
          )}
        </FormDialog>
      )
    }
  </Query>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(CourseCreate)
