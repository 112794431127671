/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation } from 'react-apollo'
import CheckboxTable from 'lib/tables/CheckboxTable'
import withConfirm from 'lib/withConfirm'
import moment from 'moment'

import { getCourses, removeCourse } from 'api/courses'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const Courses = ({ classes, history, match, confirm }) => (
  <React.Fragment>
    <Mutation mutation={removeCourse} refetchQueries={['getCourses']}>
      {(remove, { loading: loadingRemove }) => (
        <CheckboxTable
          query={getCourses}
          initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              width: 400,
              Cell: ({ original: course }) => (
                <div>
                  <strong>{course.name}</strong>
                  {course.checkoutPageUrl && (
                    <div>
                      Checkout Page:{' '}
                      <a href={course.checkoutPageUrl} target="_blank" rel="noopener noreferrer">
                        {course.checkoutPageUrl}
                      </a>
                    </div>
                  )}
                </div>
              )
            },
            {
              Header: 'Count Lessons',
              accessor: 'countLessons'
            },
            {
              Header: 'Status',
              accessor: 'status',
              Cell: ({ original: course }) => (
                <div>
                  <div>{course.status}</div>
                  {course.status === 'published' && <div>On {moment(course.publishedAt).format('LLL')}</div>}
                </div>
              )
            },
            {
              Header: 'Actions',
              sortable: false,
              filterable: false,
              accessor: 'id',
              Cell: ({ original: course }) => (
                <div>
                  <IconButton onClick={() => history.push(`${match.url}/builder/${course.id}/update`)} title="Edit Course">
                    <Icon fontSize="small">edit</Icon>
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={confirm(() => remove({ variables: { id: course.id } }), {
                      title: 'Course Remove',
                      description: 'Are you sure you want to delete this Course?'
                    })}
                    title="Remove Course"
                    disabled={loadingRemove}
                  >
                    <Icon fontSize="small">delete</Icon>
                  </IconButton>
                </div>
              )
            }
          ]}
          actions={[
            <Button key="create" variant="contained" color="primary" onClick={() => history.push(`${match.url}/create`)}>
              <Icon>add</Icon>
              Create Course
            </Button>
          ]}
          deleteMany={false}
        />
      )}
    </Mutation>
  </React.Fragment>
)

const styles = theme => ({
  root: {}
})

const enhance = compose(withConfirm, withStyles(styles))

export default enhance(Courses)
