/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import {TextField, Checkbox, SelectField} from 'lib/form/fields'
import { required, numericality, email, url } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getDataUpdatingMonitoringObject, updateMonitoringObject } from 'api/monitoringObjects'

import withStyles from '@material-ui/core/styles/withStyles'

import { monitoringObjectTypeOptions, monitoringObjectAccountTypeOptions } from 'data/options'
import {getUsers} from "api/users"
import {acl} from "../../lib/acl";


const MonitoringObjectUpdate = ({ classes, match }) => (
  <React.Fragment>
    <Query query={getDataUpdatingMonitoringObject} variables={match.params}>
      {({ data: { monitoringObject, products: { data: products } = {} } = {}, loading }) =>
        !loading && (
          <FormDialog
            title="Update Monitoring Object"
            form="MonitoringObjectUpdate"
            mutation={[updateMonitoringObject]}
            initialValues={monitoringObject}
            success="MonitoringObject updated successfully"
          >
            {({ doc, invalid, submitting }) => (
              <React.Fragment>
                {acl.hasAccess("admin") &&  <Field name="userId"
                                                   component={SelectField}
                                                   label="User"
                                                   fld="users"
                                                   query={getUsers}
                />}
                <Field name="name" component={TextField} label="Monitoring Object Name" />
                <Field
                  name="type"
                  component={TextField}
                  label="Monitoring Object Type"
                  options={monitoringObjectTypeOptions}
                  validate={[required()]}
                  select
                />
                {doc.type === 'email' && (
                  <React.Fragment>
                    <Field name="email" component={TextField} label="Email Address" validate={[required(), email()]} />
                  </React.Fragment>
                )}
                {doc.type === 'domain' && (
                  <React.Fragment>
                    <Field
                      name="domain"
                      component={TextField}
                      label="Domain Name"
                      validate={[
                        required(),
                        url({
                          emptyProtocol: false,
                          protocolIdentifier: false,
                          host: true,
                          path: false,
                          search: false,
                          hash: false
                        })
                      ]}
                    />
                  </React.Fragment>
                )}
                <Field
                  name="accountType"
                  component={TextField}
                  label="Monitoring Object Account Type"
                  options={monitoringObjectAccountTypeOptions}
                  validate={[required()]}
                />
                <Field name="active" component={Checkbox} label="Active" />
              </React.Fragment>
            )}
          </FormDialog>
        )
      }
    </Query>
  </React.Fragment>
)

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(MonitoringObjectUpdate)
