/** @format */

import React from 'react'
import { compose } from 'recompose'
import { useMutation } from 'react-apollo'
import moment from 'moment'
import numeral from 'numeral'

import { getCommissions, removeCommission, removeCommissions } from 'api/commissions'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import withConfirm from 'lib/withConfirm'
import CheckboxTable from 'lib/tables/CheckboxTable'
import UserCell from 'lib/tables/UserCell'
import SelectFilter from 'lib/tables/SelectFilter'

const Commissions = ({ classes, user, history, match, confirm }) => {
  const [remove, { loading: removing }] = useMutation(removeCommission, { refetchQueries: ['getCommissions'] })
  //const [removeMany] = useMutation(removeCommissions, { refetchQueries: ['getCommissions'] })

  return (
    <CheckboxTable
      query={getCommissions}
      variables={match.params}
      initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
      columns={[
        {
          Header: 'Client',
          accessor: 'user.id',
          Cell: ({ original: commission }) => <UserCell user={commission.user} />
          //Filter: props => <SelectFilter {...props} data={users} />
        },
        {
          Header: 'Description',
          accessor: 'description',
          Cell: ({ original: commission }) => (
            <div>
              <div>{commission.description}</div>
              {commission.invoice && (
                <div>
                  <div>
                    <em>Invoice: </em>
                    <a href={commission.invoice.invoice_pdf} target="_blank" rel="noopener noreferrer">
                      {commission.invoice.number}
                    </a>
                  </div>
                </div>
              )}
              <div>{commission.notes}</div>
            </div>
          )
        },
        {
          Header: 'Amount',
          accessor: 'commissionAmount',
          Cell: ({ original: commission }) => (
            <div>
              <div>
                {numeral(commission.commissionAmount).format('$0,0.00')}{' '}
                <em>
                  ({numeral(commission.totalAmount).format('$0,0.00')} x {numeral(commission.commissionPercent / 100).format('0.00%')})
                </em>
              </div>
              {commission.transfer && (
                <div>
                  <div>
                    <em>Transfered Amount: </em>
                    <strong>{numeral(commission.transfer.amount / 100).format('$0,0.00')}</strong>
                  </div>
                  {commission.transfer.reversed && (
                    <div>
                      <em>Reversed Amount: </em>
                      <strong>{numeral(commission.transfer.amount_reversed / 100).format('$0,0.00')}</strong>
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ original: commission }) => (
            <div>
              <div>
                <em>Status: </em>
                <strong>{commission.transfer && commission.transfer.reversed ? 'reversed' : commission.status}</strong>
              </div>
              <div>
                <em>Payment Status: </em>
                <strong>{commission.invoiceStatus}</strong>
              </div>
              {commission.chargeId && (
                <div>
                  <em>Payment ID: </em>
                  <strong>{commission.chargeId}</strong>
                </div>
              )}
              {commission.transferId && (
                <div>
                  <em>Transfer ID: </em>
                  <strong>{commission.transferId}</strong>
                </div>
              )}
            </div>
          ),
          Filter: props => <SelectFilter {...props} data={['pending', 'transfered'].map(s => ({ id: s, name: s }))} />
        },
        {
          Header: 'Created Date',
          accessor: 'createdAt',
          Cell: ({ original: commission }) => moment(commission.createdAt).format('LLL')
        },
        {
          Header: 'Actions',
          sortable: false,
          filterable: false,
          accessor: 'id',
          Cell: ({ original: commission }) => (
            <div>
              {[
                <IconButton
                  key="delete"
                  color="secondary"
                  onClick={confirm(() => remove({ variables: { commissionId: commission.id } }), {
                    title: 'Commission Remove',
                    description: 'Are you sure you want to delete this Commission?'
                  })}
                  title="Remove Commission"
                  disabled={removing}
                >
                  <Icon fontSize="small">delete</Icon>
                </IconButton>
              ]}
            </div>
          )
        }
      ]}
      //onDeleteAll={selection => removeMany({ variables: { commissionIds: selection } })}
      deleteMany={false}
    />
  )
}

const styles = theme => ({
  root: {}
})

const enhance = compose(withConfirm, withStyles(styles))

export default enhance(Commissions)

/*
                <IconButton key="edit" onClick={() => history.push(`${match.url}/${commission.id}/update`)} title="Edit Commission">
                  <Icon fontSize="small">edit</Icon>
                </IconButton>,
                  <IconButton
                    key="transfer"
                    color="default"
                    onClick={() =>
                      alert.confirm('', 'Are you sure you want to transfer this commission now?', () =>
                        transfer({ variables: { commissionId: commission.id } })
                      )
                    }
                    title="Transfer Commission"
                    disabled={loadingTransfer}
                  >
                    <Icon fontSize="small">forward</Icon>
                  </IconButton>
                      actions={[
                        ['admin'].includes(user.accountType) && (
                          <Button
                            key="create"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => history.push(`${match.url}/create`)}
                          >
                            <Icon className={classes.leftIcon}>add</Icon>
                            Create Commission
                          </Button>
                        )
                      ]}
                      */
