import React from 'react'


const Player = ({url, playlist}) => {
  const [swarm, setSwarm] =  React.useState(false)
  let id = (new Date())*1
  const checkSwarm = ()=>{
    console.log('checkSwarm', window.swarmify)
    if (window.swarmify){
      window.swarmify.swarmifyVideo(`swarm_${id}`, {
        swarmcdnkey: "04ce4d0f-5dab-42dc-bd06-79800666f0dd",
        iframeReplacement: "iframe",
        autoreplace: {
          youtube: true
        },
        theme: {
          button: "circle"
        }
      })
      setSwarm(true)
    } else setTimeout(checkSwarm, 100)
  }
  React.useEffect(()=>{
    let scriptElem = document.createElement("script");
    scriptElem.src = "https://assets.swarmcdn.com/cross/swarmdetect.js";
    let firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(scriptElem, firstScript)
    setTimeout(checkSwarm, 100)
  },[])
  return (
    <React.Fragment>
      <div id={`swarm_${id}`} src={url} width="960" height="540" className="swarm-fluid" controls="controls" preload="auto"></div>
    </React.Fragment>
  )
}

export default Player