/** @format */

import React, { useCallback, useState } from 'react'
import compose from 'recompose/compose'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useQuery, useMutation } from 'react-apollo'
import { useSnackbar } from 'notistack'

import { getDataCreationUserLicense } from 'api/userLicenses'
import { createEmployees } from 'api/employees'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import Spinner from 'components/Spinner'

import { parseCsvFile } from 'lib/parseCsv'

const mapHeaders = {
  Phone: 'phone',
  Cellphone: 'phone',
  department: 'department',
  Email: 'email',
  NotificationsEmail: 'notifyByEmail',
  FirstName: 'firstName',
  LastName: 'lastName',
  NotificationsSMS: 'notifyBySMS'
}

function getSteps() {
  return ['Upload Csv File', 'Select License', 'Confirm Data']
}

const EmployeeImport = ({ classes, fullScreen }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { data: { licenses: { data: licenses = [] } = {} } = {} } = useQuery(getDataCreationUserLicense)
  const [importEmployees, { loading: importing }] = useMutation(createEmployees, { refetchQueries: ['getEmployees'] })
  let history = useHistory()

  const [results, setResults] = useState([])
  const [licenseId, setLicenseId] = useState('')
  const [selectedRows, setSelectedRows] = useState({})
  const selectedIds = Object.keys(selectedRows)
    .filter(id => selectedRows[id])
    .map(id => +id)
  const selectedLicense = licenses.find(l => l.value === licenseId)
  const selectedEmployees = results.filter(emp => selectedIds.includes(emp.id))

  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  const submitFinalStep = async variables => {
    try {
      const result = await importEmployees({ variables })
      history.push('/account/employees')
      return result
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      return await submitFinalStep({ data: selectedEmployees, licenseId })
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = async () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const onDrop = useCallback(async acceptedFiles => {
    let { data, errors } = await parseCsvFile(acceptedFiles[0], {
      transformHeader(header) {
        const formatedHeader = String(header).trim()
        return mapHeaders[formatedHeader] || formatedHeader
      }
    })
    data = data.map((emp, index) => ({ ...emp, id: index + 1 }))
    setResults(data)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: ['.csv', '.txt'] })

  return (
    <Dialog fullScreen={fullScreen} open fullWidth maxWidth="md">
      <DialogTitle>Import Employees</DialogTitle>
      <container>
        <div className="mt-4" style={{ paddingLeft: 10 }}>
          <p>
            If you need help importing your list please reference this article or contact our support team for help.{' '}
            <strong>Review CSV template here </strong>
            <a className="beacon-help-link" href="#" data-beacon-article-sidebar="/5e591a492c7d3a7e9ae870fe">
              Get help here
            </a>
          </p>
        </div>
      </container>
      <DialogContent>
        <div>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className="text-center">All steps completed</Typography>
              </div>
            ) : (
              <div>
                {activeStep === 0 && (
                  <React.Fragment>
                    {results.length === 0 ? (
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <div>Drop the files here ...</div>
                        ) : (
                          <div>Drag 'n' drop some files here, or click to select files</div>
                        )}
                      </div>
                    ) : (
                      <div className="text-center">
                        You have imported {results.length} records{' '}
                        <a href="#cancel" onClick={() => setResults([])}>
                          Select another file
                        </a>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {activeStep === 1 && (
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={330} margin="0 auto">
                    <TextField
                      select
                      label="Select License"
                      className={classes.textField}
                      value={licenseId}
                      onChange={event => setLicenseId(event.target.value)}
                      margin="normal"
                      style={{ width: '100%' }}
                    >
                      {licenses.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          <span>
                            {option.label}
                            <br />
                            <strong>{option.quantity - option.usageCount} Left</strong>
                          </span>
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormControl component="fieldset" style={{ margin: 10, width: '100%' }}>
                      <FormLabel component="legend">
                        Assign license to{' '}
                        <a href="#select-all" onClick={() => setSelectedRows(results.reduce((o, row) => ({ ...o, [row.id]: true }), {}))}>
                          Select All
                        </a>{' '}
                        |{' '}
                        <a href="#unselect-all" onClick={() => setSelectedRows({})}>
                          Unselect All
                        </a>
                      </FormLabel>
                      <FormGroup>
                        {results.map(row => (
                          <FormControlLabel
                            key={row.id}
                            control={
                              <Checkbox
                                checked={!!selectedRows[row.id]}
                                onChange={event => setSelectedRows({ ...selectedRows, [row.id]: event.target.checked })}
                                value={row.id}
                              />
                            }
                            label={`${row.firstName} ${row.lastName}`}
                          />
                        ))}
                      </FormGroup>
                      {selectedLicense && selectedLicense.quantity - selectedLicense.usageCount < selectedIds.length ? (
                        <p style={{ color: 'red' }}>
                          You have chosen a license with fewer uses left than you have chosen the number of employees
                        </p>
                      ) : null}
                    </FormControl>
                  </Box>
                )}
                {activeStep === 2 && (
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={600} margin="0 auto">
                    {importing ? (
                      <Spinner />
                    ) : (
                      <React.Fragment>
                        {selectedLicense && (
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                            {selectedLicense.label}
                          </Typography>
                        )}
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                          Number of Employees is {selectedEmployees.length}
                        </Typography>
                        <Typography>List of Employess</Typography>
                        <ul>
                          {selectedEmployees.map(emp => (
                            <li key={emp.id}>{`${emp.firstName} ${emp.lastName}`}</li>
                          ))}
                        </ul>
                      </React.Fragment>
                    )}
                  </Box>
                )}
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
          <div>
            <Button disabled={activeStep === 0 || importing} onClick={handleBack} className={classes.backButton}>
              Back
            </Button>
            <Button
              disabled={
                (activeStep === 0 && results.length === 0) ||
                (activeStep === 1 &&
                  (!licenseId || selectedIds.length === 0 || selectedLicense.quantity - selectedLicense.usageCount < selectedIds.length)) ||
                (activeStep === 2 && (!selectedLicense || selectedEmployees.length === 0)) ||
                importing
              }
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </div>
          <div>
            <Button color="default" onClick={() => history.push('/account/employees')} disabled={importing}>
              Cancel
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const styles = () => ({})

export default compose(withMobileDialog(), withStyles(styles))(EmployeeImport)

/*
      {buttons
        ? buttons(props)
        : [
            <Button color="primary" variant="contained" onClick={props.submit} key="submit">
              {props.submitting ? 'Submitting...' : btnSubmitText}
            </Button>
          ]}
          */
