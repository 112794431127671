/** @format */

import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'

import TextField from '@material-ui/core/TextField'

const PhoneNumberCustom = ({ inputRef, ...other }) => {
  return (
    <PhoneInput
      defaultCountry={'us'}
      preferredCountries={['us', 'ca', 'au']}
      inputStyle={{ width: '100%', padding: '18.5px 14px 18.5px 48px', height: '1.1875em', border: '1px solid rgba(0, 0, 0, 0.23)' }}
      {...other}
    />
  )
}

const PhoneNumber = ({ input, meta: { touched, invalid, error }, label, ...rest }) => (
  <div>
    {label && <label style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.85rem', marginBottom: '0.25rem' }}>{label}</label>}
    <TextField
      value={input.value}
      onChange={e => input.onChange(e !== '+' && e !== '+1' ? e : null)}
      error={touched && invalid}
      helperText={touched && error}
      placeholder={label}
      InputProps={{
        inputComponent: PhoneNumberCustom,
        disableUnderline: true
      }}
      margin="none"
      fullWidth
      {...rest}
    />
  </div>
)

export default PhoneNumber
