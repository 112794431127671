import React from 'react'

import RoundBar from './RoundBar'

const FinalGrade = ({ title, domain, points = 0, letter = 'F', className = '', width = 300, height = 270 }) => {
  return (
    <div className="text-center">
      {title && <h2 style={{ margin: 0 }}>{title}</h2>}
      {domain && <h2 style={{ color: '#172DA6', margin: 0 }}>{domain}</h2>}
      <div className={className}>
        <RoundBar width={width} height={height} value={points} letter={letter} />
      </div>
    </div>
  )
}

export default FinalGrade
