/** @format */

import React from 'react'
import { Field, FormSection, Address } from 'lib/form'
import { TextField, PhoneNumber, Checkbox, AvatarUploadField } from 'lib/form/fields'
import { required, email } from 'lib/form/validators'

import FormGroup from './FormGroup'

class Company extends React.Component {
  static defaultProps = {
    name: 'company'
  }

  render() {
    const { company = {}, categories = [] } = this.props

    return (
      <React.Fragment>
        <FormGroup title="Company Information">
          {categories && categories.length > 0 && (
            <Field
              name="categories"
              component={TextField}
              label="Categories"
              options={categories.map(({ id, name, parent }) => ({
                label: name,
                value: id,
                optgroup: !parent && categories.filter(c => c.parent === name).length > 0
              }))}
              select
              multiple
            />
          )}
          <Field name="logo" component={AvatarUploadField} label="Company Logo" btnLabel="Upload Logo" />
          <Field name="name" component={TextField} label="Company Name" validate={[required()]} />
          <Field name="website" component={TextField} label="Company Website" />
          <Field name="screenshot" component={TextField} label="Company Screenshot Website" />
          <p className="text-muted">1280x720 size</p>
          <Field name="thumbnail" component={TextField} label="Company Thumbnail Website" />
          <p className="text-muted">320x180 size</p>
          <Field name="email" component={TextField} label="Company Email Address" validate={[email()]} />
          <Field name="phone" component={PhoneNumber} label="Company Cell Phone" />
          <Field name="noComparison" component={Checkbox} label="Remove from Comparison" />
        </FormGroup>
        {/*<Field name="formattedAddress" component={TextField} label="Full Address" />*/}
        <FormSection name="address">
          <Address title="Company Address" address={(company && company.address) || {}} />
        </FormSection>
        {/*<FormGroup title="Sponsor Box">
          <Field name="sponsorBox" component={Checkbox} label="Activation Sponsor Box" />
          {company && company.sponsorBox && (
            <React.Fragment>
              <Field name="sponsorName" component={TextField} label="Sponsor Name" margin="none" />
              <Field name="sponsorEmail" component={TextField} label="Sponsor Email Address" validate={[email()]} />
              <Field name="sponsorPhone" component={PhoneNumber} label="Sponsor Phone Number" />
              <Field name="sponsorWebsite" component={TextField} label="Sponsor Website" />
              <Field name="sponsorLogo" component={TextField} label="Sponsor Logo" />
            </React.Fragment>
          )}
        </FormGroup>
        */}
        <FormGroup title="Company Representative">
          <Field name="representativeName" component={TextField} label="Name" margin="none" />
          <Field name="representativeEmail" component={TextField} label="Email Address" validate={[email()]} />
          <Field name="representativePhone" component={PhoneNumber} label="Phone Number" />
        </FormGroup>
      </React.Fragment>
    )
  }
}

export default Company
