import React from 'react'
import moment from "moment/moment"
import CheckboxTable from 'lib/tables/CheckboxTable'
import _ from 'lodash'
import { getVisits } from 'api/visitors'

export default ({ match, location, history }) => (
  <React.Fragment>
    <div className="p-4">

      <h3>Visits</h3>
      <CheckboxTable
        query={getVisits}
        initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
        variables={{ ...match.params }}
        columns={[
          {
            Header: 'IDs',
            accessor: 'name',
            Cell: ({ original: visit }) => (
              <span>{visit.id}</span>
            )
          },
          {
            Header: 'Date & Time',
            accessor: 'createdAt',
            Cell: ({ original: visit }) => (
              <span>{new Date(visit.createdAt).toLocaleString()}</span>
            )
          },
          {
            Header: 'Location',
            accessor: 'location'
          },
          {
            Header: 'Actions',
            sortable: false,
            filterable: false,
            accessor: 'id',
            Cell: ({ original: visit }) => (
              <div className={'pull-right'}>
                {visit.sessionUrl && <a className={'btn btn-primary btn-sm mr-1'} target={'_blank'} href={`http://chatfortress.com/lp/lp-replay?key=${visit.id}`}>See Session Record</a>}
              </div>
            )
          }
        ]}
        deleteMany={false}
      />

    </div>
  </React.Fragment>
)
