/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required } from 'lib/form/validators'

import { getInvoiceEmailsForUpdating, updateInvoiceEmail } from 'api/invoiceEmails'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

const InvoiceEmailUpdate = ({ classes, match }) => {
  return (
    <React.Fragment>
      <Query query={getInvoiceEmailsForUpdating} variables={match.params}>
        {({ data: { invoiceEmails = {} } = {}, loading }) =>
          !loading && (
            <FormDialog
              title="Update Source"
              form="SourceUpdate"
              mutation={[updateInvoiceEmail]}
              initialValues={invoiceEmails}
              success="Source updated successfully"
              onSubmit={({ data, mutation }) => {
                mutation({ variables: { ...match.params, data: { invoice_email: data.email } } })
              }}
            >
              {({ doc, invalid, submitting }) => (
                <React.Fragment>
                  {invoiceEmails && (
                    <React.Fragment>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <Field name="email" component={TextField} label="Email" validate={[required()]} />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </FormDialog>
          )
        }
      </Query>
    </React.Fragment>
  )
}

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(InvoiceEmailUpdate)
