/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { FormCard, FormSection, Profile } from 'lib/form'

import { getEmployee, updateEmployee } from 'api/employees'

import withStyles from '@material-ui/core/styles/withStyles'

const EmployeeProfile = ({ classes, match }) => (
  <Query query={getEmployee} variables={match.params}>
    {({ data: { employee } = {}, loading }) =>
      !loading && (
        <FormCard
          form="EmployeeProfile"
          mutation={[updateEmployee]}
          initialValues={employee}
          success="Employee updated successfully"
          btnSubmitText="Save Employee Profile"
        >
          {({ doc, submit, submitting }) => (
            <React.Fragment>
              <FormSection name="">
                <Profile doc={doc} />
              </FormSection>
            </React.Fragment>
          )}
        </FormCard>
      )
    }
  </Query>
)

const styles = theme => ({
  root: {}
})

export default withStyles(styles)(EmployeeProfile)

/*
 */
