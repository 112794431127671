/** @format */

import gql from 'graphql-tag'

export const scheduleFragment = gql`
  fragment schedule on Schedule {
    id
    attendeeType
    type
    sent
    filterType
    lessonId
    userId
    courseId
    scheduleType
    scheduleDate
    updatedAt
    createdAt
    user {
      id
      name
    }
    lesson {
      id
      name
    }
    course {
      id
      name
    }
  }
`

export const getSchedules = gql`
  query getSchedules($courseId: String, $skip: Int, $limit: Int, $sort: [String]) {
    schedules(courseId: $courseId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        lessonId
        userId
        sent
        courseId
        scheduleDate
        user {
          id
          name
        }
        lesson {
          id
          name
        }
        course {
          id
          name
        }
        updatedAt
        createdAt
      }
      count
      pages
    }
  }
`

export const getDataSchedules = gql`
  query getDataSchedules($courseId: String!) {
    course(id: $courseId) {
      id
      name
      product {
        id
        name
      }
      plans {
        id
        name
      }
    }
  }
`

export const getSchedule = gql`
  query getSchedule($scheduleId: String!) {
    schedule(id: $scheduleId) {
      id
      ...schedule
    }
  }
  ${scheduleFragment}
`

export const getDataCreationSchedule = gql`
  query getDataCreationSchedule($courseId: String!, $masterAccountId: ID!) {
    employees: users(masterAccountId: $masterAccountId, accountType: "Employee", limit: 1000) {
      data {
        value: id
        label: name
      }
      count
      pages
    }
    course(id: $courseId) {
      id
      name
      product {
        id
        name
      }
      lessons {
        value: id
        label: name
      }
      plans {
        value: id
        label: name
      }
    }
  }
`

export const createSchedule = gql`
  mutation createSchedule($data: ScheduleInput!) {
    createSchedule(data: $data) {
      id
      ...schedule
    }
  }
  ${scheduleFragment}
`

export const getDataUpdatingSchedule = gql`
  query getDataUpdatingSchedule($masterAccountId: ID, $courseId: String!, $scheduleId: String!) {
    schedule(id: $scheduleId) {
      id
      ...schedule
    }
    employees: users(masterAccountId: $masterAccountId, accountType: "Employee", limit: 1000) {
      data {
        value: id
        label: name
      }
      count
      pages
    }
    course(id: $courseId) {
      id
      name
      product {
        id
        name
      }
      lessons {
        value: id
        label: name
      }
      plans {
        value: id
        label: name
      }
    }
  }
  ${scheduleFragment}
`

export const updateSchedule = gql`
  mutation updateSchedule($scheduleId: String!, $data: ScheduleInput!) {
    updateSchedule(id: $scheduleId, data: $data) {
      id
      ...schedule
    }
  }
  ${scheduleFragment}
`

export const removeSchedule = gql`
  mutation removeSchedule($id: String!) {
    removeSchedule(id: $id) {
      id
    }
  }
`

export const onCreateSchedule = (cache, { data: { createSchedule: schedule } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { schedules } = cache.readQuery({ query: getSchedules, variables })
  cache.writeQuery({
    query: getSchedules,
    variables,
    data: {
      schedules: {
        ...schedules,
        data: [schedule].concat(schedules.data)
      }
    }
  })
}

export const onRemoveSchedule = (cache, { data: { removeSchedule: schedule } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { schedules } = cache.readQuery({ query: getSchedules, variables })
  cache.writeQuery({
    query: getSchedules,
    variables,
    data: {
      schedules: {
        ...schedules,
        data: schedules.data.filter(r => r.id !== schedule.id)
      }
    }
  })
}
