/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormCard, Field } from 'lib/form'
import { TextField, Checkbox, DateTimeField } from 'lib/form/fields'
import { required, numericality } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'
import { Roles } from 'lib/acl'

import withContext from 'lib/withAppContext'

import { createSchedule, getDataCreationSchedule } from 'api/schedules'

import withStyles from '@material-ui/core/styles/withStyles'

import { scheduleOptions, attendeeTypeOptions, lessonTypeOptions, scheduleTypeOptions } from 'data/options'

const ScheduleCreate = ({ match, history, context }) => (
  <Query query={getDataCreationSchedule} variables={{ courseId: match.params.courseId, masterAccountId: context.user.id }}>
    {({ data: { course, employees } = {} }, loading) =>
      !loading && (
        <FormCard
          title="Create Schedule"
          form="ScheduleCreate"
          mutation={[createSchedule, { refetchQueries: ['getSchedules'] }]}
          initialValues={{ courseId: match.params.courseId, attendeeType: Roles.EMPLOYEE }}
          btnSubmitText="Create Schedule"
          success="Schedule created successfully"
          onComplete={() =>
            history.push(
              match.url
                .split('/')
                .slice(0, -1)
                .join('/')
            )
          }
        >
          {({ doc, invalid, submitting }) => (
            <React.Fragment>
              <Field
                name="attendeeType"
                component={TextField}
                label="Attendee Type"
                options={attendeeTypeOptions}
                validate={[required()]}
                select
              />
              <Field name="type" component={TextField} label="Schedule Type" options={scheduleOptions} validate={[required()]} select />
              {doc.attendeeType && employees && doc.attendeeType === Roles.EMPLOYEE && (
                <Field
                  name="users"
                  component={TextField}
                  label="Employees"
                  options={employees.data}
                  validate={[required()]}
                  multiple
                  select
                />
              )}

              {doc.type && doc.type === 'single' && (
                <React.Fragment>
                  <Field name="lessonId" component={TextField} label="Lesson" options={course.lessons} validate={[required()]} select />
                </React.Fragment>
              )}

              {doc.type && doc.type === 'course' && (
                <React.Fragment>
                  <h4>Course: {course.name}</h4>
                  <Field
                    name="filterType"
                    component={TextField}
                    label="Lesson Type Filter (optional)"
                    options={[{ label: 'All Lessons', value: null }, ...lessonTypeOptions]}
                    select
                  />
                </React.Fragment>
              )}

              {doc.type && doc.attendeeType && (
                <React.Fragment>
                  {/*<Field
                    name="scheduleType"
                    component={TextField}
                    label="Schedule Date Type"
                    options={scheduleTypeOptions}
                    select
                    validate={[required()]}
                  />*/}

                  {doc.type === 'course' && (
                    <React.Fragment>
                      <Field
                        name="scheduleDate"
                        type="datetime"
                        component={DateTimeField}
                        maxTime={17}
                        minTime={9}
                        label={doc.type === 'course' ? 'First Lesson Schedule Date & Time' : 'Schedule Date & Time'}
                        validate={[required()]}
                      />

                      <Field
                        name="schedulePeriod"
                        component={TextField}
                        label="Period in Days"
                        options={lessonTypeOptions}
                        type="number"
                        min="0"
                        max="90"
                        step="1"
                        validate={[required()]}
                      />
                    </React.Fragment>
                  )}

                  {doc.type !== 'course' && (
                    <React.Fragment>
                      <Field
                        name="scheduleDate"
                        type="datetime"
                        component={DateTimeField}
                        label="Schedule Date & Time"
                        validate={[required()]}
                        maxTime={17}
                        minTime={9}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </FormCard>
      )
    }
  </Query>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles), withContext)(ScheduleCreate)
