/** @format */

import React, { useEffect } from 'react'
import Payment from 'payment'
import { Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, ccnumber, ccexpiry, cccvc } from 'lib/form/validators'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const CreditCard = ({ title = 'Credit Card Information' }) => {
  useEffect(() => {
    Payment.formatCardNumber(document.querySelector('.cc-num input'))
    Payment.formatCardExpiry(document.querySelector('.cc-exp input'))
    Payment.formatCardCVC(document.querySelector('.cc-cvc input'))
  })

  return (
    <React.Fragment>
      <Typography variant="h5">{title}</Typography>
      <Field className="cc-num" name="number" component={TextField} label="Card Number" validate={[required(), ccnumber()]} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Field name="name" component={TextField} label="Name as it appears on card" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field className="cc-exp" name="expiry" component={TextField} label="MM/YY" validate={[required(), ccexpiry()]} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Field className="cc-cvc" name="cvc" component={TextField} label="CSV" validate={[required(), cccvc()]} />
        </Grid>
      </Grid>
      <img src="https://chatfortress.s3.amazonaws.com/img/payments-accepted.png" width="155" alt="Secure SSL Encryption" />
      <div className="small">
        <em>Secure SSL Encryption - Your Information is Safe and Secure</em>
      </div>
    </React.Fragment>
  )
}

CreditCard.defaultProps = {
  name: 'source'
}

export default CreditCard
