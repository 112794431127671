/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Schedules from 'components/Schedules/Schedules'
import ScheduleCreate from 'components/Schedules/ScheduleCreate'

export default ({ match }) => (
  <React.Fragment>
    <Switch>
      <Route path={`${match.path}/create`} component={ScheduleCreate} />
      <Route path={`${match.path}`} component={Schedules} />
    </Switch>
  </React.Fragment>
)
