import React from 'react'
import _ from 'lodash'

import { getColorByValue } from 'lib/chartHelpers'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'

const getDefaultComparison = gql`
  query getCompanies {
    companies(featured: true, sort: ["rank", "1"], rankPoints: { gt: 0 }) {
      data {
        id
        name
        website
        domain
        logo
        thumbnail
        categories
        formattedAddress
        featuredIcon
        rank
        totalRank
        rankPoints
        rankLetter
        lastScannedAt
        createdAt
        report {
          id
          token
          status
        }
      }
    }
  }
`

const FeaturedCompaniesTable = ({ company = {}, featuredCompanies = [], title = null }) => {
  featuredCompanies[featuredCompanies.length] = company
  featuredCompanies = _.sortBy(featuredCompanies, fc => -1 * fc.rankPoints)
  return (
    <section className="fdb-block py-4">
      <div className="container">
        <div className="" style={{ width: '100%' }}>
          <div className="card-body" style={{ padding: '0.95rem' }}>
            {title && <h3>{title}</h3>}
            <div className="featured-container">
              {featuredCompanies.map((c, i) => (
                <div key={i} className="row justify-content-center align-items-center featured-row">
                  <div
                    className="col col-2 text-center text-white text-uppercase col-pre"
                    style={{
                      justifyContent: 'center',
                      backgroundColor:
                        c.id === company.id ? 'blue' : c.rankPoints >= company.rankPoints ? 'rgb(233,73,73)' : 'rgb(88,217,113)'
                    }}
                  >
                    {c.id === company.id ? (
                      <strong>Your Company</strong>
                    ) : (
                      <span>
                        <strong>{c.rankPoints >= company.rankPoints ? 'Less' : 'More'}</strong> secure than
                      </span>
                    )}
                  </div>

                  <div
                    className="col col-md-2 col-sm-3 col-icon"
                    style={{
                      backgroundImage: c.featuredIcon
                        ? `url("https://chatfortress.s3.us-east-1.amazonaws.com/img/comparison/${c.featuredIcon}")`
                        : `url(${c.thumbnail || 'https://via.placeholder.com/200x150?text=n/a'})`,
                      backgroundPosition: '-2px -3px',
                      backgroundRepeat: 'no-repeat',
                      backgroundColor: '#f4f4f4'
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    className="col col-md-6 cols-sm-5 col-website"
                    style={{
                      backgroundColor: '#f4f4f4',
                      color: c.id === company.id ? 'blue' : 'black'
                    }}
                  >
                    {c.name} website ({c.rankPoints} points)
                  </div>

                  <div
                    className={'col col-2 col-points text-center text-' + (c.rankPoints < 75 ? 'white' : 'black')}
                    style={{
                      backgroundColor: getColorByValue(c.rankPoints)
                    }}
                  >
                    <div>
                      {c.rankLetter.length > 1 ? '\u00A0' + c.rankLetter : c.rankLetter}
                      <div style={{ fontSize: 14, color: 'black', marginTop: -2 }}>{c.rankPoints}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const FeaturedCompanies = ({ company = {} }) => {
  const { loading, data: { companies: featuredCompanies = [] } = {} } = useQuery(getDefaultComparison)

  const getCategoryComparison = gql`
  query getCompanies($categories: [String]) {
    companiesByCategory(categories: $categories, featuredInCategory: true, limit: ${company.categories.length *
      10}, sort: ["rank", "1"], rankPoints: { gt: 0 }) {
      data {
        id
        name
        website
        domain
        thumbnail
        categories
        formattedAddress
        featuredIcon
        rank
        totalRank
        rankPoints
        rankLetter
        lastScannedAt
        createdAt
        report {
          id
          token
          status
        }
      }
    }
  }
`
  const { loading: loading2, data: { companiesByCategory = [] } = {} } = useQuery(getCategoryComparison, {
    variables: { categories: company.categories }
  })

  return !loading && !loading2 ? (
    <div>
      <div className="container mt-5">
        <h1 className="display-4">How does your website compare?</h1>
        <div className="row justify-content-center align-items-center">
          <div className="col-12">
            <h2>More or Less Secure than...?</h2>
            <p>
              Discover how your website compares to other businesses and public figures. This helps to give you context because each of
              these companies has experienced a cybersecurity incident due to their cybersecurity weaknesses.
            </p>
          </div>
        </div>
      </div>
      <FeaturedCompaniesTable company={company} featuredCompanies={featuredCompanies.data} />
      {company.categories.map(c => {
        let cmps = _.filter(companiesByCategory.data, cm => cm.categories.includes(c))
        if (cmps.length < 1) return null
        return (
          <FeaturedCompaniesTable key={company.id} title={` How you compare against featured "${c}" companies`} company={company} featuredCompanies={cmps} />
        )
      })}
    </div>
  ) : null
}

export default FeaturedCompanies
