/** @format */

import gql from 'graphql-tag'

export const sourceFragment = gql`
  fragment source on Source {
    address_city
    address_country
    address_line1
    address_line2
    address_state
    address_zip
    exp_month
    exp_year
    name
    account_holder_name
    account_holder_type
  }
`
export const getSources = gql`
  query getSources($userId: String, $skip: Int, $limit: Int, $sort: [String]) {
    sources(userId: $userId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        displayName
        object
        status
      }
      count
      pages
    }
  }
`

export const getSource = gql`
  query getSource($sourceId: String!) {
    source(id: $sourceId) {
      id
      ...source
      displayName
    }
  }
  ${sourceFragment}
`

export const getSourceForUpdating = gql`
  query getSourceForUpdating($sourceId: String!) {
    source(id: $sourceId) {
      id
      ...source
      object
    }
  }
  ${sourceFragment}
`

export const createSource = gql`
  mutation createSource($userId: ID, $data: SourceInput!) {
    createSource(userId: $userId, data: $data) {
      id
      ...source
    }
  }
  ${sourceFragment}
`

export const updateSource = gql`
  mutation updateSource($sourceId: String!, $data: SourceInput!) {
    updateSource(id: $sourceId, data: $data) {
      id
      ...source
    }
  }
  ${sourceFragment}
`

export const verifySource = gql`
  mutation verifySource($sourceId: String!, $data: SourceVerifyInput!) {
    verifySource(id: $sourceId, data: $data) {
      id
      ...source
    }
  }
  ${sourceFragment}
`

export const removeSource = gql`
  mutation removeSource($id: String!) {
    removeSource(id: $id) {
      id
    }
  }
`

export const onCreateSource = (cache, { data: { createSource: source } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { sources } = cache.readQuery({ query: getSources, variables })
  cache.writeQuery({
    query: getSources,
    variables,
    data: {
      sources: {
        ...sources,
        data: [source].concat(sources.data)
      }
    }
  })
}

export const onRemoveSource = (cache, { data: { removeSource: source } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { sources } = cache.readQuery({ query: getSources, variables })
  cache.writeQuery({
    query: getSources,
    variables,
    data: {
      sources: {
        ...sources,
        data: sources.data.filter(r => r.id !== source.id)
      }
    }
  })
}
