/** @format */

import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import { useQuery, useMutation } from 'react-apollo'

import { getSubscriptionsWithProduct, cancelSubscription, removeSubscription } from 'api/subscriptions'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import withContext from 'lib/withAppContext'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { withSnackbar } from 'notistack'


const Products = ({ classes, history, match, confirm, context, enqueueSnackbar }) => {

  const [copied, setCopied] = React.useState(null)

  return (
    <React.Fragment>
      <div className={classes.cards}>
        {_.filter(context.user.publicPages, p=>p.type==='product').map( page => (<Card className={classes.card} key={page.title}>
          <CardContent>
            <div style={{paddingTop: 12, display: 'inline-block'}}>{page.title}</div>

            <CopyToClipboard
              text={page.shortUrl}
              onCopy={() => {
                setCopied(page.shortUrl)
                enqueueSnackbar('The url is copied to clipboard', { variant: 'info' })
              }}
            >
              <IconButton className='float-right'><Icon>file_copy</Icon></IconButton>
            </CopyToClipboard>
            <div>
              <small className='text-muted'>{page.shortUrl}</small>
            </div>
            {copied && page.shortUrl===copied &&
            <div>
              <small className='text-success'>The url is copied to your clipboard</small>
            </div>
            }
          </CardContent>
        </Card>)
        )}
      </div>
    </React.Fragment>
  )
}

const styles = theme => ({
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  card: {
    maxWidth: 300,
    minWidth: 300,
    marginRight: 10,
    marginBottom: 10
  }
})

export default compose(withContext, withSnackbar, withStyles(styles))(Products)
