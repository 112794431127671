/** @format */

import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useQuery, useMutation } from 'react-apollo'
import { useSnackbar } from 'notistack'

import { getDataCreationUserLicense } from 'api/userLicenses'
import { createContacts } from 'api/contacts'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from 'lib/Avatar'
import UserIcon from '@material-ui/icons/Person'
import Box from '@material-ui/core/Box'
import Spinner from 'components/Spinner'

import { parseCsvFile } from 'lib/parseCsv'
import Papa from "papaparse";

const mapHeaders = {
  Phone: 'phone',
  Email: 'email',
  FirstName: 'firstName',
  LastName: 'lastName'
}

function getSteps() {
  return ['Upload Csv File', 'Confirm Data']
}

const ContactImport = ({ classes, fullScreen, match }) => {
  const listId = match.params.listId
  const { enqueueSnackbar } = useSnackbar()
  const { data: { licenses: { data: licenses = [] } = {} } = {} } = useQuery(getDataCreationUserLicense)
  const [importContacts, { loading: importing }] = useMutation(createContacts, { refetchQueries: ['getContacts'] })
  let history = useHistory()

  const [results, setResults] = useState([])
  const [selectedRows, setSelectedRows] = useState({})
  const selectedIds = Object.keys(selectedRows)
    .filter(id => selectedRows[id])
    .map(id => +id)
  const selectedContacts = results.filter(emp => selectedIds.includes(emp.id))

  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  const downloadCSV = ()=>{
    let data = [
      {
        firstName: 'Test',
        lastName: 'Test',
        email: 'test@test.test',
        phone: '+15555551234',
      },
      {
        firstName: 'Test2',
        lastName: 'Test2',
        email: 'test2@test.test',
        phone: '+15555555678',
      }
    ]
    let csv = Papa.unparse(data);
    var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var csvURL =  null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, 'sample.csv');
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'sample.csv');
    tempLink.click();
  }

  const submitFinalStep = async variables => {
    try {
      const result = await importContacts({ variables })
      history.push(`/smishing-simulator/lists/${listId}`)
      return result
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      return await submitFinalStep({ data: results})
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = async () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const onDrop = useCallback(async acceptedFiles => {
    let { data, errors } = await parseCsvFile(acceptedFiles[0], {
      transformHeader(header) {
        const formatedHeader = String(header).trim()
        return mapHeaders[formatedHeader] || formatedHeader
      }
    })
    data = data.map((emp, index) => ({ ...emp, phone: (emp.phone?(emp.phone+""):null), id: index + 1, listId }))
    setResults(_.filter(data, c=>c.phone&&c.firstName))
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: ['.csv', '.txt'] })

  return (
    <Dialog fullScreen={fullScreen} open fullWidth maxWidth="md">
      <DialogTitle>Import Contacts</DialogTitle>
      <div className='container'>
        <div className="mt-4" style={{ paddingLeft: 10 }}>
          <p>
            If you need help importing your list please reference this article or contact our support team for help.{' '}
            <strong>
              <a className="beacon-help-link" href="#" onClick={downloadCSV}>Review CSV template here</a>
            </strong>
          </p>
        </div>
      </div>
      <DialogContent>
        <div>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className="text-center">All steps completed</Typography>
              </div>
            ) : (
              <div>
                {activeStep === 0 && (
                  <React.Fragment>
                    {results.length === 0 ? (
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <div>Drop the files here ...</div>
                        ) : (
                          <div>Drag 'n' drop some files here, or click to select files</div>
                        )}
                      </div>
                    ) : (
                      <div className="text-center">
                        You have imported {results.length} records{' '}
                        <a href="#cancel" onClick={() => setResults([])}>
                          Select another file
                        </a>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {activeStep === 1 && (
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={600} margin="0 auto">
                    {importing ? (
                      <Spinner />
                    ) : (
                      <React.Fragment>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} gutterBottom>
                          Number of Contacts is {results.length}
                        </Typography>

                        <List>
                          {results.map(emp => (
                            <ListItem key={`${emp.firstName} ${emp.lastName}`}>

                              <ListItemAvatar>
                                <Avatar name={`${emp.firstName} ${emp.lastName}`}>
                                  <UserIcon />
                                </Avatar>
                              </ListItemAvatar>
                              {`${emp.firstName} ${emp.lastName}`}</ListItem>
                          ))}
                        </List>
                      </React.Fragment>
                    )}
                  </Box>
                )}
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
          <div>
            <Button disabled={activeStep === 0 || importing} onClick={handleBack} className={classes.backButton}>
              Back
            </Button>
            <Button
              disabled={
                (activeStep === 0 && results.length === 0) ||
                (activeStep === 1 && selectedContacts.length !== 0) ||
                importing
              }
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </div>
          <div>
            <Button color="default" onClick={() => history.push('/account/contacts')} disabled={importing}>
              Cancel
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const styles = () => ({})

export default compose(withMobileDialog(), withStyles(styles))(ContactImport)

/*
      {buttons
        ? buttons(props)
        : [
            <Button color="primary" variant="contained" onClick={props.submit} key="submit">
              {props.submitting ? 'Submitting...' : btnSubmitText}
            </Button>
          ]}
          */
