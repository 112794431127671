/** @format */

import React from 'react'
import { Query, Mutation } from 'react-apollo'
import { Form, Field } from 'lib/form'
import { TextField, Checkbox, ImageUploadField } from 'lib/form/fields'
import { required, length, url } from 'lib/form/validators'
import {acl} from 'lib/acl'

import { getDataUpdatingCourse, updateCourse, publishCourse, unpublishCourse } from 'api/courses'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

const CourseUpdate = ({ classes, match }) => (
  <Mutation mutation={publishCourse}>
    {(publish, { loading: publishing }) => (
      <Mutation mutation={unpublishCourse}>
        {(unpublish, { loading: unpublishing }) => (
          <Query query={getDataUpdatingCourse} variables={match.params}>
            {({ data: { course, products: { data: products } = {} } = {}, loading }) =>
              !loading && (
                <Paper className="p-3">
                  <Form form="CourseUpdate" mutation={[updateCourse]} initialValues={course} success="Course updated successfully">
                    {({ doc, submit, submitting }) => (
                      <React.Fragment>
                        <Field name="productId" component={TextField} label="Product" options={products} validate={[required()]} select />
                        <Field name="roles" component={TextField} label="Roles" options={Object.keys(acl.roles).map(r=>({label: r, value: r}))} select multiple />
                        <Field name="name" component={TextField} label="Course Name" validate={[required(), length({ min: 2 })]} />
                        <Field name="description" component={TextField} label="Course Description" multiline />
                        <Field name="cover" component={ImageUploadField} label="Course Cover Image" btnLabel="Upload Cover Image" />
                        <Field
                          name="checkoutPageUrl"
                          component={TextField}
                          label="Course Checkout Page URL"
                          validate={[url({ protocols: ['http', 'https'] })]}
                        />
                        <Field name="instantRoles" component={TextField} label="Roles that have instant access" options={Object.keys(acl.roles).map(r=>({label: r, value: r}))} select multiple />
                        <Field
                          name="isSequence"
                          component={Checkbox}
                          label="Is Sequence (next lesson available instantly after previous)"
                        />
                        <Field name="paid" component={Checkbox} label="Paid Course" />
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Button variant="contained" color="primary" onClick={submit} disabled={submitting}>
                            Update Course
                          </Button>
                          {course.status === 'published' ? (
                            <Button onClick={() => unpublish({ variables: { courseId: course.id } })} disabled={publishing || submitting}>
                              Unpublish Course
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => publish({ variables: { courseId: course.id } })}
                              disabled={unpublishing || submitting}
                            >
                              Publish Course
                            </Button>
                          )}
                        </Box>
                      </React.Fragment>
                    )}
                  </Form>
                </Paper>
              )
            }
          </Query>
        )}
      </Mutation>
    )}
  </Mutation>
)

const styles = theme => ({
  root: {}
})

export default withStyles(styles)(CourseUpdate)

/*
 */
