/** @format */

import React from 'react'
import Avatar from 'lib/Avatar'

import { withStyles } from '@material-ui/core/styles'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'

const CompanyCell = ({ classes, company = {} }) => {
  return (
    <div className={classes.root}>
      {company && (
        <React.Fragment>
          <Avatar className={classes.avatar} {...company} avatar={company.thumbnail || company.logo} size={40} round />
          <address className={classes.address}>
            <strong>{company.name}</strong>
            {company.email && (
              <span className={classes.email}>
                <MailOutlineIcon style={{ width: 20, height: 20 }} />{' '}
                <a className={classes.emailLink} href={`mailto:${company.email}`}>
                  {company.email}
                </a>
              </span>
            )}
            {company.phone && (
              <span className={classes.phone}>
                <PhoneIcon style={{ width: 20, height: 20 }} /> <span className={classes.phoneLink}>{company.phone}</span>
              </span>
            )}
            {company.formattedAddress && <span className={classes.formattedAddress}>{company.formattedAddress}</span>}
          </address>
        </React.Fragment>
      )}
    </div>
  )
}

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatar: {
    marginRight: 7
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 5
  },
  email: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  emailLink: {
    marginLeft: 5
  },
  phone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  phoneLink: {
    marginLeft: 5
  }
})

export default withStyles(styles)(CompanyCell)
