/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Link, withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

const MainMenu = ({ menuContent = [], location, onClick }) => (
  <List>
    {menuContent.map(item => (
      <ListItem key={item.title} button component={Link} to={item.to} selected={location.pathname.startsWith(item.to)} onClick={onClick}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItem>
    ))}
  </List>
)

const styles = theme => ({})

export default compose(
  withRouter,
  withStyles(styles)
)(MainMenu)
