/** @format */

import _ from 'lodash'
import React from 'react'
import compose from 'recompose/compose'
import { Redirect } from 'react-router-dom'
import withContext from 'lib/withAppContext'
import ProductMatcher from 'lib/productMatcher'

import Training from 'components/Training'

import Button from '@material-ui/core/Button'

import HackedDash from './HackedDash'
import ReportDash from './ReportDash'

import Dashboard from './Dashboard'
import DashboardReseller from './DashboardReseller'
import DashboardResellerWizard from './DashboardResellerWizard'
import { from } from 'apollo-link'
import {acl} from "../../lib/acl";
import {AdminDashboard} from "./index";

const dashboards = {
  HackedDash,
  ReportDash
}

class DashboardClient extends React.Component {
  state = {}

  render() {
    const { context } = this.props
    const { user, acl } = context

    const boards = _.filter(
      user.purchasedProducts.map(p => {
        if (!ProductMatcher(p.id)) return null
        else
          return {
            ...p,
            componentData: ProductMatcher(p.id)
          }
      }),
      b => b
    )
    if(acl.hasAccess("admin")){
      return  <AdminDashboard {...this.props} />
    }
    if(acl.hasAccess("activityLog")){
      return  <AdminDashboard {...this.props} />
    }

    if(_.intersection(acl.getRolesFromUser(user), ['RC-Reseller','RC-Reseller-Pro','RC-Reseller-Starter','CF-Reseller-Starter','CF-Reseller','CF-Reseller-Pro', 'RC-Vendor', 'RC-Influencer', 'RC-SalesTier1','RC-SalesTier2','CF-RC-SalesTier1','CF-RC-SalesTier2']).length>0) {
      let wizardSteps = []
      if(_.some([user.email, user.name, user.phone, user.mailAddress?user.mailAddress.addressLine1:null],_.isEmpty)){
        wizardSteps.push('profile')
      }
      if(!user.services || !user.services.stripe){
        wizardSteps.push('stripe')
      }
      if(wizardSteps.length > 0){
        return <DashboardResellerWizard wizardSteps={wizardSteps} {...this.props} />
      }
      return <DashboardReseller {...this.props} />
    }

    if (user && user.company && user.company.isDomainVerified && user.company.isQuizCompleted && user.company.isCore4Completed) {
      if (acl.hasAccess('dashboard.reseller')) {
        return <DashboardReseller {...this.props} />
      }
      return <Dashboard {...this.props} />
    }

    if (boards.length === 0) {
      return (
        <div>
          <Training {...this.props} />
          <Button
            style={{ marginLeft: 16 }}
            color="primary"
            variant="contained"
            onClick={() => this.props.history.push('/account/profile')}
          >
            Update Your Account
          </Button>
        </div>
      )
    }

    return (
      <div className="p-2">
        {boards &&
          boards.map(board => {
            return React.createElement(dashboards[board.componentData.dashboardComponent], { key: board.id, ...this.props })
          })}
      </div>
    )
  }
}

export default compose(withContext)(DashboardClient)
