import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Templates from "./Templates"
import TemplateView from "./TemplateView"
import TemplateEdit from "./TemplateEdit"


export default ({ match, location }) => (
  <div className={'p-3'}>
    <h2>Easy Compliance Document Templates</h2>
    <Switch>
      <Route path={`${match.path}`} exact={true} component={Templates} />
      <Route path={`${match.path}/:templateId/view`} exact={true} component={TemplateView} />
    </Switch>
    <Route path={`${match.path}/:templateId/edit`} component={TemplateEdit} />
  </div>
)
