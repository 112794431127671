/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import TrainingActivity from './TrainingActivity'
import TrainingActivityUser from './TrainingActivityUser'

const Module = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:userId`} component={TrainingActivityUser} />
    <Route path={`${match.path}`} component={TrainingActivity} />
  </Switch>
)

export default Module
