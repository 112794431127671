import React from 'react'
import Quill from 'quill'
import _ from 'lodash'

import QuillBetterTable from 'lib/quill-better-table/src/quill-better-table'
import Select from 'react-select'

import 'react-quill/dist/quill.snow.css'
import 'quill-better-table/dist/quill-better-table.css'
import './style.css'

import TurndownService from 'turndown'
import * as turndownPluginGfm from 'joplin-turndown-plugin-gfm'

var turndownService = new TurndownService()

var gfm = turndownPluginGfm.gfm

var tables = turndownPluginGfm.tables
var strikethrough = turndownPluginGfm.strikethrough
turndownService.use(gfm)
turndownService.use([tables, strikethrough])


function html2md(html) {
  if(!html)
    return ''
  if(html.indexOf('<html')===-1)
    return html
  let md = turndownService.turndown(html.replace(/\t/g,''))
  //console.log(md)
  return md
}
const Delta = Quill.import('delta')
const Embed = Quill.import('blots/embed');

class PlaceholderBlot extends Embed {
  static create(value) {
    let destNode = super.create(value)

    destNode.setAttribute('data-id', value.id||'')
    destNode.setAttribute('data-label', value.label||'')
    destNode.setAttribute('data-type', value.type||'')
    destNode.setAttribute('data-description', value.description||'')
    destNode.setAttribute('data-image', value.image||'')
    destNode.setAttribute('spellcheck', false)
    destNode.setAttribute('contenteditable', false)
    destNode.style.display = 'block'

    switch(value.type){
      case 'plain':
        destNode.style.display = 'inline'
        break;
      case 'block':
        break;
      case 'plain':
        break;
      default:
        value.type='block'
        break;
    }
    if(value.type==='plain') {
      destNode.innerText = `{{${value.id}}}`
    } else {
      if(value.type==='block')
        destNode.innerHTML = `<span class="placeholder-component">
${value.image? `<img src="${value.image}"/>`:'<span>BLOCK</span>'}
<strong>${value.label}</strong><br/><small>${value.description||''}</small></span>`
      if(value.type==='image')
        destNode.innerHTML = `<span class="placeholder-component">
${value.image? `<img src="${value.image}"/>`:'<span>IMAGE</span>'}
<strong>${value.label}</strong><br/><small>${value.description||''}</small></span>`
    }

    return destNode
  }

  static value(domNode) {
    return domNode.dataset
  }

  length() {
    return 1
  }


}

PlaceholderBlot.blotName = 'placeholder'
PlaceholderBlot.tagName = 'span'
PlaceholderBlot.className = 'ql-placeholder-content'

Quill.register(PlaceholderBlot)

const BlockBlot = Quill.import('blots/block/embed');
class DivBlot extends BlockBlot {
  static create(url) {
    let node = super.create();
    node.setAttribute('class', 'placeholder-component');
    return node;
  }
}
DivBlot.blotName = 'placeholderComponent'
DivBlot.tagName = 'div'
DivBlot.className = 'placeholder-component'
Quill.register(DivBlot)


class Placeholder {
  constructor(quill, options) {
    this.quill = quill
    this.onTextChange = this.onTextChange.bind(this)
    this.onClick = this.onClick.bind(this)
    this.onSelectionChange = this.onSelectionChange.bind(this)

    this.quill.on(Quill.events.TEXT_CHANGE, this.onTextChange)
    this.quill.on(Quill.events.SELECTION_CHANGE, this.onSelectionChange)
    this.quill.root.addEventListener('click', this.onClick)
  }

  onTextChange(_, oldDelta, source) {
    if (source === Quill.sources.USER) {
      const currrentContents = this.quill.getContents()
      const delta = currrentContents.diff(oldDelta)

      const shouldRevert = delta.ops.filter(op => op.insert &&
        op.insert.placeholder && op.insert.placeholder.required).length

      if (shouldRevert) {
        this.quill.updateContents(delta, Quill.sources.SILENT)
      }
    }
  }


  onClick = (ev) => {
    let Parchment = Quill.import('parchment')

    const blot = Parchment.Registry.find(ev.target.parentNode)
    if (blot instanceof PlaceholderBlot) {
      const index = this.quill.getIndex(blot)
      this.quill.setSelection(index, blot.length(), Quill.sources.USER)
      //console.log('click',ev.target.parentNode, blot)
    }
  }

  onSelectionChange(range, oldRange, source) {
    if (!range) return
    //console.log('a, b, c', range, oldRange, source)
    let Parchment = Quill.import('parchment')
    const selection = document.getSelection()
    //console.log(selection, range, quill.getLine(range.index)[0])
    if(selection) {
      const rng = selection.getRangeAt(0)
      if (rng) {
        let node = selection.focusNode//rng.startContainer
        //console.log(node,node.tagName, node.children)

        if(node.tagName!=='span' && node.children && node.children.length>0) {
          //console.log(node.children[0])
          node = node.children[0]
        }
        if (node) {
          const blot = Parchment.Registry.find(node)
          //console.log(blot)
          Array.from(document.querySelectorAll(".ql-placeholder-content > span") || []).map(n => {
            n.classList.remove("selected")
          })
          if (blot instanceof PlaceholderBlot) {
            if(node.getAttribute('data-type')==='plain'){
              //console.log(blot, blot.contentNode, selection, range)
              //quill.setSelection(range.index, 1)
            }
            blot.contentNode.classList.add("selected")
          }
          //console.log('a, b, c', range, oldRange, source, blot)
        }
      }
    }
//     const nativeRange = this.quill.selection.getNativeRange()

  }
}

Quill.register('modules/placeholder', Placeholder)

let quill = null

const RichEditor = ({value='<p>Start typing here...</p>', onChange, stickyToolbar=false, stickyElement=null, stickyOffset=0, placeholders=[], onSave=null}) => {

  const [loading, setLoading] = React.useState(true)
  React.useEffect(()=>{
    quill = null
    console.log('###React.useEffect')
  },[])
  let Parchment = Quill.import('parchment');

  let Align = new Parchment.StyleAttributor('align', 'text-align', {
    whitelist: ['right', 'center', 'justify']
  });

  Quill.register(Align, true);

  let FontSize = new Parchment.StyleAttributor('fs', 'font-size', { scope: Parchment.Scope.INLINE});

  Quill.register(FontSize, true);

  let Color = new Parchment.StyleAttributor('color', 'color', { scope: Parchment.Scope.INLINE});

  Quill.register(Color, true);



  Quill.register({
    'modules/better-table': QuillBetterTable
  }, true)
  Quill.register('formats/placeholder',PlaceholderBlot, true);


  setTimeout( () => {
    if(!quill) {
      quill = new Quill('#editor', {
        theme: 'snow',
        modules: {
          placeholder: {
          },
          toolbar: {
            container: '#toolbar-container',  // Selector for toolbar container
            handlers: {
              'placeholder': (value) => {
                const range = quill.getSelection()

                // if (!range || range.length != 0) return
                const position = range && range.index || 0

                //quill.insertEmbed(position, 'placeholder', placeholders.filter((pl) => pl.id === value)[0], Quill.sources.USER)
               // quill.setSelection(position + 1)
              }
            }
          },

          table: false,

          'better-table': {
            operationMenu: {
              items: {
                unmergeCells: {
                  text: 'Another unmerge cells name'
                }
              },
              color: {
                colors: ['green', 'red', 'yellow', 'blue', 'white'],
                text: 'Background Colors:'
              }
            }
          }
        }
      })

      quill.on('text-change', function(delta, oldDelta, source) {
        //if(loading)setLoading(false)
        if(onChange)
          onChange(document.querySelector('#editor .ql-editor').innerHTML)
      });
      setTimeout(()=>setLoading(false), 100)
    }
    if(stickyToolbar) {
      (stickyElement || document.body).onscroll = onScroll
      let navbar = document.getElementById("toolbar-container");
      let sticky = navbar.offsetTop;

      function onScroll() {
        if ((stickyElement || document.body).scrollTop >= sticky) {
          navbar.classList.add("sticky")
          navbar.style.top = stickyOffset+'px'
        } else {
          navbar.classList.remove("sticky");
          navbar.style.top ='unset'
        }
      }
    }

  }, 100)


  let groupedPlaceholders = _.groupBy(placeholders, (p)=>p.group||'Other')

  const onSelect = (value) => {
    //const value = e.target.value
    let scrollTop = (stickyElement || document.body).scrollTop;
    const range = quill.getSelection(true)
//console.log("##RANGE", scrollTop,range, quill.getSelection(true))
    const position = range && range.index || 0
    quill.insertEmbed(position, 'placeholder', value, Quill.sources.USER)
    quill.setSelection(position + 2)
    setTimeout(() => {
      (stickyElement || document.body).scrollTop = scrollTop
    }, 1);
  }

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  let opts = _.reduce(groupedPlaceholders, (obj, v, k)=>{
    obj.push({label: k, options: v.map(item=>{
       let type = item.type
        if(type!=='plain' && type!=='image' && type!=='link' && type!=='block')
          type='block'
      return {...item,
        type,
        value: item.id}
      })})
    return obj
  },[])
  //console.log(opts)

  return (
    <div>
      {loading && <div className={'flex text-center'}>Loading...</div>}
      <div id="standalone-container" style={{all: 'unset', display:loading?"none":"unset"}}>
        <div id="toolbar-container">
          <div style={{width:'100%', paddingBottom: 3, marginBottom: 3, borderBottom: 'solid 1px silver', display: "inline-block"}}>
            <div style={{width:'50%', display: "inline-block"}}>
              <Select
                placeholder={'Insert Merge Variable...'}
                options={opts}
                onChange={onSelect}
                onMenuFocus={(m)=>console.log(m)}
                formatGroupLabel={formatGroupLabel}
                formatOptionLabel={({ value, label, type, image })=>(
                  <div style={{ display: "flex" }}>
                    {image && <img src={image} style={{height: 48}}/>}
                    <div>{label}</div>
                    <div style={{ marginLeft: "10px", color: "#ccc" }}>{type}</div>
                  </div>
                  )}
              />
            </div>
            {onSave && <div style={{width:'50%', display: "inline-block", textAlign:"right"}}>
              <button onClick={e=>{e.preventDefault(); onSave()}} className="btn-save btn-save-default">SAVE</button>
            </div>}
          </div>
        <span className="ql-formats">
          <select className="ql-font"></select>
          <select className="ql-size"></select>
        </span>
          <span className="ql-formats">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-strike"></button>
        </span>
          <span className="ql-formats">
          <select className="ql-color"></select>
          <select className="ql-background"></select>
        </span>
          <span className="ql-formats">
          <button className="ql-script" value="sub"></button>
          <button className="ql-script" value="super"></button>
        </span>
          <span className="ql-formats">
          <button className="ql-header" value="1"></button>
          <button className="ql-header" value="2"></button>
          <button className="ql-blockquote"></button>
          <button className="ql-code-block"></button>
        </span>
          <span className="ql-formats">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-indent" value="-1"></button>
          <button className="ql-indent" value="+1"></button>
        </span>
          <span className="ql-formats">
          <button className="ql-direction" value="rtl"></button>
          <select className="ql-align"></select>
        </span>
          <span className="ql-formats">
          <button className="ql-link"></button>
          <button className="ql-image"></button>
          <button className="ql-video"></button>
          <button className="ql-formula"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-clean"></button>
        </span>

        </div>
        <div style={{all: 'unset'}} id={'editor'} dangerouslySetInnerHTML={{__html: value}}></div>
      </div>
    </div>
  )
}

export default RichEditor