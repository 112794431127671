/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useQuery } from 'react-apollo'
import { FormDialog, FormSection, FormGroup, Field, Address } from 'lib/form'
import { TextField, PhoneNumber, Checkbox, AvatarUploadField } from 'lib/form/fields'
import { required, email } from 'lib/form/validators'

import { getCompanyDataForCreation, createCompany } from 'api/companies'

import withStyles from '@material-ui/core/styles/withStyles'

import withAppContext from 'lib/withAppContext'

const CompanyCreate = ({ context }) => {
  const { loading, data: { categories = [], resellers: { data: resellers = [] } = {} } = {} } = useQuery(getCompanyDataForCreation)
  const { acl, user } = context
  let initialValues = {
    notifySentAt: new Date()
  }

  if (acl.denyAccess('companies:setOwner')) {
    initialValues = {
      owner: user.id,
      sponsorName: user.name,
      sponsorEmail: user.email,
      sponsorPhone: user.phone,
      sponsorWebsite: user.company && user.company.website,
      sponsorLogo: user.company && user.company.logo,
      notifySentAt: new Date()
    }
  }

  return (
    !loading && (
      <FormDialog
        title="Create Company"
        form="CompanyCreate"
        mutation={[createCompany, { refetchQueries: ['getCompanies'] }]}
        initialValues={initialValues}
        btnSubmitText="Create"
        success="Company created successfully"
      >
        {({ doc, invalid, submitting }) => (
          <React.Fragment>
            <FormGroup title="Company Information">
              {categories && categories.length > 0 && (
                <Field
                  name="categories"
                  component={TextField}
                  label="Categories"
                  options={categories.map(({ id, name, parent }) => ({
                    label: name,
                    value: id,
                    optgroup: (!parent && categories.filter(c => c.parent === name).length > 0)?1:undefined
                  }))}
                  select
                  multiple
                />
              )}
              <Field name="logo" component={AvatarUploadField} label="Company Logo" btnLabel="Upload Logo" />
              <Field name="name" component={TextField} label="Company Name" validate={[required()]} />
              <Field name="website" component={TextField} label="Company Website" />
              <Field name="screenshot" component={TextField} label="Company Screenshot Website" />
              <p className="text-muted">1280x720 size</p>
              <Field name="thumbnail" component={TextField} label="Company Thumbnail Website" />
              <p className="text-muted">320x180 size</p>
              <Field name="email" component={TextField} label="Company Email Address" validate={[email()]} />
              <Field name="phone" component={PhoneNumber} label="Company Cell Phone" />
            </FormGroup>
            <Field name="formattedAddress" component={TextField} label="Full Address" />
            <FormSection name="address">
              <Address title="Company Address" address={(doc && doc.address) || {}} />
            </FormSection>
            <FormGroup title="Sponsor Box">
              <Field name="sponsorBox" component={Checkbox} label="Activation Sponsor Box" />
              {doc && doc.sponsorBox && (
                <React.Fragment>
                  {acl.hasAccess('companies:setOwner') && (
                    <Field name="owner" component={TextField} label="Sponsor Reseller" options={resellers} select validate={[required()]} />
                  )}
                  <Field name="sponsorName" component={TextField} label="Sponsor Name" margin="none" />
                  <Field name="sponsorEmail" component={TextField} label="Sponsor Email Address" validate={[email()]} />
                  <Field name="sponsorPhone" component={PhoneNumber} label="Sponsor Phone Number" />
                  <Field name="sponsorWebsite" component={TextField} label="Sponsor Website" />
                  <Field name="sponsorLogo" component={TextField} label="Sponsor Logo" />
                </React.Fragment>
              )}
            </FormGroup>
            <FormGroup title="Company Representative">
              <Field name="representativeName" component={TextField} label="Name" margin="none" />
              <Field name="representativeEmail" component={TextField} label="Email Address" validate={[email()]} />
              <Field name="representativePhone" component={PhoneNumber} label="Phone Number" />
            </FormGroup>
          </React.Fragment>
        )}
      </FormDialog>
    )
  )
}

const styles = () => ({
  root: {}
})

export default compose(withAppContext, withStyles(styles))(CompanyCreate)
