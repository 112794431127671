import React from 'react'
import { FormDialog } from 'lib/form'

import { buyPhoneNumber } from 'api/phoneNumbers'

import withStyles from '@material-ui/core/styles/withStyles'

const PhoneNumberBuy = ({ classes, match }) => (
  <FormDialog
    title="Buy PhoneNumber"
    form="PhoneNumberBuy"
    mutation={[buyPhoneNumber, { refetchQueries: ['getPhoneNumbers'] }]}
    initialValues={{ userId: match.params.userId }}
    btnSubmitText="Create"
    success="PhoneNumber created successfully"
  >
    {({ doc, invalid, submitting }) => <React.Fragment></React.Fragment>}
  </FormDialog>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(PhoneNumberBuy)
