/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { FormDialog, Field } from 'lib/form'
import { TextField, RadioGroup } from 'lib/form/fields'
import { required, numericality } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { createPlan } from 'api/plans'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { intervalOptions, userAccountTypeOptions } from 'data/options'

const PlanCreate = ({ match }) => (
  <React.Fragment>
    <FormDialog
      title="Create Plan"
      form="PlanCreate"
      mutation={[createPlan, { refetchQueries: ['getPlans'] }]}
      initialValues={{ ...match.params, interval: 1, freq: 'month', setupFee: 0 }}
      btnSubmitText="Create"
      success="Plan created successfully"
    >
      {({ doc, invalid, submitting }) => (
        <React.Fragment>
          <Field name="name" component={TextField} label="Name" validate={[required()]} />
          <Field name="roles" component={TextField} label="Grant Roles" options={userAccountTypeOptions} select multiple />
          <Field
            name="usage_type"
            component={RadioGroup}
            label="Usage Type"
            options={[
              { label: 'Recurring Quantity', value: 'licensed' },
              { label: 'Metered Usage', value: 'metered' }
            ]}
            validate={[required()]}
          />
          <Typography variant="subtitle2" color="inherit">
            Billing interval
          </Typography>
          <Grid container spacing={8}>
            <Grid item>
              <Field name="amount" component={TextField} label="Amount" validate={[required(), numericality()]} />
            </Grid>
            <Grid item>
              <Field name="interval" component={TextField} label="Interval" validate={[required(), numericality()]} normalize={number} />
            </Grid>
            <Grid item>
              <Field name="freq" component={TextField} label="Frequency" options={intervalOptions} select />
            </Grid>
          </Grid>
          <Field name="setupFee" component={TextField} label="Setup Fee" validate={[required(), numericality()]} />
          <Field
            name="billTimes"
            component={TextField}
            label="Billing Times"
            helperText={`Rebill ${doc.billTimes ||
              'infinite'} Times then Cancel Subscription. This means the subscription will stop after it has successfully billed this number of times.`}
            placeholder="Leave blank if infinite billing"
            validate={[numericality({ '>': 0 })]}
            normalize={number}
          />
          <Field name="trial_period_days" component={TextField} label="Trial period" validate={[numericality()]} normalize={number} />
        </React.Fragment>
      )}
    </FormDialog>
  </React.Fragment>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(PlanCreate)
