/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, numericality, email, url } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getDataCreationMonitoringObject, createMonitoringObject } from 'api/monitoringObjects'

import withStyles from '@material-ui/core/styles/withStyles'

import { monitoringObjectTypeOptions, monitoringObjectAccountTypeOptions } from 'data/options'

const MonitoringObjectAdd = ({ match, history }) => (
  <Query query={getDataCreationMonitoringObject} fetchPolicy="cache-and-network">
    {({ data: { products: { data: products } = {}, sources: { data: sources } = {} } = {}, loading }) => {
      if (loading) return null

      return (
        <FormDialog
          onComplete={() => history.push(`/monitoring-objects${match.params.filter?`?filter=${match.params.filter}`:''}`)}
          title="Create Monitoring Object"
          subtitle="Adding a monitoring object is the item you want ChatFortress to scan the dark web for. This could be for any reference to your email or domain name. When you add a domain name we will search all databases for mention of any email address associated to your domain name. You don't need a persons permission to add a domain name or email as this is public information."
          form="MonitoringObjectAdd"
          mutation={[createMonitoringObject, { refetchQueries: ['getMonitoringObjects'] }]}
          initialValues={{
            type: match.params.filter || 'email',
            accountType: match.params.filter === 'domain' ? 'company' : match.params.filter || 'personal'
          }}
          btnSubmitText="Create"
          success="MonitoringObject created successfully"
        >
          {({ doc, invalid, submitting }) => (
            <React.Fragment>
              <Field name="name" component={TextField} label="Monitoring Object Name" />
              <Field
                name="type"
                component={TextField}
                label="Monitoring Object Type"
                options={monitoringObjectTypeOptions}
                validate={[required()]}
                select
              />
              {doc.type === 'email' && (
                <React.Fragment>
                  <Field name="email" component={TextField} label="Email Address" validate={[required(), email()]} />
                </React.Fragment>
              )}
              {doc.type !== 'email' && (
                <React.Fragment>
                  <Field
                    name="domain"
                    component={TextField}
                    label="Domain Name"
                    helperText="Please enter the domain name with or without the www. for example chatfortress.com. You can enter a full domain with https:// or without http:// also."
                    validate={[
                      required(),
                      url({
                        emptyProtocol: false,
                        protocolIdentifier: false,
                        host: true,
                        path: false,
                        search: false,
                        hash: false
                      })
                    ]}
                  />
                </React.Fragment>
              )}
              <Field
                name="accountType"
                component={TextField}
                label="Monitoring Object Account Type"
                options={monitoringObjectAccountTypeOptions}
                validate={[required()]}
                select
              />
            </React.Fragment>
          )}
        </FormDialog>
      )
    }}
  </Query>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(MonitoringObjectAdd)

// onComplete={() => (window.location.href = `/account/monitoringObjects/add`)}
//{/*<Redirect from={} to={} />*/}
