/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Nav from 'components/Nav'

import Lessons from 'components/Lessons'
import Schedules from 'components/Schedules'
import CourseUpdate from './CourseUpdate'

export default ({ match, history, location }) => (
  <React.Fragment>
    <Nav
      color="primary"
      items={[
        { key: 'update', title: 'Configure', to: `${match.url}/update` },
        { key: 'lessons', title: 'Lessons', to: `${match.url}/lessons` },
        { key: 'schedule', title: 'Schedule', to: `${match.url}/schedule` }
      ]}
      activeKey={location.pathname.split('/')[4]}
    />
    <Switch>
      <Route path={`${match.path}/update`} component={CourseUpdate} />
      <Route path={`${match.path}/lessons`} component={Lessons} />
      <Route path={`${match.path}/schedule`} component={Schedules} />
    </Switch>
  </React.Fragment>
)
