/** @format */

import React from 'react'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const FormGroup = ({ title, children }) => (
  <div style={{ marginBottom: 10 }}>
    <Typography variant="h6" style={{ margin: '0 0 -13px 20px', backgroundColor: 'white' }}>
      {title}
    </Typography>
    <Paper style={{ padding: '20px 10px 10px' }}>{children}</Paper>
  </div>
)

export default FormGroup
