/** @format */
import React from 'react'

const tpl = ({ proposal, flatPrice, unitPrice, unitsAmount }) => {
  return (
    <div>
      <section className="fdb-block py-4">
        <div className="container">
          <h1> Email Guardian Small Business Program is real-time threat detection and protection for your email inbox.</h1>
          <p className="lead">
            {' '}
            <span className="h3">9 out of 10 cyberattacks occur via your email inbox</span>. With Email Guardian you can detect and remove
            these threats in 3 seconds or less.
          </p>
          <p>
            {' '}
            All Email Guardian Small Business Programs require a valid form of payment to be attached to the acceptance agreement. This can
            be either a credit card or bank account ACH. This payment source will be charged on acceptance to start your program.{' '}
          </p>
          <p>
            {' '}
            Based on the Email Guardian Small Business program plan you choose will determine the features you have access too. You can
            upgrade your account at any point in time. Your are charged for mailboxes that are active on your account.{' '}
          </p>
          <p>
            The Email Guardian Small Business Scholarship provides you with a discounted access to the Email Guardian program. Email
            Guardian Small Business Scholarship accounts gain instant access to the Email Guardian Suite without the Real-Time Virtual
            Security Analyst that Email Guardian enterprise accounts use. A Email Guardian Virtual Security Analyst will review you email
            threats once per day for a maximum 15 minutes. You will receive a Virtual Security Analyst threat response time of 3 business
            days for emails that are reported via the Report Phish button inside your email inbox.
          </p>
          <h2> Measuring the success of Email Guardian Small Business Program: </h2>
          <p>
            {' '}
            Here is what we do for you - We measure a successful Email Guardian Small Business Program by using all of the following
            metrics:{' '}
          </p>
          <ul>
            <li> Successful installation of the email Guardian system and identifying any malware/phishing attacks. </li>
            <li>
              {' '}
              Actively scan, clean and remove any infections/threats from your network and each of the listed email accounts from the last
              12 months.{' '}
            </li>
            <li> Create a personal company risk profile to show any phishing attacks, impersonation attacks, malware attacks, etc. </li>
            <li>
              {' '}
              Implementing an educational cyber security awareness program each month for 12 months to enlighten your employee’s cyber
              security awareness within the company.{' '}
              <i>
                (NOTE: this cyber security training will successfully address/identify phishing type emails along with the dangers of social
                engineering and how it all affects everyone personally/professionally within the company.) You will be provided with access
                to the ChatFortress Cybersecurity Awareness Monthly program inside your ChatFortress account. You are responsible to
                instructing your employee's to complete the training provided.
              </i>{' '}
            </li>
          </ul>
          <h2> How do I contact ChatFortress? </h2>
          <p> You can contact the ChatFortress team using the following communication channels: </p>
          <ol>
            <li> Website Live Chat at www.ChatFortress.com</li>
            <li> Email: help@ChatFortress.com </li>
            <li> Phone: USA (307) 999-7755</li>
          </ol>
          <h2> How do I cancel the Email Guardian Small Business program? </h2>
          <p>
            {' '}
            To cancel the Email Guardian POC you need to provide written notice to{' '}
            <a href="mailto:help@chatfortress.com">help@chatfortress.com</a>. If you want to cancel an active subscription you need to
            provide 30 days written notice to cancel your ChatFortress account. There are no refunds, or partial credits, or pro-rata
            accounting for amounts paid. You pay for a month in advance based on the number of active mailboxes within your email system.
          </p>
          <h2>Training and Awareness Disclaimer:</h2>
          <p>
            <span className="h3">Applies to Scaling Plans only:</span> You understand and accept that ChatFortress will conduct phishing
            training and awareness of all of the active emails that have been attached to your ChatFortress account for the purpose of the
            ChatFortress Email Guardian program.{' '}
          </p>
          <ul>
            <li>
              This means but not limited to you accept and give permission for ChatFortress to send phishing emails to your employees,
              contractors, independent contracts and individuals using the active email address you have provided ChatFortress via the Email
              Guardian program.
            </li>
            <li>
              These emails may be received on any device that the user accesses emails. This includes but is not limited to: mobile (cell)
              phones, tablets, desktops, personal and work devices.{' '}
            </li>
            <li>
              You will inform your company members that phishing awareness training is in place on an ongoing basis while you have an active
              ChatFortress subscription.{' '}
            </li>
            <li>
              These phishing emails are for the purpose of training and education only. Any data or information that is collected during
              this training is for training purposes only and will remain confidential. Any and all information that is collected will be
              reported to the Client directly and deleted from the ChatFortress system.{' '}
            </li>
            <li>
              All of the ChatFortress Iron School training and awareness phishing emails will be indicated visually once a person clicks on
              the email. This will display a training video for users to learn from the phishing awareness test.{' '}
            </li>
          </ul>
          <h3>
            The success of the ChatFortress Phishing training and awareness program is increased when your leadership teams verbally show
            support to the program.
          </h3>
          <p>The following recommendations are in place: </p>
          <ul>
            <li>Verbally share the lessons you learn with your team., </li>
            <li>
              Have leadership share their results good and bad with their fellow team members. This promotes trust and awareness amongst
              your team.{' '}
            </li>
            <li>Do not ridicule or embarrass team members who have successfully been phished. </li>
            <li>
              Remember 75% of people will be, can be phished at any point in time. Phishing manipulated human behaviors. The goal of this
              program is to increase awareness of phishing attacks and ensure your team know what do to if they have been attacked, or how
              to report an email attack.
            </li>
            <li>ChatFortress does not recommend allowing any user to opt-out of the ChatFortress phishing awareness training. </li>
          </ul>
          <h2>Indemnity and Hold Harmless</h2>
          <ul>
            <li>
              The client indemnifies and holds harmless ChatFortress from any and all liabilities, losses, damages, costs and expenses
              (including reasonable attorneys’ and experts’ fees) incurred by them pursuant to any of the phishing awareness email training.
            </li>
            <li>
              ChatFortress is not liability or responsible for action that users take when they receive emails used as part of the phishing
              awareness training.
            </li>
          </ul>
          <h2>Cybersecurity Awareness Monthly</h2>
          <p>
            You will be provided with access to the ChatFortress Cybersecurity Awareness Monthly program inside your ChatFortress account.
            Each one of your active licenses will be able tin invite 7 friends or family members to access the training as part of the
            active subscription.{' '}
          </p>
          <ul>
            <li>
              You authorize and allow ChatFortress to deliver to your employees, contractors, and users added to your account a text message
              (sms) for the consumption of the training content as part of the Cyber Security Awareness Monthly program.{' '}
            </li>
            <li>You authorize the deliver of up to one (1) text message conversation for this content each week.</li>
            <li>You authorize that you have permission to opt-in your employees, contractors and users to this for of content. </li>
            <li>
              Your users can opt-out of receiving these training messages each week. You will be informed via the training dashboard which
              users have opt-out of text message notifications.{' '}
            </li>
            <li>
              You consent to send 1 email to your email each month alerting your team members of the Cyber Security Awareness Monthly
              training release.{' '}
            </li>
          </ul>
          <div className="text-center">
            <a href="/termsofservice">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default tpl
