/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Invoices from './Invoices'

export default ({ match }) => (
  <React.Fragment>
    <Switch>
      <Route path={`${match.path}`} component={Invoices} />
    </Switch>
  </React.Fragment>
)
