import React from 'react'

import LinkifyIt from 'linkify-it'
const linkify = new LinkifyIt()

const Linkify = ({ properties, children }) => {
  let content = children
  if (!content) return null
  //content = ReactDOMServer.renderToStaticMarkup(content)
  let matches = linkify.match(content)

  let result = []
  let last = 0
  let out = ''

  if (matches) {
    last = 0
    matches.forEach(function(match) {
      if (last < match.index) {
        result.push(content.slice(last, match.index).replace(/\r?\n/g, '<br>'))
      }
      result.push('<a target="_blank" href="')
      result.push(match.url)
      result.push('">')
      result.push(match.text)
      result.push('</a>')
      last = match.lastIndex
    })
    if (last < content.length) {
      result.push(content.slice(last).replace(/\r?\n/g, '<br>'))
    }
    out = result.join('')
  }

  return <span {...properties} dangerouslySetInnerHTML={{ __html: out || content }}></span>
}

export default Linkify
