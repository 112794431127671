/** @format */
import React from 'react'

const tpl = ({ proposal, flatPrice, unitPrice, unitsAmount }) => {
  return (
    <div>
      <h1>ChatFortress Cybersecurity Report Card Reseller Program Acceptance</h1>
      <p>
        Your acceptance of this proposal is also an acceptance of our terms of service and privacy policy. Your account will be activated
        based on how it is explained in this proposal. It can take 72 business hours to activate your account.{' '}
      </p>
      <p>
        You accept the proposal by signing below and attaching your payment. This will start the process and move your account into pending
        activation status. You will receive an email confirmation within 24 hours, along with an introduction email for your dedicated
        account manager.
      </p>
      <h2>Next Steps Explained</h2>
      <p>
        After you click the acceptance button, you will be redirected to a page that allows you to schedule your ChatFortress Cybersecurity
        Report Card Reseller Program onboarding session. This is where your account manager will configure your account. It will take less
        than 60 minutes in total. You will gain instant access to the ChatFortress Cybersecurity Report Card Reseller Program training
        within your ChatFortress account.{' '}
      </p>
      <h2>Payment Authorization Disclaimer</h2>
      <p>
        Recurring Credit Card Charge Authorization Form I (we) hereby authorize ChatFortress LLC. to make recurring charges to my Credit
        Card listed below, and, if necessary, initiate adjustments for any transactions credited/debited in error. This authority will
        remain in effect until ChatFortress LLC. is notified by me (us) in writing to cancel it in such time as to afford ChatFortress LLC.
        and Credit Card company a reasonable opportunity to act on it. ChatFortress.com is a Product owned by ChatFortress LLC. All billing,
        support and account activation for the ChatFortress product will be billed and completed by ChatFortress LLC or it's nominated
        representative. ChatFortress has Certified and Appointed Representatives which are paid a commission on sale by ChatFortress LLC.{' '}
      </p>
      <h2>Acceptance and Payment.</h2>
      <h2>Enter your payment details (Credit Card or Bank ACH)</h2>
      <p>
        You only need to attach one payment source at this point in time. Either Credit Card or Bank ACH, not both. You can edit the payment
        methods within your ChatFortress account.{' '}
      </p>
    </div>
  )
}

export default tpl
