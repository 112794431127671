import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Lists from './Lists'
import ListContacts from './ListContacts'

export default ({ match, location }) => {
  const listId = location.pathname.split('/')[3] || null
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item md={2} >
          <Lists />
        </Grid>
        {listId && <Grid item md={10}>
          <ListContacts listId={listId}/>
        </Grid>}
      </Grid>
    </Container>
  )
}
