/** @format */

import React from 'react'
import { Field, FormSection, Address } from 'lib/form'
import { TextField, PhoneNumber, AvatarUploadField } from 'lib/form/fields'
import { required, email } from 'lib/form/validators'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import FormGroup from './FormGroup'

import { timezoneOptions } from 'data/options'

class Profile extends React.Component {
  static defaultProps = {
    name: ''
  }

  render() {
    const { doc } = this.props

    return (
      <React.Fragment>
        <FormGroup title="Contact Information">
          <Field name="avatar" component={AvatarUploadField} label="Avatar" />
          <Grid spacing={1} container>
            <Grid xs={6} item>
              <Field name="firstName" component={TextField} label="First Name" validate={[required()]} />
            </Grid>
            <Grid xs={6} item>
              <Field name="lastName" component={TextField} label="Last Name" validate={[required()]} />
            </Grid>
          </Grid>
          <Grid spacing={1} container>
            <Grid xs={6} item>
              <Field name="email" component={TextField} label="Primary Email Address" validate={[required(), email()]} />
            </Grid>
            <Grid xs={6} item>
              <Field name="workEmail" component={TextField} label="Work Email Address" validate={[email()]} />
            </Grid>
          </Grid>
          <Grid spacing={1} container>
            <Grid xs={6} item>
              <Field name="phone" component={PhoneNumber} label="Cell Phone" validate={[required()]} />
            </Grid>
            <Grid xs={6} item>
              <Field name="workPhone" component={PhoneNumber} label="Work Phone" />
            </Grid>
          </Grid>
          <Field name="timezone" component={TextField} label="Timezone" options={timezoneOptions} select />
        </FormGroup>
        <FormSection name="mailAddress">
          <Address title="Mail Address" address={doc.mailAddress} />
        </FormSection>
      </React.Fragment>
    )
  }
}

export default Profile
