/** @format */

import React from 'react'
import compose from 'recompose/compose'
import classnames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

import withAppContext from 'lib/withAppContext'
import Avatar from 'lib/Avatar'

import MainMenu from './MainMenu'
import menuContent from 'data/menu'

const SidebarMenu = ({ classes, user, menuContent, acl, handleDrawerClose }) => (
  <React.Fragment>
    <div className={classes.toolbarIcon}>
      <Avatar {...user} size={40} />
      <Typography style={{ marginLeft: 7 }}>{user.name}</Typography>
    </div>
    <Divider />
    <MainMenu menuContent={menuContent.filter(item => acl.hasAccess(item.scope))} onClick={handleDrawerClose} />
  </React.Fragment>
)

const Sidebar = ({ context: { user, acl }, classes, open, mobileOpen, handleMobileDrawerToggle, handleMobileDrawerClose }) => {
  const sidebarMenuProps = {
    classes,
    user,
    menuContent,
    acl
  }

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="js">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleMobileDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
          anchor="left"
        >
          <SidebarMenu {...sidebarMenuProps} handleDrawerClose={handleMobileDrawerClose} />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classnames(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          anchor="left"
          open={open}
        >
          <SidebarMenu {...sidebarMenuProps} />
        </Drawer>
      </Hidden>
    </nav>
  )
}

const styles = theme => ({
  drawer: {
    flexShrink: 0
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    height: '100vh',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: theme.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  }
})

export default compose(withAppContext, withStyles(styles))(Sidebar)
