/** @format */

import gql from 'graphql-tag'

export const commissionFragment = gql`
  fragment commission on Commission {
    invoiceId
    chargeId
    status
    description
    commissionAmount
    currency
    notes
  }
`
export const getCommissions = gql`
  query getCommissions($skip: Int, $limit: Int, $sort: [String]) {
    commissions(skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        user {
          id
          name
          avatar
          email
        }
        invoice {
          id
          number
          invoice_pdf
        }
        status
        invoiceStatus
        currency
        totalAmount
        commissionAmount
        commissionPercent
        description
        notes
        reference
        createdAt
      }
      count
      pages
    }
  }
`

export const getCommission = gql`
  query getCommission($commissionId: String!) {
    commission(id: $commissionId) {
      id
      ...commission
    }
  }
  ${commissionFragment}
`

export const getDataCreationCommission = gql`
  query getDataCreationCommission {
    invoices(limit: 0, sort: ["name", "1"]) {
      data {
        id
        name
      }
    }
  }
`

export const addCommission = gql`
  mutation addCommission($data: CommissionInput!) {
    createCommission(data: $data) {
      id
      ...commission
    }
  }
  ${commissionFragment}
`

export const getDataUpdatingCommission = gql`
  query getDataUpdatingCommission($commissionId: String!) {
    commission(id: $commissionId) {
      id
      ...commission
    }
  }
  ${commissionFragment}
`

export const updateCommission = gql`
  mutation updateCommission($commissionId: String!, $data: CommissionInput!) {
    updateCommission(id: $commissionId, data: $data) {
      id
      ...commission
    }
  }
  ${commissionFragment}
`

export const removeCommission = gql`
  mutation removeCommission($id: String!) {
    removeCommission(id: $id) {
      id
    }
  }
`

export const onCreateCommission = (cache, { data: { addCommission: commission } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { commissions } = cache.readQuery({ query: getCommissions, variables })
  cache.writeQuery({
    query: getCommissions,
    variables,
    data: {
      commissions: {
        ...commissions,
        data: [commission].concat(commissions.data)
      }
    }
  })
}

export const onRemoveCommission = (cache, { data: { removeCommission: commission } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { commissions } = cache.readQuery({ query: getCommissions, variables })
  cache.writeQuery({
    query: getCommissions,
    variables,
    data: {
      commissions: {
        ...commissions,
        data: commissions.data.filter(r => r.id !== commission.id)
      }
    }
  })
}
