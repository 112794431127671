/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query, Mutation } from 'react-apollo'
import moment from 'moment'
import CheckboxTable from 'lib/tables/CheckboxTable'
import withConfirm from 'lib/withConfirm'

import { getSchedules, getDataSchedules, removeSchedule } from 'api/schedules'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const Schedules = ({ classes, history, match, confirm }) => (
  <Query query={getDataSchedules} variables={match.params}>
    {({ data: { course } = {}, loading }) =>
      !loading && (
        <React.Fragment>
          <Mutation mutation={removeSchedule} refetchQueries={['getSchedules']}>
            {(remove, { loading: loadingRemove }) => (
              <CheckboxTable
                query={getSchedules}
                variables={{ courseId: match.params.courseId }}
                initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
                columns={[
                  {
                    Header: 'Lesson Name',
                    accessor: 'lesson.name'
                  },
                  {
                    Header: 'Attendee',
                    id: 'user.name',
                    accessor: schedule => schedule.user.name
                  },
                  {
                    Header: 'Schedule Date',
                    id: 'scheduleDate',
                    accessor: schedule => moment(schedule.scheduleDate).format('LLL')
                  },
                  {
                    Header: 'Status',
                    id: 'sent',
                    accessor: schedule => (schedule.sent ? 'Sent' : 'Pending')
                  },
                  {
                    Header: 'Actions',
                    sortable: false,
                    filterable: false,
                    accessor: 'id',
                    Cell: ({ original: schedule }) => {
                      return (
                        <div>
                          <IconButton
                            color="secondary"
                            onClick={confirm(() => remove({ variables: { id: schedule.id } }), {
                              title: 'Schedule Remove',
                              description: 'Are you sure you want to delete this Schedule?'
                            })}
                            title="Remove Schedule"
                            disabled={loadingRemove}
                          >
                            <Icon fontSize="small">delete</Icon>
                          </IconButton>
                        </div>
                      )
                    }
                  }
                ]}
                actions={[
                  <Button
                    key="create"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => history.push(`${match.url}/create`)}
                  >
                    <Icon>add</Icon>
                    Create Schedule
                  </Button>
                ]}
                deleteMany={false}
              />
            )}
          </Mutation>
        </React.Fragment>
      )
    }
  </Query>
)

const styles = theme => ({
  root: {}
})

export default compose(
  withConfirm,
  withStyles(styles)
)(Schedules)
