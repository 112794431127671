/** @format */

import gql from 'graphql-tag'

export const userFragment = gql`
  fragment user on User {
    name
    firstName
    lastName
    email
    workEmail
    phone
    workPhone
    avatar
    timezone
    accountType
    roles
    schedulePeriod
    masterAccountId
    mailAddress {
      addressLine1
      addressLine2
      city
      state
      county
      zip
      country
    }
    companyId
    company {
      id
      name
      website
      email
      phone
      logo
      address {
        addressLine1
        addressLine2
        city
        state
        county
        zip
        country
      }
      representativeEmail
      representativeName
      representativePhone
    }
    rcSettings {
      logo
      phone
      businessPhone
      email
      recommendations
      scheduleLink
      scheduleBtnText
      scheduleHeadline
      scheduleDescription
      btnText1
      btnText2
      faqs
      ads
    }
    notifyByEmail
    notifyBySMS
  }
`

export const getUsers = gql`
  query getUsers($name: String, $role: String, $roles: [String], $selector: Boolean, $skip: Int, $limit: Int, $sort: [String]) {
    users(name: $name, role: $role, roles: $roles, skip: $skip, selectorField: $selector, limit: $limit, sort: $sort) {
      data {
        id
        name
        email
        phone
        avatar
        accountType
        roles
        timezone
        schedulePeriod
        company {
          id
          name
          website
          email
          phone
          logo
        }
        masterAccount {
          id
          name
          email
          phone
          avatar
          accountType
        }
      }
      count
      pages
    }
  }
`

export const getUser = gql`
  query getUser($userId: String!) {
    user(id: $userId) {
      id
      ...user
    }
  }
  ${userFragment}
`

export const getDataCreationUser = gql`
  query getDataCreationUser {
    products(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
        plans {
          value: id
          label: name
        }
      }
    }
    companies(limit: 10, sort: ["name", "1"]) {
      data {
        id
        name
      }
    }
  }
`

export const getDataUpdatingUser = gql`
  query getDataUpdatingUser($userId: String!) {
    user(id: $userId) {
      id
      ...user
    }
  }
  ${userFragment}
`

/*
    companies(limit: 0, sort: ["name", "1"]) {
      data {
        id
        name
      }
    }
*/

export const createUser = gql`
  mutation createUser($data: UserInput!) {
    createUser(data: $data) {
      id
      ...user
    }
  }
  ${userFragment}
`

export const updateUser = gql`
  mutation updateUser($userId: String!, $data: UserInput!) {
    updateUser(id: $userId, data: $data) {
      id
      ...user
    }
  }
  ${userFragment}
`

export const applyCouponUser = gql`
  mutation applyCouponUser($userId: String!, $data: CouponInput!) {
    applyCouponUser(userId: $userId, data: $data) {
      id
    }
  }
`

export const addUserPaymentMethod = gql`
  mutation addUserPaymentMethod($userId: String, $data: PaymentMethodInput!) {
    addUserPaymentMethod(userId: $userId, data: $data) {
      id
    }
  }
`

export const removeUser = gql`
  mutation removeUser($id: String!) {
    removeUser(id: $id) {
      id
    }
  }
`

export const onCreateUser = (cache, { data: { createUser: user } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { users } = cache.readQuery({ query: getUsers, variables })
  cache.writeQuery({
    query: getUsers,
    variables,
    data: {
      users: {
        ...users,
        data: [user].concat(users.data)
      }
    }
  })
}
