/** @format */

import gql from 'graphql-tag'

export const monitoringObjectFragment = gql`
  fragment monitoringObject on MonitoringObject {
    name
    type
    accountType
    email
    domain
    active
    userId
  }
`
export const getMonitoringObjects = gql`
  query getMonitoringObjects($userId: String, $q: String, $type: String, $skip: Int, $limit: Int, $sort: [String]) {
    monitoringObjects(userId: $userId, q: $q, type: $type, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        email
        domain
        type
        accountType
        active
        user {
          id
          name
          email
          avatar
        }
        breachesCnt
      }
      count
      pages
    }
  }
`

export const getMonitoringObject = gql`
  query getMonitoringObject($monitoringObjectId: String!) {
    monitoringObject(id: $monitoringObjectId) {
      id
      ...monitoringObject
    }
  }
  ${monitoringObjectFragment}
`

export const getDataCreationMonitoringObject = gql`
  query getDataCreationMonitoringObject {
    sources(limit: 0) {
      data {
        id
        displayName
        object
        status
      }
    }
  }
`

export const createMonitoringObject = gql`
  mutation createMonitoringObject($data: MonitoringObjectInput!) {
    createMonitoringObject(data: $data) {
      id
      ...monitoringObject
    }
  }
  ${monitoringObjectFragment}
`

export const getDataUpdatingMonitoringObject = gql`
  query getDataUpdatingMonitoringObject($monitoringObjectId: String!) {
    monitoringObject(id: $monitoringObjectId) {
      id
      ...monitoringObject
    }
  }
  ${monitoringObjectFragment}
`

export const updateMonitoringObject = gql`
  mutation updateMonitoringObject($monitoringObjectId: String!, $data: MonitoringObjectInput!) {
    updateMonitoringObject(id: $monitoringObjectId, data: $data) {
      id
      ...monitoringObject
    }
  }
  ${monitoringObjectFragment}
`

export const removeMonitoringObject = gql`
  mutation removeMonitoringObject($id: String!) {
    removeMonitoringObject(id: $id) {
      id
    }
  }
`

export const onCreateMonitoringObject = (cache, { data: { createMonitoringObject: monitoringObject } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { monitoringObjects } = cache.readQuery({ query: getMonitoringObjects, variables })
  cache.writeQuery({
    query: getMonitoringObjects,
    variables,
    data: {
      monitoringObjects: {
        ...monitoringObjects,
        data: [monitoringObject].concat(monitoringObjects.data)
      }
    }
  })
}

export const onRemoveMonitoringObject = (cache, { data: { removeMonitoringObject: monitoringObject } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { monitoringObjects } = cache.readQuery({ query: getMonitoringObjects, variables })
  cache.writeQuery({
    query: getMonitoringObjects,
    variables,
    data: {
      monitoringObjects: {
        ...monitoringObjects,
        data: monitoringObjects.data.filter(r => r.id !== monitoringObject.id)
      }
    }
  })
}
