/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Route, Switch } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Login from './Login'
import Signup from './Signup'
import Token from './Token'
// import ForgotPwd from './ForgotPwd'
// import ResetPwd from './ResetPwd'

const Auth = ({ classes, match }) => (
  <Container maxWidth="sm">
    <Box my={4}>
      <Switch>
        <Route path={`${match.path}/login`} component={Login} />
        <Route path={`${match.path}/signup`} component={Signup} />
        <Route path={`${match.path}/token/:token/:path?`} component={Token} />
        {/*<Route path={`${match.path}/forgot-password`} component={ForgotPwd} />
        <Route path={`${match.path}/reset-password/:token`} component={ResetPwd} />*/}
      </Switch>
    </Box>
  </Container>
)

const styles = theme => ({
  root: {
    width: '100%'
  }
})

export default compose(withStyles(styles))(Auth)
