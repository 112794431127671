/** @format */

import _ from 'lodash'
import React, { useEffect } from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import {Form, Field, FieldArray, FormGroup} from 'lib/form'
import { TextField, Checkbox } from 'lib/form/fields'
import { required, url, numericality } from 'lib/form/validators'

import { getTemplate, updateTemplate } from 'api/templates'
import { useMutation } from 'react-apollo'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import RichEditor from "components/RichEditor"
import Icon from "@material-ui/core/Icon";
import placeholdersObj  from "./placeholders.json"


const placeholdersTemp = [
  {id: 'company.name', label: 'Company Name', type: "plain", group: "Company"},
  {id: 'company.logo', label: 'Company Logo', type: "image", group: "Company"},
  {id: 'deviceList', label: 'Device List', type: "block", group: "Scope Data"},
  {id: 'quiz[AC.1.001]', label: 'AC.1.001 Quiz', type: "block", group: "Gap Analysis"},
  {id: 'quiz[AC.1.002]', label: 'AC.1.002 Quiz', type: "block", group: "Gap Analysis"},
  {id: 'quiz[AC.1.003]', label: 'AC.1.003 Quiz', type: "block", group: "Gap Analysis"},
]


const placeholders = Object.values(placeholdersObj).map((p)=>{
  let image=''
  p.Image = p['Sample Image: Load to GRC/CF-Master-Templates-MergeVariable-Images folder']
  //console.log(p.Image)
  if(p.Image){
    const regex_ids = /\/file\/d\/([^\/]+)/;//or new RegExp("/file/d/([a-zA-Z0-9_]+)","g");() =capture ids
    let preId = regex_ids.exec(p.Image);
    if(preId && preId[1])
     image = `https://lh3.googleusercontent.com/d/${preId[1]}=h150?authuser=0`
  }
  let type=(p['Type(one of [plain|block|image|url|...])']||'block').toLowerCase().trim()
  if(type==='plain text') type='plain'
  if(type==='table / image' || type==='table') type='block'

  return {
    id: p['ID'],
    label: p['Label (Name of Variable)'],
    type,
    group: p['Group'],
    description: p['Description (of Variable)'],
    image
  }
})

console.log(placeholders)

const TemplateUpdate = ({ classes, match }) => {

  const [body, setBody] = React.useState('')
  const [save, { loading: saving }] = useMutation(updateTemplate, { refetchQueries: ['getTemplate'] })

  return (
    <React.Fragment>
      <Query query={getTemplate} variables={match.params}>
        {({data: {template} = {}, loading}) => {
          if(loading) return null
          if(!body)
            setBody(template.body||'<p>Start typing here...</p>')

          return !loading && (
            <Form
              title={`Template`}
              form="TemplateUpdate"
              mutation={[updateTemplate]}
              initialValues={_.omit(template, ['id'])}
              success="Template updated successfully"
            >
              {({doc, invalid, submitting, change, submit, ...rest}) => {
                return (
                  <React.Fragment>
                    <Field name="name" component={TextField} label="Template Name" validate={[required()]}/>
                    <Field name="description" component={TextField} multiline rows={3} label="Template Description"/>
                    <h3>Editor</h3>

                    {!loading && <Paper className="p-2 " style={{margin:'auto', maxWidth: 595*2}}>
                      <RichEditor onSave={()=>{
                        let d = _.cloneDeep(doc)
                        d.body=body
                        save({ variables: {templateId: template.id, data: d}})}}
                       placeholders={placeholders} value={template.body} stickyToolbar={true} stickyOffset={61} stickyElement={document.getElementsByTagName("main")[0]} onChange={(html)=>setBody(html)}/>

                    </Paper>}
                  </React.Fragment>
                )
              }
              }
            </Form>
          )}
        }
      </Query>
    </React.Fragment>
  )
}

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(TemplateUpdate)
