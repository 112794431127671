/** @format */

import React from 'react'
import { Field } from 'lib/form'
import { TextField, RadioGroup, CheckboxesGroup } from 'lib/form/fields'
import * as validators from 'lib/form/validators'

const QuestionInput = ({ ignoreCorrect, question = {} }) => {
  const questionValidatorRules =
    typeof question.validate === 'string'
      ? validators[question.validate] && validators[question.validate]()
      : Object.keys(question.validate || {}).map(ruleName =>
          validators[ruleName] ? validators[ruleName](question.validate[ruleName]) : null
        )
  const validate = [validators.required()].concat(questionValidatorRules)

  // Add correctAnswers validation
  if (!ignoreCorrect) {
    validate.push(validators.correctAnswers({ answers: question.answers }))
  }

  return (
    <React.Fragment>
      {question.type === 'text' && <Field name="answer" component={TextField} label="Type your answer here..." validate={validate} />}
      {question.type === 'select' && (
        <Field name="answer" component={TextField} label="Type your answer here..." options={question.answers} validate={validate} select />
      )}
      {question.type === 'radio' && (
        <Field
          name="answer"
          component={RadioGroup}
          options={question.answers.map(({ label, value }) => ({ label, value }))}
          validate={validate}
        />
      )}
      {(question.type === 'checkboxes' || question.type === 'checkbox') && (
        <Field
          name="answer"
          component={CheckboxesGroup}
          //label="Type your answer here..."
          options={question.answers.map(({ label, value }) => ({ label, value }))}
          validate={validate}
        />
      )}
    </React.Fragment>
  )
}

export default QuestionInput
