/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field, FormSection, PaymentMethod } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, numericality, length } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'
import withAppContext from 'lib/withAppContext'

import { addLicense } from 'api/licenses'
import { getDataBuyingCourse } from 'api/courses'
import { createCCToken } from 'api/actions'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import PlanSummary from 'components/Plans/Summary'
import Spinner from 'components/Spinner'

const LicenseAdd = ({ match, context }) => {
  const { user } = context
  return (
    <Query query={getDataBuyingCourse} variables={{ ...match.params, masterAccountId: user.id }} fetchPolicy="cache-and-network">
      {({
        data: { course = {}, products: { data: products } = {}, employees: { data: employees } = {}, sources: { data: sources } = {} } = {},
        loading
      }) => {
        return loading ? (
          <Spinner />
        ) : (
          <FormDialog
            title={course.name}
            form="BuyingCourse"
            mutation={[addLicense, { refetchQueries: ['getTrainingCourses'] }]}
            onSubmit={({ data, mutation }) =>
              createCCToken(data.source).then(({ token }) => mutation({ variables: { data: { ...data, source: token } } }))
            }
            initialValues={{
              name: course.name,
              productId: course.productId,
              employees: employees.map(({ id }) => id),
              paymentMethod: sources && sources.length > 0 ? sources[0].id : null
            }}
            btnSubmitText="Buy Course"
            success="Course purchased successfully"
          >
            {({ doc, invalid, submitting }) => {
              let product = null
              let plan = null
              if (doc.productId) {
                product = products.find(it => it.id === doc.productId)
              }
              if (product && doc.planId) {
                plan = product.plans.find(it => it.id === doc.planId)
              }

              return (
                <React.Fragment>
                  <Typography variant="h5">License Information</Typography>
                  <Field
                    name="name"
                    component={TextField}
                    label="License Name"
                    validate={[required()]}
                    helperText="Enter a name for your reference that helps you understand who you are applying these licenses for. This name if for your reference only."
                  />
                  {doc.productId && (
                    <Field
                      name="planId"
                      component={TextField}
                      label="Plan"
                      options={product && product.plans.map(({ id, name }) => ({ label: name, value: id }))}
                      validate={[required()]}
                      select
                    />
                  )}
                  <Field name="coupon" component={TextField} label="Coupon Code" placeholder="Left empty if you dont know" />
                  {plan && product && <PlanSummary plan={plan} product={product} quantity={doc.employees.length} />}

                  <Typography variant="h5">Employees</Typography>
                  <Field
                    name="employees"
                    component={TextField}
                    label="Select Employees for auto assign"
                    validate={[length({ max: employees.length })]}
                    options={employees.map(({ id, name }) => ({ label: name, value: id }))}
                    multiple
                    select
                  />

                  <FormSection name="">
                    <PaymentMethod sources={sources} paymentMethod={doc.paymentMethod} />
                  </FormSection>
                </React.Fragment>
              )
            }}
          </FormDialog>
        )
      }}
    </Query>
  )
}

const styles = () => ({
  root: {}
})

export default compose(withAppContext, withStyles(styles))(LicenseAdd)
