/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Switch, Redirect } from 'react-router-dom'
import Route from 'lib/Route'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import AppBar from 'components/AppBar'
import Sidebar from 'components/Sidebar'
import Footer from 'components/Footer'

import { AdminDashboard, ClientDashboard } from 'components/Dashboard'
//import Messages from 'components/Messages'
import Requests from 'components/Requests'
import Proposals from 'components/Proposals'
//import Blocks from 'components/Blocks'
import Products from 'components/Products'
import Courses from 'components/Courses'
import Training from 'components/Training'
import Links from 'components/Links'
import MonitoringObjects from 'components/MonitoringObjects'
import HackedReports from 'components/HackedReports'
import CardReports from 'components/CardReports'
import PrivateReportCard from 'components/CardReports/PrivateReportCard'
import Users from 'components/Users'
import Companies from 'components/Companies'
import Marketing from 'components/Marketing'
//import Wizards from 'components/Wizards'
import Commissions from 'components/Commissions'
import Account from 'components/Account'
//import PhoneNumbers from 'components/PhoneNumbers'
import SmishingSimulator from 'components/SmishingSimulator'
import TrainingActivity from 'components/TrainingActivity'
import ActivityLog from 'components/ActivityLog'
import Visitors from 'components/Visitors'
import Experiments from 'components/Experiments'
import ECTemplates from 'components/ECTemplates'

import withAppContext from 'lib/withAppContext'
import useSidebar from 'components/Sidebar/useSidebar'

const DefaultLayout = ({ classes, match, context }) => {
  const wider = useSidebar(true)
  const mobile = useSidebar(false)

  return (
    <div className={classes.root}>
      <AppBar
        open={wider.visible}
        handleDrawerOpen={wider.open}
        handleDrawerClose={wider.close}
        mobileOpen={mobile.visible}
        handleMobileDrawerOpen={mobile.open}
        handleMobileDrawerClose={mobile.close}
      />
      <Sidebar
        open={wider.visible}
        mobileOpen={mobile.visible}
        handleMobileDrawerToggle={mobile.toggle}
        handleMobileDrawerClose={mobile.close}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.container}>
          <Switch>
            <Redirect exact from={match.path} to={`${match.path}dashboard`} />
            <Route path={`${match.path}dashboard`} component={ClientDashboard} context={context} />
            {/*<Route path={`${match.path}messages`} component={Messages} context={context} />*/}
            <Route path={`${match.path}requests`} component={Requests} context={context} />
            <Route path={`${match.path}proposals`} component={Proposals} context={context} />
            {/*<Route path={`${match.path}blocks`} component={Blocks} context={context} />*/}
            <Route path={`${match.path}products`} component={Products} context={context} />
            <Route path={`${match.path}courses`} component={Courses} context={context} />
            <Route path={`${match.path}training`} component={Training} context={context} />
            <Route path={`${match.path}links`} component={Links} context={context} />
            <Route path={`${match.path}monitoring-objects`} component={MonitoringObjects} context={context} />
            <Route path={`${match.path}hacked-reports`} component={HackedReports} context={context} />
            <Route path={`${match.path}card-reports`} component={CardReports} context={context} />
            <Route path={`${match.path}users`} component={Users} context={context} />
            <Route path={`${match.path}companies`} component={Companies} context={context} />
            <Route path={`${match.path}marketing`} component={Marketing} context={context} />
            {/*<Route path={`${match.path}wizards`} component={Wizards} context={context} />*/}
            <Route path={`${match.path}commissions`} component={Commissions} context={context} />
            <Route path={`${match.path}account`} component={Account} context={context} />
            <Route path={`${match.path}report-card`} component={PrivateReportCard} context={context} />
            <Route path={`${match.path}smishing-simulator`} component={SmishingSimulator} context={context} />
            <Route path={`${match.path}training-activity`} component={TrainingActivity} context={context} />
            <Route path={`${match.path}activity-log`} component={ActivityLog} context={context} />
            <Route path={`${match.path}visitors`} component={Visitors} context={context} />
            <Route path={`${match.path}experiments`} component={Experiments} context={context} />
            <Route path={`${match.path}ec-templates`} component={ECTemplates} context={context} />
          </Switch>
        </div>
        <Footer open={wider.visible} mobileOpen={mobile.visible} />
      </main>
    </div>
  )
}

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100vh'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    flexGrow: 1,
    overflow: 'auto'
  },
  container: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: 'rgb(244,246,251)',
    paddingBottom: 10
  }
})

export default compose(withAppContext, withStyles(styles))(DefaultLayout)
