/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation } from 'react-apollo'
import moment from 'moment'
import CheckboxTable from 'lib/tables/CheckboxTable'
import withConfirm from 'lib/withConfirm'

import { getLinks, removeLink } from 'api/links'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const Links = ({ classes, history, match, confirm }) => (
  <React.Fragment>
    <Mutation mutation={removeLink} refetchQueries={['getLinks']}>
      {(remove, { loading: loadingRemove }) => (
        <CheckboxTable
          query={getLinks}
          variables={{}}
          initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
          columns={[
            {
              Header: 'Title',
              accessor: 'title'
            },
            {
              Header: 'URL',
              accessor: 'url',
              width: 400,
              Cell: ({ original: link }) => (
                <div>
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.url}
                  </a>
                  <br />
                  <a href={link.shortUrl} target="_blank" rel="noopener noreferrer">
                    {link.shortUrl}
                  </a>
                </div>
              )
            },
            {
              Header: 'Stats',
              id: 'countClicks',
              accessor: 'countClicks',
              Cell: ({ original: link }) => <div>Clicks: {link.countClicks || 0}</div>
            },
            {
              Header: 'Date Created',
              id: 'createdAt',
              accessor: link => moment(link.createdAt).format('LLL')
            },
            {
              Header: 'Actions',
              sortable: false,
              filterable: false,
              accessor: 'id',
              Cell: ({ original: link }) => {
                return (
                  <div>
                    <IconButton onClick={() => history.push(`${match.url}/${link.id}/update`)} title="Edit Link">
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={confirm(() => remove({ variables: { id: link.id } }), {
                        title: 'Link Remove',
                        description: 'Are you sure you want to delete this Link?'
                      })}
                      title="Remove Link"
                      disabled={loadingRemove}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            }
          ]}
          actions={[
            <Button
              key="create"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => history.push(`${match.url}/create`)}
            >
              <Icon>add</Icon>
              Create Link
            </Button>
          ]}
          deleteMany={false}
        />
      )}
    </Mutation>
  </React.Fragment>
)

const styles = theme => ({
  root: {}
})

export default compose(
  withConfirm,
  withStyles(styles)
)(Links)
