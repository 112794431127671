/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Subscriptions from './Subscriptions'
import SubscriptionUpdate from './SubscriptionUpdate'

export default ({ match }) => (
  <Container maxWidth="lg">
    <Box my={2}>
      <Route path={`${match.path}`} component={Subscriptions} />
      <Route path={`${match.url}/:subscriptionId/update`} component={SubscriptionUpdate} />
    </Box>
  </Container>
)
