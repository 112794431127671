/** @format */

import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import withAppContext from 'lib/withAppContext'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Nav from 'components/Nav'

import Leads from './Leads'
import Products from './Products'

const MarketingIndex = ({ context: { acl }, match, location }) => {

  const tabs = [
    { key: 'lead', title: 'Lead Generation', to: `${match.url}/lead`, scope: 'marketing:lead' },
    { key: 'product', title: 'Product Sales', to: `${match.url}/product`, scope: 'marketing:product' }
  ].filter(item => acl.hasAccess(item.scope))

  return (
    <Container maxWidth="lg">
      <div className="mt-4">
        <p>
          This page contains marketing and sales resources you can use to engage your leads in conversations. Each of these URLs are
          automatically coded to you if the client processes their order online. If you have an idea for a sales or marketing resource please
          email help@ChatFortress.com
        </p>
      </div>

      <Nav color="primary" items={tabs} activeKey={location.pathname.split('/')[2] || 'lead'} />

      <Box my={2}>
        <Route path={`${match.path}/lead`} component={Leads} />
        <Route path={`${match.path}/product`} component={Products} />

        <Redirect from={`${match.path}`} to={`${match.path}/lead`} />
      </Box>
    </Container>
  )
}


export default withAppContext(MarketingIndex)