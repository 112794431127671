/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useQuery, useMutation } from 'react-apollo'
import withConfirm from 'lib/withConfirm'
import { withSnackbar } from 'notistack'
import _ from 'lodash'

import { getSources, removeSource } from 'api/sources'
import { addUserPaymentMethod } from 'api/users'
import { createACHToken } from 'api/actions'
import { getInvoices } from 'api/invoices'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Spinner from 'components/Spinner'
import { apiUrl } from '../../lib/handleForms'
import { getInvoiceEmails, removeInvoiceEmail } from 'api/invoiceEmails'

const Sources = ({ classes, history, match, confirm, enqueueSnackbar }) => {
  const [remove, { loading: loadingRemove }] = useMutation(removeSource, { refetchQueries: ['getSources'] })
  const [addPaymentMethod, { loading: loadingAdding }] = useMutation(addUserPaymentMethod, {
    refetchQueries: ['getSources'],
    onError(error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  })
  const { data, loading } = useQuery(getSources, { variables: match.params })
  const sources = _.get(data, 'sources.data', [])

  const getInvoice = useQuery(getInvoices)
  const invoices = _.get(getInvoice, 'data.invoices.data', [])
  const getInvoiceEmail = useQuery(getInvoiceEmails)
  const invoiceEmails = _.get(getInvoiceEmail, 'data.invoiceEmails.data', [])
  const [removeEmail, { loading: loadingRemoveEmail }] = useMutation(removeInvoiceEmail, { refetchQueries: ['getInvoiceEmails'] })

  const sendReceipts = async email => {
    await fetch(apiUrl('get-invoices'), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ invoices: invoices, email })
    })
  }

  return loading || loadingAdding ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end">
        <Button
          key="add-invoice-notification"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push(`${match.url}/add-invoice-notification`)}
          style={{ marginRight: 7 }}
        >
          <Icon>add</Icon>
          Add Invoice Email
        </Button>
        <Button
          key="add-credit-card"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push(`${match.url}/add-credit-card`)}
          style={{ marginRight: 7 }}
        >
          <Icon>add</Icon>
          Add Credit Card
        </Button>
        <Button
          key="add-bank-account"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => createACHToken().then(data => addPaymentMethod({ variables: { ...match.params, data } }))}
        >
          <Icon>add</Icon>
          Add Bank Account
        </Button>
      </Box>
      <p className="pt-3 mb-0">Credit Cards</p>
      <Paper className="mt-2">
        {sources.length === 0 ? (
          <p className="text-center py-3">No Credit Cards</p>
        ) : (
          <List>
            {sources.map(source => (
              <ListItem key={source.id}>
                <ListItemText style={{ width: '60%' }} primary={source.displayName} />
                <ListItemText style={{ width: '20%' }} primary={source.status} />
                <ListItemText
                  style={{ width: '20%' }}
                  primary={
                    <div>
                      <IconButton onClick={() => history.push(`${match.url}/${source.id}/update-card`)} title="Update Source">
                        <Icon fontSize="small">edit</Icon>
                      </IconButton>
                      {source.object === 'bank_account' && source.status === 'new' && (
                        <IconButton onClick={() => history.push(`${match.url}/${source.id}/verify`)} title="Verify Bank Account">
                          <Icon fontSize="small">check</Icon>
                        </IconButton>
                      )}
                      <IconButton
                        color="secondary"
                        onClick={confirm(() => remove({ variables: { id: source.id } }), {
                          title: 'Source Remove',
                          description: 'Are you sure you want to delete this Source?'
                        })}
                        title="Remove Source"
                        disabled={loadingRemove || loadingAdding}
                      >
                        <Icon fontSize="small">delete</Icon>
                      </IconButton>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
      <p className="pt-3 mb-0">Invoice Notification Emails</p>
      <Paper className="mt-2">
        {!invoiceEmails.length ? (
          <p className="text-center py-3">No Invoice Email</p>
        ) : (
          <List>
            {invoiceEmails.map(email => (
              <ListItem key={email.id}>
                <ListItemText style={{ width: '80%' }} primary={email.invoice_email} />
                <ListItemText
                  style={{ width: '20%' }}
                  primary={
                    <div>
                      <IconButton onClick={() => history.push(`${match.url}/${email.id}/update-invoice`)} title="Update Source">
                        <Icon fontSize="small">edit</Icon>
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={confirm(() => removeEmail({ variables: { id: email.id } }), {
                          title: 'Email Remove',
                          description: 'Are you sure you want to delete this Email?'
                        })}
                        title="Email Remove"
                        disabled={loadingRemoveEmail || loadingAdding}
                      >
                        <Icon fontSize="small">delete</Icon>
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => sendReceipts('obulgakovwork@gmail.com')}
                        title="Send receipts"
                        disabled={!invoices.length}
                      >
                        <Icon fontSize="small">send</Icon>
                      </IconButton>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
      <p className="pt-2 mb-0">
        Enter the email address of the person/s you want to automatically send a copy of the receipts for transactions to. When the receipt
        is generated this will automatically email the person/s email address listed here.
      </p>
    </React.Fragment>
  )
}

const styles = theme => ({
  root: {}
})

export default compose(withSnackbar, withConfirm, withStyles(styles))(Sources)

/*
<Button
          key="add-bank-account"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push(`${match.url}/add-bank-account`)}
        >
          <Icon>add</Icon>
          Add Bank Account
        </Button>
        */
