/** @format */

import gql from 'graphql-tag'

export const productFragment = gql`
  fragment product on Product {
    name
    proposalRoles
    description
    checkoutPageUrl
    redirectPageUrl
    wizardId
    courses
    roles
    oneTime
    setupFee
    commissions {
      role
      masterRole
      setupFee
      subscription
    }
    active
    couponUpfrontOnly
    allowLicenses
    fields
  }
`

export const getProducts = gql`
  query getProducts($skip: Int, $limit: Int, $sort: [String]) {
    products(skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        checkoutPageUrl
        active
        wizard {
          id
          name
        }
        createdAt
      }
      count
      pages
    }
  }
`

export const getProduct = gql`
  query getProduct($productId: String!) {
    product(id: $productId) {
      id
      ...product
    }
  }
  ${productFragment}
`

export const getDataCreationProduct = gql`
  query getDataCreationProduct {
    wizards(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
      }
    }
  }
`

export const createProduct = gql`
  mutation createProduct($data: ProductInput!) {
    createProduct(data: $data) {
      id
      ...product
    }
  }
  ${productFragment}
`

export const getDataUpdatingProduct = gql`
  query getDataUpdatingProduct($productId: String!) {
    product(id: $productId) {
      id
      ...product
    }
    wizards(limit: 0, sort: ["name", "1"]) {
      data {
        value: id
        label: name
      }
    }
    courses(isSequence: true, sort: ["name", "1"]) {
      data {
        value: id
        label: name
      }
    }
  }
  ${productFragment}
`

export const updateProduct = gql`
  mutation updateProduct($productId: String!, $data: ProductInput!) {
    updateProduct(id: $productId, data: $data) {
      id
      ...product
    }
  }
  ${productFragment}
`

export const removeProduct = gql`
  mutation removeProduct($id: String!) {
    removeProduct(id: $id) {
      id
    }
  }
`

export const verifyCouponAction = gql`
  mutation verifyCoupon($code: String!) {
    verifyCoupon(code: $code) {
      id
      name
      amount_off
      percent_off
    }
  }
`

export const onCreateProduct = (cache, { data: { createProduct: product } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { products } = cache.readQuery({ query: getProducts, variables })
  cache.writeQuery({
    query: getProducts,
    variables,
    data: {
      products: {
        ...products,
        data: [product].concat(products.data)
      }
    }
  })
}

export const onRemoveProduct = (cache, { data: { removeProduct: product } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { products } = cache.readQuery({ query: getProducts, variables })
  cache.writeQuery({
    query: getProducts,
    variables,
    data: {
      products: {
        ...products,
        data: products.data.filter(r => r.id !== product.id)
      }
    }
  })
}
