/** @format */

import React from 'react'
import { useQuery } from 'react-apollo'

import { getCardReportByToken } from 'api/cardReports'

const CardReportPublic = ({ match }) => {
  const { token } = match.params
  const { data, loading } = useQuery(getCardReportByToken, { variables: { token } })

  return (
    !loading && (
      <div>
        <h1>CardReportPublic</h1>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    )
  )
}

export default CardReportPublic
