/** @format */

import gql from 'graphql-tag'

export const hackedReportFragment = gql`
  fragment hackedReport on HackedReport {
    link
    lastName
    firstName
    email
    phone
    company
    reportEmail
    reportDomain
    userId
  }
`
export const getHackedReports = gql`
  query getHackedReports($userId: String, $q: String, $skip: Int, $limit: Int, $sort: [String]) {
    hackedReports(userId: $userId, q: $q, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        link
        lastName
        firstName
        email
        phone
        company
        reportEmail
        reportDomain
        breachesCnt
        user {
          id
          name
          email
          avatar
        }
      }
      count
      pages
    }
  }
`

export const getHackedReportbyToken = gql`
  query getHackedReportByToken($token: String!) {
    hackedReportByToken(token: $token) {
      id
      personalObject
      companyObject
      ...hackedReport
    }
  }
  ${hackedReportFragment}
`

export const getHackedReport = gql`
  query getHackedReport($hackedReportId: String!) {
    hackedReport(id: $hackedReportId) {
      id
      ...hackedReport
    }
  }
  ${hackedReportFragment}
`

export const getDataCreationHackedReport = gql`
  query getDataCreationHackedReport {
    sources(limit: 0) {
      data {
        id
        displayName
        object
        status
      }
    }
  }
`

export const createHackedReport = gql`
  mutation createHackedReport($data: HackedReportInput!) {
    createHackedReport(data: $data) {
      id
      ...hackedReport
    }
  }
  ${hackedReportFragment}
`

export const getDataUpdatingHackedReport = gql`
  query getDataUpdatingHackedReport($hackedReportId: String!) {
    hackedReport(id: $hackedReportId) {
      id
      ...hackedReport
    }
  }
  ${hackedReportFragment}
`

export const updateHackedReport = gql`
  mutation updateHackedReport($hackedReportId: String!, $data: HackedReportInput!) {
    updateHackedReport(id: $hackedReportId, data: $data) {
      id
      ...hackedReport
    }
  }
  ${hackedReportFragment}
`

export const removeHackedReport = gql`
  mutation removeHackedReport($id: String!) {
    removeHackedReport(id: $id) {
      id
    }
  }
`

export const onCreateHackedReport = (cache, { data: { createHackedReport: hackedReport } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { hackedReports } = cache.readQuery({ query: getHackedReports, variables })
  cache.writeQuery({
    query: getHackedReports,
    variables,
    data: {
      hackedReports: {
        ...hackedReports,
        data: [hackedReport].concat(hackedReports.data)
      }
    }
  })
}

export const onRemoveHackedReport = (cache, { data: { removeHackedReport: hackedReport } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { hackedReports } = cache.readQuery({ query: getHackedReports, variables })
  cache.writeQuery({
    query: getHackedReports,
    variables,
    data: {
      hackedReports: {
        ...hackedReports,
        data: hackedReports.data.filter(r => r.id !== hackedReport.id)
      }
    }
  })
}
