/** @format */

import gql from 'graphql-tag'

export const requestFragment = gql`
  fragment request on Request {
    name
    status
    profile {
      phone
      firstName
      middleName
      lastName
      dob
      companyName
      companyWebsite
      physicalAddress {
        addressLine1
        addressLine2
        city
        state
        county
        zip
        country
      }
      mailAddress {
        addressLine1
        addressLine2
        city
        state
        county
        zip
        country
      }
    }
  }
`

export const getRequests = gql`
  query getRequests($skip: Int, $limit: Int, $sort: [String]) {
    requests(skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        referenceNumber
        description
        status
        author {
          id
          name
          email
          phone
          avatar
        }
        user {
          id
          name
          email
          phone
          avatar
        }
        createdAt
      }
      count
      pages
    }
  }
`

export const getRequest = gql`
  query getRequest($requestId: String!) {
    request(id: $requestId) {
      id
      ...request
    }
  }
  ${requestFragment}
`

export const createRequest = gql`
  mutation createRequest($data: RequestInput!) {
    createRequest(data: $data) {
      id
      ...request
    }
  }
  ${requestFragment}
`

export const updateRequest = gql`
  mutation updateRequest($requestId: String!, $data: RequestInput!) {
    updateRequest(id: $requestId, data: $data) {
      id
      ...request
    }
  }
  ${requestFragment}
`

export const sendRequest = gql`
  mutation sendRequest($id: String!) {
    sendRequest(id: $id) {
      id
      ...request
    }
  }
  ${requestFragment}
`

export const voidRequest = gql`
  mutation voidRequest($id: String!) {
    voidRequest(id: $id) {
      id
      ...request
    }
  }
  ${requestFragment}
`

export const approveRequest = gql`
  mutation approveRequest($id: String!) {
    approveRequest(id: $id) {
      id
      ...request
    }
  }
  ${requestFragment}
`

export const removeRequest = gql`
  mutation removeRequest($id: String!) {
    removeRequest(id: $id) {
      id
    }
  }
`

export const onCreateRequest = (cache, { data: { createRequest: request } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { requests } = cache.readQuery({ query: getRequests, variables })
  cache.writeQuery({
    query: getRequests,
    variables,
    data: {
      requests: {
        ...requests,
        data: [request].concat(requests.data)
      }
    }
  })
}

export const onRemoveRequest = (cache, { data: { removeRequest: request } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { requests } = cache.readQuery({ query: getRequests, variables })
  cache.writeQuery({
    query: getRequests,
    variables,
    data: {
      requests: {
        ...requests,
        data: requests.data.filter(r => r.id !== request.id)
      }
    }
  })
}
