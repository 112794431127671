import React from 'react'
import { useQuery } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import {PhoneNumber, TextField} from 'lib/form/fields'
import { required, length } from 'lib/form/validators'

import { getCampaign, testCampaign } from 'api/campaigns'


const CampaignUpdate = ({ classes, match }) => {
  const { loading, data: { campaign = {} } = {} } = useQuery(getCampaign, { variables: match.params })

  return (
    !loading && (
      <FormDialog
        title="Test Campaign"
        form="CampaignUpdate"
        mutation={[testCampaign]}
        initialValues={{}}
        btnSubmitText="Send"
        success="Sent successfully"
      >
        {({ doc, invalid, submitting }) => (
          <React.Fragment>
            {campaign.message1 && <div>
              <Field name="firstName" component={TextField} label="First Name" validate={[required()]} />
              <Field name="phone" component={PhoneNumber} validate={[required()]} label="Test Phone Number" />
            </div>}
            {!campaign.message1 && <div>Campaign has no messages yet...</div>}
          </React.Fragment>
        )}
      </FormDialog>
    )
  )
}



export default CampaignUpdate
