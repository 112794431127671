/** @format */

class Roles {
  get PUBLIC() {
    return 'public'
  }

  get USER() {
    return 'user'
  }

  get ADMIN() {
    return 'admin'
  }

  // Client Related
  get CLIENT() {
    return 'client'
  }
  get COMPANY() {
    return 'Company'
  }
  get COMPANY_MANAGER() {
    return 'Company Manager'
  }
  get EMPLOYEE() {
    return 'Employee'
  }
  get VENDOR() {
    return 'Vendor'
  }
  get PROSPECT() {
    return 'Prospect'
  }

  // ChatFortress Related
  get ChatFortress() {
    return 'chatFortress'
  }
  get CF_ADMIN() {
    return 'CF-Admin'
  }
  get CF_MANAGER() {
    return 'CF-Manager'
  }
  get CF_EMPLOYEE() {
    return 'CF-Employee'
  }
  get CF_RESELLER() {
    return 'CF-Reseller'
  }
  get CF_RESELLER_STARTER() {
    return 'CF-Reseller-Starter'
  }
  get CF_RESELLER_GROWTH() {
    return 'CF-Reseller-Growth'
  }
  get CF_RESELLER_PRO() {
    return 'CF-Reseller-Pro'
  }
  get CF_REFERRAL() {
    return 'CF-Referral'
  }
  get CF_SUB_RESELLER() {
    return 'CF-Sub-Reseller'
  }
  get CF_SALES_REP() {
    return 'CF-Sales-Rep'
  }

  // Secure Chat Related
  get SecureChat() {
    return 'secureChat'
  }
  get SC_PERSON() {
    return 'SC-Person'
  }
  get SC_COMPANY() {
    return 'SC-Company'
  }
  get SC_VENDOR() {
    return 'SC-Vendor'
  }

  // Report Card Related
  get ReportCard() {
    return 'reportCard'
  }
  get RC_PERSON_PROSPECT() {
    return 'RC-Person-Prospect'
  }
  get RC_PERSON_STARTER() {
    return 'RC-Person-Starter'
  }
  get RC_PERSON_GROWTH() {
    return 'RC-Person-Growth'
  }
  get RC_PERSON_SCALING() {
    return 'RC-Person-Scaling'
  }
  get RC_COMPANY() {
    return 'RC-Company'
  }
  get RC_VENDOR() {
    return 'RC-Vendor'
  }
  get RC_INFUENCER() {
    return 'RC-Influencer'
  }

  get RC_SalesTier1() {
    return 'RC-SalesTier1'
  }
  get RC_SalesTier2() {
    return 'RC-SalesTier2'
  }

  get CF_RC_SalesTier1() {
    return 'CF-RC-SalesT1'
  }
  get CF_RC_SalesTier2() {
    return 'CF-RC-SalesT2'
  }
  get TF_Client() {
    return 'TF-Client'
  }
  get EC_CMMC_L1() {
    return 'EC-CMMC-L1'
  }
  get EC_CMMC_L2() {
    return 'EC-CMMC-L2'
  }
  get EC_CMMC_L3() {
    return 'EC-CMMC-L3'
  }
  get EC_CMMC_L4() {
    return 'EC-CMMC-L4'
  }
  get EC_CMMC_L5() {
    return 'EC-CMMC-L5'
  }

  get CMMC_CLIENT() {
    return 'CMMC-Client'
  }
}

export default new Roles()
