/** @format */

import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useQuery, useMutation } from 'react-apollo'
import { useSnackbar } from 'notistack'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Spinner from 'components/Spinner'

import { createCompanies, getCompanyDataForCreation } from 'api/companies'

import { acl } from 'lib/acl'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import { parseCsvFile } from 'lib/parseCsv'

const mapHeaders = [
  {
    field: 'name',
    variations: ['Company', 'Company Name', 'Name']
  },
  {
    field: 'website',
    variations: ['Website', 'Domain', 'Website Url', 'URL']
  },
  {
    field: 'categories',
    variations: ['Category', 'Categories']
  },
  {
    field: 'phone',
    variations: ['Phone', 'Phone Number', 'Contact Phone']
  },
  {
    field: 'email',
    variations: ['Email', 'E-mail']
  }
]

function getSteps() {
  return ['Upload Csv File', 'Map Fields', 'Confirm Data']
}

const CompanyImport = ({ classes, fullScreen }) => {
  const { loading, data: { resellers: { data: resellers = [] } = {} } = {} } = useQuery(getCompanyDataForCreation)
  const { enqueueSnackbar } = useSnackbar()
  const [importCompanies, { loading: importing }] = useMutation(createCompanies, { refetchQueries: ['getCompanies'] })
  let history = useHistory()

  const [owner, setOwner] = useState(null)
  const [results, setResults] = useState([])
  const [importList, setImportList] = useState([])
  const [headers, setHeaders] = useState([])
  const [fieldMap, setFieldMap] = useState({
    name: null,
    website: null,
    categories: null,
    phone: null,
    email: null
  })

  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  const submitFinalStep = async variables => {
    try {
      const result = await importCompanies({ variables })
      history.push('/companies')
      return result
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      return await submitFinalStep({ data: importList, owner })
    }
    if (activeStep === 1) {
      let tempList = results.map(res=>{
        return {
          name: res[fieldMap.name],
          website: res[fieldMap.website],
          categories: res[fieldMap.categories].split("|"),
          phone: res[fieldMap.phone],
          email: res[fieldMap.email]
        }
      })
      tempList = _.filter(tempList,f=>f.website && f.name && f.categories)
      setImportList(tempList)
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = async () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const onDrop = useCallback(async acceptedFiles => {
    let { data, errors } = await parseCsvFile(acceptedFiles[0], {
      transformHeader(header) {
        const formattedHeader = String(header).trim()
        return formattedHeader
      }
    })
    setResults(data)
    setHeaders(Object.keys(data[0]))
    mapHeaders.map((h)=>{
      fieldMap[h.field] = _.find(Object.keys(data[0]), (header)=>_.find(h.variations, v=>v.toLowerCase()==header.toLowerCase())) || null
    })
    setFieldMap(fieldMap)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: ['.csv', '.txt'] })

  return (
    <Dialog fullScreen={fullScreen} open fullWidth maxWidth="md">
      <DialogTitle>Import Employees</DialogTitle>
      <DialogContent>
        <div>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className="text-center">All steps completed</Typography>
              </div>
            ) : (
              <div>
                {activeStep === 0 && (
                  <React.Fragment>
                    {results.length === 0 ? (
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <div>Drop the files here ...</div>
                        ) : (
                          <div>Drag 'n' drop some files here, or click to select files</div>
                        )}
                      </div>
                    ) : (
                      <div className="text-center">
                        You have imported {results.length} records{' '}
                        <a href="#cancel" onClick={() => setResults([])}>
                          Select another file
                        </a>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {activeStep === 1 && (
                  <div>
                    {mapHeaders.map(field=>(
                      <Card className='mt-2'>
                        <CardContent>
                          <div className='row'>
                          <div className='col-4 pt-4'>
                            <h3>{field.field}</h3>
                          </div>
                            <div className='col-8'>
                            <TextField
                              select
                              label="Select Header"
                              className={classes.textField}
                              value={fieldMap[field.field] || _.find(headers, (f)=>_.find(field.variations, v=>v.toLowerCase()==f.toLowerCase())) || null}
                              onChange={event => {
                                let fieldMapTemp = _.cloneDeep(fieldMap)
                                fieldMapTemp[field.field] = event.target.value
                                setFieldMap(fieldMapTemp)
                              }}
                              margin="normal"
                              style={{ width: '100%' }}
                            >
                              {headers.map(option => (
                                <MenuItem key={option} value={option}>
                                  <span>
                                    {option}
                                  </span>
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                )}
                {activeStep === 2 && (
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={600} margin="0 auto">
                    {importing ? (
                      <Spinner />
                    ) : (
                      <React.Fragment>
                        <Typography>List of Companies ({importList.length} of {results.length})</Typography>
                        {acl.hasAccess("admin") && <div style={{ width: '100%' }}>
                          <TextField
                            select
                            label="Select Reseller"
                            className={classes.textField}
                            value={owner}
                            onChange={event => {
                             setOwner(event.target.value)
                            }}
                            margin="normal"
                            style={{ width: '100%' }}
                          >
                            {!loading && resellers.map(reseller => (
                              <MenuItem key={reseller.value} value={reseller.value}>
                                  <span>
                                    {reseller.label}
                                  </span>
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>}
                        <table className="table table-bordered">
                          <thead>
                          <tr>
                            {_.filter(Object.keys(fieldMap), f=>fieldMap[f]).map(f=><th>{f}</th>)}
                          </tr>
                          </thead>
                          <tbody>
                          {importList.map(company=>(
                            <tr>
                              {_.filter(Object.keys(fieldMap), f=>fieldMap[f]).map(f=><td>{company[f]}</td>)}
                            </tr>
                          ))}
                          </tbody>
                        </table>
                      </React.Fragment>
                    )}
                  </Box>
                )}
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
          <div>
            <Button disabled={activeStep === 0 || importing} onClick={handleBack} className={classes.backButton}>
              Back
            </Button>
            <Button
              disabled={
                (activeStep===0 && !results.length) ||
                (activeStep===1 && (!fieldMap.website || !fieldMap.name || !fieldMap.categories))
                || importing
              }
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </div>
          <div>
            <Button color="default" onClick={() => history.push('/companies')} disabled={importing}>
              Cancel
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const styles = () => ({})

export default compose(withMobileDialog(), withStyles(styles))(CompanyImport)
