/** @format */

import React from 'react'
import _ from 'lodash'
import { compose } from 'recompose'
import { useQuery, useLazyQuery, useMutation } from 'react-apollo'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { getCompanies, removeCompany } from 'api/companies'
//import { getCategories } from 'api/categories'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import withConfirm from 'lib/withConfirm'
import CheckboxTable from 'lib/tables/CheckboxTable'
import CompanyCell from 'lib/tables/CompanyCell'
//import SelectFilter from 'lib/tables/SelectFilter'
import withContext from 'lib/withAppContext'
import { saveCsvFile } from 'lib/parseCsv'
import { withSnackbar } from 'notistack'
//import createApolloClient from 'apollo'
import Loader from 'react-loaders'
import { acl } from 'lib/acl'


const Companies = ({ classes, history, match, confirm, context, enqueueSnackbar }) => {

  //const client = createApolloClient(window.__APOLLO_STATE__)

  const [isLoading, setLoading] = React.useState(false)
  const [remove, { loading: removing }] = useMutation(removeCompany, { refetchQueries: ['getCompanies'] })
  //const [removeMany] = useMutation(removeCompanies, { refetchQueries: ['getCompanies'] })

  return (
     (
      <CheckboxTable
        query={getCompanies}
        variables={match.params}
        initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ original: company }) => <CompanyCell company={company} />
          },
          {
            Header: 'Categories',
            accessor: 'categories',
            Cell: ({ original: company }) =>
              Array.from(company.categories || [])
                //.map(name => <span key={name}>{name}</span>)
                .join(', ')
          },
          {
            Header: 'Links',
            accessor: 'website',
            Cell: ({ original: company }) => {
              const pubDirectoryUrl = company.publicShortUrl || `${process.env.REACT_APP_GATSBY_URL}/directory/company/${company.id}`
              const shortUrl =
                company.shortUrl || (company.report && `${process.env.REACT_APP_GATSBY_URL}/report/${company.report.token}`) || ''
              return (
                <div>
                  <div>
                    <a href={company.website} target="_blank" rel="noopener noreferrer">
                      {company.website}
                    </a>
                  </div>
                  {shortUrl && (
                    <div>
                      <strong>Private Report Page:</strong>
                      <br />
                      <span>
                        <a href={shortUrl} target="_blank" rel="noopener noreferrer">
                          {shortUrl}
                        </a>
                        <CopyToClipboard text={shortUrl}>
                          <IconButton title="Copy Link">
                            <Icon fontSize="small">file_copy</Icon>
                          </IconButton>
                        </CopyToClipboard>
                      </span>
                    </div>
                  )}
                  {pubDirectoryUrl && (
                    <div>
                      <strong>Public Directory Page:</strong>
                      <br />
                      <a href={pubDirectoryUrl} target="_blank" rel="noopener noreferrer">
                        {pubDirectoryUrl}
                      </a>
                      <CopyToClipboard text={pubDirectoryUrl}>
                        <IconButton title="Copy Link" title="Copy Public Directory Page URL">
                          <Icon fontSize="small">file_copy</Icon>
                        </IconButton>
                      </CopyToClipboard>
                    </div>
                  )}
                </div>
              )
            }
          },
          {
            Header: 'Score',
            accessor: 'rankPoints',
            width: 100,
            Cell: ({ original: company }) => (
              <div>
                {company.rankPoints > 0 ? (
                  <div>
                    {company.rankLetter} ({company.rankPoints})
                  </div>
                ) : (
                  <div>N/A</div>
                )}
              </div>
            )
          },
          {
            Header: 'Ranking Data',
            accessor: 'rank',
            width: 200,
            Cell: ({ original: company }) => (
              <div>
                {company.rank > 0 && (
                  <div>
                    <span>Total:</span>{' '}
                    <strong>
                      {company.rank} of {company.totalRank}
                    </strong>
                  </div>
                )}
                {company.categoryRank > 0 && (
                  <div>
                    <span>By Category:</span>{' '}
                    <strong>
                      {company.categoryRank} of {company.totalCategoryRank}
                    </strong>
                  </div>
                )}
              </div>
            )
          },
          {
            Header: 'Report Status',
            accessor: 'report.status',
            width: 100
          },
          /*
        {
          Header: 'Private Donations',
          accessor: 'privateDonations'
        },
        {
          Header: 'Total Revenue',
          accessor: 'totalRevenue'
        },
        {
          Header: 'Fundraising Efficiency %',
          accessor: 'fundraisingEfficiency',
          Cell: ({ original: company }) => numeral(company.fundraisingEfficiency).format('0,0.0%')
        },
        {
          Header: 'Charitable Commitment %',
          accessor: 'charitableCommitment',
          Cell: ({ original: company }) => numeral(company.charitableCommitment).format('0,0.0%')
        },
        */
          {
            Header: 'Actions',
            sortable: false,
            filterable: false,
            accessor: 'id',
            Cell: ({ original: company }) => {
              let widgetCode = `
<script src="https://web.chatfortress.com/embed-widget.js?t=${1 * new Date()}"></script>
<script type="text/javascript" id="cf-widget-${company.id}">
    (function(){
      var widget = new CFWidget('${company.id}','${context.user.apiKeyPublic}', {size: 'small'});
      widget.init();
    })()
</script>
`
              return (
                <div>
                  {company.report && company.report.token && (
                    <CopyToClipboard
                      text={widgetCode}
                      onCopy={() => enqueueSnackbar('The Widget Embdedding code copied to clipboard', { variant: 'info' })}
                    >
                      <IconButton title="Copy Widget Code" target="_blank">
                        <Icon>link</Icon>
                      </IconButton>
                    </CopyToClipboard>
                  )}
                  {company.report && company.report.token && (
                    <IconButton
                      href={company.shortUrl ? company.shortUrl : `${process.env.REACT_APP_GATSBY_URL}/report/${company.report.token}`}
                      title="View Report"
                      target="_blank"
                    >
                      <Icon>report</Icon>
                    </IconButton>
                  )}
                  <IconButton onClick={() => history.push(`${match.url}/${company.id}/notes`)} title="Company Notes">
                    <Icon fontSize="small">comment</Icon>
                  </IconButton>
                  <IconButton onClick={() => history.push(`${match.url}/${company.id}/update`)} title="Edit Company">
                    <Icon fontSize="small">edit</Icon>
                  </IconButton>
                  <IconButton
                    key="delete"
                    color="secondary"
                    onClick={confirm(() => remove({ variables: { id: company.id } }), {
                      title: 'Company Remove',
                      description: 'Are you sure you want to delete this Company?'
                    })}
                    title="Remove Company"
                    disabled={removing}
                  >
                    <Icon fontSize="small">delete</Icon>
                  </IconButton>
                </div>
              )
            }
          }
        ]}
        actions={[
          <Button
            key="add"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              history.push(`${match.url}/create`)
              //history.push(`${match.url}/credit-card-add`)
            }}
          >
            <Icon>add</Icon>
            Add Company
          </Button>,<div key='loading'>&nbsp;{isLoading && <div className='modal fade show' style={{display: "block"}}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center bg-dark">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
          }</div>,
          <Button
            key="export"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              setLoading(true)
              const token = localStorage.getItem('loginToken')
              fetch(`${process.env.REACT_APP_API_URL}/v1/export-companies`, {headers: {Authorization: `Bearer ${token}`}}).then(r=>r.json()).then(r=>{
                saveCsvFile("companies.csv", r.map(r=>({
                  'First Name': r.representativeName,
                  'Last Name': '',
                  'Category': r.categories.join(", "),
                  'Email': r.email,
                  'Phone Number': r.phone,
                  'Company': r.name,
                  'Company ID': r.id,
                  'LinkedIn': '',
                  'Website': r.website,
                  'Final Grade': r.rankPoints,
                  'Public Report Card Link': r.publicShortUrl || `${process.env.REACT_APP_GATSBY_URL}/directory/company/${r.id}`,
                  'Private Report Card Link': r.shortUrl || (r.report && `${process.env.REACT_APP_GATSBY_URL}/report/${r.report.token}`) || '',
                  'Total Numbers of Vulnerabilities Found': r.vulnCount||0,
                  'Number of Leaked Credentials': r.breachesCount
                }))).then(r=>setLoading(false))
              })

            }}
          >
            <Icon>unarchive</Icon>
            Export All
          </Button>,<div key='loading2'>&nbsp;</div>,
          <Button
            key="importCSV"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              history.push({pathname: `${match.url}/import`})
            }}
          >
            <Icon>archive</Icon>
            Import CSV
          </Button>
        ]}
        bulkActions={[
          {
            label: 'Bulk Edit',
            icon: 'edit',
            action: selected => {
              history.push({pathname: `${match.url}/updateBulk`, state: {selected}})
            }
          },
          {
            label: 'Export CSV',
            icon: 'unarchive',
            action: (selected, rows) => {
              let selectedRows = _.filter(rows, r=>selected.includes(r.id))
              saveCsvFile("companies.csv", selectedRows.map(r=>({
                'First Name': r.representativeName,
                'Last Name': '',
                'Category': r.categories.join(", "),
                'Email': r.email,
                'Phone Number': r.phone,
                'Company': r.name,
                'LinkedIn': '',
                'Website': r.website,
                'Final Grade': r.rankPoints,
                'Public Report Card Link': r.publicShortUrl || `${process.env.REACT_APP_GATSBY_URL}/directory/company/${r.id}`,
                'Private Report Card Link': r.shortUrl || (r.report && `${process.env.REACT_APP_GATSBY_URL}/report/${r.report.token}`) || '',
                'Total Numbers of Vulnerabilities Found': r.vulnCount||0,
                'Number of Leaked Credentials': r.breachesCount
              })))
            }
          }
        ]}
        deleteMany={false}
      />
    )
  )
}

const styles = theme => ({
  root: {}
})

export default compose(withConfirm, withContext, withSnackbar, withStyles(styles))(Companies)
