/** @format */

import React from 'react'
import _ from 'lodash'
import { Query } from 'react-apollo'
import {FormDialog, Field, FormSection, Address, Form} from 'lib/form'
import { TextField, Birthdate, Checkbox, DateTimeField } from 'lib/form/fields'
import { required } from 'lib/form/validators'
import { proposalSystemOptions } from 'data/options'

import { getProposal, updateProposal, addProposalNote } from 'api/proposals'
import { getDataCreationProposal } from 'api/proposals'
import templates from './templates.json'
import { currencyOptions } from 'data/options'

import withStyles from '@material-ui/core/styles/withStyles'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

const ProposalUpdate = ({ classes, match }) => (
  <Query query={getProposal} variables={match.params}>
    {({ data: { proposal } = {}, loading, refetch}) =>
      !loading && (
        <FormDialog
          title="Update Proposal"
          form="ProposalUpdate"
          mutation={[updateProposal]}
          initialValues={_.omit(proposal, ['user', 'additionalFees'])}
          btnSubmitText="Save"
          success="Proposal updated successfully"
        >
          {({ doc, change }) => {
            let minPrice = null
            let rPrice = null
            const getRPrice = (v, p) => {
              if (doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).prices && doc.emailCount) {
                let tpl = _.find(templates, { id: doc.template })
                let tierIndex = _.findIndex(_.sortBy(Object.keys(tpl.prices), i => i * 1), i => {
                  return v * 1 <= i * 1
                })
                if (tierIndex === -1) tierIndex = Object.keys(tpl.prices).length
                if (tierIndex === 0) tierIndex = 1
                let tier = Object.values(tpl.prices)[tierIndex - 1]
                console.log(p, tier)
                if (p < tier[1] && p >= tier[0]) return p
                return tier[1]
              }
              return null
            }
            if (doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).prices && doc.emailCount) {
              let tpl = _.find(templates, { id: doc.template })
              minPrice = Object.values(tpl.prices)[0][0]
              rPrice = Object.values(tpl.prices)[0][1]
              let tierIndex = _.findIndex(_.sortBy(Object.keys(tpl.prices), i => i * 1), i => {
                return doc.emailCount * 1 <= i * 1
              })
              if (tierIndex === -1) tierIndex = Object.keys(tpl.prices).length
              if (tierIndex === 0) tierIndex = 1
              let tier = Object.values(tpl.prices)[tierIndex - 1]
              minPrice = tier[0]
              rPrice = tier[1]
            }
            return (
              <Query query={getDataCreationProposal}>
                {({ data: { products: { data: products } = {}, resellers: { data: resellers } = {} } = {}, loading }) => {
                  let req = doc.type !== 'template' && (doc.productId === 'prod_Fjoibv8fL79rh7' || doc.productId === 'dNetH2TMph6E96efZgYK')
                  let t = doc.type === 'template' ? _.find(templates, { id: doc.template }) : {}
                  if (t.minPrice) {
                    minPrice = t.minPrice
                    if (doc.unitPrice < minPrice) change('unitPrice', minPrice)
                  }
                  return (
                    !loading && (
                      <React.Fragment>
                        <Field name="name" component={TextField} label="Proposal Name" validate={[required()]} />
                        <Field name="canceled" component={Checkbox} label="Canceled" />
                        {doc.type === 'template' && (
                          <Field
                            name="template"
                            component={TextField}
                            label="Template"
                            options={templates.map(t => ({ label: t.label, value: t.id }))}
                            validate={[required()]}
                            select
                          />
                        )}

                        {doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).reseller && (
                          <Field name="resellerId" component={TextField} label="Proposal Reseller" options={resellers} select />
                        )}
                        {doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).items && (
                          <div>
                            <fieldset>
                              <legend>Package Items</legend>
                              <div>Leave price or quantity blank or "0" to omit item</div>
                              {Object.keys(_.find(templates, { id: doc.template }).items).map((item, index)=>(
                                <div className="row">
                                  <div className="col-8">
                                    <Field name={`items.${item}.price`} component={TextField} label={`${item} Price`} type="number" />
                                  </div>
                                  <div className="col-4">
                                    <Field name={`items.${item}.qty`} component={TextField} label={`Quantity`} type="number" />
                                  </div>
                                </div>
                              ))}
                            </fieldset>
                          </div>)}
                        {doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).sellOptions && (
                          <div>
                            <fieldset>
                              <legend>Selling Options</legend>
                              <Field name="setupFee" component={TextField} label="Setup Fee" type="number" />
                              {t.dynamicPrice && (
                                <Field
                                  name="unitPrice"
                                  onChange={e => {
                                    if (e.target.value < minPrice) {
                                      e.preventDefault()
                                      change('unitPrice', minPrice)
                                    }
                                  }}
                                  component={TextField}
                                  label={(t.yearlyOnly ? 'Yearly ' : '') + 'Price'}
                                  type="number"
                                  validate={[required()]}
                                />
                              )}
                              <Field
                                name="guardianAddon"
                                component={TextField}
                                label="Email Guardian Addon"
                                options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                                select
                                validate={[required()]}
                              />
                              {doc.guardianAddon === 'Yes' && (
                                <React.Fragment>
                                  <Field
                                    name="guardianAmount"
                                    onChange={e => {
                                      if (e.target.value < 0) e.preventDefault()
                                    }}
                                    component={TextField}
                                    label="Email Guardian Addon Quantity"
                                    type="number"
                                  />
                                  <Field
                                    name="guardianPrice"
                                    onBlur={e => {
                                      console.log(e.target.value )
                                      if (e.target.value*1 < 9) {
                                        console.log(e.target.value)
                                        setTimeout(()=>change('guardianPrice', 9), 10)
                                      }
                                    }}
                                    component={TextField}
                                    label="Email Guardian Addon Price"
                                    type="number"
                                  />
                                </React.Fragment>
                              )}
                            </fieldset>
                          </div>
                        )}
                        {doc.type === 'template' && doc.template && t.sellOptions && (
                          <div>
                            <fieldset>
                              <legend>Selling Options</legend>
                              <Field name="msp" component={Checkbox} label="MSP" />

                              {proposal.additionalFees.length > 0 && (
                                <Field
                                  name="additionalFee"
                                  component={TextField}
                                  label="Additional Fee"
                                  options={[{ label: 'No Fee', value: null }].concat(
                                    proposal.additionalFees.map(t => ({ label: `${t.label} (${t.priceLabel})`, value: t.id }))
                                  )}
                                  select
                                />
                              )}

                              <Field
                                name="currency"
                                component={TextField}
                                label="Currency"
                                validate={[required()]}
                                options={currencyOptions}
                                select
                              />
                              <Field name="soldAsPOC" component={Checkbox} label="Sold as POC" />
                              <Field name="setupFee" component={TextField} label="Setup Fee" type="number" />
                              <Field
                                name="emailCount"
                                component={TextField}
                                label="Units (quantity)"
                                type="number"
                                validate={[required()]}
                                onChange={e => {
                                  if (e.target.value < 0) e.preventDefault()
                                  setTimeout(() => change('unitPrice', getRPrice(e.target.value, doc.unitPrice)), 100)
                                }}
                              />
                              <Field
                                name="unitPrice"
                                onChange={e => {
                                  //if (e.target.value && e.target.value < minPrice) e.preventDefault()
                                }}
                                component={TextField}
                                label="Unit Price"
                                type="number"
                                validate={[required()]}
                              />
                              <Field
                                name="additionalUnitPrice"
                                onChange={e => {
                                  if (e.target.value < 0) e.preventDefault()
                                }}
                                component={TextField}
                                label="BYOD Additional Unit Price"
                                type="number"
                                validate={doc.additionalUnitAmount > 0 ? [required()] : []}
                              />
                              <Field
                                name="additionalUnitAmount"
                                onChange={e => {
                                  if (e.target.value < 0) e.preventDefault()
                                }}
                                component={TextField}
                                label="BYOD Additional Unit Quantity"
                                type="number"
                              />
                              <Field
                                name="guardianAddon"
                                component={TextField}
                                label="Email Guardian Addon"
                                options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                                select
                                validate={[required()]}
                              />
                              {doc.guardianAddon === 'Yes' && (
                                <React.Fragment>
                                  <Field
                                    name="guardianAmount"
                                    onChange={e => {
                                      if (e.target.value < 0) e.preventDefault()
                                    }}
                                    component={TextField}
                                    label="Email Guardian Addon Quantity"
                                    type="number"
                                  />
                                  <Field
                                    name="guardianPrice"
                                    onBlur={e => {
                                      console.log(e.target.value )
                                      if (e.target.value*1 < 9) {
                                        console.log(e.target.value )
                                        setTimeout(()=>change('guardianPrice', 9), 10)
                                      }
                                    }}
                                    component={TextField}
                                    label="Email Guardian Addon Price"
                                    type="number"
                                  />
                                </React.Fragment>
                              )}
                            </fieldset>
                          </div>
                        )}
                        {doc.type !== 'template' && (
                          <div>
                            <Field
                              name="productId"
                              component={TextField}
                              label="Product"
                              options={products}
                              validate={[required()]}
                              select
                            />
                            {doc.productId && _.find(products, { value: doc.productId }) && (
                              <Field
                                name="planId"
                                component={TextField}
                                label="Plan"
                                options={_.find(products, { value: doc.productId }).plans}
                                validate={[required()]}
                                select
                              />
                            )}
                          </div>
                        )}
                        <Field name="email" component={TextField} label="Email" validate={[required()]} />
                        <Field name="phone" component={TextField} label="Cell Phone" validate={[required()]} />
                        <Field name="firstName" component={TextField} label="First Name" validate={[required()]} />
                        <Field name="middleName" component={TextField} label="Middle Name" />
                        <Field name="lastName" component={TextField} label="Last Name" validate={[required()]} />
                        <Field name="companyName" component={TextField} label="Company Name" validate={req ? [required()] : []} />
                        <Field name="domain" component={TextField} label="Domain" validate={req ? [required()] : []} />

                        <Field
                          name="emailSystem"
                          component={TextField}
                          label="Current Email System"
                          options={proposalSystemOptions}
                          select
                          validate={req ? [required()] : []}
                        />
                        {(doc.type !== 'template' || !doc.template || !_.find(templates, { id: doc.template }).sellOptions) && (
                          <Field name="emailCount" component={TextField} label="Number of Active Email Accounts " type="number" />
                        )}
                        <Field
                          name="techInquiry"
                          component={TextField}
                          label="Are you point of contact for technical enquiries?"
                          options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                          select
                        />
                        <Field
                          name="comment"
                          component={TextField}
                          multiline
                          rows={5}
                          label="How do you currently completed phishing awareness and training? "
                        />
                        <Field name="signedOffline" component={Checkbox} label="Signed Oflline" />
                        <Field name="coupon" component={TextField} label="Coupon" />
                        <Field name="couponComments" component={TextField} multiline rows={5} label="Notes about Discounted Price" />

                        <Field name="trialTypeDate" component={Checkbox} label="Trial By Date" />
                        {doc.trialTypeDate && (
                          <Field name="trialDate" component={DateTimeField} label="Trial by the following Date" dateOnly={true} />
                        )}
                        {!doc.trialTypeDate && <Field name="trialDays" component={TextField} label="Trial in Days" type="number" />}


                        <div className={'p-3'}>
                          {proposal.notes && proposal.notes.length>0 &&<div className={'text-left'}>
                            <h5>Notes:</h5>
                            <List>
                              {_.reverse(_.sortBy([...Array.from(proposal.notes || [])], n=>n.createdAt)).map(note=>(
                                <ListItem alignItems="flex-start">
                                  <ListItemAvatar>
                                    <Avatar alt={note.author.name} src={note.author.avatar} />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={note.author.name}
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          color="textPrimary"
                                        >
                                          {(new Date(note.createdAt)).toLocaleString()}
                                        </Typography>
                                        <p className='lead'>{note.body}</p>
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>}
                          <Form
                            form="ProposalNote"
                            mutation={[addProposalNote]}
                            refetchQueries={['getProposal']}
                            initialValues={{}}
                            success="Note Added"
                            onSubmit={({ data: { body }, mutation, ...props })=> {
                              return mutation({ variables: { proposalId: proposal.id, body} })
                            }}
                            onSubmitSuccess={(result, dispatch, { enqueueSnackbar, reset, ...rest }) =>{
                              enqueueSnackbar("Note Added", { variant: 'success' })
                              reset()
                              refetch()
                            }}
                          >
                            {({doc, submit, submitting}) => (
                              <React.Fragment>
                                <Field name="body" component={TextField} label="Note" multiline rows={2} validate={[required()]} style={{width:'calc(100% - 130px)'}} />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={submit}
                                  disabled={submitting}
                                  className={'mt-3'}
                                >
                                  <Icon>add</Icon>
                                  Add Note
                                </Button>
                              </React.Fragment>
                            )}
                          </Form>
                        </div>


                      </React.Fragment>
                    )
                  )
                }}
              </Query>
            )
          }}
        </FormDialog>
      )
    }
  </Query>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(ProposalUpdate)
