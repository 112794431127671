/** @format */
import React from 'react'

const tpl = ({ proposal, flatPrice, unitPrice, unitsAmount }) => {
  return (
    <div>
      <section className="fdb-block py-4">
        <div className="container">
          <h1>Goal:</h1>
          <p>
            The goal of the ChatFortress Cybersecurity Report Card Reseller program is to allow the Reseller to use the ChatFortress
            Cybersecurity Report Card in their marketing and sales to maximize conversations and sales conversions.
          </p>
          <h1>Summary: </h1>
          <p>
            {' '}
            ChatFortress grants the Reseller with the ability to apply their branding to the Cybersecurity Report Card and the Cybersecurity
            Report Card within the Public directory based on the branding options provided by ChatFortress.{' '}
          </p>
          <p>
            {' '}
            The Reseller will have access to the ChatFortress platform based on the Cybersecurity Report Card Reseller plan they subscribe
            to. The Reseller will earn a commission for the associated ChatFortress products they sell via their Reseller tracking links or
            sales created within the Reseller’s ChatFortress Portal.{' '}
          </p>
          <p>
            {' '}
            Based on the Reseller’s plan, they can create Cybersecurity Report Cards for their promotion and marketing. Each Reseller plan
            limits the number of Cybersecurity Report Cards that can be created each month. When a Reseller creates a Cybersecurity Report
            Card for a company that does not already exist within the public directory, the Reseller will become the sponsor for this
            company as displayed on the public directory on www.CybersecurityReportCard.org.{' '}
          </p>
          <h1> Commission: </h1>
          <p>
            {' '}
            Commissions are paid while a Reseller maintains an active subscription. If a Reseller’s subscription has become inactive due to
            payment failure for 30 days, the Reseller will void commission payments until their status is returned to good standing.{' '}
          </p>
          <p>
            {' '}
            ChatFortress uses Stripe.com for instant commission payout to Resellers. This provides Resellers with full transparency on
            payment processing and commission payout security. Resellers will be required to connect their ChatFortress account to their
            Stripe account to receive payments. If Resellers do not have a Stripe account, they can create one during the activation stage
            at no cost.{' '}
          </p>
          <h1>ChatFortress Cybersecurity Report Card Reseller Program Agreement</h1>
          <p>
            This ChatFortress Reseller Agreement (the “Reseller Agreement”) is between the applicable reseller (“you or “Reseller”), and
            ChatFortress LLC, Mailing Address: 11407 SW Amu St, Suite #69437, Tualatin, OR 97062, USA. If you are agreeing to this Reseller
            Agreement not as an individual but on behalf of your company, then “Reseller” or “you” means you're company, and you are binding
            your company to this Reseller Agreement.{' '}
          </p>
          <p>
            This Reseller Agreement is a separate agreement from the agreement that ChatFortress has with its end users at{' '}
            <a href="https://chatfortress.com/termsofservice">https://chatfortress.com/termsofservice</a> and privacy policy{' '}
            <a href="https://chatfortress.com/privacy">https://chatfortress.com/privacy</a> (as may be modified by ChatFortress from time to
            time, “ChatFortress Master Services Agreement “). For convenience and consistency, however, the definitions of certain terms
            related to our products and services (namely Software, Hosted Services, Products, and Authorized Users) will be the same as
            those in the ChatFortress Master Services Agreement .{' '}
          </p>
          <h2> Application of this agreement </h2>
          <p>
            {' '}
            This Reseller Agreement does not have to be signed in order to be binding. You indicate your assent to the terms by clicking on
            the “I agree” (or similar button), using the ChatFortress Application, Submitting a Client, or Receiving Commission Payment that
            is presented to you at the time of your account creation.{' '}
          </p>
          <ol>
            <li>you agree to this Agreement; and</li>
            <li>
              where you are applying on behalf of another person (e.g. a company), you confirm that you are authorised to, and do in fact,
              agree to this Agreement on that person’s behalf and that, by agreeing to this Agreement on that person’s behalf, that person
              is bound by this Agreement.
            </li>
          </ol>
          <h2> Changes </h2>
          <p>
            ChatFortress may change this Agreement at any time by notifying the Reseller of the change by email or by posting a notice on
            the Portal. Unless stated otherwise, any change takes effect from the date set out in the notice, or 30 days from the date of
            notice if change date is stated in the notice. The Reseller is responsible for ensuring it is familiar with the latest
            Agreement. By continuing to access and use the Service from the date on which the Agreement is changed, the Reseller agrees to
            be bound by the changed Agreement.
          </p>
          <h2>Appointment </h2>
          <p>
            {' '}
            ChatFortress grants to the Reseller the non-exclusive, non-transferable right to conduct the Business in accordance with the
            Agreement for the duration of the Agreement. The Reseller must use reasonable efforts to conduct the Business.
          </p>
          <p>
            {' '}
            The Reseller is an independent contractor of ChatFortress. No other relationship (e.g. employment, joint venture, agency, trust
            or partnership) exists under the Agreement. The Agreement does not transfer or license any ChatFortress Intellectual Property to
            the Reseller, except as expressly set out in the Agreement. Reseller can sell to clients within any country that ChatFortress is
            licensed to operate world-wide.{' '}
          </p>
          <p>
            {' '}
            To avoid doubt, the rights granted to the Reseller under the Agreement are non-exclusive. Nothing in the Agreement applies to
            limit or restrict ChatFortress’s right to conduct the Business (and to authorise third parties to conduct the Business).
          </p>
          <h2> Marketing and Promotion</h2>
          <p>The Reseller must, at its own expense:</p>
          <ul>
            <li>
              work diligently to promote and protect ChatFortress’s interests, enhance and maintain the reputation of the Services and
              ChatFortress, and act loyally and faithfully towards ChatFortress, including:
            </li>
            <ul>
              <li>
                {' '}
                sell, transfer, license, sublicense, display, lease, assign or otherwise deal with or grant a security interest in the
                Services, other than as permitted under the Agreement;
              </li>
              <li> maintaining good relationships with all of the Customers and prospective Customers in the Territory; and </li>
            </ul>
            <li> use reasonable efforts to maximise sales of the Services. </li>
          </ul>
          <h2> Reseller Obligations</h2>
          <p>In addition to complying with its other obligations in the Agreement, the Reseller must:</p>
          <ul>
            <li> conduct the Business in accordance with Good Industry Practice; </li>
            <li> comply with:</li>
            <ul>
              <li>
                {' '}
                all applicable laws, regulations, rules and professional codes of conduct or practice when conducting the Business and
                performing its other obligations in the Agreement; and
              </li>
              <li>
                {' '}
                the Reseller Documentation, as applicable to the Reseller’s Reseller Category; procure all licences, authorisations and
                consents necessary for the Reseller to conduct the Business; and promptly notify ChatFortress of:
              </li>
              <li>
                {' '}
                any breach of the Reseller’s obligations under the Agreement or any matter which may impact on the Reseller’s ability to
                perform its obligations under the Agreement; and
              </li>
              <li>
                {' '}
                any complaint relating to the Services that is received by the Reseller, together with all available information relating to
                the complaint.
              </li>
            </ul>
          </ul>
          <h2>ChatFortress Obligations </h2>
          <p>In addition to complying with its other obligations in the Agreement, ChatFortress will:</p>
          <ul>
            <li> use reasonable efforts to support and assist the Reseller’s conduct of the Business;</li>
            <li>
              {' '}
              at the Reseller’s cost and reasonable request, provide the Reseller with: (i.) any existing marketing material to enable the
              Reseller to conduct the Business; and (ii.) reasonable technical training and guidance about the Services at dates and times
              agreed by the parties; and
            </li>
            <li>
              {' '}
              use reasonable efforts to keep the Reseller informed about any update to the Services and other products and services that
              ChatFortress may be making available to the Reseller.
            </li>
            <li>
              {' '}
              Provide the Reseller with training and education on the ChatFortress products and services and the sales process for each of
              these products.{' '}
            </li>
            <li>Provide the Reseller with access to the ChatFortress Reseller portal. </li>
          </ul>
          <h2>Commission </h2>
          <p>
            Subject to the remainder of this, ChatFortress must pay the Reseller the Commission inclusive Sales of Tax (if any) in
            accordance with this and the Reseller Documentation. The payment of Sales Tax is subject to the Reseller supplying ChatFortress
            with a valid tax invoice. Sales Tax payments are based on ChatFortress company registration in Delaware USA regardless of the
            Resellers location.{' '}
          </p>
          <p>
            {' '}
            To avoid doubt, the Reseller is not entitled to any Commission unless and until the Customer has entered into a Customer
            Agreement and paid the relevant Fees per their agreement. Commission payments may be broken into monthly payouts based on the
            customers payment plans.{' '}
          </p>
          <p>
            {' '}
            Within 14 days of the end of each calendar month, ChatFortress will provide the Reseller with a monthly statement of the
            Commission earned by the Reseller in the previous month, including all information reasonably required to verify the Commission.
            This statement will be provided inside the reseller portal as the payment and invoices ledger. The Reseller is responsible for
            validating this transaction ledger.{' '}
          </p>
          <p>
            {' '}
            The Reseller must notify ChatFortress in writing of any error in the calculation of the Commission within 7 days of receiving
            the statement or the Reseller is deemed to have accepted the statement.{' '}
          </p>
          <p>
            {' '}
            ChatFortress must pay the Commission within 30 days of the later of receipt from the Customer of payment of the Fees to which
            the Commission relates or a valid invoice that complies with this. The Reseller must provide ChatFortress with a completed IRS
            W9 tax form to receive commission payments, even if the reseller is located outside the USA.{' '}
          </p>
          <p>
            {' '}
            Other than Sales Tax, the Reseller is responsible for paying all taxes, duties, fees or other government charges relating to the
            Commission. ChatFortress may deduct any withholding tax required to be withheld by law from the Commission payments made under
            this. If withholding occurs, ChatFortress will advise the Reseller of the amount withheld at the time each payment is made.
          </p>
          <p>
            {' '}
            If any Commission that has been paid by ChatFortress is subject to downwards adjustment in accordance with the Reseller
            Documentation or ChatFortress has otherwise overpaid any Commission the Reseller must, on demand by ChatFortress, promptly repay
            the relevant amount. ChatFortress may set off against any future Commission payment any amount that becomes repayable by the
            Reseller under this clause but which has not been received by ChatFortress.
          </p>
          <p>
            {' '}
            If commission payments are not redeemed within 90 days through no fault of ChatFortress the Reseller forfeits their commissions
            earned for the associated invoices.
          </p>
          <p>
            {' '}
            Client Ownership: Client’s sold by the Reseller become clients of the ChatFortress company. ChatFortress can continue a
            relationship with the clients directly, and reserves all rights to communicate with the clients directly. If Reseller is
            entitled to commission generated from sales that occur by the ChatFortress inside-Sales team the Reseller will be paid their
            commission at the reduced referral rate.
          </p>
          <h2> Intellectual Property </h2>
          <p>
            {' '}
            ChatFortress (and its licensors) owns all Intellectual Property Rights in the Services and the Documentation. The Reseller must
            not contest or dispute that ownership, or the validity of those Intellectual Property Rights.
          </p>
          <p>
            {' '}
            As between the parties, from the date it is created, ChatFortress solely owns: all new Intellectual Property created by
            ChatFortress in the course of providing the Services or otherwise in connection with the Agreement; and ball new Intellectual
            Property in any documentation or other materials created by the Reseller, to the extent the documentation or materials relate to
            the Services.
          </p>
          <p>
            {' '}
            If the Reseller or any Customer provides ChatFortress with ideas, comments or suggestions relating to the Services or the
            Documentation (together feedback): all Intellectual Property Rights in that feedback, and anything created as a result of that
            feedback (including new material, enhancements, modifications or derivative works), are owned solely by ChatFortress; and
            ChatFortress may use or disclose the feedback for any purpose.
          </p>
          <p>
            {' '}
            ChatFortress grants to the Reseller a non-exclusive, non-transferable licence for the duration of the Agreement to use the
            Brands in order to conduct the Business in accordance with the Agreement.
          </p>
          <p>
            {' '}
            The Reseller must not: use ChatFortress’s Intellectual Property other than to carry out its obligations under the Agreement; or
            alter, remove or otherwise interfere with any copyright or proprietary marking on the Documentation.
          </p>
          <p>
            {' '}
            ChatFortress grants to the Reseller a non-exclusive, non-transferable licence for the duration of the Agreement to use the
            Brands in order to conduct the Business in accordance with the Agreement.
          </p>
          <p>
            {' '}
            The Services must be marketed and sold by the Reseller using the Brands. ChatFortress may add or remove a Brand from the scope
            of the Agreement at any time by notice to the Reseller. On receipt of that notice, the definition of Brands in the Agreement is
            deemed to be amended in accordance with the notice.
          </p>
          <p>
            {' '}
            The Reseller must use the Brands only in accordance with any usage and marketing guidelines provided by ChatFortress from time
            to time.
          </p>
          <p>
            {' '}
            The Reseller must promptly notify ChatFortress: where the Reseller becomes aware, or reasonably suspects, that ChatFortress’s
            Intellectual Property Rights are being infringed, or are likely to be infringed, including any circumstance that suggests a
            person may have unauthorised knowledge, possession or use of the Services; and of any proceeding or known intention to bring
            proceedings against the Reseller and/or ChatFortress, including for infringement of Intellectual Property Rights.
          </p>
          <p>
            {' '}
            ChatFortress has the sole right to take, defend or settle any proceeding for any alleged infringement of any Intellectual
            Property Right of any third party relating to the Intellectual Property of ChatFortress.
          </p>
          <h2> FCPA and Anti-corruption Laws</h2>
          <p>
            {' '}
            Reseller acknowledges that it is familiar with and understands the provisions of the U.S. Foreign Corrupt Practices Act (the
            “FCPA”) and the U.K. Bribery Act of 2010 (“UKBA”) and agrees to comply with its terms as well as any provisions of local law or
            ChatFortress’s corporate policies and procedures related thereto. OEM Partner further understands the provisions relating to the
            FCPA and UKBA’s prohibitions regarding the payment or giving of anything of value, including but not limited to payments, gifts,
            travel, entertainment and meals, either directly or indirectly, to an official of a foreign government or political party for
            the purpose of influencing an act or decision in his or her official capacity or inducing the official to use his or her party's
            influence with that government, to obtain or retain business involving the Products. Reseller agrees to not violate or knowingly
            let anyone violate the FCPA or UKBA, and Reseller agrees that no payment it makes will constitute a bribe, influence payment,
            kickback, rebate, or other payment that violates the FCPA, the UKBA, or any other applicable anti corruption or anti bribery
            law.
          </p>
          <h2> Confidentiality </h2>
          <p>
            {' '}
            Each party must, unless it has the prior written consent of the other party: keep confidential at all times the Confidential
            Information of the other party; effect and maintain adequate security measures to safeguard the other party’s Confidential
            Information from unauthorised access or use; and disclose Confidential Information to its personnel or professional advisors on
            a need to know basis only and, in that case, ensure that the personnel or professional advisor is aware of, and complies
          </p>
          <h2> Warranties </h2>
          <p>
            {' '}
            Each party warrants that it has full power and authority to enter into and perform its obligations under the Agreement which,
            when signed, will constitute binding obligations on the warranting party.
          </p>
          <p>
            {' '}
            ChatFortress does not give any warranty to either the Reseller or any Customer relating to the Services, other than the
            warranties: that ChatFortress makes directly to Customers in a Customer Agreement; or for which ChatFortress cannot exclude its
            liability.{' '}
          </p>
          <p>
            {' '}
            The Reseller must not give any warranty or make any representation relating to the Services, including that the Services will be
            exclusively licensed or made available to the Customer or prospective Customer.
          </p>
          <p>
            {' '}
            To the maximum extent permitted by law: ChatFortress’s warranties are limited to those set out in Customer Agreements and the
            Agreement, and all other conditions, guarantees or warranties whether expressed or implied by statute or otherwise are expressly
            excluded; and ChatFortress makes no representation concerning: i. the quality of the Services, and does not promise that the
            Services will operate without error or interruption; or ii. the level of Commission to be earned from the conduct of the
            Business.
          </p>
          <h2> Indemnity</h2>
          <p>
            {' '}
            The Reseller indemnifies ChatFortress from and against any claim, proceeding, damage, loss, liability, cost and expense
            (including legal costs on a solicitor and own client basis) suffered or incurred by ChatFortress resulting from: infringement of
            ChatFortress’s Intellectual Property Rights by the Reseller, including any misuse of the Brands; and any warranty given or
            representation made by the Reseller to a Customer or prospective Customer
          </p>
          <h2> Liability </h2>
          <p>
            The maximum aggregate liability of ChatFortress to the Reseller under or in connection with the Agreement, whether in contract,
            tort (including negligence), breach of statutory duty or otherwise: relating to any Customer, must not exceed an amount equal to
            the Commission paid or payable by ChatFortress to the Reseller in relation to that Customer; and must not in any Year exceed an
            amount equal to the Commission paid to the Reseller under the Agreement in the previous Year (which in the first Year is deemed
            to be the total Commission paid to the Reseller from the Start Date to the date of the first event giving rise to liability).{' '}
          </p>
          <p>
            {' '}
            Neither party is liable to the other under or in connection with the Agreement for any: loss of profit, revenue, savings,
            business, data and/or goodwill; or consequential, indirect, incidental or special damage or loss of any kind.{' '}
          </p>
          <p>
            {' '}
            Neither party will be responsible, liable, or held to be in breach of the Agreement for any failure to perform its obligations
            under the Agreement or otherwise, to the extent the failure is directly caused by the other party failing to comply with its
            obligations under the Agreement, or by the negligence or misconduct of the other party or its personnel.
          </p>
          <p>
            {' '}
            Each party must take reasonable steps to mitigate any loss or damage, cost or expense it may suffer or incur arising out of
            anything done or not done by the other party under or in connection with the Agreement.
          </p>
          <p>
            {' '}
            At its own expense, the Reseller must maintain in effect at all times during the term of the Agreement the insurance policies,
            with a reputable third party insurance company, that a prudent entity conducting the Business would maintain, taking into
            account the Reseller’s risks and potential liabilities under the Agreement.
          </p>
          <p>
            {' '}
            As part of ChatFortress’s vendor management ChatFortress may request proof of the Resellers insurance for Cyber Liability, and
            Errors and Omissions. This insurance must be valid and the Reseller must provide copies of the certificates each renewal.{' '}
          </p>
          <p>
            {' '}
            As part of ChatFortress’s vendor management ChatFortress may request copies the the Resellers cybersecurity policies and
            procedures. Copies of these policies will be added to the Reseller portal as part of the Reseller verification and validation.
            ChatFortress can request document or proof of business registration, copies of identification documents and bank account
            validation as part of the vendor management processes. The Reseller grants ChatFortress with permission to validate the
            Resellers identity and their business registration as part of this process.{' '}
          </p>
          <h2> Term and Termination </h2>
          <p> Unless terminated under this clause</p>
          <p>
            {' '}
            the Agreement: starts on the Start Date; and continues for successive terms of 12 months from the Start Date unless a party
            gives at least 30 days’ notice that the Agreement will terminate on the expiry of the then -current term.{' '}
          </p>
          <p>
            {' '}
            Other termination rights: Either party may, by notice to the other party, immediately terminate the Agreement if the other
            party: i. breaches any material provision of the Agreement and the breach is not: remedied within 10 days of the receipt of the
            notice from the first party requiring it to remedy the breach; or capable of being remedied; ii. becomes insolvent, liquidated
            or bankrupt, has an administrator, receiver, liquidator, statutory manager, mortgagee’s or chargee’s agent appointed, becomes
            subject to any form of insolvency action or external administration, or ceases to continue business for any reason; or iii is
            unable to perform a material obligation under the Agreement for 30 days or more due to Force Majeure. ChatFortress may, by
            notice to the Reseller, immediately terminate the Agreement if the exercise of the remedy in clause 14.3 does not remedy or
            settle the IP Claim.
          </p>
          <p> No fault termination: either party may terminate this Agreement on at least 30 days’ prior notice.</p>
          <p>
            {' '}
            Where the Agreement is terminated by ChatFortress under clause 16.2ai, no further Commission is payable by ChatFortress from the
            termination date. In all other cases, ChatFortress must, in relation to any Customer Agreement entered into by ChatFortress
            prior to the termination or expiry date, continue to pay the applicable Commission on the Fees paid by the Customer under that
            Customer Agreement during the 12 month period from the date of termination or expiry will continue to apply to the payment of
            those Fees.
          </p>
          <p>
            {' '}
            No compensation is payable by ChatFortress to the Reseller as a result of termination of the Agreement for whatever reason.
          </p>
          <h2> Disputes </h2>
          <p>
            {' '}
            Before taking any Court action, a party must use reasonable efforts to resolve any dispute under, or in connection with, the
            Agreement through good faith negotiations. Each party must, to the extent practicable, continue to perform its obligations under
            the Agreement even if there is a dispute.
          </p>
          <p>
            {' '}
            Dispute Resolution; Arbitration. In the event of any controversy or claim arising out of or relating to this Reseller Agreement,
            the parties hereto shall consult and negotiate with each other and, recognizing their mutual interests, attempt to reach a
            solution satisfactory to both parties. If the parties do not reach a settlement within a period of 60 days, any unresolved
            controversy or claim arising out of or relating to this Reseller Agreement shall proceed to binding arbitration under the Rules
            of Arbitration of the International Chamber of Commerce. The parties shall seek to mutually appoint an arbitrator. If the
            parties cannot agree on a single arbitrator, then there shall be three (3) arbitrators: one selected by each party, and a third
            selected by the first two. Arbitration will take place in: Tampa, Florida (USA), or the head office location of ChatFortress.
            All negotiations and arbitration proceedings pursuant to this Section 15.1 will be confidential and treated as compromise and
            settlement negotiations for purposes of all similar rules and codes of evidence of applicable legislation and jurisdictions. The
            language of the arbitration shall be English.
          </p>
          <p>
            {' '}
            Governing Law; Jurisdiction. This Reseller Agreement will be governed by and construed in accordance with the applicable laws of
            the State of Delaware, USA, without giving effect to the principles of that State relating to conflicts of laws. Each party
            irrevocably agrees that any legal action, suit or proceeding that is not otherwise subject to the arbitration provisions of
            Section 15.1 (Dispute Resolution; Arbitration) must be brought solely and exclusively in, and will be subject to the service of
            process and other applicable procedural rules of, the State or Federal court in Delaware, USA, and each party irrevocably
            submits to the sole and exclusive personal jurisdiction of the courts in Delaware, USA, generally and unconditionally, with
            respect to any action, suit or proceeding brought by it or against it by the other party. Notwithstanding the foregoing,
            ChatFortress may bring a claim for equitable relief in any court with proper jurisdiction.
          </p>
          <p>
            {' '}
            Injunctive Relief; Enforcement. Notwithstanding the provisions of this Section 15, nothing in this Reseller Agreement shall
            prevent either party from seeking injunctive relief with respect to a violation of intellectual property rights, confidentiality
            obligations or enforcement or recognition of any award or order in any appropriate jurisdiction.
          </p>
          <p>
            {' '}
            Exclusion of UN Convention and UCITA. The terms of the United Nations Convention on Contracts for the Sale of Goods do not apply
            to this Reseller Agreement. The Uniform Computer Information Transactions Act (UCITA) shall not apply to this Reseller Agreement
            regardless of when or where adopted.
          </p>
          <h2> General </h2>
          <p>
            Neither party is liable to the other for any failure to perform its obligations under the Agreement to the extent caused by
            Force Majeure, provided that the affected party: promptly notifies the other party and provides full information about the Force
            Majeure; uses reasonable efforts to overcome the Force Majeure; and continues to perform its obligations to the extent
            practicable.{' '}
          </p>
          <p>
            {' '}
            No person other than ChatFortress and the Reseller has any right to a benefit under, or to enforce, the Agreement. To waive a
            right under the Agreement, that waiver must be in writing and signed by the waiving party.
          </p>
          <p>
            {' '}
            If any provision of the Agreement is, or becomes, illegal, unenforceable or invalid, the relevant provision is deemed to be
            modified to the extent required to remedy the illegality, unenforceability or invalidity. If modification under this clause is
            not possible, the provision must be treated for all purposes as severed from the Agreement without affecting the legality,
            enforceability or validity of the remaining provisions of the Agreement.
          </p>
          <p>
            {' '}
            The Agreement sets out everything agreed by the parties relating to the Services, the relationship between the parties, and the
            conduct of the Business, and supersedes and cancels anything discussed, exchanged or agreed prior to the Start Date. The parties
            have not relied on any representation, warranty or agreement relating to the subject matter of the Agreement that is not
            expressly set out in the Agreement, and no such representation, warranty or agreement has any effect from the Start Date.
          </p>
          <p>
            {' '}
            The Reseller may not assign, novate, subcontract or transfer any right or obligation under the Agreement, without the prior
            written consent of ChatFortress. The Reseller remains liable for the performance of its obligations under the Agreement despite
            any approved assignment, subcontracting, or transfer. Any assignment, novation, subcontracting or transfer must be in writing.
            Any change of control of the Reseller is deemed to be an assignment for which ChatFortress’s prior written consent is required
            under this clause. In this clause, change of control means any transfer of shares or other arrangement affecting the Reseller or
            any member of its group which results in a change in the effective control of the Reseller.
          </p>
          <p>
            {' '}
            The Agreement is governed by, and must be interpreted in accordance with, the laws of Delaware, USA. Each party submits to the
            non-exclusive jurisdiction of the courts of Delaware in relation to any dispute connected with the Agreement.
          </p>
          <p> </p>
          <h2> How do I contact ChatFortress? </h2>
          <p> You can contact the ChatFortress team using the following communication channels: </p>
          <ol>
            <li>
              {' '}
              Website Live Chat at <a href="https://chatfortress.com">www.ChatFortress.com</a>
            </li>
            <li>
              {' '}
              Email: <a href="mailto:help@chatfortress.com">help@ChatFortress.com</a>{' '}
            </li>
            <li> Phone: USA (307) 999-7755</li>
          </ol>
          <h2> How do I cancel the Email Guardian Small Business program? </h2>
          <p>
            {' '}
            To cancel the ChatFortress Cybersecurity Report Card Reseller program you need to provide written notice to{' '}
            <a href="mailto:help@chatfortress.com">help@chatfortress.com</a>. If you want to cancel an active subscription you need to
            provide 30 days written notice to cancel your ChatFortress account. There are no refunds, or partial credits, or pro-rata
            accounting for amounts paid. You pay for a month in advance.
          </p>

          <div className="text-center">
            <a href="/termsofservice">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default tpl
