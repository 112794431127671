/** @format */

import React from 'react'
import moment from 'moment-timezone'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormCard, FormSection, Profile } from 'lib/form'

import HelpIcon from '@material-ui/icons/Help'
import { getUser, updateUser } from 'api/users'

import withContext from 'lib/withAppContext'

const ProfileComponent = ({ context }) => (
  <Query query={getUser} variables={{ userId: context.user.id }}>
    {({ data: { user } = {}, loading }) =>
      !loading && (
        <FormCard
          form="Profile"
          mutation={[updateUser, { variables: { userId: context.user.id } }]}
          initialValues={{ ...user, timezone: user.timezone || moment.tz.guess() }}
          onSubmitSuccess={()=>context.refetch()}
        >
          {({ doc }) => (
            <React.Fragment>
              <div>
                {' '}
                <p>
                  Profile page is used to configure your access settings to your account. If you have questions use the live chat to ask
                  your question.
                  <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e49773f2c7d3a7e9ae7d141">
                    {' '}
                    Get help about this page here
                  </a>{' '}
                </p>
              </div>
              <FormSection name="">
                <Profile doc={doc} />
              </FormSection>
            </React.Fragment>
          )}
        </FormCard>
      )
    }
  </Query>
)

export default compose(withContext)(ProfileComponent)
