/** @format */

import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import { useQuery } from 'react-apollo'
import { FormDialog, FormSection, FormGroup, Field, Address } from 'lib/form'
import { TextField, PhoneNumber, Checkbox, AvatarUploadField } from 'lib/form/fields'
import { required, email } from 'lib/form/validators'

import { getCompanyDataForUpdating, updateCompanyBulk } from 'api/companies'

import withStyles from '@material-ui/core/styles/withStyles'

import withAppContext from 'lib/withAppContext'

const CompanyUpdate = ({ classes, match, context, location }) => {

  const { acl } = context
  let selectOptions = [
    {
      label: "No change", value: -1
    },
    {
      label: "All On", value: 1
    },
    {
      label: "All Off", value: 2
    },
  ]
if(location && location.state && location.state.selected) {
  return (
    (
      <FormDialog
        title={`Bulk Update: ${location.state.selected.length} entries`}
        form="CompanyUpdateBulk"
        refetchQueries={['getCompanies']}
        mutation={[updateCompanyBulk]}
        initialValues={{
          noFeaturedComparison: -1,
          noComparison: -1,
          noSensitiveComparison: -1,
          hiddenByOwner: -1,
        }}
        onSubmit={({data, mutation})=> {
          let updateData = _.reduce(_.filter(Object.keys(data), k=>data[k]!==-1).map(k=>({[k]:data[k]===1?true:false})), (obj, v)=>({...obj, [Object.keys(v)[0]]:Object.values(v)[0]}), {})
          console.log(mutation, location.state.selected, updateData)
          mutation({variables: {companyIds: location.state.selected, data: updateData}})
        }}
        success="Companies updated successfully"
      >
        {({doc, submitting}) => (
          <React.Fragment>
            <FormGroup title="Report Settings">
              <Field name="noFeaturedComparison" component={TextField} label="No `Featured` comparison" select options={selectOptions} />
              <Field name="noComparison" component={TextField} label="No category comparisons" select options={selectOptions}/>
              <Field
                name="noSensitiveComparison"
                component={TextField}
                label="Remove all Sex, Politics, and Religion companies from comparison"
                select options={selectOptions}
              />
              <Field name="hiddenByOwner" component={TextField} label="Hidden at request of domain owner" select options={selectOptions}/>
            </FormGroup>

          </React.Fragment>
        )}
      </FormDialog>
    )
  )
}
}

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withAppContext, withStyles(styles))(CompanyUpdate)
