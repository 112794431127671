/** @format */

import React from 'react'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, length } from 'lib/form/validators'

import { createCampaign } from 'api/campaigns'

import withStyles from '@material-ui/core/styles/withStyles'

import { userAccountTypeOptions } from 'data/options'

const CampaignCreate = ({ classes, match }) => (
  <FormDialog
    title="Create Campaign"
    form="CampaignCreate"
    mutation={[createCampaign, { refetchQueries: ['getCampaigns'] }]}
    initialValues={{}}
    btnSubmitText="Create"
    success="Campaign created successfully"
  >
    {({ doc, invalid, submitting }) => (
      <React.Fragment>
        <Field name="name" component={TextField} label="Campaign Name" validate={[required(), length({ min: 2 })]} />
      </React.Fragment>
    )}
  </FormDialog>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(CampaignCreate)
