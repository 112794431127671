/** @format */

const products = {
  iGvmrjNWNrLxXdWxEPXm: { name: 'hacked', dashboardComponent: 'HackedDash' },
  krqpTsKDeZHokowXfL7e: { name: 'report', dashboardComponent: 'ReportDash' },
  TRnFnMM7JWye86Tqg8Cv: { name: 'report', dashboardComponent: 'ReportDash' }
}

const ProductMatcher = id => {
  return products[id] || null
}

export default ProductMatcher
