/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { FormDialog, FormSection } from 'lib/form'

import { createInvoiceEmail } from 'api/invoiceEmails'

import withStyles from '@material-ui/core/styles/withStyles'
import InvoiceEmailForm from '../../lib/form/InvoiceEmailForm'

const InvoiceEmailAdd = ({ match, onComplete }) => (
  <React.Fragment>
    <FormDialog
      title="Add invoice Notification Email"
      form="InvoiceEmailAdd"
      mutation={[createInvoiceEmail, { refetchQueries: ['getInvoiceEmails'] }]}
      btnSubmitText="Add"
      success="Invoice Notification Email added successfully"
      onSubmit={({ data, mutation }) => {
        mutation({ variables: { ...match.params, data: { invoice_email: data.invoice_email.email } } })
      }}
      onComplete={onComplete}
    >
      {({ doc, invalid, submitting }) => (
        <React.Fragment>
          <FormSection name="invoice_email">
            <InvoiceEmailForm />
          </FormSection>
        </React.Fragment>
      )}
    </FormDialog>
  </React.Fragment>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(InvoiceEmailAdd)
