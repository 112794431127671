/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, numericality } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getDataCreationUserLicense, addUserLicense } from 'api/userLicenses'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const UserLicenseAdd = ({ match }) => (
  <Query query={getDataCreationUserLicense}>
    {({ data: { licenses: { data: licenses } = {} } = {}, loading }) =>
      !loading && (
        <FormDialog
          title="Apply License to User"
          form="UserLicenseAdd"
          mutation={[addUserLicense, { refetchQueries: ['getUserLicenses'] }]}
          initialValues={{ userId: match.params.employeeId, quantity: 1 }}
          btnSubmitText="Apply"
          success="License applied successfully"
        >
          {({ doc, invalid, submitting }) => {
            let license = licenses.find(({ value }) => value === doc.licenseId)
            return (
              <React.Fragment>
                <Field
                  name="licenseId"
                  component={TextField}
                  label="License"
                  options={licenses.map(({ label, value }) => ({ label, value }))}
                  validate={[required()]}
                  select
                />
                {license && (
                  <div>
                    <div>
                      <b>Total Purchased</b>: {license.quantity}
                    </div>
                    <div>
                      <b>Used</b>: {license.usageCount}
                    </div>
                    <div>
                      <b>Left</b>: {license.quantity - license.usageCount}
                    </div>
                  </div>
                )}
                {/*<Field
                name="quantity"
                component={TextField}
                label="Number of UserLicenses"
                validate={[required(), numericality()]}
                normalize={number}
              />*/}
              </React.Fragment>
            )
          }}
        </FormDialog>
      )
    }
  </Query>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(UserLicenseAdd)
