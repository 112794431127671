/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { FormCard, Field } from 'lib/form'
import { TextField, Checkbox } from 'lib/form/fields'

import { getEmployee, updateEmployee } from 'api/employees'

import withStyles from '@material-ui/core/styles/withStyles'

const EmployeeNotifications = ({ classes, match }) => (
  <Query query={getEmployee} variables={match.params}>
    {({ data: { employee } = {}, loading }) =>
      !loading && (
        <FormCard
          title=""
          form="EmployeeNotifications"
          mutation={[updateEmployee]}
          initialValues={employee}
          success="Notifications updated successfully"
          btnSubmitText="Save Employee Notifications"
        >
          {({ doc, submit, submitting }) => (
            <React.Fragment>
              <Field name="notifyByEmail" component={Checkbox} label="Notify by Email" />
              <Field name="notifyBySMS" component={Checkbox} label="Notify by SMS" />
            </React.Fragment>
          )}
        </FormCard>
      )
    }
  </Query>
)

const styles = theme => ({
  root: {}
})

export default withStyles(styles)(EmployeeNotifications)

/*
 */
