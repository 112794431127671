/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles/index'

const Module = ({ match }) => (
  <div>
    <section className="fdb-block py-4">
      <div className="container">
        <h2>Training and Awareness Disclaimer.</h2>
        <p>
          You understand and accept that ChatFortress will conduct phishing training and awareness of all of the active emails that have
          been attached to your ChatFortress account for the purpose of the ChatFortress Email Guardian program.{' '}
        </p>
        <ul>
          <li>
            This means but not limited to you accept and give permission for ChatFortress to send phishing emails to your employees,
            contractors, independent contracts and individuals using the active email address you have provided ChatFortress via the Email
            Guardian program.
          </li>
          <li>
            These emails may be received on any device that the user accesses emails. This includes but is not limited to: mobile (cell)
            phones, tablets, desktops, personal and work devices.{' '}
          </li>
          <li>
            You will inform your company members that phishing awareness training is in place on an ongoing basis while you have an active
            ChatFortress subscription.{' '}
          </li>
          <li>
            These phishing emails are for the purpose of training and education only. Any data or information that is collected during this
            training is for training purposes only and will remain confidential. Any and all information that is collected will be reported
            to the Client directly and deleted from the ChatFortress system.{' '}
          </li>
          <li>
            All of the ChatFortress Iron School training and awareness phishing emails will be indicated visually once a person clicks on
            the email. This will display a training video for users to learn from the phishing awareness test.{' '}
          </li>
        </ul>
        <h3>
          The success of the ChatFortress Phishing training and awareness program is increased when your leadership teams verbally show
          support to the program.
        </h3>
        <p>The following recommendations are in place: </p>
        <ul>
          <li>Verbally share the lessons you learn with your team., </li>
          <li>
            Have leadership share their results good and bad with their fellow team members. This promotes trust and awareness amongst your
            team.{' '}
          </li>
          <li>Do not ridicule or embarrass team members who have successfully been phished. </li>
          <li>
            Remember 75% of people will be, can be phished at any point in time. Phishing manipulated human behaviors. The goal of this
            program is to increase awareness of phishing attacks and ensure your team know what do to if they have been attacked, or how to
            report an email attack.
          </li>
          <li>ChatFortress does not recommend allowing any user to opt-out of the ChatFortress phishing awareness training. </li>
        </ul>
        <h2>Indemnity and Hold Harmless</h2>
        <ul>
          <li>
            The client indemnifies and holds harmless ChatFortress from any and all liabilities, losses, damages, costs and expenses
            (including reasonable attorneys’ and experts’ fees) incurred by them pursuant to any of the iron school phishing awareness email
            training.
          </li>
          <li>
            ChatFortress is not liability or responsible for action that users take when they receive emails used as part of the phishing
            awareness training.
          </li>
        </ul>
        <h2>Cyber Security Awareness Monthly</h2>
        <ul>
          <li>
            You authorize and allow ChatFortress to deliver to your employees, contractors, and users added to your account a text message
            (sms) for the consumption of the training content as part of the Cyber Security Awareness Monthly program.{' '}
          </li>
          <li>You authorize the deliver of up to one (1) text message conversation for this content each week.</li>
          <li>You authorize that you have permission to opt-in your employees, contractors and users to this for of content. </li>
          <li>
            Your users can opt-out of receiving these training messages each week. You will be informed via the training dashboard which
            users have opt-out of text message notifications.{' '}
          </li>
          <li>
            You consent to send 1 email to your email each month alerting your team members of the Cyber Security Awareness Monthly training
            release.{' '}
          </li>
        </ul>
      </div>
    </section>
  </div>
)

const styles = theme => ({
  root: {
    width: '100%'
  }
})

export default compose(withStyles(styles))(Module)
