/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Nav from 'components/Nav'

import Plans from 'components/Plans'
import ProductUpdate from './ProductUpdate'
import ProductCommissions from './ProductCommissions'

export default ({ match, history, location }) => (
  <React.Fragment>
    <Nav
      color="primary"
      items={[
        { key: 'update', title: 'Configure', to: `${match.url}/update` },
        { key: 'plans', title: 'Plans', to: `${match.url}/plans` },
        { key: 'commissions', title: 'Commissions', to: `${match.url}/commissions` }
      ]}
      activeKey={location.pathname.split('/')[4]}
    />
    <Switch>
      <Route path={`${match.path}/update`} component={ProductUpdate} />
      <Route path={`${match.path}/plans`} component={Plans} />
      <Route path={`${match.path}/commissions`} component={ProductCommissions} />
    </Switch>
  </React.Fragment>
)
