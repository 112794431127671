/** @format */

import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import compose from 'recompose/compose'
import withContext from 'lib/withAppContext'
import { withMutation, withQuery } from 'react-apollo'
import withStyles from '@material-ui/core/styles/withStyles'
import { withSnackbar } from 'notistack'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import ElevationScroll from 'components/ElevationScroll'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Courses from 'components/Training/Courses'
import Quiz from 'components/Quiz/Quiz'

import { getTrainingLesson } from 'api/lessons'

import { Field, FormCard, FormSection, FormGroup, Address } from 'lib/form'
import { getCompanyDataForUpdating, updateCompany } from 'api/companies'
import { TextField as FormTextField, PhoneNumber, AvatarUploadField } from 'lib/form/fields'
import { required, email as emailRule } from 'lib/form/validators'

import dashboardStyle from './styles/dashboardStyle'

class ReportDash extends React.Component {
  state = {
    activeStep: 0
  }

  render() {
    const { classes, company, categories, enqueueSnackbar, context, lesson, refetch } = this.props
    const { activeStep, email, code, quizCompleted } = this.state

    const steps = ['Complete Company Profile', 'Authorize Your Domain', 'Cybersecurity Culture Quiz', 'Attend CyberSecurity Courses']

    const setEmail = email => {
      this.setState({ email })
    }

    const setCode = code => {
      this.setState({ code })
    }

    const completeTraining = () => {
      return fetch(`${process.env.REACT_APP_API_URL}/v1/complete-training`, {
        method: 'post',
        body: JSON.stringify({ userId: context.user.id }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(r => r.json())
        .then(res => {
          enqueueSnackbar('Training Completed', { variant: 'info' })
          console.log(res)
          return res
        })
    }

    const sendEmail = () => {
      fetch(`${process.env.REACT_APP_API_URL}/v1/domain-validate-email-send`, {
        method: 'post',
        body: JSON.stringify({ email, userId: context.user.id }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(r => r.json())
        .then(res => {
          enqueueSnackbar('Email with verification code sent', { variant: 'info' })
          console.log(res)
        })
        .catch(e => console.log(e))
    }

    const checkCode = () => {
      fetch(`${process.env.REACT_APP_API_URL}/v1/domain-validate-email-check`, {
        method: 'post',
        body: JSON.stringify({ code, userId: context.user.id }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(r => r.json())
        .then(res => {
          if (res.result) {
            enqueueSnackbar('Your domain was successfully verified!', { variant: 'success' })
            setTimeout(() => setActiveStep(2), 3000)
          } else enqueueSnackbar('Code is invalid', { variant: 'error' })
        })
        .catch(e => console.log(e))
    }

    const sendQuiz = (score, answers) => {
      alert('your score is ' + score)
      fetch(`${process.env.REACT_APP_API_URL}/v1/report-dash-quiz`, {
        method: 'post',
        body: JSON.stringify({ userId: context.user.id, answers, score }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(r => r.json())
        .then(() => {
          enqueueSnackbar('QuizCompleted', { variant: 'success' })
          this.setState({ quizCompleted: true })
          refetch({ companyId: context.user.companyId })
          //setTimeout(() => setActiveStep(2), 3000)
        })
        .catch(e => console.log(e))
    }
    const checkDNS = () => {
      fetch(`${process.env.REACT_APP_API_URL}/v1/domain-validate-dns`, {
        method: 'post',
        body: JSON.stringify({ userId: context.user.id }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(r => r.json())
        .then(res => {
          if (res.ok === true) {
            enqueueSnackbar('Domain Verified', { variant: 'success' })
            setTimeout(() => setActiveStep(2), 3000)
          } else
            enqueueSnackbar('No TXT record with certain data found. It can take up to 1 hour for a record to be created.', {
              variant: 'error'
            })
        })
        .catch(e => console.log(e))
    }

    const setActiveStep = activeStep => {
      this.setState({ activeStep })
      window.scrollTo(0, 0)
    }

    if (!company.id) return null

    return (
      <div className={classes.root}>
        <ElevationScroll>
          <AppBar
            style={{
              backgroundColor: 'white',
              width: '100%',
              marginTop: 64,
              paddingTop: 20,
              boxShadow: '0px 1px 5px 0px rgba(50, 50, 50, 0.75)'
            }}
          >
            <Toolbar style={{ display: 'block', marginLeft: 240 }}>
              <p className="lead text-center text-secondary">
                Please complete the Activation Steps to access the full features of their account.
              </p>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                style={{ padding: '0px 0px 8px 0px', backgroundColor: 'transparent', width: '100%' }}
              >
                {steps.map((label, k) => (
                  <Step key={label}>
                    <StepLabel>
                      <Hidden smDown>{label}</Hidden>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Container style={{ paddingTop: 103, paddingBottom: 120 }} size="xl">
          <Box my={2}>
            {activeStep === 0 && (
              <div>
                <FormCard
                  title=""
                  form="UserCompany"
                  mutation={[updateCompany, { variables: { companyId: this.props.context.user.companyId } }]}
                  initialValues={company}
                  onComplete={() => setActiveStep(1)}
                  btnSubmitText="Save & Proceeed"
                  success="Saved successfully"
                >
                  {({ doc, submit, submitting }) => (
                    <FormSection name="" className="p-3">
                      {company.website && company.phone && company.email && (
                        <div className="text-center">
                          <Button size="large" variant="contained" color="primary" onClick={() => setActiveStep(1)}>
                            Skip
                          </Button>
                        </div>
                      )}

                      <FormGroup title="Company Information">
                        {categories && categories.length > 0 && (
                          <Field
                            name="categories"
                            component={FormTextField}
                            label="Categories"
                            options={categories.map(({ id, name, parent }) => ({
                              label: name,
                              value: id,
                              optgroup: !parent && categories.filter(c => c.parent === name).length > 0
                            }))}
                            select
                            multiple
                          />
                        )}
                        <Field name="logo" component={AvatarUploadField} label="Company Logo" btnLabel="Upload Logo" />
                        <Field name="name" component={FormTextField} label="Company Name" validate={[required()]} />
                        <Field name="website" component={FormTextField} label="Company Website" />
                        <Field name="thumbnail" component={FormTextField} label="Company Screenshot Website" />
                        <Field name="email" component={FormTextField} label="Company Email Address" validate={[emailRule()]} />
                        <Field name="phone" component={PhoneNumber} label="Company Cell Phone" />
                      </FormGroup>
                      <FormSection name="address">
                        <Address title="Company Address" address={(company && company.address) || {}} />
                      </FormSection>
                      <FormGroup title="Company Representative">
                        <Field name="representativeName" component={FormTextField} label="Name" margin="none" />
                        <Field name="representativeEmail" component={FormTextField} label="Email Address" validate={[emailRule()]} />
                        <Field name="representativePhone" component={PhoneNumber} label="Phone Number" />
                      </FormGroup>
                      {company.website && company.phone && company.email && (
                        <div className="text-center">
                          <Button size="large" variant="contained" color="primary" onClick={() => setActiveStep(1)}>
                            Skip
                          </Button>
                        </div>
                      )}
                    </FormSection>
                  )}
                </FormCard>
              </div>
            )}
            {activeStep === 1 && (
              <div>
                <Paper className="p-3">
                  <h1>Domain Verification</h1>

                  {company.isDomainVerified ? (
                    <div className="alert-primary">
                      <h2>Your Domain Is Verified</h2>
                    </div>
                  ) : (
                    <div>
                      <p className="lead">
                        In order to provide you with access to a full Cybersecurity Report Card a company has to validate their authenticity
                        to access this information. This process is called domain validation. This process is called{' '}
                        <b>domain validation</b>.
                      </p>
                      <p className="lead">
                        We want to validate that only domain admins or owners gain access to this information therefore preventing malicious
                        use. If you have questions please use our support system.
                      </p>

                      <h2>You have Two Options for Domain Validation:</h2>

                      <h2>1. Email-based domain verification</h2>
                      <div className="pl-5">
                        <p className="lead">
                          Email-based domain validation is the most common domain validation mechanism. The ChatFortress company compiles a
                          list of public emails associated with the domain, using common administrative emails (e.g. admin or webmaster), in
                          combination with the public WHOIS data for the domain.
                        </p>
                        <div>
                          <h3>The process</h3>
                          <div className="lead">
                            The email validation process consists of a few steps:
                            <ol>
                              <li>
                                When you start, we show you a list of authoritative emails identified by the ChatFortress for the domain
                                associated with the owner
                              </li>
                              <li>You select an email from the list.</li>
                              <li>
                                The ChatFortress sends a verification email to the recipient with a unique code to validate your domain
                                ownership.
                              </li>
                              <li>
                                You click on the link to validate and approve the domain. At this point the domain’s authenticity is
                                validated and you get the full access.
                              </li>
                            </ol>
                          </div>
                          <p className="lead">Please select one of the following emails:</p>

                          <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup aria-label="gender" name="gender1" onChange={e => setEmail(e.target.value)}>
                              <FormControlLabel control={<Radio />} value={`admin@${company.domain}`} label={`admin@${company.domain}`} />
                              <FormControlLabel
                                control={<Radio />}
                                value={`administrator@${company.domain}`}
                                label={`administrator@${company.domain}`}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                value={`hostmaster@${company.domain}`}
                                label={`hostmaster@${company.domain}`}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                value={`postmaster@${company.domain}`}
                                label={`postmaster@${company.domain}`}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                value={`webmaster@${company.domain}`}
                                label={`webmaster@${company.domain}`}
                              />
                            </RadioGroup>
                            <Button variant="contained" color="default" disabled={!email} onClick={sendEmail}>
                              Send Verification Email
                            </Button>
                          </FormControl>
                          <div>
                            <hr />
                            <TextField label="Code From Email" variant="outlined" onChange={e => setCode(e.target.value)} />
                            &nbsp;
                            <Button
                              style={{ marginTop: 7 }}
                              variant="contained"
                              disabled={!code}
                              color="primary"
                              size="large"
                              onClick={checkCode}
                            >
                              Check The Code
                            </Button>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h3>2. TXT DNS record verification</h3>
                      <div className="pl-5">
                        <p className="lead">This method requires you to create a TXT entry in your domain’s DNS records.</p>
                        <div className="lead">
                          Add the validation CNAME to the DNS zone file following the below instructions:
                          <ol>
                            <li>Log in to your Domain Provider(godaddy etc.) account.</li>
                            <li>Open the DNS Zone editor for your domain.</li>
                            <li>Click on Add New record button</li>
                            <li>Select Type 'TXT', Host field must be '@' and the value must be '{company.id}'</li>
                            <li>Create the record</li>
                            <li>
                              It can take up to 1 hour for a record to be created, so don't panic if check won't be successful right after
                              you created it.
                            </li>
                          </ol>
                          <Button variant="contained" color="primary" size="large" onClick={checkDNS}>
                            Check The DNS RECORD
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Paper>
                <hr />
                <div style={{ textAlign: 'center' }}>
                  <Button size="large" style={{ marginRight: 20 }} variant="contained" color="secondary" onClick={() => setActiveStep(0)}>
                    Back
                  </Button>

                  {company.isDomainVerified && (
                    <Button size="large" variant="contained" color="primary" onClick={() => setActiveStep(2)}>
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
            {activeStep === 2 && lesson && lesson.quiz && (
              <div>
                <div className="row">
                  <div className="col-6 col-sm-12">
                    {!company.isQuizCompleted && (
                      <Quiz
                        ignoreCorrect={true}
                        title="Cybersecurity Culture Quiz"
                        questions={JSON.parse(lesson.quiz).map(q => ({
                          ...q,
                          answers: q.answers.map(a => ({
                            ...a,
                            value: a.value < 0 ? a.label + a.value.toString() : a.value,
                            score: a.score || 0
                          }))
                        }))}
                        message={'Well done, thank You for participation!'}
                        onComplete={({ answers }) => {
                          let sum = _.sumBy(
                            answers.map(a =>
                              Array.isArray(a.answer) ? _.maxBy(a.qData.answers, 'score').score : _.sumBy(a.qData.answers, 'score')
                            ),
                            a => a
                          )
                          let total = _.sumBy(
                            answers.map(a =>
                              _.sumBy(
                                _.filter(a.qData.answers, aa => a.answer && (a.answer === aa.value || a.answer.indexOf(aa.value) !== -1)),
                                'score'
                              )
                            ),
                            a => a
                          )
                          let score = Math.ceil((total / sum) * 1000) / 10
                          console.log(sum, total)
                          sendQuiz(score, answers)
                        }}
                      />
                    )}
                  </div>
                  <div className="col-6 col-sm-12">
                    {(company.isQuizCompleted || quizCompleted) && (
                      <section className="fdb-block py-5">
                        <div className="container">
                          <div className="row justify-content-center">
                            <div className="col-12 text-left">
                              <h1>Well done on completing the Cybersecurity Culture quiz.</h1>
                              <p className="lead">
                                Your Cybersecurity Culture badge is getting applied to your profile.{' '}
                                <i>Please note it can take 24 hours to update and display on your profile page. </i>
                              </p>
                              <h1>How to Create a Cybersecurity Culture:</h1>
                              <p className="lead">
                                Here are our recommendations to help you create a Cybersecurity Culture within your business. If you have
                                questions, please schedule an appointment with your ChatFortress Team member or use the live chat.{' '}
                              </p>
                              <h1> Cybersecurity Policies and Documentation </h1>
                              <p className="lead">
                                It's important to have written Cybersecurity Policies and Documentation.{' '}
                                <span className="h3 text-info">
                                  Not having written policies is one of the top reasons why cybersecurity insurance claims are denied.
                                </span>{' '}
                                Ensure you remain compliant by having written policies that are reviewed with all team members on a regular
                                basis.
                              </p>
                              <p className="lead">
                                One of the biggest mistakes with written policies is not enforcing your policies. It's important to
                                establish an effective system to enforce your policies. Your Cybersecurity Policies and Documentation will
                                evolve over time and should be treated as a living document.{' '}
                              </p>
                              <p className="lead">
                                If you would like help creating Cybersecurity Policies and Documentation, we have resources available to
                                assist you.{' '}
                              </p>
                              <h1>GDPR and Consumer Privacy Laws </h1>
                              <p className="lead">
                                Yes, the GDPR applies to the EU; however, the USA is rapidly applying similar consumer-facing privacy laws.
                                California applied the CCPA, and 14 other states already have legislation in process. These new laws can
                                expose your business to increase criminal and civil liabilities. It's important to beware of requirements
                                applied within your industry for the disclosure of a data breach.{' '}
                              </p>
                              <p className="lead">
                                It's important to have your legal team review the requirements and your compliance obligations. Even if you
                                use cloud-based software, you are still liable for data breaches or losses incurred due to the providers you
                                use.{' '}
                              </p>
                              <h1> Cybersecurity Awareness Training</h1>
                              <p className="lead">
                                Educating your employees about cybersecurity threats is your first line of defense against attack. With 9
                                out of 10 attacks via email alone, if your employees aren't informed, they can unknowingly bring down your
                                entire network.{' '}
                              </p>
                              <p className="lead">
                                It's not just about bad emails; it's about what happens when someone clicks on a bad email. Who do they
                                report it too for a response plan to protect your network?{' '}
                              </p>
                              <p className="lead">
                                Creating a Cybersecurity Culture starts with having the individuals care about the threats they face.
                                Cybercriminals are likely to exploit a person's identity, which can cause devastating consequences for the
                                individual. Consequences like identity theft or financial frauds and these are the attacks against the
                                person, not just the company. Sharing stories about real-life situations is one of the best ways to educate
                                your employees about cyber threats.{' '}
                              </p>
                              <p className="lead">Here are some great questions to get you thinking: </p>
                              <ol className="lead">
                                <li> Do your employee’s know how to detect a phishing email?</li>
                                <li> How do your employee’s validate the identity of callers?</li>
                                <li> How do employee’s validate identity of other employees online and off-line?</li>
                                <li> How do employee’s report security concerns or threats?</li>
                              </ol>
                              <p className="lead">
                                If you do not have a cybersecurity training awareness program review the resources offered within your
                                Fortified program, we provide you with training videos you can use every two weeks to start conversations
                                within your team. That's right, we provide you with a done for your cybersecurity awareness training program
                                if you want to know more connect with us. You get access as part of your Fortified subscription.{' '}
                              </p>
                              <h1>Handling Mobility and Remote Access Threats</h1>
                              <p className="lead">
                                The biggest threats against companies are how to handle remote access or the bring-your-own-device (BYOD)
                                culture. If employees are accessing your systems or software remotely using unsecured wifi networks, for
                                example, Starbucks, hotels, or anywhere that has free wifi this exposes your company to cyber attacks.
                              </p>
                              <p className="lead">
                                For example, it's known that cybercriminals will pay people to attend convention or trade show hotels with
                                devices that steal your data by impersonating the free wifi networks. These types of "man-in-the-middle"
                                attacks are extremely successful and result in major data breaches or compromised executives.{' '}
                              </p>
                              <h2>How do you protect yourself while out of the office? </h2>
                              <p className="lead">
                                Ensure you use VPN's and your own personal hotspots or cellphone hotspots. Avoid using free wifi regardless
                                of the location. The added cost of providing your employees with a hotspot for remote access can prevent a
                                data breach or malicious code attack.{' '}
                              </p>
                              <p className="lead">
                                Ensure your office Wifi networks are segmented for the different sections within your business. Payroll
                                should not be on the same network as sales. Guests should not be on the same network as employees. These are
                                the little details that can significantly transform your wifi security.{' '}
                              </p>
                              <h1> Anti-virus, Malware and Ransomware Detection </h1>
                              <p className="lead">
                                We speak with many companies only to find out they are not using anti-virus software, or it has not been
                                updated. Yes, Antivirus software is a simple tool but can prevent wasting hundreds of hours on recovery
                                efforts.{' '}
                              </p>
                              <p className="lead">Yes, your mobile devices are under attack just as much as your desktops. </p>
                              <p className="lead">
                                Yes, this means even Apple OS and IOS products can experience viruses, malware, and ransomware. Yes, Android
                                products as well.{' '}
                              </p>
                              <p className="lead">
                                If you have any device that is connected to the internet, it needs to have Anti-virus, Malware, and
                                Ransomware detection software installed and up-to-date. This should be a policy that is enforced on any of
                                your third-party vendors as well.{' '}
                              </p>
                              <h1> Office Cleaning</h1>
                              <p className="lead">
                                We have heard stories of companies being breached by the cleaning staff. That's right imagine, if your
                                cleaning staff accepted $50 to plug-in a USB device into one of the computers on your network. This resulted
                                in your entire network being compromised by cybercriminals. Sounds like fiction, right? Well, this is just
                                one of the many stories we hear on a daily basis of outside vendors stealing data.{' '}
                              </p>
                              <p className="lead">
                                We have heard stories of companies allowing third parties into the office only to find out that they were
                                taking photos on their cell phones of Personal Identifiable Information. It's possible to sell PII data or
                                medical information for up to $50 per file record; therefore, it's a fast way to earn extra cash.{' '}
                              </p>
                              <p className="lead">
                                How you clean your office, and manage the people that clean your office can significantly impact your
                                business. Do your current agreements (hopefully written contracts) have clauses that relate to data privacy,
                                confidentiality, and the action plan in case of a breach?{' '}
                              </p>
                              <h1>Passwords</h1>
                              <p className="lead">
                                Passwords are a major problem and result in compromised accounts. How do you detect if someone is using an
                                employee's username and password incorrectly to access your network or software?{' '}
                              </p>
                              <p className="lead">
                                Passwords should be changed at least every 30 days, if not every 60-90 at the most. You noticed that inside
                                the Cybersecurity Report Card, there is an entire section on Leaked Credentials. This shows you how easy it
                                is to find the user name and password for almost anyone on the dark web. This type of activity is usually
                                one of the first steps a cybercriminal will take when researching how to compromise your company.{' '}
                              </p>
                              <p className="lead">
                                Many companies don't change their passwords often enough and don't enforce pass-phrase protocols. You should
                                not be using passwords but the use of pass-phrases. If you don't know the difference review{' '}
                                <a href="https://chatfortress.com/blog/ccscday1passwords">
                                  {' '}
                                  <i>why passwords are no longer safe here</i>{' '}
                                </a>
                              </p>
                              <p className="lead">
                                Yes, your social media passwords and any online system you use a password for should be included in your
                                password change protocols.{' '}
                              </p>
                              <h1>Backups</h1>
                              <p className="lead">
                                Having working backups is the difference between having to pay a ransomware demand and not. This
                                cybersecurity tip alone could be keeping on average $41,189.00 in your pocket, rather than having to pay
                                cybercriminals.{' '}
                              </p>
                              <p className="lead">
                                Even if you are using cloud-based software solutions, how are you backing up your data?{' '}
                              </p>
                              <h2>How are you backing up your data and securely storing it?</h2>
                              <p className="lead">
                                The first that that cybercriminal do is compromise your backup system. It is, therefore, causing harm to
                                your response plan.{' '}
                                <span className="h3 text-info">The average recovery time for a ransomware attack is nine days.</span>{' '}
                                Imagine how that would impact your business if you could not access your systems for nine days. Could you
                                survive, and what would you need to do differently just to keep the lights on?
                              </p>
                              <p className="lead text-center">
                                <span className="h3 text-info">
                                  {' '}
                                  Having to pay a ransomware demand is the digital idiot tax of our generation. You don't have to pay a
                                  ransom demand if you have effective cybersecurity protocols in place.
                                </span>
                              </p>
                              <p className="lead">
                                The sad reality is that many business owners are not using effective backup solutions. But more importantly,
                                regularly testing your backups to ensure they are working. You will be amazed at the companies that thought
                                they had a backup only to find it was not working, or there was a hardware issue.{' '}
                              </p>
                              <p className="lead">
                                This could leave you with access to no data, or data that is extremely old. Here are some backup tips:{' '}
                              </p>
                              <ul className="lead">
                                <li>
                                  {' '}
                                  It's important to have backups for various reasons, to have redundancy of data if they disappeared. It's
                                  important
                                </li>
                                <li>
                                  Backups should concern the whole company, and everyone should be aware of putting all data on the systems
                                  that are backed up, to ensure to have a copy of them.
                                </li>
                                <li>Backups should be done daily to avoid major data loss.</li>
                                <li>Backups should be retained at least a month to avoid problems caused by ransomware.</li>
                                <li>
                                  Backups should be outside the premises, decentralized, with more than 3 kilometers/miles between them.
                                </li>
                                <li>Backups should be disconnected, outside the local network, to be invisible by crypto-ransomware.</li>
                              </ul>
                              <p className="lead">
                                {' '}
                                Remember as part of your Fortified membership you can ask your cybersecurity questions to{' '}
                                <a href="mailto:help@chatfortress.com">help@chatfortress.com</a> or use the live that in the bottom right.{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                </div>
                <hr />
                <div style={{ textAlign: 'center' }}>
                  <Button size="large" style={{ marginRight: 20 }} variant="contained" color="secondary" onClick={() => setActiveStep(1)}>
                    Back
                  </Button>
                  {(company.isQuizCompleted || quizCompleted) && (
                    <Button size="large" variant="contained" color="primary" onClick={() => setActiveStep(3)}>
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
            {activeStep === 3 && (
              <div>
                <Courses context={context} />
                <hr />
                <div style={{ textAlign: 'center' }}>
                  <Button size="large" style={{ marginRight: 20 }} variant="contained" color="secondary" onClick={() => setActiveStep(2)}>
                    Back
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => completeTraining().then(() => (window.location.href = '/dashboard'))}
                  >
                    Finish
                  </Button>
                </div>
              </div>
            )}
          </Box>
        </Container>
      </div>
    )
  }
}

export default compose(
  withContext,
  withSnackbar,
  withStyles(dashboardStyle),
  withQuery(getCompanyDataForUpdating, {
    name: 'company',
    options: props => {
      if (!props.context.user) return null
      return {
        variables: { companyId: props.context.user.companyId }
      }
    },
    props: ({ company: { company = {}, categories = [], refetch = null } }) => {
      return {
        company,
        categories,
        refetch
      }
    }
  }),
  withQuery(getTrainingLesson, {
    name: 'lesson',
    options: props => {
      if (!props.context.user) return null
      return {
        variables: { lessonId: 'DbMDGFbgSacjKt5jbXBT' },
        refetchQueries: ['getCompanyDataForUpdating']
      }
    },
    props: ({ lesson: { lesson = {} } }) => {
      return {
        lesson
      }
    }
  })
)(ReportDash)
