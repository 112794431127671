/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { useQuery } from 'react-apollo'
import { useHistory, useRouteMatch } from 'react-router-dom'
import _ from 'lodash'
import gql from 'graphql-tag'
import withAppContext from 'lib/withAppContext'

//import { getTrainingCourses } from 'api/courses'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
//import Avatar from '@material-ui/core/Avatar'
import Avatar from 'lib/Avatar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Spinner from 'components/Spinner'

const getTrainingCourses = gql`
  query getTrainingCourses {
    trainingCourses {
      data {
        id
        type
        name
        description
        cover
        publishedAt
        paid
        countLessons
        author {
          id
          name
          email
          phone
          avatar
        }
        order {
          id
          productId
          planId
          userId
          status
        }
        license {
          id
          productId
          userId
          status
        }
      }
    }
  }
`

const TrainingCourses = ({ classes, context }) => {
  const match = useRouteMatch()
  const history = useHistory()
  const { acl } = context
  const { loading, data } = useQuery(getTrainingCourses)
  const courses = _.get(data, 'trainingCourses.data', [])

  return (
    <React.Fragment>
      <Typography variant="h6" style={{ padding: 10 }}>
        Access Your Training Courses
      </Typography>
      <div className="mt-2" style={{ paddingLeft: 10 }}>
        <p>
          This section allows you to access the Training Courses provided by ChatFortress. If you do not have access please contact support.
          <a className="beacon-help-link" href="#" data-beacon-article-sidebar="/5e59270b2c7d3a7e9ae871be">
            Get help here
          </a>
        </p>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className={classes.cards}>
          {courses.map(course => {
            const { author, order, license } = course
            const hasAccess = ((order || license) && ['active', 'trialing'].includes((order || license).status)) || !course.paid

            return (
              <Card key={course.id} className={classes.card}>
                {/*<CardHeader avatar={<Avatar {...author} />} title={author.name} subheader={moment(course.publishedAt).format('LL')} />*/}
                {course.cover && <CardMedia component="img" alt={course.name} image={course.cover} title={course.name} />}
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {course.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {course.description}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing style={{ flexDirection: 'column' }}>
                  {hasAccess && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => history.push(`${match.url}/course/${course.id}`)}
                      style={{ backgroundColor: 'green' }}
                    >
                      View
                    </Button>
                  )}
                  {acl.hasAccess('account.licenses:buy') && course.paid && (
                    <Button
                      className="mt-2"
                      variant="contained"
                      color="primary"
                      onClick={() => history.push(`${match.url}/buy/${course.id}`)}
                    >
                      Add Licenses
                    </Button>
                  )}
                </CardActions>
              </Card>
            )
          })}
        </div>
      )}
    </React.Fragment>
  )
}

const styles = theme => ({
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'
  },
  card: {
    maxWidth: 345,
    minWidth: 345,
    marginBottom: 10
  }
})

export default compose(withAppContext, withStyles(styles))(TrainingCourses)
