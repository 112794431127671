/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, numericality } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getSubscriptionForUpdating, updateSubscriptionPlan } from 'api/subscriptions'

import withStyles from '@material-ui/core/styles/withStyles'

const SubscriptionUpdate = ({ classes, match }) => (
  <React.Fragment>
    <Query query={getSubscriptionForUpdating} variables={match.params}>
      {({ data: { subscription, products: { data: products } = {} } = {}, loading }) =>
        !loading && (
          <FormDialog
            title="Update Subscription"
            form="SubscriptionUpdate"
            mutation={[updateSubscriptionPlan]}
            initialValues={{ plan: subscription.plan.id, quantity: subscription.quantity }}
            success="Subscription updated successfully"
            btnSubmitText="Update"
          >
            {({ doc, invalid, submitting }) => (
              <React.Fragment>
                <Field
                  name="plan"
                  component={TextField}
                  label="Plan"
                  options={subscription.product.plans.map(({ id, name }) => ({ label: name, value: id }))}
                  validate={[required()]}
                  select
                />
                <Field
                  name="quantity"
                  type="number"
                  component={TextField}
                  label="Number of Licenses"
                  validate={[required(), numericality()]}
                  normalize={number}
                />
              </React.Fragment>
            )}
          </FormDialog>
        )
      }
    </Query>
  </React.Fragment>
)

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(SubscriptionUpdate)
