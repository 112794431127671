/** @format */

import { ApolloClient } from 'apollo-client'
// Link
import { ApolloLink, split } from 'apollo-link'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { onError } from 'apollo-link-error'
import { getMainDefinition } from 'apollo-utilities'
// Cache
import { InMemoryCache } from 'apollo-cache-inmemory'

export default (initialState = {}) => {
  const cache = new InMemoryCache().restore(initialState) // { addTypename: false, dataIdFromObject: object => object.id || object._id }
  //const connectToDevTools = true

  // Create an http link:
  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`
  })

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_WS_URL}/websocket`,
    options: {
      reconnect: true,
      async connectionParams() {
        const token = localStorage.getItem('loginToken')
        const params = { token }
        return params
      }
    }
  })

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink
  )

  // https://github.com/apollographql/apollo-client/issues/1913#issuecomment-425281027
  const cleanTypeNameMiddleware = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key, value) => (key === '__typename' ? undefined : value)
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }
    return forward(operation)
  })

  // https://www.apollographql.com/docs/react/networking/network-layer/#middleware
  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('loginToken')
    // add the authorization to the headers
    if (token) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      }))
    }
    return forward(operation)
  })

  const middlewares = [
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(error => {
          console.log('[GraphQL error]:', error)
        })
      }
      if (networkError) {
        console.log('[Network error]:', networkError)
      }
    }),
    cleanTypeNameMiddleware,
    authMiddleware,
    link
  ]

  if (process.env.NODE_ENV === 'production') {
    middlewares.unshift(createPersistedQueryLink({ useGETForHashedQueries: true }))
  }

  const client = new ApolloClient({
    link: ApolloLink.from(middlewares),
    cache,
    resolvers: {
      Mutation: {
        submitForm(root, { data }) {
          return data
        }
      }
    }
  })

  return client
}
