/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { Form, Field, FieldArray } from 'lib/form'
import { TextField, Checkbox } from 'lib/form/fields'
import { required, length } from 'lib/form/validators'

import { getDataUpdatingProduct, updateProduct } from 'api/products'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import ProductCommissionsField from './ProductCommissionsField'

const ProductCommissions = ({ classes, match }) => (
  <Paper className="p-3">
    <Query query={getDataUpdatingProduct} variables={match.params}>
      {({ data: { product } = {}, loading }) =>
        !loading && (
          <React.Fragment>
            <Form
              form="ProductCommissions"
              mutation={[updateProduct]}
              initialValues={product}
              success="Product Commissions updated successfully"
            >
              {({ doc, submit, submitting }) => (
                <React.Fragment>
                  <Typography variant="h4">{product.name}</Typography>
                  <FieldArray name="commissions" component={ProductCommissionsField} label="Commission Rules" />
                  <Button variant="contained" color="primary" onClick={submit} disabled={submitting}>
                    Save Product Commissions
                  </Button>
                </React.Fragment>
              )}
            </Form>
          </React.Fragment>
        )
      }
    </Query>
  </Paper>
)

const styles = theme => ({
  root: {}
})

export default withStyles(styles)(ProductCommissions)

/*
ProductCommissionsField
 */
