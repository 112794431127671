/** @format */

import React from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'

import { connectWithService } from 'api/actions'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '@material-ui/core/Button'
import { withSnackbar } from 'notistack'
import withConfirm from 'lib/withConfirm'
import { updateUser } from 'api/users'
import { withMutation } from 'react-apollo'

import withContext from 'lib/withAppContext'

const Services = ({ classes, context, enqueueSnackbar, confirm, updateUser }) => {
  const { user, refetch } = context
  const stripeService = _.get(user, 'services.stripe')

  let widgetCode = `
<script src="https://web.chatfortress.com/embed-widget.js?t=${1 * new Date()}"></script>
<script type="text/javascript" id="cf-widget-<CompanyID>">
    (function(){
      var widget = new CFWidget('<CompanyID>','${user.apiKeyPublic}', {size: 'small'});
      widget.init();
    })()
</script>
`

  return (
    <React.Fragment>
      <div>
        {' '}
        <p>
          This is where you can connect third party services to your ChatFortress account. If you have questions use the live chat to ask
          your question.
          <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5eb9abf72c7d3a5ea54adbe1">
            {' '}
            Get help about this page here
          </a>{' '}
        </p>
      </div>
      <Paper>
        <List>
          <ListItem>
            <ListItemAvatar style={{ marginRight: 20 }}>
              <Typography variant="h5">Stripe</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                stripeService ? (
                  <div>Connected as {stripeService.stripe_user_id}</div>
                ) : (
                  <div>
                    <a className="stripe-connect-button" onClick={() => connectWithService(user.id, 'stripe')}>
                      <span>Connect with Stripe</span>
                    </a>
                  </div>
                )
              }
              secondary={
                <small>
                  By registering your account, you agree to our{' '}
                  <a href="https://chatrortress.com/services" target="_blank" rel="noopener noreferrer">
                    Services Agreement
                  </a>{' '}
                  and the{' '}
                  <a href="https://stripe.com/connect-account/legal" target="_blank" rel="noopener noreferrer">
                    Stripe Connected Account Agreement.
                  </a>
                </small>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginRight: 20 }}>
              <Typography variant="h5">Public API key</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                <div>
                  <span style={{ marginRight: 10, color: 'gray', fontWeight: 600, padding: '10px 40px', border: 'dashed 1px silver' }}>
                    {user.apiKeyPublic || 'Not set'}
                  </span>
                  {user.apiKeyPublic && (
                    <CopyToClipboard
                      text={user.apiKeyPublic}
                      onCopy={() => enqueueSnackbar('The API key copied to clipboard', { variant: 'info' })}
                    >
                      <Button variant="contained" color="default">
                        Copy Key
                      </Button>
                    </CopyToClipboard>
                  )}
                  <Button
                    style={{ marginLeft: 30 }}
                    variant="contained"
                    color="secondary"
                    onClick={confirm(
                      () => {
                        updateUser({ variables: { userId: user.id, data: { apiKeyPublic: '-1' } } })
                          .then(({ data }) => {
                            enqueueSnackbar('SUCCESS', { variant: 'success' })
                            refetch()
                          })
                          .catch(e => {
                            enqueueSnackbar(e.message, { variant: 'error' })
                          })
                      },
                      {
                        title: 'Key Replacement',
                        description: 'Are you sure you want to disable the previous key and generate new one?'
                      }
                    )}
                  >
                    Generate New
                  </Button>
                </div>
              }
              secondary={
                <small>
                  The embeddable widget Key, can be exposed on client side. By generating new key you disable the previous one, action is
                  not reversible.
                </small>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar style={{ marginRight: 20 }}>
              <Typography variant="h5">Private API key</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                <div>
                  <span style={{ marginRight: 10, color: 'gray', fontWeight: 600, padding: '10px 40px', border: 'dashed 1px silver' }}>
                    {user.apiKeyPrivate || 'Not set'}
                  </span>
                  {user.apiKeyPrivate && (
                    <CopyToClipboard
                      text={user.apiKeyPrivate}
                      onCopy={() => enqueueSnackbar('The API key copied to clipboard', { variant: 'info' })}
                    >
                      <Button variant="contained" color="default">
                        Copy Key
                      </Button>
                    </CopyToClipboard>
                  )}
                  <Button
                    style={{ marginLeft: 30 }}
                    variant="contained"
                    color="secondary"
                    onClick={confirm(
                      () => {
                        updateUser({ variables: { userId: user.id, data: { apiKeyPrivate: '-1' } } })
                          .then(({ data }) => {
                            enqueueSnackbar('SUCCESS', { variant: 'success' })
                            refetch()
                          })
                          .catch(e => {
                            enqueueSnackbar(e.message, { variant: 'error' })
                          })
                      },
                      {
                        title: 'Key Replacement',
                        description: 'Are you sure you want to disable the previous key and generate new one?'
                      }
                    )}
                  >
                    Generate New
                  </Button>
                </div>
              }
              secondary={
                <small>
                  The private API Key, used for signing API requests. By generating new key you disable the previous one, action is not
                  reversible.
                </small>
              }
            />
          </ListItem>
          {user.apiKeyPublic && (
            <ListItem>
              <ListItemAvatar style={{ marginRight: 20 }}>
                <Typography variant="h5">Widget template</Typography>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div>
                    <pre>{widgetCode}</pre>

                    <CopyToClipboard
                      text={widgetCode}
                      onCopy={() => enqueueSnackbar('The Widget Code copied to clipboard', { variant: 'info' })}
                    >
                      <Button variant="contained" color="default">
                        Copy Widget
                      </Button>
                    </CopyToClipboard>
                  </div>
                }
                secondary={<small>Replace &lt;CompanyID&gt; with company ID in order to have it working.</small>}
              />
            </ListItem>
          )}
        </List>
      </Paper>
    </React.Fragment>
  )
}

const styles = () => ({})

export default compose(
  withContext,
  withSnackbar,
  withConfirm,
  withMutation(updateUser, {
    name: 'updateUser'
  }),
  withStyles(styles)
)(Services)
