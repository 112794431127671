/** @format */

import React from 'react'
import { compose } from 'recompose'
import { Mutation } from 'react-apollo'
import withAppContext from 'lib/withAppContext'
import { withSnackbar } from 'notistack'
import moment from 'moment'
//import numeral from 'numeral'

import { getPhoneNumbers, releasePhoneNumber, restorePhoneNumber } from 'api/phoneNumbers'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import CheckboxTable from 'lib/tables/CheckboxTable'
//import UserCell from 'lib/tables/UserCell'
//import SelectFilter from 'lib/tables/SelectFilter'

const PhoneNumbers = ({ classes, history, match, alert, enqueueSnackbar }) => (
  <React.Fragment>
    <Mutation mutation={releasePhoneNumber} refetchQueries={['getPhoneNumbers']}>
      {(release, { loading }) => (
        <Mutation mutation={restorePhoneNumber} refetchQueries={['getPhoneNumbers']}>
          {(restore, { loading }) => (
            <CheckboxTable
              query={getPhoneNumbers}
              variables={match.params}
              initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
              columns={[
                {
                  Header: 'Number',
                  accessor: 'phoneNumber',
                  Cell: ({ original: phoneNumber }) => (
                    <div>
                      {phoneNumber.friendlyName}
                      <br />
                      {phoneNumber.phoneNumber}
                    </div>
                  )
                },
                {
                  Header: 'Created Date',
                  accessor: 'createdAt',
                  Cell: ({ original: phoneNumber }) => moment(phoneNumber.createdAt).format('LLL')
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Cell: ({ original: phoneNumber }) => (
                    <div>
                      {phoneNumber.status === 'released' ? (
                        <span>
                          <strong>Released</strong>
                          <br />
                          {moment(phoneNumber.releasedAt).format('LLL')}
                        </span>
                      ) : (
                        <span>Active</span>
                      )}
                    </div>
                  )
                },
                {
                  Header: 'Actions',
                  sortable: false,
                  filterable: false,
                  accessor: 'id',
                  Cell: ({ original: phoneNumber }) => (
                    <div>
                      <IconButton
                        key="calls"
                        onClick={() => history.push(`${match.url}/${phoneNumber.phoneNumber}/calls`)}
                        title="Phone Number Calls"
                      >
                        <Icon fontSize="small">call</Icon>
                      </IconButton>
                      <IconButton
                        key="messages"
                        onClick={() => history.push(`${match.url}/${phoneNumber.phoneNumber}/messages`)}
                        title="Phone Number Messages"
                      >
                        <Icon fontSize="small">message</Icon>
                      </IconButton>
                      <IconButton key="edit" onClick={() => history.push(`${match.url}/${phoneNumber.id}/update`)} title="Edit PhoneNumber">
                        <Icon fontSize="small">edit</Icon>
                      </IconButton>
                      {phoneNumber.status === 'released' ? (
                        <IconButton
                          key="delete"
                          color="secondary"
                          onClick={() =>
                            alert.confirm('', 'Are you sure you want to restore this PhoneNumber?', () =>
                              restore({ variables: { phoneNumberId: phoneNumber.id } })
                            )
                          }
                          title="Restore Phone Number"
                          disabled={loading}
                        >
                          <Icon fontSize="small">restore</Icon>
                        </IconButton>
                      ) : (
                        <IconButton
                          key="delete"
                          color="secondary"
                          onClick={() =>
                            alert.confirm('', 'Are you sure you want to release this PhoneNumber?', () =>
                              release({ variables: { phoneNumberId: phoneNumber.id } })
                            )
                          }
                          title="Release Phone Number"
                          disabled={loading}
                        >
                          <Icon fontSize="small">delete</Icon>
                        </IconButton>
                      )}
                    </div>
                  )
                }
              ]}
              actions={[
                <Button
                  key="create"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push(`${match.url}/search`)}
                >
                  <Icon className={classes.leftIcon}>add</Icon>
                  Search Phone Number
                </Button>
              ]}
              deleteMany={false}
            />
          )}
        </Mutation>
      )}
    </Mutation>
  </React.Fragment>
)

const styles = theme => ({
  root: {}
})

const enhance = compose(withSnackbar, withAppContext, withStyles(styles))

export default enhance(PhoneNumbers)
