/** @format */

import React from 'react'
import { compose } from 'recompose'
import { Query, Mutation } from 'react-apollo'
import { Form, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { withSnackbar } from 'notistack'
import withConfirm from 'lib/withConfirm'

import { searchPhoneNumbers, buyPhoneNumber } from 'api/phoneNumbers'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import { countriesOptions, statesOptions } from 'data/options'

const PhoneNumberSearch = ({ match, history, enqueueSnackbar, confirm }) => (
  <Mutation
    mutation={buyPhoneNumber}
    onError={error => enqueueSnackbar(error.message, { variant: 'error' })}
    onCompleted={() => enqueueSnackbar('You have successfully purchased phone number', { variant: 'success' })}
  >
    {(buy, { loading: buying }) => (
      <Form
        form="SearchPhoneNumbers"
        mutation={[buyPhoneNumber]}
        initialValues={{ country: 'US' }}
        onSubmit={(buy, query) => searchPhoneNumbers({ variables: { query } })}
      >
        {({ doc: query }) => (
          <React.Fragment>
            <Paper style={{ marginTop: 20, padding: 5 }}>
              <Grid spacing={8} container>
                <Grid item xs>
                  <Field name="country" component={TextField} label="Country" options={countriesOptions} select />
                </Grid>
                <Grid item xs>
                  <Field name="inRegion" component={TextField} label="Region" options={statesOptions} select />
                </Grid>
                <Grid item xs>
                  <Field name="areaCode" component={TextField} label="Area Code" />
                </Grid>
                <Grid item xs>
                  <Field name="inPostalCode" component={TextField} label="Postal Code" />
                </Grid>
                <Grid item xs>
                  <Field name="contains" component={TextField} label="Contains" />
                </Grid>
              </Grid>
            </Paper>
            <Query query={searchPhoneNumbers} variables={{ query }} fetchPolicy="cache-and-network">
              {({ loading, data }) => (
                <Paper style={{ marginTop: 10, padding: 5 }}>
                  {loading ? (
                    <div>
                      <LinearProgress />
                      <div className="text-center">Processing...</div>
                    </div>
                  ) : (
                    <List style={{ padding: 0 }}>
                      {Array.from((data && data.availablePhoneNumbers) || []).map(phoneNumber => (
                        <ListItem key={phoneNumber.phoneNumber} divider>
                          <ListItemText primary={phoneNumber.phoneNumber} secondary={`${phoneNumber.region} ${phoneNumber.isoCountry}`} />
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={confirm(
                                () => {
                                  buy({ variables: { data: { owner: match.params.userId, phoneNumber: phoneNumber.phoneNumber } } })
                                },
                                {
                                  title: 'Buy Phone Number',
                                  description: 'Are you sure you want to buy this phone number?'
                                }
                              )}
                              title="Buy Phone Number"
                              disabled={buying}
                            >
                              <Icon>phonelink_setup</Icon>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                      {Array.from((data && data.availablePhoneNumbers) || []).length === 0 && (
                        <p className="text-center">Couldn`t find what you`re looking for? Let us know what you need.</p>
                      )}
                    </List>
                  )}
                </Paper>
              )}
            </Query>
          </React.Fragment>
        )}
      </Form>
    )}
  </Mutation>
)

const styles = () => ({})

export default compose(withSnackbar, withConfirm, withStyles(styles))(PhoneNumberSearch)
