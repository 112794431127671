/** @format */

import React from 'react'
import { Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required } from 'lib/form/validators'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const InvoiceEmailForm = ({ title = 'Notification Email Information' }) => {
  return (
    <React.Fragment>
      <Typography variant="h5">{title}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Field name="email" component={TextField} label="Email" validate={[required()]} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default InvoiceEmailForm
