/** @format */

import React from 'react'

import Avatar from 'lib/Avatar'

import { withStyles } from '@material-ui/core/styles'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'

const UserCell = ({ classes, user = {} }) => {
  return (
    <div className={classes.root}>
      {user && (
        <React.Fragment>
          <Avatar className={classes.avatar} {...user} size={40} round />
          <address className={classes.address}>
            <strong>{user.name}</strong>
            {user.email && (
              <span className={classes.email}>
                <MailOutlineIcon style={{ width: 20, height: 20 }} />{' '}
                <a className={classes.emailLink} href={`mailto:${user.email}`}>
                  {user.email}
                </a>
              </span>
            )}
            {user.phone && (
              <span className={classes.phone}>
                <PhoneIcon style={{ width: 20, height: 20 }} /> <span className={classes.phoneLink}>{user.phone}</span>
              </span>
            )}
            {user.accountType && <span className={classes.accountType}>{user.accountType}</span>}
          </address>
        </React.Fragment>
      )}
    </div>
  )
}

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatar: {
    marginRight: 7
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 5
  },
  email: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  emailLink: {
    marginLeft: 5
  },
  phone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  phoneLink: {
    marginLeft: 5
  }
})

export default withStyles(styles)(UserCell)
