/** @format */

import React from 'react'
import { compose } from 'recompose'
import moment from 'moment'
import numeral from 'numeral'

import { getCalls } from 'api/phoneNumbers'

import { withStyles } from '@material-ui/core/styles'

import CheckboxTable from 'lib/tables/CheckboxTable'
import Typography from '@material-ui/core/Typography'
//import UserCell from 'lib/tables/UserCell'
//import SelectFilter from 'lib/tables/SelectFilter'

const Calls = ({ classes, match, history }) => (
  <React.Fragment>
    <Typography variant="h5">Calls</Typography>
    <CheckboxTable
      query={getCalls}
      variables={match.params}
      initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
      columns={[
        {
          Header: 'Direction',
          accessor: 'direction'
        },
        {
          Header: 'From',
          accessor: 'from'
        },
        {
          Header: 'To',
          accessor: 'to'
        },
        {
          Header: 'Status',
          accessor: 'status'
        },
        {
          Header: 'Duration',
          accessor: 'duration',
          Cell: ({ original: call }) => <div>{`${numeral(call.duration).format('0')} secs`}</div>
        },
        {
          Header: 'Created Date',
          accessor: 'createdAt',
          Cell: ({ original: call }) => moment(call.createdAt).format('LLL')
        },
        {
          Header: 'Actions',
          sortable: false,
          filterable: false,
          accessor: 'id',
          Cell: ({ original: call }) => <div></div>
        }
      ]}
      noDataText="There has currently been no calls to the phone number recorded. This will update when a call has been placed on the tracking number."
      actions={[]}
      deleteMany={false}
    />
  </React.Fragment>
)

const styles = theme => ({
  root: {}
})

const enhance = compose(withStyles(styles))

export default enhance(Calls)
