/** @format */

import _ from 'lodash'

export default ({ location }) => {
  let pageTitle = String(location.pathname.split('/')[1]).replace(/[\W_]+/g, ' ')
  pageTitle = pageTitle
    .split(' ')
    .map(_.upperFirst)
    .join(' ')
  return pageTitle
}
