/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required } from 'lib/form/validators'

import { getLink, updateLink } from 'api/links'

import withStyles from '@material-ui/core/styles/withStyles'

const LinkUpdate = ({ classes, match }) => (
  <React.Fragment>
    <Query query={getLink} variables={match.params}>
      {({ data: { link } = {}, loading }) =>
        !loading && (
          <FormDialog title={link.title} form="LinkUpdate" mutation={[updateLink]} initialValues={link} success="Link updated successfully">
            {({ doc, invalid, submitting }) => (
              <React.Fragment>
                <Field name="title" component={TextField} label="Link Title" validate={[required()]} />
                <Field name="url" component={TextField} label="Link Url" validate={[required()]} />
              </React.Fragment>
            )}
          </FormDialog>
        )
      }
    </Query>
  </React.Fragment>
)

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(LinkUpdate)
