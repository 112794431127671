/** @format */
import React from 'react'

const tpl = ({ proposal, flatPrice, unitPrice, unitsAmount, plan }) => {
  let percentOff = 1
  if (proposal.couponData) percentOff = proposal.couponData.percent_off ? (100 - proposal.couponData.percent_off) / 100 : 1

  return (
    <div>
      <section className="py-4">
        <div className="container">
          <h1> Cost of ChatFortress Cybersecurity Report Card Reseller Program</h1>
          <p>
            This part of the agreement outlines the costs for your Cybersecurity Report Card Reseller Program. Based on the following plans
            options:
          </p>
          <div className="text-center">
            <h1>Cybersecurity Report Card Reseller Program Plans</h1>
            <p> All plans are month to month.</p>
          </div>
          {plan.id === 'kxa4QoCnq9iLR66ydXAP' && (
            <table className="table text-center mt-2 ">
              <tbody>
                <tr>
                  <th scope="row" className="border-0"></th>
                  <td className="text-center border-0">
                    <h2 className="font-weight-light text-danger">
                      <i> You Selected</i> Starter Plan
                    </h2>
                    <p className="lead">$49/month</p>
                  </td>
                  <td className="text-center border-0">
                    <h2 className="font-weight-light">
                      <i>If you Upgrade to Pro Plan</i>
                    </h2>
                    <p className="lead">$500/month</p>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Account Setup Fee</th>
                  <td>$149</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <th scope="row">Monthly Report Cards </th>
                  <td>50</td>
                  <td>600</td>
                </tr>
                <tr>
                  <th scope="row">Account Users</th>
                  <td>1</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th scope="row">Report Card Public Directory Branding</th>
                  <td>1 Banner + ChatFortress Ads</td>
                  <td>5 Banner Ads</td>
                </tr>
                <tr>
                  <th scope="row">Report Card Public Directory Calendar Embed</th>
                  <td>Button Only</td>
                  <td>Calendar Embed</td>
                </tr>
                <tr>
                  <th scope="row">Cybersecurity Awareness Monthly Training</th>
                  <td>1 License</td>
                  <td>25 Licenses</td>
                </tr>
                <tr>
                  <th scope="row">Cybersecurity Report Card Reseller Quick Start Training </th>
                  <td>Recordings Only</td>
                  <td>Recordings + Live Call Access</td>
                </tr>
                <tr>
                  <th scope="row">Success Training </th>
                  <td>1 x 30 minute strategy call</td>
                  <td>1 x 30 minute strategy call every 14 days for 90 days!</td>
                </tr>
                <tr>
                  <th scope="row">ChatFortress Fortified Program Access</th>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Access to Cybersecurity Report Card Marketing Vault</th>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Access to ChatFortress API</th>
                  <td></td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">ChatFortress Product Commissions*</th>
                  <td>20% Sales Commission for 6 months of transaction.</td>
                  <td>60% Sales Commission for Life of Client.</td>
                </tr>
              </tbody>
            </table>
          )}

          {plan.id === 'K3eSmSNzZzyfdQ3QXE4y' && (
            <table className="table text-center mt-2 ">
              <tbody>
                <tr>
                  <th scope="row" className="border-0"></th>
                  <td className="text-center border-0">
                    <h2 className="font-weight-light text-danger">
                      <i>You Selected</i> Growth Plan
                    </h2>
                    <p className="lead">$199/month</p>
                  </td>
                  <td className="text-center border-0">
                    <h2 className="font-weight-light">
                      <i> if you upgrade to Pro Plan</i>
                    </h2>
                    <p className="lead">$500/month</p>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Account Setup Fee</th>
                  <td>$500</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <th scope="row">Monthly Report Cards </th>
                  <td>250</td>
                  <td>600</td>
                </tr>
                <tr>
                  <th scope="row">Account Users</th>
                  <td>3</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th scope="row">Report Card Public Directory Branding</th>
                  <td>3 Banner Ads + 1 ChatFortress Ad</td>
                  <td>5 Banner Ads</td>
                </tr>
                <tr>
                  <th scope="row">Report Card Public Directory Calendar Embed</th>
                  <td>Button Only</td>
                  <td>Calendar Embed</td>
                </tr>
                <tr>
                  <th scope="row">Cybersecurity Awareness Monthly Training</th>
                  <td>3 Licenses</td>
                  <td>25 Licenses</td>
                </tr>
                <tr>
                  <th scope="row">Cybersecurity Report Card Reseller Quick Start Training </th>
                  <td>Recordings + Live Calls Access</td>
                  <td>Recordings + Live Call Access</td>
                </tr>
                <tr>
                  <th scope="row">Success Training </th>
                  <td>1 x 30 minute strategy call every 30 days for 60 days!</td>
                  <td>1 x 30 minute strategy call every 14 days for 90 days!</td>
                </tr>
                <tr>
                  <th scope="row">ChatFortress Fortified Program Access</th>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Access to Cybersecurity Report Card Marketing Vault</th>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Access to ChatFortress API</th>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">ChatFortress Product Commissions*</th>
                  <td>40% Sales Commission for 12 Months of transaction</td>
                  <td>60% Sales Commission lifetime of client</td>
                </tr>
              </tbody>
            </table>
          )}

          {plan.id === '6Lip74TzkTB7bupzGub4' && (
            <table className="table text-center mt-2 ">
              <tbody>
                <tr>
                  <th scope="row" className="border-0"></th>
                  <td className="text-center border-0">
                    <h2 className="font-weight-light">Pro Plan $500/month</h2>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Account Setup Fee</th>
                  <td>$500</td>
                </tr>
                <tr>
                  <th scope="row">Monthly Report Cards </th>
                  <td>600</td>
                </tr>
                <tr>
                  <th scope="row">Account Users</th>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th scope="row">Report Card Public Directory Branding</th>
                  <td>5 Banner Ads</td>
                </tr>
                <tr>
                  <th scope="row">Report Card Public Directory Calendar Embed</th>
                  <td>Calendar Embed</td>
                </tr>
                <tr>
                  <th scope="row">Cybersecurity Awareness Monthly Training</th>
                  <td>25 Licenses</td>
                </tr>
                <tr>
                  <th scope="row">Cybersecurity Report Card Reseller Quick Start Training </th>
                  <td>Recordings + Live Call Access</td>
                </tr>
                <tr>
                  <th scope="row">Success Training </th>
                  <td>1 x 30 minute strategy call every 14 days for 90 days!</td>
                </tr>
                <tr>
                  <th scope="row">ChatFortress Fortified Program Access</th>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Access to Cybersecurity Report Card Marketing Vault</th>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Access to ChatFortress API</th>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">ChatFortress Product Commissions*</th>
                  <td>60% Sales Commission</td>
                </tr>
              </tbody>
            </table>
          )}

          {plan.id === 'kxa4QoCnq9iLR66ydXAP' && <h2> Your investment based on Cybersecuirty Report Card Starter plan selected: </h2>}
          {plan.id === 'K3eSmSNzZzyfdQ3QXE4y' && <h2> Your investment based on Cybersecuirty Report Card Growth plan selected: </h2>}
          {plan.id === '6Lip74TzkTB7bupzGub4' && <h2> Your investment based on Cybersecuirty Report Card Pro plan selected: </h2>}

          {plan.id === 'kxa4QoCnq9iLR66ydXAP' && (
            <p>As part of the Cybersecurity Report Card Reseller Starter plan your investment will be as follows: </p>
          )}

          {plan.id === 'K3eSmSNzZzyfdQ3QXE4y' && (
            <p>As part of the Cybersecurity Report Card Reseller Growth plan your investment will be as follows:</p>
          )}

          {plan.id === '6Lip74TzkTB7bupzGub4' && (
            <p>As part of the Cybersecurity Report Card Reseller Pro plan your investment will be as follows:</p>
          )}

          <table className="table table-bordered" style={{ fontSize: '1.3em', fontWeight: 600 }}>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>DESCRIPTION</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cybersecurity Report Card Reseller Setup Fee</td>
                <td style={{ textAlign: 'right' }}>${plan.setupFee}</td>
              </tr>
              <tr>
                <td>Cybersecurity Report Card Reseller Program Monthly</td>
                <td style={{ textAlign: 'right' }}>${flatPrice}/Month</td>
              </tr>
              <tr>
                <td colSpan={4}></td>
              </tr>
              <tr>
                <td style={{ textAlign: 'right' }}>TOTAL INVESTMENT TODAY</td>
                <td style={{ textAlign: 'right' }}>
                  {proposal.couponData && (
                    <span>
                      <span style={{ textDecoration: 'line-through', color: 'red' }}>${plan.setupFee + flatPrice}</span>{' '}
                      <span style={{ color: 'green', weight: 600 }}>
                        ${plan.setupFee * percentOff - proposal.couponData.amount_off / 100 + flatPrice * (!proposal.productData.couponUpfrontOnly?percentOff:1)}
                      </span>
                    </span>
                  )}
                  {!proposal.couponData && <span>${plan.setupFee + flatPrice}</span>}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'right' }}>TOTAL INVESTMENT MONTHLY</td>
                <td style={{ textAlign: 'right' }}>
                  {proposal.couponData && !proposal.productData.couponUpfrontOnly && (
                    <span>
                      <span style={{ textDecoration: 'line-through', color: 'red' }}>${flatPrice}</span>{' '}
                      <span style={{ color: 'green', weight: 600 }}>${flatPrice * percentOff - proposal.couponData.amount_off / 100}</span>
                    </span>
                  )}
                  {(!proposal.couponData || proposal.productData.couponUpfrontOnly) && <span>${flatPrice}/Month</span>}
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            ChatFortress Report Card Reseller Program is a monthly subscription service. Users must give 30 days notice to cancel their
            ChatFortress account. There are no refunds or partial credits. Your subscription will renew automatically every 30 days until
            canceled in writing by the Reseller. If your subscription payment fails three consecutive times your Reseller account will be
            canceled. Your payment will be processed instantly on acceptance of this agreement.
          </p>
          <h1> Commission on Sale of ChatFortress Products by Reseller</h1>

          {plan.id === 'kxa4QoCnq9iLR66ydXAP' && (
            <div>
              <p>
                As part of your Cybersecurity Report Card Reseller Starter plan you will earn the following commissions on product sales you
                generate.{' '}
              </p>
              <table className="table table-bordered" style={{ fontSize: '1.3em', fontWeight: 600 }}>
                <thead>
                  <tr>
                    <th scope="col">Product Name</th>
                    <th scope="col">Commission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">ChatFortress Report Card Fortified program</th>
                    <td>20% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">ChatFortress Hacked Dark Web scan</th>
                    <td>20% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">ChatFortress Transaction Risk Reports</th>
                    <td>20% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">ChatFortress Cybersecurity Awareness program</th>
                    <td>20% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      ChatFortress Cybersecurity Report Card Reseller program (sign up another reseller){' '}
                      <div>
                        <p>0% of the setup fee with 0% of the recurring subscription.</p>
                      </div>
                    </th>
                    <td>Commission Not Payable</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Commissions are paid instantly using Stripe.com while the Reseller's account is active. Based on the Reseller plan
                commission may be paid on the life of the sale.{' '}
              </p>
            </div>
          )}

          {plan.id === 'K3eSmSNzZzyfdQ3QXE4y' && (
            <div>
              <p>
                As part of your Cybersecurity Report Card Reseller Growth plan you will earn the following commissions on product sales you
                generate.{' '}
              </p>
              <table className="table table-bordered" style={{ fontSize: '1.3em', fontWeight: 600 }}>
                <thead>
                  <tr>
                    <th scope="col">Product Name</th>
                    <th scope="col">Commission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">ChatFortress Report Card Fortified program</th>
                    <td>40% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">ChatFortress Hacked Dark Web scan</th>
                    <td>40% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">ChatFortress Transaction Risk Reports</th>
                    <td>40% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">ChatFortress Cybersecurity Awareness program</th>
                    <td>40% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      ChatFortress Cybersecurity Report Card Reseller program (sign up another reseller){' '}
                      <p>20% of the setup fee with 0% of the recurring subscription.</p>
                    </th>
                    <td>20% of Sale Price</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Commissions are paid instantly using Stripe.com while the Reseller's account is active. Based on the Reseller plan
                commission may be paid on the life of the sale.{' '}
              </p>
            </div>
          )}

          {plan.id === '6Lip74TzkTB7bupzGub4' && (
            <div>
              <p>
                As part of your Cybersecurity Report Card Reseller Pro plan you will earn the following commissions on product sales you
                generate.
              </p>
              <table className="table table-bordered" style={{ fontSize: '1.3em', fontWeight: 600 }}>
                <thead>
                  <tr>
                    <th scope="col">Product Name</th>
                    <th scope="col">Commission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">ChatFortress Report Card Fortified program</th>
                    <td>60% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">ChatFortress Hacked Dark Web scan</th>
                    <td>60% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">ChatFortress Transaction Risk Reports</th>
                    <td>40% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">ChatFortress Cybersecurity Awareness program</th>
                    <td>40% of Sale Price</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      ChatFortress Cybersecurity Report Card Reseller program (sign up another reseller){' '}
                      <p>40% of the setup fee with 10% of the recurring subscription.</p>
                    </th>
                    <td>40% of Sale Price</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Commissions are paid instantly using Stripe.com while the Reseller's account is active. Based on the Reseller plan
                commission may be paid on the life of the sale.{' '}
              </p>
            </div>
          )}

          {proposal.couponData && proposal.couponComments && (
            <section className="py-4">
              <div className="container">
                <h2>Notes about Discounted Price</h2>
                <p className="lead">
                  <i>{proposal.couponComments}</i>
                </p>
              </div>
            </section>
          )}
        </div>
      </section>
      <section className="py-2">
        <div className="container">
          <div className="row justify-content-center text-center">
            <a href="/termsofservice">Terms of Service</a> &nbsp;and&nbsp; <a href="/privacy">Privacy Policy</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default tpl
