/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, numericality, email, url, oneof } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getDataCreationHackedReport, createHackedReport } from 'api/hackedReports'

import withStyles from '@material-ui/core/styles/withStyles'

import { hackedReportTypeOptions, hackedReportAccountTypeOptions } from 'data/options'

import SA from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const Swal = withReactContent(SA)


const HackedReportAdd = ({ match }) => (
  <Query query={getDataCreationHackedReport} fetchPolicy="cache-and-network">
    {({ data: { products: { data: products } = {}, sources: { data: sources } = {} } = {}, loading }) => {
      return (
        !loading && (
          <FormDialog
            title="Create Report"
            form="HackedReportAdd"
            mutation={[createHackedReport, { refetchQueries: ['getHackedReports'] }]}
            initialValues={{}}
            btnSubmitText="Create"
            onSubmit={({ data, mutation }) => {
              return new Promise((resolve, reject) => {
              Swal.fire({
                title: 'Paid Service Warning',
                text: "Creation of Hacked report will cost you $1.00",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
              }).then((result) => {
                if(result.value) {
                  return mutation({variables: {data}}).then(e=>{
                    Swal.fire(
                      'Done!',
                      'Your report has been created.',
                      'success'
                    )
                    resolve()
                  }).catch(e=>{
                    reject(e)
                    console.log(e)
                  })
                }
                resolve()
              })
            })
            }}
          >
            {({ doc, invalid, submitting }) => (
              <React.Fragment>
                <fieldset>
                  <legend>Client Data</legend>
                  <Field name="firstName" component={TextField} label="Client First Name" validate={[required()]} />
                  <Field name="lastName" component={TextField} label="Client Last Name" validate={[required()]} />
                  <Field name="email" component={TextField} label="Client Email" validate={[email({ allowBlank: false })]} />
                  <Field name="phone" component={TextField} label="Client Phone" />
                  <Field name="company" component={TextField} label="Company Name" />
                </fieldset>
                <fieldset>
                  <legend>Report Data</legend>
                  <Field
                    name="reportEmail"
                    component={TextField}
                    label="Email Address"
                    validate={[oneof({ reportEmail: 'Email Address', reportDomain: 'Domain Name', allowBlank: false }), email()]}
                  />
                  <Field
                    name="reportDomain"
                    component={TextField}
                    label="Domain Name"
                    validate={[
                      oneof({ reportEmail: 'Email Address', reportDomain: 'Domain Name' }),
                      url({
                        emptyProtocol: false,
                        protocolIdentifier: false,
                        host: true,
                        path: false,
                        search: false,
                        hash: false
                      })
                    ]}
                  />
                </fieldset>
              </React.Fragment>
            )}
          </FormDialog>
        )
      )
    }}
  </Query>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(HackedReportAdd)

// onComplete={() => (window.location.href = `/account/hackedReports/add`)}
//{/*<Redirect from={} to={} />*/}
