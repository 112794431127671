/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField, Checkbox } from 'lib/form/fields'
import { required } from 'lib/form/validators'
import { proposalSystemOptions } from 'data/options'

import { createProposal, onCreateProposal } from 'api/proposals'
import { getDataCreationProposal } from 'api/proposals'
import templates from './templates.json'
import withAppContext from 'lib/withAppContext'

import withStyles from '@material-ui/core/styles/withStyles'
import _ from 'lodash'

const ProposalCreate = ({ classes, match, context: { acl, user } }) => (
  <FormDialog
    title="Create Proposal"
    form="ProposalCreate"
    initialValues={{ productId: 'prod_Fjoibv8fL79rh7', type: 'common' }}
    mutation={[createProposal, { update: onCreateProposal }]}
    refetchQueries={['getProposals']}
    btnSubmitText="Create"
    success="Proposal created successfully"
  >
    {({ doc, change }) => {
      let minPrice = null
      let rPrice = null
      const getRPrice = v => {
        if (doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).prices && doc.emailCount) {
          let tpl = _.find(templates, { id: doc.template })
          let tierIndex = _.findIndex(_.sortBy(Object.keys(tpl.prices), i => i * 1), i => {
            return v * 1 <= i * 1
          })
          if (tierIndex === -1) tierIndex = Object.keys(tpl.prices).length
          let tier = Object.values(tpl.prices)[tierIndex - 1]
          return tier[1]
        }
        return null
      }
      if (doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).prices && doc.emailCount) {
        let tpl = _.find(templates, { id: doc.template })
        minPrice = Object.values(tpl.prices)[0][0]
        rPrice = Object.values(tpl.prices)[0][1]
        let tierIndex = _.findIndex(_.sortBy(Object.keys(tpl.prices), i => i * 1), i => {
          return doc.emailCount * 1 <= i * 1
        })
        if (tierIndex === -1) tierIndex = Object.keys(tpl.prices).length
        let tier = Object.values(tpl.prices)[tierIndex - 1]
        minPrice = tier[0]
        rPrice = tier[1]
      }
      return (
        <Query query={getDataCreationProposal}>
          {({ data: { products: { data: products } = {}, resellers: { data: resellers } = {} } = {}, loading }) => {
            let req = doc.type !== 'template' && (doc.productId === 'prod_Fjoibv8fL79rh7' || doc.productId === 'dNetH2TMph6E96efZgYK')
            return (
              !loading && (
                <React.Fragment>
                  <Field name="name" component={TextField} label="Proposal Name" validate={[required()]} />
                  {acl.hasAccess('proposals-roles') && (
                    <Field
                      name="type"
                      component={TextField}
                      label="Proposal Type"
                      validate={[required()]}
                      options={[{ label: 'Common', value: 'common' }, { label: 'Template', value: 'template' }]}
                      select
                    />
                  )}
                  {doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).reseller && (
                    <Field name="resellerId" component={TextField} label="Proposal Reseller" options={resellers} select />
                  )}
                  {doc.type === 'template' && (
                    <Field
                      name="template"
                      component={TextField}
                      label="Template"
                      options={templates.map(t => ({ label: t.label, value: t.id }))}
                      validate={[required()]}
                      select
                    />
                  )}
                  {doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).items && (
                    <div>
                      <fieldset>
                        <legend>Package Items</legend>
                        <div>Leave price or quantity blank or "0" to omit item</div>
                        {Object.keys(_.find(templates, { id: doc.template }).items).map((item, index)=>(
                          <div className="row">
                            <div className="col-8">
                              <Field name={`items.${item}.price`} component={TextField} label={`${item} Price`} type="number" />
                            </div>
                            <div className="col-4">
                              <Field name={`items.${item}.qty`} component={TextField} label={`Quantity`} type="number" />
                            </div>
                          </div>
                        ))}
                      </fieldset>
                  </div>)}
                  {doc.type === 'template' && doc.template && _.find(templates, { id: doc.template }).sellOptions && (
                    <div>
                      <fieldset>
                        <legend>Selling Options</legend>
                        <Field name="msp" component={Checkbox} label="MSP" />
                        <Field name="soldAsPOC" component={Checkbox} label="Sold as POC" />
                        <Field name="setupFee" component={TextField} label="Setup Fee" type="number" />
                        <Field
                          name="emailCount"
                          component={TextField}
                          label="Units (quantity)"
                          type="number"
                          validate={[required()]}
                          onChange={e => {
                            if (e.target.value < 1) e.preventDefault()
                            setTimeout(() => change('unitPrice', getRPrice(e.target.value)), 100)
                          }}
                        />
                        <Field
                          name="unitPrice"
                          onChange={e => {
                            if (e.target.value < minPrice) e.preventDefault()
                          }}
                          component={TextField}
                          label="Unit Price"
                          type="number"
                          validate={[required()]}
                        />
                        <Field
                          name="additionalUnitPrice"
                          onChange={e => {
                            if (e.target.value < 0) e.preventDefault()
                          }}
                          component={TextField}
                          label="BYOD Additional Unit Price"
                          type="number"
                          validate={doc.additionalUnitAmount > 0 ? [required()] : []}
                        />
                        <Field
                          name="additionalUnitAmount"
                          onChange={e => {
                            if (e.target.value < 0) e.preventDefault()
                          }}
                          component={TextField}
                          label="BYOD Additional Unit Quantity"
                          type="number"
                        />
                        <Field
                          name="guardianAddon"
                          component={TextField}
                          label="Email Guardian Addon"
                          options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                          select
                          validate={[required()]}
                        />

                        {doc.guardianAddon === 'Yes' && (
                          <Field
                            name="guardianAmount"
                            onChange={e => {
                              if (e.target.value < 0) e.preventDefault()
                            }}
                            component={TextField}
                            label="Email Guardian Addon Quantity"
                            type="number"
                          />
                        )}
                      </fieldset>
                    </div>
                  )}
                  {doc.type !== 'template' && (
                    <div>
                      <Field name="productId" component={TextField} label="Product" options={products} validate={[required()]} select />
                      {doc.productId && _.find(products, { value: doc.productId }) && (
                        <Field
                          name="planId"
                          component={TextField}
                          label="Plan"
                          options={_.find(products, { value: doc.productId }).plans}
                          validate={[required()]}
                          select
                        />
                      )}
                    </div>
                  )}
                  <Field name="email" component={TextField} label="Email" validate={[required()]} />
                  <Field name="phone" component={TextField} label="Cell Phone" validate={[required()]} />
                  <Field name="firstName" component={TextField} label="First Name" validate={[required()]} />
                  <Field name="middleName" component={TextField} label="Middle Name" />
                  <Field name="lastName" component={TextField} label="Last Name" validate={[required()]} />

                  <Field name="companyName" component={TextField} label="Company Name" validate={req ? [required()] : []} />
                  <Field name="domain" component={TextField} label="Domain" validate={req ? [required()] : []} />

                  <Field
                    name="emailSystem"
                    component={TextField}
                    label="Current Email System"
                    options={proposalSystemOptions}
                    select
                    validate={req ? [required()] : []}
                  />

                  {(doc.type !== 'template' || !doc.template || !_.find(templates, { id: doc.template }).sellOptions) && (
                    <Field name="emailCount" component={TextField} label="Number of Active Email Accounts " type="number" />
                  )}
                  <Field
                    name="techInquiry"
                    component={TextField}
                    label="Are you point of contact for technical enquiries?"
                    options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                    select
                  />
                  <Field
                    name="comment"
                    component={TextField}
                    multiline
                    rows={5}
                    label="How do you currently completed phishing awareness and training?"
                  />
                  <Field name="coupon" component={TextField} label="Coupon" />
                  <Field name="couponComments" component={TextField} multiline rows={5} label="Notes about Discounted Price" />
                  <Field name="trialDays" component={TextField} label="Trial in Days" type="number" />
                </React.Fragment>
              )
            )
          }}
        </Query>
      )
    }}
  </FormDialog>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(withAppContext(ProposalCreate))
