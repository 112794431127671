/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const Requests = ({ classes }) => (
  <Paper className={classes.root}>
    <Typography variant="h5">Requests</Typography>
  </Paper>
)

const styles = theme => ({
  root: {
    padding: 5
  }
})

export default compose(
  withRouter,
  withStyles(styles)
)(Requests)
