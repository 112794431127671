/** @format */

import React from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const sizes = {
  sm: 40
}

const Logo = ({ color = 'blue', size = 'sm' }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <img alt={color+size} src={`/logo-${color}-${size}.png`} height={sizes[size]} />
    <Typography variant="h6" style={{ marginLeft: 7 }}>
      ChatFortress
    </Typography>
  </Box>
)

export default Logo
