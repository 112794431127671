/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import UserLicenses from './UserLicenses'
import UserLicenseAdd from './UserLicenseAdd'

export default ({ match }) => (
  <Container maxWidth="lg">
    <Box my={2}>
      <Switch>
        <Route path={`${match.path}`} component={UserLicenses} />
      </Switch>
      <Route path={`${match.path}/add`} component={UserLicenseAdd} />
    </Box>
  </Container>
)
