/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField, Checkbox, SelectField } from 'lib/form/fields'
import { required, numericality, email, url } from 'lib/form/validators'
import withAppContext from 'lib/withAppContext'

import { getCompaniesShort } from 'api/companies'
import { getDataUpdatingCardReport, updateCardReport } from 'api/cardReports'

import withStyles from '@material-ui/core/styles/withStyles'
//import Avatar from '@material-ui/core/Avatar'

import { cardReportStatusOptions } from 'data/options'

const CardReportUpdate = ({ classes, match, context }) => {
  const { acl } = context
  return (
    <React.Fragment>
      <Query query={getDataUpdatingCardReport} variables={match.params}>
        {({ data: { cardReport = {} } = {}, loading }) =>
          !loading && (
            <FormDialog
              title="Update Card Report"
              form="CardReportUpdate"
              mutation={[updateCardReport]}
              initialValues={cardReport}
              success="Card Report updated successfully"
            >
              {({ doc, invalid, submitting }) => (
                <React.Fragment>
                  <Field
                    name="companyId"
                    component={SelectField}
                    label="Company"
                    fld="companies"
                    query={getCompaniesShort}
                    validate={[required()]}
                  />
                  <Field
                    name="domain"
                    component={TextField}
                    label="Domain Name"
                    validate={[
                      required(),
                      url({
                        emptyProtocol: false,
                        protocolIdentifier: false,
                        host: true,
                        path: false,
                        search: false,
                        hash: false
                      })
                    ]}
                  />
                  <Field name="status" component={TextField} label="Report Status" options={cardReportStatusOptions} select />
                  {/*<Field name="active" component={Checkbox} label="Active" />*/}
                </React.Fragment>
              )}
            </FormDialog>
          )
        }
      </Query>
    </React.Fragment>
  )
}

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withAppContext, withStyles(styles))(CardReportUpdate)
