/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Companies from 'components/Companies/Companies'
import CompanyCreate from 'components/Companies/CompanyCreate'
import CompanyUpdate from 'components/Companies/CompanyUpdate'
import CompanyNotes from 'components/Companies/CompanyNotes'
import CompanyUpdateBulk from 'components/Companies/CompanyUpdateBulk'
import CompanyImport from 'components/Companies/CompanyImport'

export default ({ match }) => (
  <Container maxWidth="xl">
    <div className="mt-4">
      <p>
        Companies is how you create a Cybersecurity Report Card. A Cybersecurity Report Card is generated a company entity and this allows
        one domain name per company. If you have questions use the live chat to ask your question.
        <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e501bf504286364bc95b29e">
          Get help about this page here
        </a>
      </p>
    </div>
    <Box my={2}>
      <Route path={`${match.path}/create`} component={CompanyCreate} />
      <Route path={`${match.path}/:companyId/update`} component={CompanyUpdate} />
      <Route path={`${match.path}/:companyId/notes`} component={CompanyNotes} />
      <Route path={`${match.path}/updateBulk`} component={CompanyUpdateBulk} />
      <Route path={`${match.path}/import`} component={CompanyImport} />
      <Switch>
        <Route path={`${match.path}`} component={Companies} />
      </Switch>
    </Box>
  </Container>
)
