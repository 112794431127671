/** @format */

import gql from 'graphql-tag'
import {employeeFragment} from "./employees";

export const getExperiments = gql`
  query getExperiments($name: String, $skip: Int, $limit: Int, $sort: [String]) {
    experiments(name: $name,skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
        hypothesis
        description
        variations {
          name
          description
          percentage
          views
          goalType
          goalValue
          winner
        }
        goalType
        goalValue
        createdAt
        updatedAt
      }
      count
      pages
    }
  }
`

export const getExperiment = gql`
  query getExperiment($experimentId: ID!) {
    experiment(id: $experimentId) {
      id
      name
      weight
      hypothesis
      description
      url
      enabled
      variations {
          name
          description
          percentage
          views
          goalType
          goalValue
          winner
      }
      goalType
      goalValue
      createdAt
      updatedAt
    }
  }
`
export const getExperimentStats = gql`
  query getExperimentStats($experimentId: ID!) {
    experiment(id: $experimentId) {
      id
      name
      weight
      hypothesis
      description
      url
      enabled
      variations {
          name
          description
          percentage
          views
          goalType
          goalValue
          winner
      }
      visits
      notes
      sessionNotes
      goalType
      goalValue
      createdAt
      updatedAt
    }
  }
`
export const createExperiment = gql`
  mutation createExperiment($data: ExperimentInput!) {
    createExperiment(data: $data) {
      id
    }
  }
`
export const updateExperiment = gql`
  mutation updateExperiment($experimentId: String!, $data: ExperimentInput!) {
    updateExperiment(id: $experimentId, data: $data) {
      id
    }
  }
`

export const removeExperiment = gql`
  mutation removeExperiment($id: String!) {
    removeExperiment(id: $id) {
      id
    }
  }
`
export const addExperimentNote = gql`
  mutation addExperimentNote($experimentId: String!, $body: String) {
    addExperimentNote(id: $experimentId, body: $body) {
      id
      name
      weight
      hypothesis
      description
      url
      enabled
      variations {
          name
          description
          percentage
          views
          goalType
          goalValue
          winner
      }
      visits
      notes
      sessionNotes
      goalType
      goalValue
      createdAt
      updatedAt
    }
  }
`
