/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Sources from './Sources'
import CreditCardAdd from './CreditCardAdd'
import BankAccountAdd from './BankAccountAdd'
import SourceUpdate from './SourceUpdate'
import BankAccountVerify from './BankAccountVerify'
import InvoiceEmailAdd from './InvoiceEmailAdd'
import InvoiceEmailUpdate from './InvoiceEmailUpdate'

export default ({ match }) => (
  <React.Fragment>
    <Switch>
      <Route path={`${match.path}`} component={Sources} />
    </Switch>
    <Route path={`${match.path}/add-credit-card`} component={CreditCardAdd} />
    <Route path={`${match.path}/add-invoice-notification`} component={InvoiceEmailAdd} />
    <Route path={`${match.path}/add-bank-account`} component={BankAccountAdd} />
    <Route path={`${match.path}/:sourceId/update-card`} component={SourceUpdate} />
    <Route path={`${match.path}/:sourceId/update-invoice`} component={InvoiceEmailUpdate} />
    <Route path={`${match.path}/:sourceId/verify`} component={BankAccountVerify} />
  </React.Fragment>
)
