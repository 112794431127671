import React from 'react'
import moment from "moment/moment"
import CheckboxTable from 'lib/tables/CheckboxTable'
import Button from '@material-ui/core/Button'

import { getTrainingReport } from 'api/training'

export default ({ match, location, history }) => (
  <React.Fragment>
    <div className="p-4">

      <div>
        <h3>Cybersecurity Awareness Monthly</h3>
        <CheckboxTable
          query={getTrainingReport}
          initialVariables={{ courseId: 's3zZy57Lc2JRLYnWZErb', sorted: [{ id: 'createdAt', desc: true }] }}
          variables={{ courseId: 's3zZy57Lc2JRLYnWZErb' }}
          columns={[
            {
              Header: 'User',
              accessor: 'user',
              id: 'name',
              width: 400,
              Cell: ({ original: item }) => (
                <div>
                  <strong>{item.user.name}</strong>
                  <p>{item.user.email}</p>
                </div>
              )
            },
            {
              sortable: true,
              filterable: false,
              Header: 'Lessons Completed',
              accessor: 'lessonsCompleted'
            },
            {
              sortable: true,
              filterable: false,
              Header: 'Lessons released not completed',
              accessor: 'lessonsReleasedDue'
            },
            {
              sortable: true,
              filterable: false,
              Header: 'Lessons Yet to Complete',
              accessor: 'lessonsDue'
            },
            {
              sortable: false,
              filterable: false,
              Header: '',
              accessor: '_id',
              Cell: ({ original: item }) => (
                <div>
                  <Button variant='contained' color='primary' onClick={() => history.push(`${match.url}/${item.user.id}`)}>Access Report</Button>
                </div>
              )
            }
          ]}
          deleteMany={false}
        />
        <br/>
        <h3>﻿Cybersecurity CORE 4</h3>
        <CheckboxTable
          query={getTrainingReport}
          initialVariables={{ courseId: 'mtyXTpwkt7ogjSu36mKx', sorted: [{ id: 'createdAt', desc: true }] }}
          variables={{ courseId: 'mtyXTpwkt7ogjSu36mKx' }}
          columns={[
            {
              Header: 'User',
              accessor: 'user',
              id: 'name',
              width: 400,
              Cell: ({ original: item }) => (
                <div>
                  <strong>{item.user.name}</strong>
                  <p>{item.user.email}</p>
                </div>
              )
            },
            {
              sortable: true,
              filterable: false,
              Header: 'Lessons Completed',
              accessor: 'lessonsCompleted'
            },
            {
              sortable: true,
              filterable: false,
              Header: 'Lessons released not completed',
              accessor: 'lessonsReleasedDue'
            },
            {
              sortable: true,
              filterable: false,
              Header: 'Lessons Yet to Complete',
              accessor: 'lessonsDue'
            },
            {
              sortable: false,
              filterable: false,
              Header: '',
              accessor: '_id',
              Cell: ({ original: item }) => (
                <div>
                  <Button variant='contained' color='primary' onClick={() => history.push(`${match.url}/${item.user.id}`)}>Access Report</Button>
                </div>
              )
            }
          ]}
          deleteMany={false}
        />

      </div>
    </div>
  </React.Fragment>
)
