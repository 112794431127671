import gql from 'graphql-tag'
import { invoiceFragment } from './invoices'

export const invoiceEmailFragment = gql`
  fragment invoice on InvoiceEmail {
    invoice_email
  }
`

export const getInvoiceEmails = gql`
  query getInvoiceEmails($userId: String, $status: String, $skip: Int, $limit: Int, $sort: [String]) {
    invoiceEmails(userId: $userId, status: $status, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        invoice_email
      }
      count
      pages
    }
  }
`

export const createInvoiceEmail = gql`
  mutation createInvoiceEmail($userId: ID, $data: InvoiceEmailInput) {
    createInvoiceEmail(userId: $userId, data: $data) {
      id
      ...invoice
    }
  }
  ${invoiceEmailFragment}
`

export const removeInvoiceEmail = gql`
  mutation removeInvoiceEmail($id: String!) {
    removeInvoiceEmail(id: $id) {
      id
    }
  }
`

export const getInvoiceEmailsForUpdating = gql`
  query getSourceForUpdating($id: String!) {
    invoiceEmails(id: $id) {
      id
      ...invoice
    }
  }
  ${invoiceFragment}
`

export const updateInvoiceEmail = gql`
  mutation updateInvoiceEmail($sourceId: String!, $data: InvoiceEmailInput!) {
    updateInvoiceEmail(id: $sourceId, data: $data) {
      id
      invoice_email
    }
  }
`

export const onCreateInvoiceEmails = (cache, { data: { createInvoiceEmail: invoiceEmails } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { invoices } = cache.readQuery({ query: getInvoiceEmails, variables })
  cache.writeQuery({
    query: getInvoiceEmails,
    variables,
    data: {
      invoices: {
        ...invoices,
        data: [invoiceEmails].concat(invoices.data)
      }
    }
  })
}

export const onRemoveInvoiceEmails = (cache, { data: { removeInvoice: invoiceEmails } }) => {
  const variables = { limit: 10, skip: 0, sort: ['createdAt', '-1'] }
  const { invoices } = cache.readQuery({ query: getInvoiceEmails, variables })
  cache.writeQuery({
    query: getInvoiceEmails,
    variables,
    data: {
      invoices: {
        ...invoices,
        data: invoices.data.filter(r => r.id !== invoiceEmails.id)
      }
    }
  })
}
