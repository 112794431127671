import React from 'react'
import { FormDialog, Field } from 'lib/form'
import { TextField, PhoneNumber } from 'lib/form/fields'
import { required, length } from 'lib/form/validators'

import { createContact } from 'api/contacts'

import withStyles from '@material-ui/core/styles/withStyles'


const ListCreate = ({ classes, match }) => (
  <FormDialog
    title="Create Target"
    form="TargetCreate"
    mutation={[createContact, { refetchQueries: ['getLists', 'getContacts'] }]}
    initialValues={{listId: match.params.listId}}
    btnSubmitText="Create"
    success="Target created successfully"
  >
    {({ doc, invalid, submitting }) => (
      <React.Fragment>
        <Field name="firstName" component={TextField} label="First Name" validate={[required(), length({ min: 2 })]} />
        <Field name="lastName" component={TextField} label="Last Name" validate={[required(), length({ min: 2 })]} />
        <Field name="email" component={TextField} label="Email" type={'email'} />
        <Field name="phone" component={PhoneNumber} label="Phone" validate={[required()]} />
      </React.Fragment>
    )}
  </FormDialog>
)

const styles = () => ({
  root: {}
})

export default withStyles(styles)(ListCreate)
