import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import withContext from 'lib/withAppContext'

import withStyles from '@material-ui/core/styles/withStyles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Card, CardHeader, CardIcon, CardBody, CardFooter } from 'components/Card'
import Calendly from './Calendly'
import { Box } from '@material-ui/core'

import JWPlayer from 'react-jw-player'

const data = [
  {
    roles: ['RC-Reseller'],
    videoUrl : null,
    calendlyLink: 'https://calendly.com/chatfortressclients/cfreportcardreseller'
  },
  {
    roles: ['RC-Influencer'],
    videoUrl : 'https://content.jwplatform.com/videos/6uBfWm8H-gLqksrpw.mp4',
    calendlyLink: 'https://calendly.com/chatfortressclients/cfvendor'
  },
  {
    roles: ['RC-Vendor'],
    videoUrl : 'https://content.jwplatform.com/videos/6uBfWm8H-gLqksrpw.mp4',
    calendlyLink: 'https://calendly.com/chatfortressclients/cfvendor'
  },
  {
    roles: ['RC-SalesTier1', 'CF-RC-SalesTier1'],
    videoUrl: 'https://content.jwplatform.com/videos/0qgEzADX-gLqksrpw.mp4',
    calendlyLink: 'https://calendly.com/chatfortressclients/chatfortress-sales-team--tier-1'
  },
  {
    roles: ['RC-SalesTier2', 'CF-RC-SalesTier2'],
    videoUrl: 'https://content.jwplatform.com/videos/0qgEzADX-gLqksrpw.mp4',
    calendlyLink: 'https://calendly.com/chatfortressclients/chatfortress-sales-team--tier-2'
  },
  {
    roles: ['Fortified'],
    videoUrl: 'https://content.jwplatform.com/videos/2tzIDF0D-gLqksrpw.mp4',
    calendlyLink: 'https://calendly.com/chatfortressclients/cfclientsupport-fortified'
  },
  {
    roles: ['Email Guardian'],
    videoUrl: 'https://content.jwplatform.com/videos/fsd4OjWq-gLqksrpw.mp4',
    calendlyLink: 'https://calendly.com/chatfortressclients/chatfortressemailguardian'
  }
]

const DashboardReseller = ({ classes, context: { user } }) => {
  let userRoles = user.roles || [user.accountType]
  let role = _.find(data, d=>_.intersection(d.roles, userRoles).length>0)

  return (

      <div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={7} lg={8}>
            <Card>
              <CardBody>
                <Box textAlign="center">
                  <div>
                    <JWPlayer
                      mute={true}
                      isMuted={true}
                      playerId="34Tcdns1"
                      playerScript="https://cdn.jwplayer.com/libraries/34Tcdns1.js"
                      file={role?role.videoUrl:'https://content.jwplatform.com/videos/YzLhDl4O-gLqksrpw.mp4'}
                    />
                  </div>
                </Box>
              </CardBody>
            </Card>
          </Grid>

          <Grid item xs={12} md={5} lg={4}>
            <Card>
              <CardHeader className='text-center'><h2>Have question? Schedule an appointment!</h2></CardHeader>
              <CardBody style={{padding: 0}}>
                <Box textAlign="center">
                  <Calendly url={role?role.calendlyLink:'https://calendly.com/chatfortressclients/general-chatfortress-support-qna'}/>
                </Box>
              </CardBody>
            </Card>
          </Grid>
        </Grid>

      </div>
  )
}

const styles = () => ({
  root: {}
})

export default compose(withContext, withStyles(styles))(DashboardReseller)
