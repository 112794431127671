/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { verifySource } from 'api/sources'

import withStyles from '@material-ui/core/styles/withStyles'

const BankAccountVerify = ({ classes, match }) => (
  <FormDialog
    title="Microdeposit amounts in cents"
    form="BankAccountVerify"
    mutation={[verifySource]}
    success="Source verified successfully"
  >
    {({ doc, invalid, submitting }) => (
      <React.Fragment>
        <Field name="amount1" component={TextField} label="First deposit" validate={[required()]} normalize={number} />
        <Field name="amount2" component={TextField} label="Second deposit" validate={[required()]} normalize={number} />
      </React.Fragment>
    )}
  </FormDialog>
)

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(BankAccountVerify)
