import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import _ from 'lodash'
import { Field, FieldArray, FormCard, FormSection, FormGroup } from 'lib/form'
import { TextField, AvatarUploadField, ImageUploadField } from 'lib/form/fields'
import { required } from 'lib/form/validators'

import { getUser, updateUser } from 'api/users'

import withContext from 'lib/withAppContext'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Box from '@material-ui/core/Box'

import defaultFaqs from 'data/defaultFaqs.json'
import { useRouteMatch } from 'react-router-dom'

const defaultRcSettings = {
  scheduleBtnText: 'Schedule Now',
  scheduleHeadline: 'Speak with a Specialist',
  scheduleDescription: 'Discover how we can help your company reduce your risk. Schedule time with your Specialist now.',
  btnText1: 'Chat with Cybersecurity Specialist',
  btnText2: 'Schedule Your Discovery Session Now!',
  recommendations: `
Below are the recommendations based on your Cybersecurity Report Card. Our recommendations include strategies that allow you to detect and respond to threats in real-time—providing you with an early warning system against attacks, therefore, reducing your exposure and possible down-time.

Here are the recommendations based on your Cybersecurity Report Card. If you would like to speak with a cybersecurity specialist, you can schedule a conversation using this <a href="/reportcarddiscoverysession/">link.</a> Speak to a ChatFortress agent, call [+1 307-999-7755](+13079997755) or email [Help@ChatFortress.com](mailto:Help@ChatFortress.com)

Watch this short video below to understand the biggest mistakes that companies make with their cybersecurity programs. This video demonstrates how companies are failing to protect their most valued assets and using a costly outdated approach to cybersecurity

<video src="https://content.jwplatform.com/videos/J9IIN2LQ-gLqksrpw.mp4" preload="auto" controls="" style="width: 100%; height: 100%;"></video>

# Your Recommendations
----------------------------------

## **1. Review your vulnerabilities and create a plan with your IT team**.
Part of your Cybersecurity Defense Plan should include receiving your Cybersecurity Report Card every month. This provides you with an early warning system against attacks and new threats. Learn more about the ChatFortress Fortified monitoring programs here or ask your specialist.
_You can gain access to monthly cybersecurity report cards with our [Fortified program.](/fortified?company=DiGpKddAjr9EWe6RjDx6)_

Have your IT Team immediately resolve any issues classified as high or critical that was discovered as part of your Cybersecurity Report Card. Vulnerabilities and remediation blueprint defined below.

## **2. Review your compromised accounts and change all passwords.**
This includes changing passwords on social media accounts, online software solutions, not just your computer or network access. The best structured passwords to use, is to change all your**passwords to paraphrases** to promote increased security. To learn more about this, review this article at [https://chatfortress.com/blog/ccscday1passwords](https://chatfortress.com/blog/ccscday1passwords)

## **3. Activate a solution that detects and mitigates email phishing attacks from your inbox.**
This will help reduce human error; therefore, reducing the chances of ransomware, malware or
compromising your email. ***9 out of 10 cyber attacks occur via email***, therefore you need a system that can detect and remove bad emails.  We recommend the Email Guardian solution and offer Small Business Scholarships to ensure your email can be protected against cybercriminals. To learn more visit [https://chatfortress.com/emailphishing](https://chatfortress.com/emailphishing)

<video src="https://content.jwplatform.com/videos/zwoYLS0b-gLqksrpw.mp4" preload="auto" controls="" style="width: 100%; height: 100%;"></video>
`,
  faqs: defaultFaqs,
  ads: []
}

export const FAQField = ({ fields, meta, label }) => (
  <FormGroup title={label}>
    {fields.map((member, index) => (
      <Box display="flex" flexDirection="column" key={index}>
        <Box display="flex" alignItems="center">
          <Field name={`${member}.question`} component={TextField} label="Question" validate={[required()]} multiline />
          <IconButton onClick={() => fields.remove(index)}>
            <Icon>delete</Icon>
          </IconButton>
        </Box>
        <Field name={`${member}.answer`} component={TextField} label="Answer" multiline margin="none" />
      </Box>
    ))}
    <IconButton onClick={() => fields.push({})}>
      <Icon>add_circle</Icon>
    </IconButton>
  </FormGroup>
)

export const ADSField = ({ fields, meta, label, values }) => (
  <FormGroup title={label}>
    {fields.map((member, index) => (
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Field name={`${member}.title`} component={TextField} label="Title" validate={[required()]} style={{ marginRight: 8 }} />
          <Field name={`${member}.link`} component={TextField} label="Link" />
          <IconButton onClick={() => fields.remove(index)}>
            <Icon>delete</Icon>
          </IconButton>
        </Box>
        <Field name={`${member}.image`} component={ImageUploadField} label="Image" btnLabel="Upload Image" />
        {/*<Field name={`${member}.description`} component={TextField} label="Description" multiline />*/}
      </Box>
    ))}
    {Array.from(values || []).length < 3 && (
      <IconButton onClick={() => fields.push({})}>
        <Icon>add_circle</Icon>
      </IconButton>
    )}
  </FormGroup>
)

const RCResselerComponent = ({ context }) => {
  const match = useRouteMatch()
  const userId = match.params.userId || context.user.id

  return (
    <Query query={getUser} variables={{ userId }}>
      {({ data: { user } = {}, loading }) =>
        !loading && (
          <FormCard
            form="RCResseler"
            mutation={[updateUser, { variables: { userId } }]}
            initialValues={{
              rcSettings: {
                ...defaultRcSettings,
                ..._.omitBy(user.rcSettings, v => !v)
              }
            }}
          >
            {({ doc }) => (
              <React.Fragment>
                <div>
                  <p>
                    If you have questions use the live chat to ask your question.
                    <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e49894f2c7d3a7e9ae7d158">
                      Get help about this page here
                    </a>
                  </p>
                </div>
                <FormSection name="rcSettings">
                  <FormGroup title="Contact Information">
                    <Field name="logo" component={AvatarUploadField} label="Company Logo" btnLabel="Upload Logo" />
                    <Field name="email" component={TextField} label="Email Address" />
                    <Field name="phone" component={TextField} label="Cell Phone Number" />
                    <Field name="businessPhone" component={TextField} label="Business Phone Number" />
                  </FormGroup>
                  <FormGroup title="Recommendations Report Card Section">
                    <Field
                      name="recommendations"
                      component={TextField}
                      margin="none"
                      helperText="This is the recommendations section of your Report Card. Here you should load the next steps that you are recommending to your clients based on this Cybersecurity Report Card. As you can see as an example here are the recommendations that we make to clients from this report card for ChatFortress."
                      multiline
                    />
                  </FormGroup>
                  <FormGroup title="Schedule Report Card Section">
                    <p>This is the section of the report card related to scheduling appointments or Call to Action. </p>
                    <Field name="scheduleLink" component={TextField} label="Schedule Link" />
                    <small id="scheduleLink" className="form-text text-muted">
                      This is the URL for the "Schedule" button. Use your appointment scheduler URL. This should be a FULL "https://" URL.
                    </small>
                    <Field name="scheduleBtnText" component={TextField} label="Schedule Button Text" />
                    <small id="scheduleBtnText" className="form-text text-muted">
                      What do you want Button call to action to be? "Schedule Discovery Session"
                    </small>
                    <Field name="scheduleHeadline" component={TextField} label="Schedule Headline" />
                    <Field name="scheduleDescription" component={TextField} label="Schedule Description" />
                    <Field name="btnText1" component={TextField} label="Button Text 1" />
                    <Field name="btnText2" component={TextField} label="Button Text 2" />
                  </FormGroup>
                  <FormGroup title="FAQ Report Card Section">
                    <p>This is the section of the report card is the FAQ's that you can customize unique to your business. </p>
                    <FieldArray name="faqs" component={FAQField} label="FAQs Section of Report Card" />
                  </FormGroup>
                  <FieldArray name="ads" component={ADSField} label="Ads Section" values={doc.rcSettings && doc.rcSettings.ads} />
                </FormSection>
              </React.Fragment>
            )}
          </FormCard>
        )
      }
    </Query>
  )
}

export default compose(withContext)(RCResselerComponent)
