/** @format */

import React from 'react'
import ReactCodeInput from 'react-code-input'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

export default ({ label, fields = 6, input, meta: { touched, invalid, error }, ...rest }) => {
  return (
    <div>
      <FormControl margin="normal" fullWidth error={touched && invalid} {...rest} style={{ textAlign: 'center' }}>
        <label>{label}</label>
        <ReactCodeInput
          filterChars={'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_'}
          fields={fields}
          onChange={e => {
            e = e
              .toString()
              .replace(/[^\d]/g, '')
              .slice(0, fields)
            if (e) input.onChange(e.toString())
          }}
          inputStyle={{
            fontFamily: 'monospace',
            MozAppearance: 'textfield',
            borderRadius: '6px',
            border: '1px solid',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
            margin: '4px',
            paddingLeft: '8px',
            width: '36px',
            height: '42px',
            fontSize: '26px',
            boxSizing: 'border-box'
          }}
        />
        {touched && error && (
          <FormHelperText margin="dense" error>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  )
}
