/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Campaigns from './Campaigns'
import CampaignUpdate from './CampaignUpdate'
import CampaignCreate from './CampaignCreate'
import CampaignTest from './CampaignTest'
import CampaignStats from './CampaignStats'

const Module = ({ match }) => (
  <Container maxWidth="xl">
    <Box my={2}>
      <Route path={`${match.path}/:campaignId/stats`} component={CampaignStats} />
      <Switch>
        <Route path={`${match.path}`} component={Campaigns} />
      </Switch>
      <Route path={`${match.path}/create`} component={CampaignCreate} />
      <Route path={`${match.path}/:campaignId/update`} component={CampaignUpdate} />
      <Route path={`${match.path}/:campaignId/test`} component={CampaignTest} />
    </Box>
  </Container>
)

export default Module
