/** @format */

import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from 'react-apollo'
import _ from 'lodash'
import JWPlayer from 'react-jw-player'
import Player from 'components/Player'

import { getTrainingLesson } from 'api/lessons'
import { sendTrainingActivity, getTrainingActivities, sendLink } from 'api/training'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Spinner from 'components/Spinner'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import Quiz from 'components/Quiz/Quiz'
import Button from '@material-ui/core/Button'

const isMobile = () => typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1

const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const Lesson = ({ classes, match }) => {
  let dt = 0
  const [videoCompleted, setVideoCompleted] = useState(false)
  const [lessonSent, setLessonSent] = useState(false)
  //const [dt, setDT] = useState(0)
  const { courseId, lessonId } = match.params
  const trainingActivity = useQuery(getTrainingActivities, { variables: match.params })
  const activityData = (!trainingActivity.loading && trainingActivity.data && trainingActivity.data.trainingActivities.data) || []
  const { data, loading, refetch } = useQuery(getTrainingLesson, { variables: match.params })
  const [sendAction] = useMutation(sendTrainingActivity, {
    onCompleted() {
      refetch(match.params)
      trainingActivity.refetch(match.params)
    }
  })
  const [sendLessonLink] = useMutation(sendLink, {
    onCompleted() {
      setLessonSent(true)
    }
  })

  useInterval(() => {
    dt++
  }, 1000)
  if(loading) return <Spinner />

  const lesson = _.get(data, 'lesson', {})
  const course = _.get(lesson, 'course', {})
  const lessons = _.get(course, 'lessons', [])
  const quizPoints = (lesson.userStats && lesson.userStats.quizPoints) || 0
  const curLessonIdx = lessons.findIndex(l => l.id === lessonId)
  const prevCursor = lessons[curLessonIdx - 1] ? lessons[curLessonIdx - 1].id : null
  const nextCursor = lessons[curLessonIdx + 1] ? lessons[curLessonIdx + 1].id : null
  const questions = lesson.quiz ? JSON.parse(lesson.quiz) : []

  setTimeout(()=>{
    if (
      !_.find(activityData, { action: 'video completed' }) &&
      !videoCompleted
    ) {
      sendAction({
        variables: {
          data: {
            action: 'video completed',
            data: {},
            lessonId,
            courseId: course.id
          }
        }
      })
      setVideoCompleted(true)
    }
  }, 5000)

  const onTime = e => {
    if (
      !_.find(activityData, { action: 'video completed' }) &&
      !videoCompleted &&
      e.currentTime / e.duration > 0.85 &&
      dt / e.duration > 0.5
    ) {
      sendAction({
        variables: {
          data: {
            action: 'video completed',
            data: {},
            lessonId,
            courseId: course.id
          }
        }
      })
      setVideoCompleted(true)
    }
  }
  return loading ? (
    <Spinner />
  ) : (
    <div className="p-2">
      <Typography className="my-3" variant="h4">
        Lesson #{curLessonIdx + 1}:{' '}
        <Typography component="span" variant="h4" color="primary">
          {lesson.name}
        </Typography>
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          {prevCursor ? (
            <a className={classes.navLink} href={`/training/course/${courseId}/lesson/${prevCursor}`}>
              Prev
            </a>
          ) : (
            <span className={classes.navLink}>Prev</span>
          )}
          {nextCursor ? (
            <a className={classes.navLink} href={`/training/course/${courseId}/lesson/${nextCursor}`}>
              Next
            </a>
          ) : (
            <span className={classes.navLink}>Next</span>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h4">{quizPoints}</Typography>
          <Typography variant="body2" color="textSecondary">
            Quiz score
          </Typography>
        </Box>
      </Box>
      {lesson.teaserDescription && (
        <Paper className="p-2 mb-3">
          <Typography>{lesson.teaserDescription}</Typography>
        </Paper>
      )}
      {!isMobile() && (
        <Paper className="p-2 mb-3">
          <Typography style={{ fontStyle: 'italic' }}>
            You can send this lesson to your authorized mobile device to watch the lesson on your mobile device. This is a tokenized link
            that will automatically log you into this lesson. This link does expire in 60 minutes. Click this button and you will receive a
            text message with a link to access the lesson.
          </Typography>
          <div style={{ textAlign: 'center' }}>
            {lessonSent && <Typography className="text-success">Lesson link sent. Check Your Authorized Mobile Device</Typography>}
            {!lessonSent && (
              <Button variant="outlined" onClick={() => sendLessonLink({ variables: { lessonId } })}>
                Send Lesson to Mobile Device
              </Button>
            )}
          </div>
        </Paper>
      )}
      <Grid spacing={2} container>
        <Grid lg={7} item>
          <Paper className="p-2">
            <Typography variant="h5">Video</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {lesson.summary}
            </Typography>
            {lesson.video && 1===3 &&<JWPlayer
              playerId="34Tcdns1"
              playerScript="https://cdn.jwplayer.com/libraries/34Tcdns1.js"
              image={lesson.nuggetImage}
              file={lesson.video}
              onTime={onTime}
            />}
            {lesson.video &&<Player
              url={lesson.video}
              onTime={onTime}
            />}
            {!lesson.video && <img style={{width:"100%"}} src={lesson.nuggetImage} alt={'image'} />}
            {lesson.htmlBlock && <div className='pt-5' dangerouslySetInnerHTML={{__html: lesson.htmlBlock}}/>}
          </Paper>
        </Grid>
        <Grid lg={5} item>
          {!_.find(activityData, { action: 'video completed' }) && (
            <div className="text-center">
              <SnackbarContent
                className={classes.snackbar}
                message={<strong>Quiz will load when video completes, please wait...</strong>}
                variant="error"
              />
            </div>
          )}
          <div style={!_.find(activityData, { action: 'video completed' }) ? { filter: 'blur(8px)', '-webkit-filter': 'blur(8px)' } : {}}>
            <Quiz
              title="Quiz"
              questions={questions}
              message={lesson.quizSuccessMessage}
              onComplete={({ answers }) =>
                sendAction({ variables: { data: { action: 'quiz completed', data: { answers }, lessonId, courseId: course.id } } })
              }
            />
          </div>
        </Grid>
      </Grid>
      {/*<Paper className="p-2 mt-3">
        <Typography variant="h5">Resourses</Typography>
      </Paper>*/}
    </div>
  )
}

const styles = theme => ({
  root: {},
  navLink: {
    fontSize: 16,
    marginRight: 10
  },
  snackbar: {
    backgroundColor: theme.palette.error.dark
  }
})

export default withStyles(styles)(Lesson)
