import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { getVisits } from 'api/visitors'
import { useApolloClient } from 'react-apollo'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getColorByValue } from 'lib/chartHelpers'

export default ({ match, location, history }) => {
  const [advanced, setAdvanced] = useState(false)
  const [path, setPath] = useState('')
  const [scale, setScale] = useState(1)
  const [pages, setPages] = useState([])
  const [visits, setVisits] = useState([])
  const client = useApolloClient()

  const receiveMessage = async (event) => {
    if(scale===1) {
      setScale(event.data / 667)
      console.log('receiveMessage',event.data)
    }
  }
  window.addEventListener("message", receiveMessage, false);

  useEffect(() => {
    const getPages = async () => {
      if(pages.length===0) {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/v1/sitemap`, {
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then(r => r.json())
          .catch(e => console.log(e))
        setPages(res)
      } else {
        console.log('afterpages')
      }
    }
    getPages()
  }, [pages])

  const onChangePage = async (loc) => {
    await setPath(loc)
    console.log('onChangePage', "/"+(loc.replace(/\//g,"")))
    const {data: {visits} = {}} = await client.query({
      query: getVisits,
      variables: { location: loc.replace("//","/") }
    })
    visits.data = _.filter(visits.data, v=>v.scrollRate*1<=100)
    setVisits([])
    if(visits.data.length>0){
      let temp = _.groupBy(visits.data.map(v=>v.scrollRate*1), Math.floor)
      let temp2 = {}
      let percentage = Object.values(temp).map(v=>(v.length/visits.data.length)*100)
      Object.keys(temp).forEach((k, i)=>{
        if(k*1<=100)
          temp2[k]={
            percent: percentage[i],
            amount: _.filter(visits.data, v=>v.scrollRate*1===k*1).length
          }
      })
      setVisits(temp2)
    }
  }


  return (
    <React.Fragment>
      <div className="p-4">

        <h3>Scrolling Stats</h3>

        {pages.length>0 &&<TextField
          id="standard-select-currency-native"
          select
          label="Select page"
          value={path}
          onChange={(e)=>onChangePage(e.target.value)}
          helperText="Please select desired page"
        >
          <MenuItem key={'null'} value={''}>

          </MenuItem>
          {pages.map(p=>p.path).sort().map((path) => (
            <MenuItem key={path} value={path}>
              {path}
            </MenuItem>
          ))}
        </TextField>}
        {path && Object.keys(visits).length===0 && <div>No data recorded for this page...</div>}
        {path && Object.keys(visits).length>0 && <div className={'row1'}>
          <FormControlLabel
            control={
              <Switch
                checked={advanced}
                onChange={(e)=>setAdvanced(e.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label="Advanced View"
          />{advanced && <small>In Advancewd View 0% means that people did not leave at this section. They continued to the next page segment.</small>}
          <div style={{display: "flex"}}>
            <div style={{
              display: 'inline-block',
            width: 375,
            height: 667,
            overflow: 'hidden'
          }}>
            <iframe src={`${process.env.REACT_APP_GATSBY_URL}${path}`} id={'frame1'} style={{
              width: 375 * scale,
              height: 667 * scale,
              transform: 'scale('+(1/scale)+')',
              transformOrigin: '0 0'
            }}></iframe>
          </div>
            <div className={'col'}>
              <div style={{
                marginTop: -18,
                paddingLeft: 12,
                background: 'linear-gradient(0deg, rgba(74,255,0,0.3) 0%, rgba(255,0,0,0.3) 100%)',
                display: 'inline-block',
                width: 375,
                height: 687,
                overflow: 'hidden'
              }}>
                {
                  Array.from(Array(11)).map((v, i)=>{
                    let data = Object.keys(visits).map((v,k)=>({
                      rate: v,
                      percent: Object.values(visits)[k].percent,
                      amount: Object.values(visits)[k].amount
                    }))
                    //console.log(data)
                    let percent = _.sumBy(_.filter(data, d=>{
                      if(!advanced)
                        return d.rate>=i*10
                      else
                        return d.rate>=i*10 && d.rate<(i+1)*10
                    }), d=>d.percent)
                    let amount = _.sumBy(_.filter(data, d=>{
                      if(!advanced)
                        return d.rate>=i*10
                      else
                        return d.rate>=i*10 && d.rate<(i+1)*10
                    }), d=>d.amount)
                    return (<div style={{color: getColorByValue(percent), width: 375, borderBottom: 'solid 1px gray', position:'absolute', top: 667*((i/10))-18}}>
                      <span style={{fontWeight: 'bold', textShadow: '2px 2px 3px silver'}}>{i*10}: {_.round(percent, 2)}% ({amount})</span>
                    </div>)
                  })
                }
                {/*
                Object.keys(visits).map((k)=>{
                  return (<div style={{backgroundColor: 'white', width: 375, borderBottom: 'solid 1px gray', position:'absolute', top: 667*((k/100))-18}}>
                    {k}: {visits[k]}%
                  </div>)
                })*/
              }</div>
            </div>
        </div>
        </div>}
      </div>
    </React.Fragment>
  )
}
