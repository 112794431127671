/** @format */

import _ from 'lodash'
import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField, Checkbox } from 'lib/form/fields'
import { required, numericality, email, url, oneof } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getDataUpdatingHackedReport, updateHackedReport } from 'api/hackedReports'

import withStyles from '@material-ui/core/styles/withStyles'

import { hackedReportTypeOptions, hackedReportAccountTypeOptions } from 'data/options'

const HackedReportUpdate = ({ classes, match }) => (
  <React.Fragment>
    <Query query={getDataUpdatingHackedReport} variables={match.params}>
      {({ data: { hackedReport, products: { data: products } = {} } = {}, loading }) =>
        !loading && (
          <FormDialog
            title={`Report`}
            form="HackedReportUpdate"
            mutation={[updateHackedReport]}
            initialValues={_.omit(hackedReport, ['link'])}
            success="HackedReport updated successfully"
          >
            {({ doc, invalid, submitting }) => (
              <React.Fragment>
                <fieldset>
                  <legend>Client Data</legend>
                  <Field name="firstName" component={TextField} label="Client FirstName" validate={[required()]} />
                  <Field name="lastName" component={TextField} label="Client LastName" validate={[required()]} />
                  <Field name="email" component={TextField} label="Client Email" />
                  <Field name="phone" component={TextField} label="Client Phone" />
                  <Field name="company" component={TextField} label="Company Name" />
                </fieldset>
                <fieldset>
                  <legend>Report Data</legend>
                  <Field
                    name="reportEmail"
                    component={TextField}
                    label="Email Address"
                    validate={[oneof({ reportEmail: 'Email Address', reportDomain: 'Domain Name', allowBlank: false }), email()]}
                  />
                  <Field
                    name="reportDomain"
                    component={TextField}
                    label="Domain Name"
                    validate={[
                      oneof({ reportEmail: 'Email Address', reportDomain: 'Domain Name' }),
                      url({
                        emptyProtocol: false,
                        protocolIdentifier: false,
                        host: true,
                        path: false,
                        search: false,
                        hash: false
                      })
                    ]}
                  />
                </fieldset>
              </React.Fragment>
            )}
          </FormDialog>
        )
      }
    </Query>
  </React.Fragment>
)

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withStyles(styles))(HackedReportUpdate)
