import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import withContext from 'lib/withAppContext'

import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Card, CardHeader, CardIcon, CardBody, CardFooter } from 'components/Card'

import Profile from 'components/Account/Profile'
import Services from 'components/Account/Services'
import OnboardingSection from './OnboardingSection'

class Dashboard extends React.Component {
  state = {}

  render() {
    const { classes, context, wizardSteps } = this.props
    const { user } = context

    return (
      <div className="p-2">
        <OnboardingSection />

        {wizardSteps && wizardSteps.includes("profile") && <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon color="warning">
              <Icon>people</Icon>
            </CardIcon>
            <p className={classes.cardCategory}>Please complete your profile</p>
          </CardHeader>
          <CardBody>
            <Profile/>
          </CardBody>
          <CardFooter stats>
            <Button className='float-right' variant="contained" color="primary" onClick={()=>null} disabled={!_.some([user.email, user.name, user.phone, user.mailAddress?user.mailAddress.addressLine1:null],_.isEmpty)}>Complete Step</Button>
          </CardFooter>
        </Card>}

        {wizardSteps && wizardSteps.includes("stripe") && <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon color="warning">
              <Icon>attach_money</Icon>
            </CardIcon>
            <p className={classes.cardCategory}>Please connect your Stripe acccount</p>
          </CardHeader>
          <CardBody>
            <Services/>
          </CardBody>
          <CardFooter stats>
            <Button className='float-right' variant="contained" color="primary" onClick={()=>null} disabled={!user.services || !user.services.stripe}>Complete Step</Button>
          </CardFooter>
        </Card>}

      </div>
    )
  }
}

const styles = () => ({
  root: {},
  cardTitle: {
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    },
    marginTop: "0",
    marginBottom: "3px",
    minHeight: "auto",
    "& a": {
      marginTop: ".625rem",
      marginBottom: "0.75rem",
      minHeight: "auto"
    }
  },
  cardIconTitle: {
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    minHeight: "auto",
    "& a": {
      marginTop: ".625rem",
      marginBottom: "0.75rem",
      minHeight: "auto"
    },
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      color: "#777",
      fontSize: "80%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardCategory: {
    marginTop: "10px",
    fontSize: "1.5em",
    color: "#000000 !important",
    textAlign: "center"
  },
  description: {
    color: "#999999"
  }
})

export default compose(withContext, withStyles(styles))(Dashboard)
