/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required, numericality, email, url } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getDataCreationCardReport, createCardReport } from 'api/cardReports'

import withStyles from '@material-ui/core/styles/withStyles'
//import Avatar from '@material-ui/core/Avatar'

const CardReportAdd = ({ match, history }) => (
  <Query query={getDataCreationCardReport} fetchPolicy="cache-and-network">
    {({ data: { companies: { data: companies = [] } = {} } = {}, loading }) => {
      return (
        !loading && (
          <FormDialog
            title="Create Card Report"
            subtitle="subtitle here"
            form="CardReportAdd"
            mutation={[createCardReport, { refetchQueries: ['getCardReports'] }]}
            initialValues={{}}
            btnSubmitText="Create"
            success="CardReport created successfully"
          >
            {({ doc, invalid, submitting }) => (
              <React.Fragment>
                <Field
                  name="companyId"
                  component={TextField}
                  label="User"
                  options={Array.from(companies || []).map(company => ({
                    label: company.name,
                    value: company.id
                  }))}
                  select
                />
                {/*<Field name="name" component={TextField} label="Company Name" />*/}
                <Field
                  name="domain"
                  component={TextField}
                  label="Domain Name"
                  helperText="Please enter the domain name with or without the www. for example chatfortress.com. You can enter a full domain with https:// or without http:// also."
                  validate={[
                    required(),
                    url({
                      emptyProtocol: false,
                      protocolIdentifier: false,
                      host: true,
                      path: false,
                      search: false,
                      hash: false
                    })
                  ]}
                />
              </React.Fragment>
            )}
          </FormDialog>
        )
      )
    }}
  </Query>
)

const styles = () => ({
  root: {}
})

export default compose(withStyles(styles))(CardReportAdd)
