/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useQuery } from 'react-apollo'

import { Timeline, TimelineEvent } from 'react-event-timeline'
import _ from "lodash";
import moment from "moment";
import Icon from "@material-ui/core/Icon"
import { getActivityLogs } from 'api/activityLog'

const ActivityFeed = ({ classes }) => {
  const { loading, data: { activityLogs: {data: events = []} = {} } = {} } = useQuery(getActivityLogs, { variables: {limit: 100}})
  if(loading) return <div>Loading...</div>
  if(_.filter(events, e => e.eventDate && e.userLabel).length===0) return <Paper className={classes.root}>No Activity Log data</Paper>
  return (
    <Paper className={classes.root}>
      <Typography variant="h5">Activity Feed</Typography>
      {!loading && events.length<1 && <div>No data</div>}
      <Timeline>
        <div>&nbsp;</div>
        {!loading && events.length>0 &&
        _.filter(events, e => e.eventDate && e.userLabel).map(event => {
          return (
            <TimelineEvent
              key={event.id}
              title={<div>{event.userLabel}</div>}
              createdAt={moment(event.eventDate).format('LLLL')}
              icon={<Icon>{event.actionType}</Icon>}
            >
              <div className="">Object: {event.objectType} <strong>{event.objectLabel}</strong></div>
              <div className="">Action: <strong>{event.actionType}</strong></div>
              {event.description &&<div>{event.description}</div>}
            </TimelineEvent>
          )
        })}
      </Timeline>
    </Paper>
  )
}

const styles = theme => ({
  root: {
    padding: 16
  }
})

export default compose(
  withRouter,
  withStyles(styles)
)(ActivityFeed)
