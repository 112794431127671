/** @format */

import React from 'react'
import md5 from 'md5'
import url from 'url'

const Avatar = props => {
  const { avatar = '', name = '', email = '', size = 50, d = 'mp', round = true, style = {} } = props
  const s = size
  const hash = md5(email || '')
  const initials = getInitials(name)
  const query = { s }
  let src = null

  if (avatar) {
    src = avatar
  } else if (initials && !email) {
    src = `https://fakeimg.pl/${s}/?text=${initials}&font=lobster&font_size=${Math.ceil(s * 0.6)}`
  } else {
    query.d = d
    src = url.format({
      protocol: 'https',
      hostname: 'secure.gravatar.com',
      pathname: `avatar/${hash}`,
      query
    })
  }

  const styles = {
    width: '100%',
    height: '100%'
    //boxSizing: 'content-box'
  }

  return (
    <div
      style={{
        width: s,
        height: s,
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        fontSize: '1.25rem',
        alignItems: 'center',
        flexShrink: 0,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        userSelect: 'none',
        borderRadius: round ? '50%' : 0,
        justifyContent: 'center',
        ...style
      }}
      title={name}
    >
      <img className="user-avatar" src={src+(src.indexOf('?')===-1?'?t=':'&t=')+(new Date())} style={styles} alt={name} />
    </div>
  )
}

const getInitials = name => {
  let initials = String(name || '').match(/\b\w/g) || []
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
}

export default Avatar
