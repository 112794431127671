/** @format */

import React from 'react'
import compose from 'recompose/compose'
import {useQuery} from 'react-apollo'
import {Form, Field} from 'lib/form'
import {TextField} from 'lib/form/fields'
import {required} from 'lib/form/validators'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'

import withAppContext from 'lib/withAppContext'
import {getCompanyDataForUpdating, addCompanyNote} from 'api/companies'

import withStyles from '@material-ui/core/styles/withStyles'


const CompanyUpdate = ({classes, match, context, history}) => {
  const {loading, data: {company = {}, categories = [], resellers: {data: resellers = []} = {}} = {}} = useQuery(
    getCompanyDataForUpdating,
    {
      variables: match.params
    }
  )

  return (
    !loading && (
      <Form
        form="CompanyUpdate"
        mutation={[addCompanyNote, {refetchQueries: ['getCompanies']}]}
        refetchQueries={['getCompanies']}
        initialValues={company}
        success="Company notes updated successfully"
        onSubmit={({ data: { body }, mutation, ...props })=> {
          mutation({ variables: { ...match.params, body} })
        }}
        onSubmitSuccess={(result, dispatch, { enqueueSnackbar }) =>{
          enqueueSnackbar("Company notes updated successfully", { variant: 'success' })
          history.goBack()
        }}
      >
        {({doc, submit, submitting}) => (
          <React.Fragment>
            <Dialog open fullWidth maxWidth="md">
              <DialogTitle>{company.name}: notes</DialogTitle>
              <DialogContent>


                <List className={classes.root}>
                  {Array.from(doc.notes || []).map(note=>(
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt={note.author.name} src={note.author.avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={note.author.name}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {(new Date(note.createdAt)).toLocaleString()}
                            </Typography>
                            <p className='lead'>{note.body}</p>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
                <hr />
                <Field name="body" component={TextField} label="Note" multiline rows={4} validate={[required()]} />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={submit}
                  disabled={submitting}
                >
                  <Icon>add</Icon>
                  Add Note
                </Button>

              </DialogContent>
              <DialogActions>
                <Button color="default" variant='contained' onClick={() => history.goBack()} disabled={submitting}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
      </Form>
    )
  )
}

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

export default compose(withAppContext, withStyles(styles))(CompanyUpdate)
