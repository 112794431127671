/** @format */

import React from 'react'
import moment from 'moment'

import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from "@material-ui/core/FormControl";

class DateTimeField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customError: null
    }
  }

  render() {
    const {
      input,
      type = 'text',
      label,
      meta: { touched, invalid, error },
      select,
      options = [],
      multiple = false,
      dateOnly = false,
      timeOnly = false,
      ...rest
    } = this.props
    const { customError } = this.state

    const onChange = value => {
      this.setState({ customError: null })
      if (rest.minTime && rest.maxTime) {
        let time = moment(moment(value).format('HH') * 1, 'HH'),
          beforeTime = moment(rest.minTime, 'HH') - 1,
          afterTime = moment(rest.maxTime, 'HH') + 1
        if (!time.isBetween(beforeTime, afterTime, 'h')) {
          this.setState({
            customError: `Time must be between ${moment(rest.minTime, 'HH').format('hha')} and ${moment(rest.maxTime, 'HH').format('hha')}`
          })
          return null
        }
      }
      if(timeOnly)
        value = moment(value).format('LT')
      console.log(value)
      return input.onChange(value)
    }

    if(timeOnly)input.value=moment(input.value, 'LT').toDate()
    return (
      <FormControl margin="normal" fullWidth error={touched && invalid}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {!dateOnly && !timeOnly && <DateTimePicker
            label={label}
            fullWidth
            disablePast
            inputVariant="outlined"
            value={input.value || null}
            onChange={onChange}
            error={Boolean(touched && error)}
          />}
          {dateOnly && !timeOnly && <DatePicker
            label={label}
            fullWidth
            disablePast
            inputVariant="outlined"
            value={input.value || null}
            onChange={onChange}
            error={Boolean(touched && error)}
          />}
          {timeOnly && <TimePicker
            label={label}
            fullWidth
            disablePast
            inputVariant="outlined"
            value={input.value || null}
            onChange={onChange}
            error={Boolean(touched && error)}
          />}
        </MuiPickersUtilsProvider>
        {touched && error && (
          <FormHelperText margin="dense" error>
            {error}
          </FormHelperText>
        )}
        {customError && (
          <FormHelperText margin="dense" error>
            {customError}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

export default DateTimeField
