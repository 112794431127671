import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import withContext from 'lib/withAppContext'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment'

import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Card, CardHeader, CardIcon, CardBody, CardFooter } from 'components/Card'
import { Box } from '@material-ui/core'
import OnboardingSection from './OnboardingSection'

const getData = gql`
  query getDashboardResellerData {
    dashboardResellerData
  }
`

const DashboardReseller = ({ classes, context: { user }, history }) => {
  const { loading, data = {} } = useQuery(getData)
  const {
    paysUntil,
    planRenewal,
    countReportCards = 0,
    remainingReportCards = 0,
    generatedReportCards = 0,
    avgPoints = 0,
    countVulms = 0,
    countLeakedCreds = 0
  } = (data && data.dashboardResellerData) || {}

  return (
    !loading && (
      <div className="p-2">
        <OnboardingSection />
        <Grid container spacing={2}>
          {(planRenewal || paysUntil) && (
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardBody>
                  <Box textAlign="center">
                    {paysUntil && (
                      <Typography variant="h5" gutterBottom>
                        Pays Until: <b>{moment(paysUntil).format('ll')}</b>
                      </Typography>
                    )}
                    {planRenewal && (
                      <Typography variant="h5">
                        Plan Renewal: <b>{moment(planRenewal).format('ll')}</b>
                      </Typography>
                    )}
                  </Box>
                </CardBody>
              </Card>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardBody>
                <Box textAlign="center">
                  <Typography variant="h5">Current Report Cards</Typography>
                  <Typography variant="h4">
                    {countReportCards} / {remainingReportCards}
                  </Typography>
                  <Box marginTop={2}>
                    <Button color="primary" variant="contained" onClick={() => history.push(`/companies/create`)}>
                      Create New Report Card
                    </Button>
                  </Box>
                </Box>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardBody>
                <Box textAlign="center">
                  <Typography variant="h5">Report Cards Generated</Typography>
                  <Typography variant="h4">{generatedReportCards}</Typography>
                </Box>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardBody>
                <Box textAlign="center">
                  <Typography variant="h5">Avg Grade</Typography>
                  <Typography variant="h4">{avgPoints}</Typography>
                </Box>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardBody>
                <Box textAlign="center">
                  <Typography variant="h5">Vulnerabilities Found</Typography>
                  <Typography variant="h4">{countVulms}</Typography>
                </Box>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardBody>
                <Box textAlign="center">
                  <Typography variant="h5">Leaked Credentials Found</Typography>
                  <Typography variant="h4">{countLeakedCreds}</Typography>
                </Box>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  )
}

const styles = () => ({
  root: {}
})

export default compose(withContext, withStyles(styles))(DashboardReseller)
