/** @format */

import React from 'react'
import _ from 'lodash'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles/index'

import templates from './templates'

let CostsComponent = templates.costTemplates.EmailGuardianCosts

const getPriceByAmount = (amount, proposal) => {
  let tier = getTierByAmount(amount, proposal)
  return tier.unit_amount
}

const getTierByAmount = (amount, proposal) => {
  let { plans } = proposal.productData
  let plan = _.find(plans, {id: proposal.planId}) || plans[0]
  if(!plan.tiers){
    if(plan.usage_type==='metered')
      return {flat_amount: plan.setupFee?plan.setupFee:0 , unit_amount: plan.amount}
    return {flat_amount: plan.amount, unit_amount: plan.amount}
  }
  //let plan = _.find(plans, p => p.id === 'plan_GGipvtVG5USV3K') // mode===tiered ?
  let tier = _.find(_.sortBy(plan.tiers, 'up_to'), t => {
    return amount <= t.up_to
  })
  if (!tier) tier = _.find(plan.tiers, t => !t.up_to)
  return tier
}

const getFlatPriceByAmount = (amount, proposal) => {
  let tier = getTierByAmount(amount, proposal)
  return tier.flat_amount
}

const Module = ({ match, proposal }) => {
  if(proposal && (proposal.productId==='dNetH2TMph6E96efZgYK' || proposal.productId==='9N2ejyGd9xEPyEHAwMF9'))
    CostsComponent = templates.costTemplates.EmailGuardianCostsSMB
  if(proposal && (proposal.productId==='jRgZQA7y5Sn5q4zJEB3p'))
    CostsComponent = templates.costTemplates.ReportCardResellerCosts

  let plan = null
  if(proposal.productData && proposal.productData.plans)
    plan = _.find(proposal.productData.plans, {id: proposal.planId}) || proposal.productData.plans[0]

  return (
    <div>
      {proposal.productData && (
        <CostsComponent proposal={proposal} plan={plan} flatPrice={getFlatPriceByAmount(proposal.emailCount, proposal)} unitPrice={getPriceByAmount(proposal.emailCount, proposal)} unitsAmount={getPriceByAmount(proposal.emailCount, proposal) * proposal.emailCount} />
      )}
    </div>
  )
}

const styles = theme => ({
  root: {
    width: '100%'
  }
})

export default compose(withStyles(styles))(Module)
