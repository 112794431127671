import React from 'react'

const defaultUrl = 'https://calendly.com/chatfortress-demo/chatfortress?hide_event_type_details=1'

class Calendly extends React.Component {
  componentDidMount() {
    const { url = defaultUrl, prefill = {}, utm = {} } = this.props
    const { Calendly } = window
    if (typeof Calendly !== 'undefined') {
      Calendly.initInlineWidget({
        parentElement: this.container,
        url: url+'?hide_event_type_details=1',
        prefill,
        utm
      })
    }
  }
  render() {
    const { size = '1by1' } = this.props
    return (
      <div
        style={{ height: 503, maxWidth: 700, textAlign: 'center' }}
        className={`embed-responsive embed-responsive-${size}`}
        ref={el => (this.container = el)}
      />
    )
  }
}

export default Calendly

/*
style={{ height: '100%' }}
*/
