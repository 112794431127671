/** @format */

import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import classnames from 'classnames'

import { onLogout } from 'api/auth'

import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import NotificationsIcon from '@material-ui/icons/Notifications'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MailIcon from '@material-ui/icons/Mail'
import MoreIcon from '@material-ui/icons/MoreVert'
import LogoutIcon from '@material-ui/icons/SettingsPower'
import Hidden from '@material-ui/core/Hidden'

import withAppContext from 'lib/withAppContext'
import Avatar from 'lib/Avatar'

import getPageTitle from './getPageTitle'

const AppBarComponent = ({
  classes,
  context: { user, acl },
  location,
  history,
  open,
  handleDrawerOpen,
  handleDrawerClose,
  mobileOpen,
  handleMobileDrawerOpen,
  handleMobileDrawerClose
}) => {
  const pageTitle = getPageTitle({ location })
  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null)
  }

  function handleMenuClose() {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {acl.hasAccess('messages:read') && (
        <MenuItem
          onClick={() => {
            handleMenuClose()
            history.push('/messages')
          }}
        >
          <ListItemIcon style={{ minWidth: 30 }}>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary="Messages" />
        </MenuItem>
      )}
      {/*<MenuItem
        onClick={() => {
          handleMenuClose()
          history.push('/account/notifications')
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </MenuItem>*/}
      <MenuItem
        onClick={() => {
          handleMenuClose()
          history.push('/account/profile')
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose()
          onLogout()
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMobileMenuClose()
          history.push('/messages')
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <MailIcon />
        </ListItemIcon>
        <ListItemText primary="Messages" />
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMobileMenuClose()
          history.push('/account/notifications')
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMobileMenuClose()
          history.push('/account/profile')
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMobileMenuClose()
          onLogout()
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  )

  return (
    <React.Fragment>
      <Hidden smUp implementation="css">
        <AppBar position="absolute" className={classnames(classes.appBar)}>
          <Toolbar className={classes.toolbarMobile}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              className={classnames(classes.menuButton)}
              onClick={handleMobileDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.pageTitle}>
              {pageTitle}
            </Typography>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden xsDown implementation="css">
        <AppBar position="absolute" className={classnames(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={classnames(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close drawer"
              className={classnames(classes.menuButton, !open && classes.menuButtonHidden)}
              onClick={handleDrawerClose}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.pageTitle}>
              {pageTitle}
            </Typography>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar {...user} size={30} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
      {renderMobileMenu}
      {renderMenu}
    </React.Fragment>
  )
}

const styles = theme => ({
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarMobile: {},
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: theme.drawerWidth,
    width: `calc(100% - ${theme.drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 5
  },
  menuButtonHidden: {
    display: 'none'
  },
  pageTitle: {
    flexGrow: 1
  }
})

export default compose(withRouter, withAppContext, withStyles(styles))(AppBarComponent)
