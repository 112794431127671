/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormCard, Field, FormSection, MetaDataPage } from 'lib/form'
import { TextField, Checkbox, EditorField } from 'lib/form/fields'
import { required, numericality, url } from 'lib/form/validators'
import { number } from 'lib/form/normalizers'

import { getDataUpdatingLesson, updateLesson } from 'api/lessons'

import withStyles from '@material-ui/core/styles/withStyles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

//import { lessonTypeOptions } from 'data/options'

const LessonUpdate = ({ classes, match }) => {
  const [tabIndex, setTabIndex] = React.useState(0)

  return (
    <React.Fragment>
      <Query query={getDataUpdatingLesson} variables={match.params}>
        {({ data: { lesson, course } = {}, loading }) =>
          !loading && (
            <FormCard
              title={lesson.name}
              form="LessonUpdate"
              mutation={[updateLesson]}
              initialValues={lesson}
              success="Lesson updated successfully"
            >
              {({ doc, submitting }) => (
                <React.Fragment>
                  <Tabs
                    value={tabIndex}
                    onChange={(event, index) => setTabIndex(index)}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    <Tab label="Main" />
                    <Tab label="Access" />
                    <Tab label="Content" />
                    <Tab label="Quiz" />
                    <Tab label="SEO" />
                  </Tabs>
                  {tabIndex === 0 && (
                    <React.Fragment>
                      <Field name="name" component={TextField} label="Name" validate={[required()]} />
                      <Field name="summary" component={TextField} label="Summary" multiline />
                      <Field name="released" component={Checkbox} label="Lesson Released" />
                      {doc.released ? (
                        <React.Fragment>
                          <Field
                            name="releasedAt"
                            type="date"
                            component={TextField}
                            label="Released Date"
                            InputLabelProps={{ shrink: true }}
                            validate={[required()]}
                          />
                          <Field name="featured" component={Checkbox} label="Lesson featured" />
                          {doc.featured && (
                            <React.Fragment>
                              <Field name="featureHeadline" component={TextField} label="Feature Headline" />
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Field name="releaseHeadline" component={TextField} label="Release Headline" />
                          <Field
                            name="sheduleAt"
                            type="date"
                            component={TextField}
                            label="Shedule Release Date"
                            InputLabelProps={{ shrink: true }}
                            validate={[required()]}
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {tabIndex === 1 && (
                    <React.Fragment>
                      <Field name="paid" component={Checkbox} label="Paid Access" />
                      {doc.paid && (
                        <React.Fragment>
                          <Field
                            name="plans"
                            component={TextField}
                            label="Plans"
                            options={course && course.plans}
                            validate={[required()]}
                            multiple
                            select
                          />
                        </React.Fragment>
                      )}
                      {/*<Field name="ldGuestAccess" component={Checkbox} label="LD Guest Access" />*/}
                    </React.Fragment>
                  )}
                  {tabIndex === 2 && (
                    <React.Fragment>
                      {/*<Field name="type" component={TextField} label="Type" options={lessonTypeOptions} validate={[required()]} select />*/}
                      <Field
                        name="teaserVideo"
                        component={TextField}
                        label="Teaser Video"
                        validate={[url({ protocols: ['http', 'https'] })]}
                      />
                      <Field name="teaserDescription" component={TextField} label="Teaser Description" multiline />
                      <Field name="video" component={TextField} label="Video" validate={[url({ protocols: ['http', 'https'] })]} />
                      <Field
                        name="corporateVideo"
                        component={TextField}
                        label="Corporate Video"
                        validate={[url({ protocols: ['http', 'https'] })]}
                      />
                      <Field name="nanoVideo" component={TextField} label="Nano Video" validate={[url({ protocols: ['http', 'https'] })]} />
                      <Field
                        name="infographicImage"
                        component={TextField}
                        label="Infographic Image"
                        validate={[url({ protocols: ['http', 'https'] })]}
                      />
                      <Field
                        name="cartoonImage"
                        component={TextField}
                        label="Cartoon Lesson Image"
                        validate={[url({ protocols: ['http', 'https'] })]}
                      />
                      <Field
                        name="promotionPosterImage"
                        component={TextField}
                        label="Promotion Poster Image"
                        validate={[url({ protocols: ['http', 'https'] })]}
                      />
                      <Field
                        name="teachableMomentImage"
                        component={TextField}
                        label="Teachable Moment Image"
                        validate={[url({ protocols: ['http', 'https'] })]}
                      />
                      <Field
                        name="nuggetImage"
                        component={TextField}
                        label="Nugget Image"
                        validate={[url({ protocols: ['http', 'https'] })]}
                      />
                      <Field name="htmlBlock" component={EditorField} label="HTML Block under Video" />
                    </React.Fragment>
                  )}
                  {tabIndex === 3 && (
                    <React.Fragment>
                      <Field name="quiz" component={EditorField} label="Quiz" />
                      <Field
                        name="quizPoints"
                        component={TextField}
                        label="Quiz Total Points"
                        validate={[numericality()]}
                        normalize={number}
                      />
                      <Field name="quizSuccessMessage" component={TextField} label="Quiz Success Message" />
                    </React.Fragment>
                  )}
                  {tabIndex === 4 && (
                    <React.Fragment>
                      <FormSection name="metaData">
                        <MetaDataPage title="Social Share Details" />
                      </FormSection>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </FormCard>
          )
        }
      </Query>
    </React.Fragment>
  )
}

const styles = () => ({
  root: {},
  tabs: {
    margin: '5px 0'
  }
})

const enhance = compose(withStyles(styles))

export default enhance(LessonUpdate)
