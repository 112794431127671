/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles/index'

const Module = ({ match }) => (
  <div>
    <section className="fdb-block py-4">
      <div className="container">
        <h2>Email Guardian Terms of Service</h2>
        <h1>ChatFortress LLC End User License Agreement</h1>
        <p>
          THIS AGREEMENT (THE "AGREEMENT") CONSTITUTES A BINDING CONTRACT BETWEEN ChatFortress LLC. ("WE", "US", "OUR", THE "COMPANY"), AND
          YOU – A LEGAL ENTITY (A COMPANY, A PARTNERSHIP, OR ANY OTHER LEGAL ENTITY, HEREINAFTER: “ORGANIZATION” OR “CUSTOMER”), IDENTIFIED
          BY THE DETAILS PROVIDED DURING THE REGISTRATION PROCESS OR ELSEWHERE, OR AN INDIVIDUAL (EMPLOYEE OR OTHERWISE, HEREINAFTER:
          “USER”) DESIGNATED BY THE ORGANIZATION TO USE THE SERVICE FOR THE BENEFIT OF THE ORGANIZATION. THE TERMS "YOU" AND "YOUR" WILL
          APPLY COLLECTIVELY TO SUCH ORGANIZATION AND SUCH USER, UNLESS OTHERWISE EVIDENT FROM THE CONTEXT.
        </p>
        <p>
          IF YOU ARE ACTING ON BEHALF OF THE ORGANIZATION TO ACQUIRE A RIGHT TO USE THE SERVICE, THEN YOU REPRESENT AND WARRANT THAT YOU ARE
          DULY AUTHORIZED TO ENTER INTO THIS AGREEMENT ON BEHALF OF THE ORGANIZATION AND THAT YOU HAVE THE PROPER AUTHORITY TO LEGALLY BIND
          THE ORGANIZATION, BY THIS AGREEMENT.
        </p>
        <p>
          TAKING ANY STEP TO SET-UP, CONFIGURE OR INSTALL THE PLATFORM CONSTITUTES YOUR ASSENT TO AND ACCEPTANCE OF THIS END USER LICENSE
          AGREEMENT. WRITTEN APPROVAL IS NOT A PREREQUISITE TO THE VALIDITY OR ENFORCEABILITY OF THIS AGREEMENT AND NO SOLICITATION OF ANY
          SUCH WRITTEN APPROVAL BY OR ON BEHALF OF YOU SHALL BE CONSTRUED AS AN INFERENCE TO THE CONTRARY. IF YOU HAVE ORDERED THIS PLATFORM
          AND SUCH ORDER IS CONSIDERED AN OFFER BY YOU, THE COMPANY’S ACCEPTANCE OF YOUR OFFER IS EXPRESSLY CONDITIONAL ON YOUR ASSENT TO
          THE TERMS OF THIS AGREEMENT, TO THE EXCLUSION OF ALL OTHER TERMS. IF THESE TERMS ARE CONSIDERED AN OFFER BY THE COMPANY, YOUR
          ACCEPTANCE IS EXPRESSLY LIMITED TO THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE WITH ALL THE TERMS OF THIS AGREEMENT, YOU MUST
          RETURN THIS PLATFORM WITH THE ORIGINAL PACKAGE AND THE PROOF OF PAYMENT TO THE PLACE YOU OBTAINED IT FOR A FULL REFUND.{' '}
        </p>
        <h2>Definitions:</h2>
        <ul>
          <li>
            “Content" means information and data related to the Customer’s use of the Platform, the Customer’s network architecture and
            layout, the Platform’s functions and processes as carried out on the Customer’s network and security threats in the Customer’s
            network that the Platform has detected.
          </li>
          <li>
            ”Documentation" means the technical specifications, user-guides and tutorials associated with the Platform, as provided by the
            Company.
          </li>
          <li>
            “Fees" means, collectively, the License Fees and the Annual Maintenance &amp; Support Services fee, as specified in Your
            Purchase Order.
          </li>
          <li>
            “Maintenance &amp; Support Services" means the provision of technical assistance and Platform updates, upgrades and
            enhancements, provided to Customers that choose to subscribe to such supplementary services, all as set forth in section 11
            below.
          </li>
          <li>
            “Marks" means all trademarks, service marks, logos, insignia or any other designation of source or origin, whether registered or
            not.
          </li>
          <li>“Output Reports" means the findings regarding network security threats that the Platform has detected.</li>
          <li>
            ”Platform" means the Company’s proprietary automated security assessment and anti-spear phishing training platform, and the
            Documentation.
          </li>
          <li>“Purchase Order" means that purchase order submitted by You or by the Organization to Our Value Added Reseller.</li>
        </ul>
        <p>
          The term “including”, means including, but not limited to, and without limitation, to the generality of the preceding phrase. All
          examples in the Agreement and all "i.e." and "such as" notations, indicate an illustration, by way of example only, of the
          preceding phrase, without limiting its generality. Terms defined in the Order Form shall have the same meaning as defined there
        </p>
        <h2>1. License; Use of the Platform;</h2>
        <p>
          License. Subject to the terms of this Agreement, We grant only to the Customer a limited, non-exclusive, non-transferable, and
          non-sublicensable right, during the Term of this Agreement, to install and use the Platform in accordance with the relevant end
          user documentation provided by the Company for the Platform (the "License") provided that such right may be deployed only
          internally, within your Organization. You have no right to receive, use or examine any source code or design documentation
          relating to the Platform. Your limited right to install and use the Platform shall automatically expire upon termination or
          cancellation of this Agreement by the Company in accordance with Section 4.3 below.
        </p>
        <h2>2. Output Reports.</h2>
        <p>
          You hereby assume sole and exclusive responsibility: (i) for all acts or omissions performed by You in response to the Output
          Reports; (ii) to thoroughly review the Output Reports frequently, check for any alerts or warnings issued by the Platform, address
          the findings specified in the Output Reports and determine what actions are appropriate in light thereof and (iii) to carry out
          the actions which You deem appropriate as a result of the Output Reports.
        </p>
        <h2>3. Restrictions</h2>
        <p>
          You may not, by yourself or through others, use or permit the use of the Platform by more than such number of users agreed upon,
          unless the scope of the license is specifically extended, by express prior authorization of the Company, for additional users, and
          the corresponding additional license fees are paid.
        </p>
        <p>
          Without prejudice to any other right the Company has under this Agreement or under law, the Company may employ technological
          measures to detect and prevent fraudulent or unauthorized use of the Platform or parts thereof. The Company may revoke your
          license to use the platform and without prior notice, if the company, at its discretion, deems your use of the platform to be
          fraudulent or unauthorized.
        </p>
        <p>
          You may not, by yourself or through others: Modify the Platform in any manner not clearly and conspicuously instructed by the
          Company or documented in the Documentation; Make derivative works of, disassemble, de-compile or reverse engineer any part of the
          Platform; Redistribute or sublicense the Platform, or any part thereof, to any other party, under OEM or other arrangements;
          Develop, or create, or permit others to develop or create, a product or service similar to or in competition with, the Platform;
          Collect, harvest, obtain or process information about the Platform itself, or about other users of the Platform.
        </p>
        <h2>You may not engage in or attempt to engage in:</h2>
        <ul>
          <li>Any form of testing, scanning, scraping, probing, robotic navigating, bulk extracting or hacking of the Platform;</li>
          <li>Breaching the security of the Platform or identifying any security vulnerabilities thereof;</li>
          <li>
            Interfering with, circumventing, manipulating, impairing or disrupting the operation, or the functionality of the Platform;
          </li>
          <li>Working around or circumventing any technical limitations in the Platform;</li>
          <li>
            Activities which may enable features or functionalities which are otherwise disabled, inaccessible or undocumented in the
            Platform.
          </li>
          <li>
            You may not modify or alter the Marks or any other material covering the Marks provided by the Company, without The Company’s
            express prior written approval.
          </li>
          <li>You agree not to allow others to use the Platform and You will not use the Platform for the benefit of third parties.</li>
          <li>
            The Platform is licensed to You based on the applicable license configuration purchased. The license permits the use of the
            Platform in accordance with the type of system and the number of users indicated in Your Purchase Order and any other term set
            forth herein and/or in any applicable purchase order. Without derogation from any applicable laws, it is a violation of this End
            User License Agreement to create or design any hardware, software or system which alters any component of the Platform with the
            intent or resulting effect, of circumventing the license provided herein.
          </li>
          <li>
            YOU MAY NOT USE THE PLATFORM FOR ANY ACTIVITY THAT CONSTITUTES, OR ENCOURAGES CONDUCT THAT WOULD CONSTITUTE, A CRIMINAL OFFENSE,
            GIVE RISE TO CIVIL LIABILITY OR OTHERWISE VIOLATE ANY APPLICABLE LAW OR INDUSTRY STANDARD, INCLUDING ANY APPLICABLE LAWS AND
            REGULATIONS GOVERNING COMPUTER HACKING, PRIVACY AND EXPORT CONTROL.
          </li>
          <li>
            “Open Source Software” shall mean software licensed under terms that require You to make modifications to the Open Source
            Software or any software that You combine with the Open Source Software freely available in source code form. You shall not use
            Open Source Software, including software licensed under the GNU General Public License (“GPL”), the Mozilla Public License
            ("MPL") or the IBM Public License ("IPL"), in the creation of interfaces or in any other way together with or in combination
            with the Platform or any part thereof if such use may: (i) create, or purport to create, obligations of the Company with respect
            to the Platform; (ii) grant, or purport to grant, to any third party any rights to, waivers of or immunities under the Company’s
            intellectual property or proprietary rights; and/or (iii) cause, or be interpreted or asserted to cause, the Platform in whole
            or in part or any modifications or enhancements thereof to become subject to the terms of the GPL, MPL or IPL or other Open
            Source Software license.
          </li>
        </ul>
        <h2>4. Title and Intellectual Property</h2>
        <ul>
          <li>
            All rights, title and interest in and to the Platform, including, patents, copyrights, trademarks, trade names, service marks,
            trade secrets and other intellectual property rights, and any goodwill associated therewith, are owned by or licensed to the
            Company. Other than what is expressly granted by this Agreement, the Company does not grant any other rights to patents,
            copyrights, trademarks (whether registered or unregistered), trade names, trade secrets, domain names or any other rights,
            functions, licenses or content with respect to, or in connection with, the Platform.
          </li>
          <li>
            Nothing in this Agreement constitutes a waiver of the Company’s intellectual property rights under any law. The licenses for the
            Platform granted pursuant to this Agreement gives You a limited right to use the Platform, but does not constitute a sale of the
            Platform.
          </li>
          <li>
            ANY USE OF THE PLATFORM IN VIOLATION OF THE LIMITED LICENSE GRANTED HEREUNDER OR RESTRICTIONS IMPOSED IN THIS AGREEMENT MAY
            RESULT IN THE REVOCATION OF THE LICENSE AND MAY EXPOSE YOU TO CLAIMS FOR DAMAGES. IF THE COMPANY DETERMINES THAT THE PLATFORM
            HAS BEEN USED IN VIOLATION OF THE LIMITED LICENSE GRANTED HEREUNDER OR RESTRICTIONS IMPOSED IN THIS AGREEMENT, YOU WILL, AT THE
            COMPANY’S REQUEST, COMPENSATE THE COMPANY FOR EACH YEAR OR PART THEREOF DURING WHICH THE VIOLATION WAS COMMITTED, IN AN AMOUNT
            EQUAL TO FIVE (5) TIMES THE LICENSE FEE THAT WITH WHICH THE VIOLATION IS ASSOCIATED, HAVE PAID. PAYMENT OF THIS LIABILITY FEE
            DOES NOT REPLACE THE COMPANY’S RIGHTS TO OTHER REMEDIES OR DAMAGES AWARDED BY A COMPETENT COURT OR ARBITRATION PROCESS. THE
            PARTIES AGREE AND ACKNOWLEDGE THAT THE ABOVE AMOUNT CONSTITUTES A SOUND AND BALANCED APPROXIMATION OF THE DAMAGES THE COMPANY IS
            LIKELY TO SUFFER IN SUCH A CASE.
          </li>
          <li>You agree to take all reasonable steps to prevent unauthorized copying, use or disclosure of the Platform.</li>
          <li>
            All goodwill arising out of any use of a Party’s Marks, by the other Party, will inure solely to the benefit of the proprietor
            Party. Each Party agrees that it will not engage or participate in any activity or course of action that dilute, diminishes or
            tarnishes the image or reputation of the other Party or its Mark.
          </li>
        </ul>
        <h2>5. Confidentiality</h2>
        <ul>
          <li>
            In the course of its operation, the Platform may transmit the Content to the Company. The Company may use the Content for the
            following purposes:
          </li>
          <li>
            To perform its obligations under this Agreement, improve and customize the Platform and the Maintenance &amp; Support Services;
          </li>
          <li>
            To collect Fees, to conduct administrative activities necessary to maintain and provide the Platform and the Maintenance &amp;
            Support Services, enforce this Agreement, take any action in any case of dispute, or legal proceeding of any kind, involving You
            with respect to the Platform, and prevent fraud, misappropriation, infringements, identity theft and other illegal activities
            and misuse of the Platform;
          </li>
          <li>
            If the Company is required, or reasonably believes that it is required, by law, to share or disclose the Content, or if such
            sharing or disclosure is required pursuant to a subpoena, order, or decree issued by a competent judicial or administrative
            authority, provided that, to the extent legally permitted, the Company will give You prompt notice of the requirement prior to
            such disclosure, to allow it, at its exclusive cost and expense, to intervene and protect its interests in the Content, should
            the Customer so desire;
          </li>
          <li>
            Derive information and analyses of security issues, from the Content, in a manner that does not identify You, and use such
            derived information and analyses for any purpose, including commercial purposes. You will not be entitled to any remuneration
            from the Company, for the Company’s use of such derived data or analyses.
          </li>
          <li>
            Subject to the foregoing, We will take precautions to maintain the confidentiality of the Content. We will not use or disclose,
            or have the Content used or disclosed, except as described above, or otherwise subject to Your express, prior, written
            permission. Our personnel will access the Content on a strict 'need to know' basis, subject to the terms herein.
          </li>
          <li>
            You hereby agree that, if We deem that We cannot remotely access the Content, then You will grant Us and Our representatives,
            during normal business hours, such physical access to Your computer network as may be necessary for Us to adequately obtain the
            Content;
          </li>
          <li>
            You acknowledge that the Platform and all elements thereof, including without limitation, its design, structure, capabilities,
            functionality, payment details and Documentation, constitute Our confidential information. Accordingly, You must treat all such
            information as confidential material in a manner no less protective than You use to protect Your own similar assets, but in no
            event less than reasonable care. Without derogating from the foregoing, You will maintain in strict confidentiality any
            information regarding the Platform's functionality, capabilities, structure, design and all other details related thereto, any
            of Our business practices, tutorials and training material, and will not disclose them, or have them disclosed, directly or
            indirectly to any third party without Our prior written consent.
          </li>
          <li>
            Notwithstanding the foregoing, you may, disclose confidential information, only if and to the extent such disclosure is required
            in order to comply with a legal obligation including, orders, subpoenas, decrees or request prescribed by a competent judicial,
            administrative or regulatory authority, provided that, to the extent legally permitted, you promptly notify Us of such legal
            obligation, to give Us an opportunity to challenge the legally required disclosure.
          </li>
        </ul>
        <h2>6. Customer Data</h2>
        <ul>
          <li>
            Customer hereby acknowledges and agrees that Company is acting as a data processor and will use any data (including personal
            data) supplied or disclosed to it by Customer, or processed through the Platform (“Customer Data”) only in accordance with
            Customer's instructions in performing its obligations under this Agreement. Company will implement appropriate technical and
            organizational measures to protect the Customer Data provided by Customer against unauthorized or unlawful processing and
            against accidental loss, destruction, damage, alteration or disclosure of the Customer Data.
          </li>
          <li>
            Customer hereby warrants and represents that it will provide all appropriate notices, obtain all appropriate informed consents,
            comply at all times with all applicable privacy and data protection laws and regulations (including the EU General Data
            Protection Regulation (“GDPR”)) and industry guidelines to which Customer is subject, for allowing Company to use the Customer
            Data in accordance with this Agreement (including, without limitation, the provision of such data to Company, the transfer of
            such data by Company to its affiliates and subcontractors, including transfers outside of the European Economic Area).
          </li>
          <li>
            To the extent that Customer is subject to the GDPR, Customer shall sign the Company's Data Processing Agreement (“DPA”) attached
            hereto as Exhibit A.
          </li>
          <li>
            In the event Customer fails to comply with any data protection or privacy law or regulation, the GDPR and/or any provision of
            the DPA, and/or fails sign the DPA, then: (a) to the maximum extent permitted by law, Customer shall be fully liable for any
            such breach, violation, infringement and/or processing of Customer Data without a DPA by Company and Company's affiliates and
            subsidiaries (including, without limitation, their employees, officers, directors, subcontractors and agents); (b) in the event
            of any claim of any kind related to any such breach, violation or infringement and/or any claim related to processing of
            Customer without a DPA, Customer shall defend, hold harmless and indemnify Company and Company's affiliates and subsidiaries
            (including, without limitation, their employees, officers, directors, subcontractors and agents) from and against any and all
            losses, penalties, fines, damages, liabilities, settlements, costs and expenses, including reasonable attorneys' fees; and (c)
            the limitation of Customer’s liability under Section 10 below shall not apply in connection with Sections 7.4(a) and 7.4(b)
            above.
          </li>
        </ul>
        <h2>7. Term and Termination</h2>
        <ul>
          <li>
            This Agreement is affective until the termination thereof as set forth herein. The Company may terminate this Agreement at any
            time upon Your breach of any of the provisions hereof (the "Term"). Upon termination of this Agreement, You agree to cease all
            use of the Platform and to return the Platform and all documentation and related materials in Your possession to the Company.
            For the removal of any doubt, no refunds or any portion thereof will be made. Except for the License granted herein and as
            expressly provided herein, the terms of this Agreement shall survive termination.
          </li>
        </ul>
        <h2>8. Limited Warranty, Warranty Disclaimers</h2>
        <ul>
          <li>
            The Company does not warrant that Your use of the Platform will be error-free or uninterrupted. The Company warrants that,
            during the Term of this Agreement and subject to full and timely payment of applicable Annual Maintenance &amp; Support Services
            fees by You, the Company will, at its own expense, as its sole obligation, and as Your exclusive remedy: (i) maintain the
            Platform with certain periodic releases pursuant to the scope of maintenance and support services agreed between You and the
            Company; and (ii) make efforts to correct errors in the Platform reported to the Company by You, and respond to inquiries, in
            the manner, time frames and scope agreed upon between the parties.
          </li>
          <li>
            EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, THE COMPANY HEREBY DISCLAIMS ALL OTHER WARRANTIES EXPRESS, IMPLIED, OR
            STATUTORY, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY
            OF THE OUTPUT REPORTS, PLATFORM INTEGRATION, MAINTENANCE &amp; SUPPORT SERVICES, AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS.
            WITHOUT LIMITING THE FOREGOING, THE COMPANY SPECIFICALLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES THAT THE OUTPUT REPORTS,
            THE COMPANY’S MAINTENANCE &amp; SUPPORT SERVICES, OR THE PLATFORM WILL MEET YOUR REQUIREMENTS OR FULFILL ANY OF YOUR NEEDS. TO
            THE EXTENT THE COMPANY MAY NOT, AS A MATTER OF APPLICABLE LAW, DISCLAIM ANY WARRANTY, THE SCOPE AND DURATION OF SUCH WARRANTY
            SHALL BE THE MINIMUM PERMITTED UNDER SUCH LAW.
          </li>
          <li>
            You acknowledge that the Platform relies on network, infrastructure, hardware and software, partly managed and operated by
            others. The Company does not warrant that the Platform will operate in an uninterrupted or error-free manner, or that it will
            always be available, free from errors or omissions, malfunctions, bugs or failures, including hardware failures, software
            failures and software communication failures. If the Company receives notice of any Platform failure or malfunction, or if the
            Company identifies them by itself, the Company will endeavor to regain the Platform’s full availability, pursuant to section 11
            below. However, such incidents will not be considered a breach of this Agreement, and the Company shall be excused from the
            performance of its obligations under this Agreement, insofar as the performance is prevented, or interfered with, due to acts by
            third parties, or conditions beyond the Company’s reasonable control.
          </li>
          <li>
            Exclusions: Notwithstanding anything herein to the contrary, the Company’s warranty obligations above, and its obligations
            pursuant to Section 11, will not apply, in any of the following events:
          </li>
          <li>
            The non-performance was caused by Your acts or omissions or anyone on your behalf, which are not compliant with the permitted
            use of the Platform under this Agreement.
          </li>
          <li>
            The Platform has been modified, repaired or altered by anyone other than the Company, or in the event that customizations
            thereto have been developed and/or changes thereto have been introduced by anyone other than the Company, or without the
            Company’s prior written approval; or
          </li>
          <li>
            The Platform has been combined with, or installed on or with, irregular software or equipment, not authorized by the Company.
          </li>
          <li>
            The version of the Platform at the time of the event setting the base for the warranty claim was not the most recent Up-to-date
            Version, provided that you, decline d Automated Update and We have notified You that such Up-to-date Version is available.
          </li>
          <li>
            The Company will assume no liability whatsoever for damages incurred or sums paid by You, in connection with any fault by You or
            any third party’s harmful components impacting Your computer network (such as computer viruses, worms, computer sabotage, or
            “denial of service” attacks).
          </li>
        </ul>
        <h2>9. Limitation of Liability</h2>
        <ul>
          <li>
            EXCEPT FOR ANY BREACH OF SECTION 5, 6 OR 7, OR EACH PARTY’S OBLIGATIONS UNDER SECTION 10, IN NO EVENT WILL EITHER PARTY BE
            LIABLE TO THE OTHER PARTY WITH RESPECT TO THE SUBJECT MATTER OF THIS AGREEMENT FOR ANY INDIRECT, SPECIAL, EXEMPLARY, STATUTORY,
            INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING DAMAGES FOR LOSS OF DATA OR PROFITS, INABILITY TO USE THE PLATFORM OR RELIANCE
            UPON THE OUTPUT REPORTS, WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, EVEN IF SUCH PARTY HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. EXCEPT FOR ANY BREACH OF SECTION 5 OR 6 OR EACH PARTY’S OBLIGATIONS UNDER
            SECTION 10, THE COMPANY’S ENTIRE LIABILITY ARISING FROM THIS AGREEMENT WILL NOT EXCEED AN AMOUNT EQUAL TO THE AGGREGATE FEES
            PAID BY YOU TO THE COMPANY, PURSUANT TO THIS AGREEMENT, IN THE 12 MONTHS PRECEDING THE EVENT PURPORTEDLY GIVING RISE TO THE
            LIABILITY.
          </li>
          <li>
            The Company’s sole liability and your exclusive remedy, for any claims regarding the Platform’s performance, availability,
            errors or malfunctions, is the performance of the Maintenance &amp; Support Services pursuant to section 11 below.
          </li>
          <li>
            The Company has no responsibility or liability in respect to the Your reliance upon, or use of, the Output Reports, any actions
            or omission You perform in connection with the Output Reports, or any consequences resulting therefrom.
          </li>
        </ul>
        <h2>10. Indemnification</h2>
        <ul>
          <li>
            The Company Indemnity. The Company shall indemnify, defend and/or settle, and hold You and Your directors, officers and
            employees, harmless from and against any and all liabilities, losses, damages, costs and expenses (including reasonable
            attorneys’ and experts’ fees) incurred by them pursuant to any third party claim brought against them that the Platform
            infringes any valid copyright, patents, trademarks or trade secrets. The Company shall have no obligation under this section
            with respect to any claim based upon or arising from (A) modification of the Platform in any manner not expressly permitted in
            the Documentation, by any person or entity other than the Company, if an infringement claim would have been avoided but for the
            modification; or (B) any use of the Platform, outside the scope of the license granted in, or contrary to, the provisions of
            this Agreement; or (C) the Output Reports or (D) the combination of the Platform with any other service or product. In the event
            that the Platform is held to, or the Company believes is likely to, be held to infringe any intellectual property right of a
            third party, the Company may, at its own expense, (x) modify or substitute the Platform so that it is no longer infringing but
            retains substantially similar features and functionality; (y) obtain for You a license to continue using the Platform as
            contemplated herein; or (z) if (x) and (y) are not reasonably practicable, terminate this Agreement as to the infringing
            Platform.
          </li>
          <li>
            Your Indemnity. You shall indemnify, defend and/or settle, and hold the Company and its directors, officers, employees, and
            agents, harmless from and against any and all liabilities, losses, damages, costs and expenses (including reasonable attorneys’
            and experts’ fees) incurred by the Company pursuant to any third party claim brought against the Company to the extent such
            claim results from or relates to: (A) modification of the Platform in any manner not expressly permitted in the Documentation,
            by any person or entity other than the Company, if an infringement claim would have been avoided but for the modification; or
            (B) any use of the Platform, outside the scope of the license granted in, or contrary to, the provisions of this Agreement; (C)
            the combination of the Platform with any other service or product, or (D) any other breach of Your undertakings or covenants
            hereunder.
          </li>
          <li>
            Indemnity Procedure. The foregoing indemnification obligations are dependent upon the Party seeking indemnification
            (“Indemnitee”) providing the indemnifying party (“Indemnitor”) with (i) prompt written notice of any indemnifiable claim; (ii)
            all reasonable assistance and cooperation in the defense of such indemnifiable claim and any related settlement negotiations, at
            the Indemnitor’s expense; and (iii) exclusive control over the defense of such indemnifiable claim and any related settlement
            negotiations. Indemnitee will not settle or compromise any such claim without the Indemnitor’s prior written consent, which will
            not be unreasonably withheld or delayed. The Indemnitee will have the right, at its own expense, to participate in the defense
            (and related settlement negotiations) of any indemnifiable claim with counsel of its own selection.
          </li>
        </ul>
        <h2>11. Maintenance &amp; Support Services</h2>
        <ul>
          <li>
            During the Term, and subject to the terms and conditions of this agreement, and Your full and timely payment to the Company of
            all applicable Fees, We and/or Our Value Added Reseller, will provide you technical support for questions, problems and
            inquiries regarding the Platform, pursuant to the support scheme, hours and channels agreed upon between You and the Company
            (directly and/or through Our Value Added Reseller). We will endeavor to respond to support requests within a reasonable time,
            and provide a reasonable resolution to your question, problem or inquiry.
          </li>
          <li>
            The Company may, upon Your prior consent, remotely send and automatically install on the Platform – updates, upgrades, code
            modifications, enhancements, bug fixes, improvements, and any other form of code or settings changes in, or to, the Platform
            (each an "Automated Update"). Such installation may change the layout, design or display of the Platform (an "Up-to-date
            Version"). Should You decline an Automated Update, You shall have the ability to install such Automated Update manually by
            downloading such Up-to-date Version from Our website, provided that You are subscribed to the Maintenance &amp; Support Services
            and provide further that Your license is still in effect.
          </li>
          <li>
            The Company will provide the technical support services agreed upon, only if You have subscribed to such Maintenance &amp;
            Support Services. In any event, the Company’s performance of its obligations in this respect, is conditioned upon Your full and
            timely payment of all applicable monthly subscription Fees for Maintenance &amp; Support Services.
          </li>
          <li>
            The Company may discontinue the provision of Maintenance &amp; Support Services (and, accordingly, discontinue collecting the
            respective monthly subscription Fees for Maintenance &amp; Support Services), through prior notice to You of no less than thirty
            (30) days.{' '}
          </li>
        </ul>
        <h2>12. General</h2>
        <p>
          Injunctive Relief. In the event of breach of this Agreement or the License hereunder, You hereby acknowledge that such a breach
          may cause irreparable harm to the Company for which monetary or other damages may not be an adequate remedy, and therefore, in
          addition to any other legal or equitable remedies, The Company will be entitled to seek an injunction or other equitable remedy
          against such breach. You may not assign this Agreement, or any of its rights and obligations hereunder, without Our express prior
          written consent. Notwithstanding the aforesaid, We are entitled, without the need for any consent, to assign this Agreement, in
          its entirety, or any of its rights and obligations hereunder, to its affiliates, or upon a merger, acquisition, change of control
          or the sale of all or substantially all of its equity or assets relating to this Agreement. With such assignment of the entire
          Agreement, the Company is released from all its duties, liabilities and obligations under this Agreement, except its
          confidentiality obligations. Any purported assignment by You, except as provided above, will be null and void.
        </p>
        <h2>Governing Law and Jurisdiction.</h2>
        <p>
          This Agreement and any dispute related thereto or in connected therewith, will be exclusively governed by, and construed in
          accordance with, the laws of the State of Delaware, without regard to its conflicts of law principles which may result in the
          application of provisions of law other than those of Delaware. In such a case, the sole and exclusive personal jurisdiction and
          venue for any legal proceedings in connection with this Agreement will be in the competent courts located in Delaware. Third party
          Software. Certain third parties software may be provided with the Platform for use in connection with the Platform subject to the
          licenses of their respective proprietors. The third parties software may be used only in connection with the Platform. The
          provisions of this Agreement shall apply to all third party software provided and to third party software as if they were the
          Platform and the Company, respectively.
        </p>
        <h2>Complete Terms and Severability.</h2>
        <p>
          This Agreement constitutes the entire and complete agreement between you and us concerning any use of, or in connection with, the
          Platform. This Agreement supersedes all prior oral or written statements, understandings, negotiations and representations with
          respect to the subject matter herein. If any provision of this Agreement is held invalid or unenforceable, that provision must be
          construed in a manner consistent with the applicable law to reflect, as nearly as possible, the original intentions of the
          parties, and the remaining provisions will remain in full force and effect. This Agreement may be modified or amended only in
          writing, signed by the duly authorized representatives of both parties.
        </p>
        <p>
          No Waiver of Rights and Remedies. Neither Party will, by mere lapse of time, without giving notice thereof, be deemed to have
          waived any breach by the other Party of any terms or provisions of this Agreement. A waiver by either Party, of any breach, will
          not be construed as a waiver of subsequent breaches or as a continuing waiver of such breach.
        </p>
      </div>
    </section>
  </div>
)

const styles = theme => ({
  root: {
    width: '100%'
  }
})

export default compose(withStyles(styles))(Module)
