/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import PhoneNumbers from 'components/PhoneNumbers/PhoneNumbers'
import PhoneNumberBuy from 'components/PhoneNumbers/PhoneNumberBuy'
import PhoneNumberSearch from 'components/PhoneNumbers/PhoneNumberSearch'
import PhoneNumberUpdate from 'components/PhoneNumbers/PhoneNumberUpdate'
import Calls from 'components/PhoneNumbers/Calls'
import Messages from 'components/PhoneNumbers/Messages'

export default ({ match }) => (
  <Container maxWidth="xl">
    <Box my={2}>
      <Switch>
        <Route path={`${match.path}/search`} component={PhoneNumberSearch} />
        <Route path={`${match.path}/:phoneNumber/calls`} component={Calls} />
        <Route path={`${match.path}/:phoneNumber/messages`} component={Messages} />
        <Route exact path={`${match.path}`} component={PhoneNumbers} />
      </Switch>
      <Route path={`${match.path}/buy`} component={PhoneNumberBuy} />
      <Route exact path={`${match.path}/:phoneNumberId/update`} component={PhoneNumberUpdate} />
    </Box>
  </Container>
)
