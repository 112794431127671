/** @format */

import React, {useState} from 'react'
import _ from 'lodash'
import Avatar from 'lib/Avatar'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import createApolloClient from 'apollo'
const client = createApolloClient(window.__APOLLO_STATE__)


export default ({ input, fld, label, query, variables={}, type = 'text', meta: { touched, invalid, error }, multiple = false, ...rest }) => {
  const [options, setOptions] = useState(null)
  let value = input.value
  const loadOptions = (inputValue, callback) => {
    if(input.value && !inputValue)
      inputValue = input.value
    if(inputValue.length<3)
      inputValue=''
    client.query({query, variables: _.merge(variables, {selector: true, name: inputValue})}).then(r=>{
      let res = r.data[fld].data.map(c=>({label: <span><Avatar avatar={c.logo || c.thumbnail || c.avatar} size={20} style={{float:'left'}} round /> &nbsp;<strong>{c.name}</strong> ({c.website}) {c.phone?`tel: ${c.phone}`:''} {c.email?`email: ${c.email}`:''} </span>, value: c.id}))
      callback(res)
      setOptions(res)
    })
  }

  return (
   <div>
     {label && <label style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.85rem', marginBottom: '0.25rem' }}>{label}</label>}
     <AsyncSelect
       isMulti={multiple}
       loadOptions={loadOptions}
       defaultOptions
       value={value?_.find(options, {value}):null}
       onChange={(a)=>input.onChange(a.value)}
       styles={{menu: (provided, state) => ({
         ...provided,
         backgroundColor: 'white !important',
         background: 'white !important',
         zIndex: '999999999 !important'
       })}}
     />
   </div>
  )
}
