/** @format */

import React from 'react'
import { Field } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required } from 'lib/form/validators'

import { countriesOptions, currencyOptions, accountHolderTypeOptions } from 'data/options'

const BankAccount = () => {
  return (
    <React.Fragment>
      <Field name="country" component={TextField} label="Country" validate={[required()]} options={countriesOptions} select />
      <Field name="currency" component={TextField} label="Currency" validate={[required()]} options={currencyOptions} select />
      <Field name="account_number" component={TextField} label="Account Number" validate={[required()]} />
      <Field name="routing_number" component={TextField} label="Routing Number" />
      <Field name="account_holder_name" component={TextField} label="Account Holder Name" />
      <Field
        name="account_holder_type"
        component={TextField}
        label="Account Holder Type"
        validate={[required()]}
        options={accountHolderTypeOptions}
        select
      />
    </React.Fragment>
  )
}

BankAccount.defaultProps = {
  name: 'source'
}

export default BankAccount
