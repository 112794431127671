import React from 'react'
import moment from "moment/moment"
import CheckboxTable from 'lib/tables/CheckboxTable'

import { getExperiments, createExperiment, removeExperiment } from 'api/experiments'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { Field, FormDialog } from 'lib/form'
import { TextField } from 'lib/form/fields'
import { required } from 'lib/form/validators'
import IconButton from '@material-ui/core/IconButton'
import { Mutation } from 'react-apollo'
import withConfirm from 'lib/withConfirm'

const Experiments = ({ match, location, history, confirm }) => {
  const [dialog, setDialog] = React.useState(false)

  return (
    <React.Fragment>
      <div className="p-4">
        <h3>Split Tests (Experiments)</h3>
        <Mutation mutation={removeExperiment} refetchQueries={['getExperiments']}>
          {(remove, { loading: loadingRemove }) => (
            <div>
            {dialog &&
              <FormDialog
                title="Create Experiment"
                form="ExperimentCreate"
                mutation={[createExperiment, { refetchQueries: ['getExperiments'] }]}
                initialValues={{ }}
                onCancel={()=>setDialog(false)}
                onComplete={({data: { createExperiment }})=> {
                  history.push(`${match.url}/${createExperiment.id}/edit`)
                }}
                btnSubmitText="Create"
                success="Experiment created successfully"
              >
                {({ doc, invalid, submitting }) => (
                  <React.Fragment>
                    <Field name="name" component={TextField} label="Experiment Name" validate={[required()]} />
                    <Field name="hypothesis" component={TextField} multiline rows={2} label="Experiment Hypothesis" />
                    <Field name="description" component={TextField} multiline rows={4}  label="Experiment Description" />
                  </React.Fragment>
                )}
              </FormDialog>
            }

            <CheckboxTable
              query={getExperiments}
              initialVariables={{ sorted: [{ id: 'createdAt', desc: true }] }}
              variables={{ }}
              actions={[
                <Button key="create" variant="contained" color="primary" onClick={() => setDialog(true)}>
                  <Icon className="mr-1">add</Icon>
                  Create Experiment
                </Button>
              ]}
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                  id: 'name',
                  width: 300,
                  Cell: ({ original: item }) => (
                    <div>
                      <div><strong>{item.name}</strong></div>
                      <div><small>{item.hypothesis}</small></div>
                    </div>
                  )
                },
                {
                  sortable: true,
                  filterable: false,
                  Header: 'Creation Date',
                  accessor: 'eventDate',
                  width: 300,
                  Cell: ({ original: item }) => (
                    <span>
                      {moment(item.createdAt).format('LLL')}
                    </span>
                  )
                },
                {
                  sortable: true,
                  filterable: false,
                  Header: 'Variations #',
                  accessor: 'variations',
                  Cell: ({ original: item }) => (
                    <strong>
                      {(item.variations || []).length}
                    </strong>
                  )
                },
                {
                  sortable: true,
                  filterable: false,
                  Header: 'Action',
                  accessor: 'actionType',
                  Cell: ({ original: experiment }) => (
                    <div>
                      <IconButton
                        onClick={() => history.push(`${match.url}/${experiment.id}/edit`)}
                        title="Edit Experiment"
                        disabled={loadingRemove}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </IconButton>
                      <IconButton
                        onClick={() => history.push(`${match.url}/${experiment.id}/view`)}
                        title="View Experiment"
                        disabled={loadingRemove}
                      >
                        <Icon fontSize="small">zoom_in</Icon>
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={confirm(() => remove({ variables: { id: experiment.id } }), {
                          title: 'Experiment Removal',
                          description: 'Are you sure you want to delete this Experiment?'
                        })}
                        disabled={loadingRemove}
                        title="Remove Employee"
                      >
                        <Icon fontSize="small">delete</Icon>
                      </IconButton>
                    </div>
                  )
                }
              ]}
              deleteMany={false}
            />
            </div>
        )}
        </Mutation>
      </div>
    </React.Fragment>
  )
}

export default withConfirm(Experiments)