/** @format */

import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import deepOrange from '@material-ui/core/colors/deepOrange'
//import grey from '@material-ui/core/colors/grey'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

export default () => {
  const theme = createMuiTheme({
    drawerWidth: 240,
    typography: {
      htmlFontSize: 18
    },
    palette: {
      primary: {
        main: '#182ce2'
      },
      success: {
        main: green[500],
      },
      secondary: deepOrange /*{
        main: '#7157f3'
      }*/,
      error: red,
      background: {
        default: '#f5f5f5'
      }
    }
  })

  return responsiveFontSizes(theme)
}
