/** @format */

import React from 'react'
import { Route } from 'react-router-dom'
import HelpIcon from '@material-ui/icons/Help'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Sources from 'components/Sources'
import Invoices from 'components/Invoices'

const Billing = ({ match }) => (
  <React.Fragment>
    <Route
      path={`${match.path}`}
      render={props => (
        <React.Fragment>
          <div>
            {' '}
            <p>
              If you have questions use the live chat to ask your question.
              <a className="beacon-help-link" href="#" data-beacon-article-sidebar="5e49776a04286364bc9568f5">
                {' '}
                Get help about this page here
              </a>{' '}
            </p>
          </div>
          <Sources {...props} />
          <Invoices {...props} />
        </React.Fragment>
      )}
    />
  </React.Fragment>
)

export default Billing
