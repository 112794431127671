/** @format */

//import { successColor, tooltip, cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'

//import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx'
//import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'

const dashboardStyle = theme => ({
  white: {
    color: 'white',
    borderColor: 'white',
    '&:before': {
      borderColor: 'white'
    },
    '&:after': {
      borderColor: 'white'
    }
  },
  chip: {
    fontSize: '1.2em',
    margin: '8px'
  },
  chipLabel: {
    background: 'linear-gradient(60deg, #ffa726, #fb8c00) !important',
    color: 'white'
  },
  card: {
    margin: 0
  },
  cardTitle: {
    color: '#999999',
    marginTop: '0px',
    marginBottom: '3px'
  },
  cardIconTitle: {
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardProductTitle: {
    marginTop: '0px',
    marginBottom: '3px',
    textAlign: 'center'
  },
  cardCategory: {
    color: '#999999',
    fontSize: '14px',
    paddingTop: '10px',
    marginBottom: '0',
    marginTop: '0',
    margin: '0',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },
  cardProductDesciprion: {
    textAlign: 'center',
    color: '#999999'
  },
  stats: {
    color: '#999999',
    fontSize: '12px',
    lineHeight: '22px',
    display: 'inline-flex',
    [theme.breakpoints.down('lg')]: {
      fontSize: '9px'
    },
    '& svg': {
      position: 'relative',
      top: '4px',
      width: '16px',
      height: '16px',
      marginRight: '3px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      top: '4px',
      fontSize: '16px',
      marginRight: '3px'
    }
  },
  productStats: {
    paddingTop: '7px',
    paddingBottom: '7px',
    margin: '0'
  },
  successText: {
    color: 'green'
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: '17px',
    height: '17px'
  },
  price: {
    color: 'inherit',
    '& h4': {
      marginBottom: '0px',
      marginTop: '0px'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  gridItemHead: {
    padding: '0 !important'
  }
})

export default dashboardStyle
