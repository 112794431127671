/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Quiz from 'components/Quiz/Quiz'

const getLessonQuiz = gql`
  query getLessonQuiz($lessonId: String!) {
    lesson(id: $lessonId) {
      id
      name
      quiz(format: "json")
    }
  }
`

const LessonQuiz = ({ match }) => (
  <Query query={getLessonQuiz} variables={match.params}>
    {({ data: { lesson } = {}, loading }) =>
      !loading && <Quiz title={lesson.name} questions={JSON.parse(lesson.quiz)} onComplete={({ answers }) => console.log(answers)} />
    }
  </Query>
)

export default LessonQuiz
