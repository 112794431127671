import gql from 'graphql-tag'

export const listFragment = gql`
  fragment list on List {
    id
    name
  }
`

export const getLists = gql`
  query getLists($text: String, $userId: ID, $skip: Int, $limit: Int, $sort: [String]) {
    lists(text: $text, userId: $userId, skip: $skip, limit: $limit, sort: $sort) {
      data {
        id
        name
      }
      count
      pages
    }
  }
`

export const getList = gql`
  query getList($listId: ID!) {
    list(id: $listId) {
      id
      ...list
    }
  }
  ${listFragment}
`

export const createList = gql`
  mutation createList($data: ListInput!) {
    createList(data: $data) {
      id
      ...list
    }
  }
  ${listFragment}
`

export const updateList = gql`
  mutation updateList($listId: ID!, $data: ListInput!) {
    updateList(id: $listId, data: $data) {
      id
      ...list
    }
  }
  ${listFragment}
`

export const removeList = gql`
  mutation removeList($id: ID!) {
    removeList(id: $id) {
      id
    }
  }
`
