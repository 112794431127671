import EmailGuardianCosts from './EmailGuardianCosts'
import ReportCardResellerCosts from './ReportCardResellerCosts'
import EmailGuardianCostsSMB from './EmailGuardianCostsSMB'

import EmailGuardianGoals from './EmailGuardianGoals'
import EmailGuardianGoalsSMB from './EmailGuardianGoalsSMB'
import ReportCardResellerGoals from './ReportCardResellerGoals'

import EmailGuardianAcceptanceStatic from './EmailGuardianAcceptanceStatic'
import EmailGuardianAcceptanceStaticSMB from './EmailGuardianAcceptanceStaticSMB'
import ReportCardResellerAcceptanceStatic from './ReportCardResellerAcceptanceStatic'


const templates = {
  costTemplates: {
    EmailGuardianCosts,
    EmailGuardianCostsSMB,
    ReportCardResellerCosts
  },
  goalsTemplates: {
    EmailGuardianGoals,
    EmailGuardianGoalsSMB,
    ReportCardResellerGoals
  },
  acceptanceTemplates: {
    EmailGuardianAcceptanceStatic,
    EmailGuardianAcceptanceStaticSMB,
    ReportCardResellerAcceptanceStatic
  }
}

export default templates

