/** @format */

import React from 'react'
import { Field, FormSection, Company } from 'lib/form'
import { TextField, SelectField } from 'lib/form/fields'
import { required } from 'lib/form/validators'
import { getCompaniesShort } from 'api/companies'

import FormGroup from './FormGroup'

class CompanyInformation extends React.Component {
  static defaultProps = {
    name: ''
  }

  render() {
    const { doc = {}, companies = [] } = this.props
    const { company = {} } = doc

    return (
      <FormGroup title="Company">
        <Field name="companyId" component={SelectField} label="Company" fld="companies" query={getCompaniesShort} validate={[required()]} />
        {doc.companyId === 'new' && (
          <FormSection name="company">
            <Company company={company} />
          </FormSection>
        )}
      </FormGroup>
    )
  }
}

export default CompanyInformation
