/** @format */

import React from 'react'
import { Query } from 'react-apollo'
import { Form, Field } from 'lib/form'
import { TextField, Checkbox, CheckboxesGroup } from 'lib/form/fields'
import { required, length } from 'lib/form/validators'

import { getDataUpdatingProduct, updateProduct } from 'api/products'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import { userAccountTypeOptions, productFieldOptions } from 'data/options'
import {acl} from 'lib/acl'
import {numericality} from "../../lib/form/validators";

const ProductUpdate = ({ classes, match }) => (
  <Paper className="p-3">
    <Query query={getDataUpdatingProduct} variables={match.params}>
      {({ data: { product, wizards, courses } = {}, loading }) =>
        !loading && (
          <React.Fragment>
            <Form form="ProductUpdate" mutation={[updateProduct]} initialValues={product} success="Product updated successfully">
              {({ doc, submit, submitting }) => (
                <React.Fragment>
                  <Field name="name" component={TextField} label="Product Name" validate={[required(), length({ min: 2 })]} />
                  <Field name="description" component={TextField} label="Product Description" multiline />
                  <Field name="roles" component={TextField} label="All Supported Roles" options={userAccountTypeOptions} select multiple />
                  <Field name="checkoutPageUrl" component={TextField} label="Checkout Page URL" />
                  <Field name="redirectPageUrl" component={TextField} label="After Purchase Page URL" />
                  <Field
                    name="fields"
                    component={CheckboxesGroup}
                    label="Visible Fields"
                    options={productFieldOptions}
                    FormGroupProps={{ row: true }}
                  />
                  <Field name="allowLicenses" component={Checkbox} label="Allow Adding Licenses" />
                  <Field name="oneTime" component={Checkbox} label="One-time buy" />
                  {doc.oneTime && <Field name="setupFee" component={TextField} label="Setup Fee" validate={[required(), numericality()]} />}
                  <Field name="couponUpfrontOnly" component={Checkbox} label="Apply Coupon to Setup Fee(Upfront) Only" />
                  <Field name="active" component={Checkbox} label="Active" />
                  {courses.data && <Field name="courses" component={TextField} label="Associated courses" options={courses.data} select multiple />}
                  <Field name="proposalRoles" component={TextField} label="Available for selling by Roles" options={Object.keys(acl.roles).map(r=>({label: r, value: r}))} select multiple />
                  <Button variant="contained" color="primary" onClick={submit} disabled={submitting}>
                    Update Product
                  </Button>
                </React.Fragment>
              )}
            </Form>
          </React.Fragment>
        )
      }
    </Query>
  </Paper>
)

const styles = theme => ({
  root: {}
})

export default withStyles(styles)(ProductUpdate)

/*
                  <Field
                    name="wizardId"
                    component={TextField}
                    label="Wizard"
                    options={[{ label: 'None', value: '' }].concat(Array.from((wizards && wizards.data) || []))}
                    select
                  />
*/
