/** @format */

import React from 'react'
import compose from 'recompose/compose'

import AppContext from 'AppContext'

export default WrappedComponent => {
  class WithUserContext extends React.Component {
    render() {
      return <AppContext.Consumer>{context => <WrappedComponent {...this.props} context={context} />}</AppContext.Consumer>
    }
  }
  return compose()(WithUserContext)
}
