/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Query } from 'react-apollo'
import { FormDialog, Field } from 'lib/form'
import { TextField, PhoneNumber } from 'lib/form/fields'
import { required, length, email } from 'lib/form/validators'
import withAppContext from 'lib/withAppContext'
import { Roles } from 'lib/acl'

import { createEmployee, getDataCreationEmployee } from 'api/employees'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

const EmployeeCreate = ({ classes, context: { user }, match }) => (
  <Query query={getDataCreationEmployee}>
    {({ data: { products: { data: products } = {} } = {}, loading }) =>
      !loading && (
        <FormDialog
          title="Create Employee"
          form="EmployeeCreate"
          mutation={[createEmployee, { refetchQueries: ['getEmployees'] }]}
          initialValues={{ accountType: Roles.EMPLOYEE, masterAccountId: user.id }}
          btnSubmitText="Create"
          success="Employee created successfully"
        >
          {({ doc, invalid, submitting }) => (
            <React.Fragment>
              <div className="mt-4" style={{ paddingLeft: 10 }}>
                <p>
                  This is the information that is required to create an employee who can access your ChatFortress system. The{' '}
                  <strong>Required fields are First Name, Last Name, Cell Phone OR Email. </strong>
                  <a className="beacon-help-link" href="#" data-beacon-article-sidebar="/5e591a492c7d3a7e9ae870fe">
                    Get help about this page here
                  </a>
                </p>
              </div>
              <Grid spacing={1} container>
                <Grid xs={6} item>
                  <Field name="firstName" component={TextField} label="First Name" validate={[required()]} />
                </Grid>
                <Grid xs={6} item>
                  <Field name="lastName" component={TextField} label="Last Name" validate={[required()]} />
                </Grid>
              </Grid>
              <Field name="email" component={TextField} label="Employee Email" validate={[email()]} />
              <Field name="phone" component={PhoneNumber} label="Employee Phone Number" />
            </React.Fragment>
          )}
        </FormDialog>
      )
    }
  </Query>
)

const styles = () => ({
  root: {}
})

export default compose(withAppContext, withStyles(styles))(EmployeeCreate)
