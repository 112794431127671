/** @format */

import Validators, { addValidator } from 'redux-form-validators'
import _ from 'lodash'
import Payment from 'payment'

Object.assign(Validators.defaultOptions, {
  allowBlank: true
})

export * from 'redux-form-validators'

export const oneof = addValidator({
  validator(options, value, allValues) {
    let errors = _.filter(Object.keys(options), field => !allValues[field])
    if (errors.length === Object.keys(options).length)
      return {
        id: 'form.errors.oneof',
        defaultMessage: `One of the following fields is required: ${Object.values(options).join(', ')}`
      }
  }
})

export const correctAnswers = addValidator({
  validator(options, value) {
    const { answers = [] } = options
    const correctAnswers = answers.filter(item => item.correct).map(({ value }) => value)
    const incorrectAnswers = answers.filter(item => !item.correct).map(({ value }) => value)
    if (_.intersection(correctAnswers, value).length === 0 || _.intersection(incorrectAnswers, value).length > 0) {
      return {
        id: 'form.errors.correctAnswers',
        defaultMessage: `Incorrect answers`
      }
    }
  }
})

export const phone = addValidator({
  validator(options, value, allValues) {
    const length = options.length || 11
    if (value && value.replace(/\D/g, '').length !== length) {
      return {
        id: 'form.errors.phone',
        defaultMessage: 'Phone number is not valid'
      }
    }
  }
})

export const password = addValidator({
  validator(options, value, allValues) {
    const length = options.length || 8
    if (value && (!/^(?=.*[0-9])(?=.*[a-zA-Z])(.+)$/.test(value) || value.length < length)) {
      return {
        id: 'form.errors.password',
        defaultMessage: 'Password must contain at least one number and one letter and be at least {length, number} characters long',
        values: { length }
      }
    }
  }
})

export const ccnumber = addValidator({
  validator(options, value, allValues) {
    if (!Payment.fns.validateCardNumber(value)) {
      return {
        id: 'form.errors.ccnumber',
        defaultMessage: 'Card number is not valid',
        values: {}
      }
    }
  }
})

export const ccexpiry = addValidator({
  validator(options, value) {
    if (!Payment.fns.validateCardExpiry(value)) {
      return {
        id: 'form.errors.ccexpiry',
        defaultMessage: 'Expiry date is not valid',
        values: {}
      }
    }
  }
})

export const cccvc = addValidator({
  validator(options, value) {
    if (!Payment.fns.validateCardCVC(value)) {
      return {
        id: 'form.errors.cccvc',
        defaultMessage: 'CVC is not valid',
        values: {}
      }
    }
  }
})
