/** @format */

import React from 'react'
import compose from 'recompose/compose'
import { Mutation } from 'react-apollo'
import moment from 'moment'
import CheckboxTable from 'lib/tables/CheckboxTable'
import UserCell from 'lib/tables/UserCell'
import withConfirm from 'lib/withConfirm'
import withAppContext from 'lib/withAppContext'

import { getMonitoringObjects, removeMonitoringObject } from 'api/monitoringObjects'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

const MonitoringObjects = ({ classes, history, match, context, confirm }) => {
  const { acl } = context
  const params = new URLSearchParams(window.location.search.slice(1))
  const filter = params.get('filter')

  return (
    <React.Fragment>
      {filter === 'email' && <h3>Personal Email Monitoring Objects</h3>}
      {filter === 'domain' && <h3>Company Domain Monitoring Objects </h3>}
      {filter === 'vendor' && <h3>Vendor Monitoring Objects</h3>}
      <Mutation mutation={removeMonitoringObject} refetchQueries={['getMonitoringObjects']}>
        {(remove, { loading: loadingRemove }) => (
          <React.Fragment>
            <Typography variant="h6">Your Monitoring Objects</Typography>
            <Typography variant="subtitle1">
              Adding a monitoring object is the item you want ChatFortress to scan the dark web for. This could be for any reference to your
              email or domain name. When you add a domain name we will search all databases for mention of any email address associated to
              your domain name. You don't need a persons permission to add a domain name or email as this is public information.
            </Typography>
            <CheckboxTable
              query={getMonitoringObjects}
              variables={filter ? { type: filter } : {}}
              initialVariables={{ ...(filter ? { type: filter } : {}), sorted: [{ id: 'createdAt', desc: true }] }}
              columns={[
                {
                  Header: 'Object',
                  accessor: 'q',
                  Cell: ({ original: monitoringObject }) => (
                    <div style={{ color: monitoringObject.breachesCnt ? 'red' : 'green' }}>
                      <div>
                        <strong>{monitoringObject.name}</strong>
                      </div>
                      <div>{monitoringObject.email || monitoringObject.domain}</div>
                      <div>{monitoringObject.type}</div>
                    </div>
                  )
                },
                {
                  Header: 'User',
                  accessor: 'user.name',
                  Cell: ({ original: monitoringObject }) => <UserCell user={monitoringObject.user} />
                },
                {
                  Header: 'Active',
                  accessor: 'active',
                  Cell: ({ original: monitoringObject }) => <div>{monitoringObject.active ? 'Yes' : 'No'}</div>
                },
                {
                  Header: 'Breaches',
                  accessor: 'breachesCnt',
                  sortable: true,
                  filterable: false
                },
                {
                  Header: 'Actions',
                  sortable: false,
                  filterable: false,
                  accessor: 'id',
                  Cell: ({ original: monitoringObject }) => {
                    return (
                      <div>
                        <IconButton
                          onClick={() => history.push(`${match.url}/${monitoringObject.id}/update`)}
                          title="Edit Monitoring Object"
                        >
                          <Icon fontSize="small">edit</Icon>
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={confirm(() => remove({ variables: { id: monitoringObject.id } }), {
                            title: 'MonitoringObject Remove',
                            description: 'Are you sure you want to delete this Monitoring Object?'
                          })}
                          title="Remove Monitoring Object"
                          disabled={loadingRemove}
                        >
                          <Icon fontSize="small">delete</Icon>
                        </IconButton>
                      </div>
                    )
                  }
                }
              ]}
              actions={[
                acl.hasAccess('monitoringObjects:add') && (
                  <Button
                    key="add"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      history.push(`${match.url}/add`)
                    }}
                  >
                    <Icon>add</Icon>
                    Add Monitoring Object
                  </Button>
                )
              ].filter(v => !!v)}
              deleteMany={false}
              noDataText={`Please create your first Hacked Dark Web Scan monitoring objects by clicking the "Add Monitoring Object" button.`}
            />
          </React.Fragment>
        )}
      </Mutation>
    </React.Fragment>
  )
}

const styles = theme => ({
  root: {}
})

export default compose(withAppContext, withConfirm, withStyles(styles))(MonitoringObjects)
