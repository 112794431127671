/** @format */

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Experiments from './Experiments'
import ExperimentEdit from './ExperimentEdit'
import ExperimentView from './ExperimentView'

const Module = ({ match }) => (
  <div>
    <Switch>
      <Route path={`${match.path}`} exact={true} component={Experiments} />
      <Route path={`${match.path}/:experimentId/view`} exact={true} component={ExperimentView} />
    </Switch>
    <Route path={`${match.path}/:experimentId/edit`} component={ExperimentEdit} />
  </div>
)

export default Module
